function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

function setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

let cookie = getCookie('lang');

if (cookie === '') {
    let language = window.navigator.userLanguage || window.navigator.language;
    if (language && language.toLowerCase().includes('fr')) {
        cookie = 1;
    } else {
        cookie = 0;
    }
} else {
    cookie = parseInt(cookie, 10);
}

const langs = (state = {
    indexLang: cookie, //0 = english, 1 = french
}, action) => {
    switch (action.type) {
        case `SET_LANG`:
            setCookie('lang', action.payload, 7);
            return {
                ...state,
                indexLang: action.payload,
            };
        default:
            return state
    }
}

export default langs
