import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import SuccessableButton from '../../components/buttons/SuccessableButton';
import { ADRESSEMODIFIER, MODIFIERADRESS } from '../../constant/stringTraduction';
import validateAddress from '../../utils/validateAddress';
import AddressFields from './AddressFields';

let AddressItem = (props) => {
    const handleFormSubmit = (values) => {
        const { item: { _id }, onAddressUpdate } = props
        return onAddressUpdate(_id, values)
    }
    const handleDelete = (e) => {
        e.preventDefault();
        const { item: { _id }, onAddressDelete } = props
        return onAddressDelete(_id)
    }

    const {
        error,
        handleSubmit,
        invalid,
        pristine,
        reset,
        submitSucceeded,
        submitting,
        indexLang,
        item,
        submittingID,
    } = props;

    return (
        <div className='white-background' style={{ padding: '15px' }}>
            <form
                onSubmit={handleSubmit(handleFormSubmit)}
                style={{ flex: '1 1 auto' }}
            >
                <AddressFields
                    autoFocus={false} indexLang={indexLang} />
                <div className="button-container">
                    <SuccessableButton
                        style={{ width: '25%', marginRight: '10px' }}
                        disabled={pristine || invalid}
                        error={error}
                        label={MODIFIERADRESS[indexLang]}
                        reset={reset}
                        submitSucceeded={submitSucceeded}
                        submitting={submitting}
                        successLabel={ADRESSEMODIFIER[indexLang]}
                    />
                    <SuccessableButton
                        error={error}
                        style={{ width: '25%', marginLeft: '10px' }}
                        label={'X'}
                        reset={reset}
                        submitting={item._id === submittingID}
                        onClick={handleDelete}
                    />
                </div>
            </form>
        </div>
    )
}

AddressItem = compose(
    connect((state, { item }) => ({
        form: `address_${item._id}`,
    })),
    reduxForm({
        enableReinitialize: true,
        validate: validateAddress,
        destroyOnUnmount: false,
    }),
)(AddressItem)

export default AddressItem
