import React, { Component } from 'react'
import { connect } from 'react-redux'

const retroactionThanksContainer = (ComposedComponent) => {
    class RetroactionThanksContainer extends Component {
        render() {
            return (
                <ComposedComponent {...this.props} />
            )
        }
    }
    const mapStateToProps = ({
        langs: { indexLang },
        retroaction: { survey, orderId },
    }) => ({
        indexLang,
        survey,
        orderId,
    })
    return connect(mapStateToProps)(RetroactionThanksContainer)
}

export default retroactionThanksContainer
