import { type } from '../actions/cart'

const carts = (state = {
    isFetching: false,
    cart: {
        total: null,
        subTotal: null,
        rabais: null,
        taxes: null,
        item: null,
    },
    isAdded: false,
    isAdding: false,
    error: '',
    errorCoupon: { error: '' },
    submitSucceededCoupon: false,
    submittingCoupon: false,
    slugItemSubmittingCoupon: '',
    typeItemSubmittingCoupon: null,
    idDeletingCoupon: '',
}, action) => {
    switch (action.type) {
        case `REQUEST_${type}`:
            return {
                ...state,
                isFetching: true,
            }
        case `ADD_${type}`:
            return {
                ...state,
                cart: action.cart,
                isAdded: true,
                isAdding: false,
            }
        case `RECEIVE_${type}`:
            return {
                ...state,
                cart: action.cart,
                isFetching: false,
            }
        case `UPDATE_${type}`:
            return {
                ...state,
                cart: action.cart,
                isFetching: false,
            }
        case `DELETE_${type}`:
            return {
                isFetching: false,
                cart: {
                    items: [],
                },
            }
        case `ERROR_${type}`:
            return {
                ...state,
                error: action.error,
                isFetching: false,
                isAdding: false,
            }
        case 'IS_ADDING_CART':
            return {
                ...state,
                isAdded: false,
                isAdding: true,
            }
        case 'RESET_ADDING_ADDED':
            return {
                ...state,
                isAdded: false,
                isAdding: false,
            }
        case 'ADDING_COUPON_CART': {
            return {
                ...state,
                slugItemSubmittingCoupon: action.itemSlug,
                typeItemSubmittingCoupon: action.itemType,
                submittingCoupon: true,
                errorCoupon: { error: '' },
                submitSucceededCoupon: false,
            }
        }
        case 'RECEIVE_CART_COUPON': {
            return {
                ...state,
                cart: action.cart,
                submittingCoupon: false,
                errorCoupon: { error: '' },
                submitSucceededCoupon: true,
            }
        }
        case 'ERROR_CART_COUPON': {
            return {
                ...state,
                errorCoupon: action.error,
                submittingCoupon: false,
                submitSucceededCoupon: false,
            }
        }
        case 'ERROR_RESET_COUPON': {
            return {
                ...state,
                errorCoupon: { error: '' },
            }
        }
        case 'REMOVING_CART_COUPON': {
            return {
                ...state,
                idDeletingCoupon: action.couponId,
            }
        }
        case 'REMOVED_CART_COUPON': {
            return {
                ...state,
                cart: action.cart,
                idDeletingCoupon: '',
            }
        }
        case 'RESET_COUPON_INPUT': {
            return {
                ...state,
                errorCoupon: { error: '' },
                submitSucceededCoupon: false,
            }
        }
        case 'SET_TRIAL_CART': {
            let i = {
                ...state,
                typeId: 0,
            }
            i.cart.item.trial = action.payload;
            return i
            
        }
        default:
            return state
    }
}

export default carts
