export const fetchSendMessage = (values) => {
    return (dispatch) => {
        dispatch({ type: 'IS_SENDING_MESSAGE' });
        return fetch(`/api/faq`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
                dispatch({ type: 'IS_DONE' });
                throw new Error('Network response was not ok.');
            })
            .then((json) => {
                if (json.error) {
                    dispatch({ type: 'FAQ_MSG_ERROR' });
                    return Promise.reject(json.error);
                }
                dispatch({ type: 'SUCCEEDED' });
            })
            .catch((error) => dispatch({ type: 'FAQ_MSG_ERROR' }))
    }
}
