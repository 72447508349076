import Payment from 'payment'

const validateCreditCard = (values) => {
    const errors = {}
    const requiredFields = [
        'number',
        'exp',
        'cvc',
    ]
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = ['*', '*'];
        }
    })
    if (values.number) {
        const type = Payment.fns.cardType(values.number);
        const cards = document.querySelectorAll('[data-brand]');

        [].forEach.call(cards, (element) => {
            if (element.getAttribute('data-brand') === type) {
                element.classList.add('active');
            } else {
                element.classList.remove('active');
            }
        })
    }
    if (values.exp) {
        if (!/^([0][0-9]|[1][0-2])[ ][/][ ][0-9][0-9][0-9][0-9]$/.test(values.exp) && !/^([0][0-9]|[1][0-2])[/][0-9][0-9][0-9][0-9]$/.test(values.exp) && !/^([0][0-9]|[1][0-2])[ ][/][0-9][0-9][0-9][0-9]$/.test(values.exp) && !/^([0][0-9]|[1][0-2])[/][ ][0-9][0-9][0-9][0-9]$/.test(values.exp)){
            errors.exp = ['EX: 12/2020', 'EX: 12/2020'];
        }
    }

    return errors
}

export default validateCreditCard
