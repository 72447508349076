import React from 'react';
import { Col, Row, FormGroup, Label, Input } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import renderTextFieldBlack from '../fields/renderTextFieldBlack';
import couponContainer from '../../containers/coupon/couponContainer';
import SuccessableButton from '../buttons/SuccessableButton';

const validate = (values) => {
    const errors = {}
    const requiredFields = ['slug', 'name', 'description']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = '*';
        }
    })
    return errors
}

const AddCoupon = ({
    handleSubmit,
    handleDurationChange,
    utilisationIllimite,
    handleUtilisationIllimite,
    dateExpiration,
    handleDateExpiration,
    submitSucceeded,
    submitting,
    isFetching,
    pristine,
    invalid,
    errorCoupon,
    resetError,
    handleCouponSubmit,
    illimite,
    expiration,
    isForever,
}) => {
    const reg = new RegExp('^[0-9]+$');
    const handleSubmitCoupon = (values) => {
        handleCouponSubmit(values)
    }
    return (
        <form style={{ borderBottom: 'solid 1px black', paddingBottom: '15px' }} onSubmit={handleSubmit(handleSubmitCoupon)}>
            <h2>Ajouter un coupon</h2>
            <Row style={{ marginTop: '70px' }}>
                <Col md='4'>
                    <Field
                        name="slug"
                        label="Slug/Code"
                        type="text"
                        className="field"
                        fullWidth={true}
                        component={renderTextFieldBlack}
                        color='black'
                    />
                </Col>
                <Col md='4'>
                    <Field
                        name="name"
                        label="Nom"
                        type="text"
                        className="field"
                        fullWidth={true}
                        component={renderTextFieldBlack}
                        color='black'
                    />
                </Col>
                <Col md='4'>
                    <Field
                        name="description"
                        label="Description"
                        type="text"
                        fullWidth={true}
                        className="field"
                        component={renderTextFieldBlack}
                        color='black'
                    />
                </Col>
                <Col md='4'>
                    <Field
                        name="amountOff"
                        label="Montant en rabais (en cennes)"
                        type="number"
                        className="field"
                        component={renderTextFieldBlack}
                        color='black'
                        fullWidth={true}
                        normalize={(val) => {
                            if (!val.match(reg)) {
                                return 0;
                            }
                            return val > 100000 ? 100000 : val;
                        }}
                    />
                </Col>
                <Col md='4'>
                    <Field
                        name="percentOff"
                        label="Pourcentage du rabais"
                        type="number"
                        className="field"
                        max="100"
                        min="0"
                        component={renderTextFieldBlack}
                        color='black'
                        fullWidth={true}
                        normalize={(val) => {
                            if (!val.match(reg)) {
                                return 0;
                            }
                            return val > 100 ? 100 : val;
                        }}
                    />
                </Col>
                <Col md='12' style={{ textAlign: 'left' }}>
                    <FormGroup tag="fieldset">
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" name="isForever" onChange={(e) => handleDurationChange(e.target.checked)} checked={isForever} />{' '}
                                Applicable pour toute la durée de l'abonnement
                            </Label>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup tag="fieldset">
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" onChange={(e) => handleUtilisationIllimite(e.target.checked)} checked={!illimite} />{' '}
                                Limité le nombre d'utilisations
                            </Label>
                        </FormGroup>
                    </FormGroup>
                    {!utilisationIllimite &&
                        <Col md='4'>
                            <Field
                                name="maximumUsage"
                                label="Nombre d'utilisation maximum"
                                type="number"
                                className="field"
                                max="500"
                                min="0"
                                component={renderTextFieldBlack}
                                color='black'
                                fullWidth={true}
                                normalize={(val) => {
                                    if (!val.match(reg)) {
                                        return 0;
                                    }
                                    return val > 100 ? 100 : val;
                                }}
                            />
                        </Col>
                    }
                    <FormGroup tag="fieldset">
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" onChange={(e) => handleDateExpiration(e.target.checked)} checked={expiration} />{' '}
                                Mettre une date d'expiration
                            </Label>
                        </FormGroup>
                    </FormGroup>
                    {dateExpiration &&
                        <Col md='4'>
                            <Field
                                name="expiration"
                                label=""
                                type="date"
                                className="field"
                                component={renderTextFieldBlack}
                                color='black'
                                fullWidth={true}
                            />
                        </Col>
                    }
                </Col>
                <Col lg='12' style={{ width: '100%', textAlign: 'center' }}>
                    {isFetching ?
                        <CircularProgress size={36} style={{ verticalAlign: 'middle', color: '#000000' }} />
                        :
                        <SuccessableButton
                            disabled={pristine || invalid}
                            error={errorCoupon}
                            label='Ajouter le Coupon'
                            reset={resetError}
                            submitSucceeded={submitSucceeded}
                            submitting={submitting}
                            successLabel='Coupon Ajouté'
                        />
                    }
                </Col>
            </Row>
        </form>
    )
}

export default reduxForm({
    form: 'adminCouponAdd',
    validate,
})(couponContainer(AddCoupon))
