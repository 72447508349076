const contests = (state = {
    message: [],
}, action) => {
    switch (action.type) {
        case 'CONCOURS_OK':
            return {
                ...state,
                message: action.payload,
            }
        case 'FAILED_CONCOURS_SEND':
            return {
                ...state,
                message: [],
            }
        default:
            return state
    }
}

export default contests
