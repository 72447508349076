import React, { Component } from 'react';
import { Container } from 'reactstrap';
import userContainer from '../../containers/user/userContainer';
import { reduxForm } from 'redux-form';
import { CONFIRMEDEMAIL, ISBEINGCONFIRMEDEMAIL, CONNEXION } from '../../constant/stringTraduction';
import { confirmEmail } from '../../actions/user';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Button } from '@material-ui/core';
class EmailConfirmation extends Component {
    constructor() {
        super();
        this.signIn = this.signIn.bind(this);
    }
    componentDidMount = () => {
        const { dispatch } = this.props;
        const { userId } = this.props.match.params;
        dispatch(confirmEmail(userId));
    }
    signIn() {
        const { history } = this.props;
        history.push('/user/signin');
    }
    render () {
        const {
            indexLang,
            user,
        } = this.props
        return (
            <Container className="white-background container-top-margin">
                {user.error == null ? 
                    <div>
                        {user.isUpdating ? <LinearProgress style={{ marginBottom:20 }}/> : ''}
                        <div style={{ textAlign:'center', paddingTop:20 }}>
                            <h2>{user.confirmedEmail ? CONFIRMEDEMAIL[indexLang] : ISBEINGCONFIRMEDEMAIL[indexLang]}</h2>
                            {user.confirmedEmail ? 
                                <Button 
                                    style={{
                                        padding: '10px 30px',
                                        margin:'20px',
                                        fontFamily: 'Gotham-Black',
                                        border: 'none',
                                        backgroundColor:'#fff100',
                                    }}
                                    onClick={this.signIn}>
                                    {CONNEXION[indexLang]}
                                </Button> 
                                : 
                                ''
                            }
                        </div>
                    </div>
                    :
                    <div>
                        <h3>{user.error[indexLang]}</h3>
                    </div>
                }
            </Container>
        )
    }
}
  
export default userContainer(reduxForm({
    form: 'email_confirmation',
})(EmailConfirmation))
