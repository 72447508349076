import CircularProgress from '@material-ui/core/CircularProgress';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Container, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import * as addressesActions from '../../actions/addresses';
import { fetchCancelSubscription, fetchResumeSubscription, fetchPauseSubscription } from '../../actions/orders';
import * as userActions from '../../actions/user';
import AddressesForm from '../../components/addresses/AddressesForm';
import UserProfileForm from '../../components/user/UserProfileForm';
import UserReferedForm from '../../components/user/UserReferedForm';
import {
    ADRESSES, ANNULERABO, AUCUNABONNEMENTS, COMMANDES, PROFIL, ABONNEMENTS,
    NUMEROCOMMANDE, AJOUTCODE, NEXTPAYMENT, NEXTSHIPPING, MODIFINFOS,
    HIVER, ETE, NEXTSEASON, RESUME, ENPAUSE, REFERENCETAB, ADDBED, REMOVEBED, GOTOSUPER,
    NOPARTYULTIMATE, NOPARTYULTIMATE2, NOPARTYULTIMATE3, NOPARTYULTIMATE4, REFUNDED,
} from '../../constant/stringTraduction';
import { CREDIT_AMOUNT, DAY_FOR_SHIPPING, SLUG_START, SUPPORT_EMAIL } from '../../constant/otherConst';
import formatPrice from '../../utils/formatPrice';
import imgOrdersHeaderEnglish from '../../assets/my_orders_english.jpg';
import imgOrdersHeaderFrench from '../../assets/my_orders_french.jpg';
import CouponInput from '../coupons/CouponInput';
import { getNextPackDate } from '../../utils/dates';
import ResumSubModal from './ResumSubModal';
import UpgradeModal from './UpgradeModal';
import { getProductPrice } from '../../actions/user';
import UserReferedStats from './UserReferedStats';
import OldPartyPackImage from '../../assets/products/old/party_pack_128.png';
import OldSuperPartyPackImageEnglish from '../../assets/products/old/super_party_pack_english_128.png';
import OldSuperPartyPackImageFrench from '../../assets/products/old/super_party_pack_french_128.png';
import OldUltimatePartyPackImageEnglish from '../../assets/products/old/ultimate_party_pack_english_128.png';
import OldUltimatePartyPackImageFrench from '../../assets/products/old/ultimate_party_pack_french_128.png';
import SuperPartyPackImageEnglish from '../../assets/products/new/super_pack_128x128.png';
import SuperPartyPackImageFrench from '../../assets/products/new/super_pack_FR_128x128.png';
import DiscoveryPackImageEnglish from '../../assets/products/new/discovery_pack_128x128.png';
import DiscoveryPackImageFrench from '../../assets/products/new/discovery_pack_FR_128x128.png';
import PictoBed from '../../assets/products/new/picto_bed.png';
import { getNoMoreOrders, getClosed } from '../../utils/specialEvents';
import AlertCovid from '../home/alertCovid';

class UserProfilePage extends Component {
    constructor() {
        super();
        this.handleCouponUpdated = this.handleCouponUpdated.bind(this);
        this.handleCouponError = this.handleCouponError.bind(this);
        this.state = {
            p_subId: null,
            upgradeModal: false,
            orderToUpgrade: null,
        };
    }
    componentDidMount() {
        if (window.innerWidth <= 768) {
            this.setState({ onMobile: true });
        }
    }

    toggleModal = () => {
        this.props.dispatch({ type: 'CLOSE_ALERT' }); 
    }
    handleCouponUpdated = () => {
        return this.props.dispatch({ type: 'COUPON_SUCCESS' }); 
    }
    handleCouponError = () => {
        return this.props.dispatch({ type: 'COUPON_ERROR' }); 
    }
    resetError = () => {
        return this.props.dispatch(userActions.resetError()) 
    }
    handleUserValuesPW = (values) => {
        return this.props.dispatch(userActions.fetchUpdatePW({ values })) 
    }
    handleUserValues = (values) => {
        return this.props.dispatch(userActions.fetchUpdate({ values }))
    }
    handleAddressAdd = (values) => {
        return this.props.dispatch(addressesActions.fetchAdd({ values }))
    }
    handleAddressUpdate = (itemId, values) => {
        return this.props.dispatch(addressesActions.fetchUpdate(itemId, { values }))
    }
    handleAddressDelete = (itemId) => {
        return this.props.dispatch(addressesActions.fetchDelete(itemId))
    }
    toggle(tab) {
        if (this.props.activeTab !== tab) {
            this.props.dispatch({ type: 'SWITCH_TAB', indexTab: tab })
        }
    }
    // Trial + subscriptions
    getArraySubscription = () => {
        const arraySubs = [];
        if (this.props.orders) {
            this.props.orders.map((order) => {
                if (!order.item.canceled && (!order.paymentId || (order.paymentId && order.item.trial))) {
                    arraySubs.push(order);
                }
                return null;
            });
        }
        return arraySubs;
    };
    // Regular orders
    getArrayOrders = () => {
        const arrayOrders = [];
        if (this.props.orders) {
            this.props.orders.map((order) => {
                if (order.paymentId) {
                    arrayOrders.push(order);
                }
                return null;
            });
        }
        return arrayOrders;
    };
    handleSubscriptionCancel = async (orderId) => {
        this.props.history.push('/retroaction/unsubscribe/' + orderId)
    };
    onConfirm = async () => {
        const { dispatch, indexLang, confirmType, pauseSubscriptionId, resumeSubscriptionId } = this.props;
        await dispatch({ type: 'CLOSE_ALERT' });

        if (confirmType === 0) {
            dispatch(fetchCancelSubscription(this.state.p_subId, indexLang));
        } else if (confirmType === 1) {
            dispatch(fetchPauseSubscription(pauseSubscriptionId));
        } else {
            dispatch(fetchResumeSubscription(resumeSubscriptionId));
        }
    }
    handleSubscriptionPause = async (orderId) => {
        const { dispatch } = this.props;
        await dispatch({ type: 'SET_RESUME_ID', payload: orderId })
        dispatch({ type: 'RESUME_SUB' });

    }
    openUpgradeModal = (order) => {
        this.setState({
            upgradeModal: true,
            orderToUpgrade: order,
        })
        this.props.dispatch(getProductPrice())
    }
    closeUpgradeModal = () => {
        this.setState({
            upgradeModal: false,
            orderToUpgrade: null,
        })
        this.props.dispatch({ type: 'RESET_UPGRADING_SUBSCRIPTION' });
    }

    render() {
        const {
            dispatch,
            isFetching,
            user,
            indexLang,
            activeTab,
            submittingID,
            confirmation,
            modal,
            title,
            text,
            isSendingReference,
            allReferedUsers,
            modal2,
        } = this.props;
        const arraySubs = this.getArraySubscription();

        const nextPayment = () => {
            const { nextDate, indexSaison } = getNextPackDate();
            let date = nextDate.clone();
            if (indexLang) {
                date.locale('fr-ca')
                if (date.format('D') === '1') {
                    return { date: date.format('Do MMMM YYYY'), indexSaison: indexSaison };
                } else {
                    return { date: date.format('D MMMM YYYY'), indexSaison: indexSaison };
                }
            } else {
                date.locale('en-ca')
                return { date: date.format('MMMM Do YYYY'), indexSaison: indexSaison };
            }
        }
        const nextShipping = () => {
            const { nextDate, indexSaison } = getNextPackDate();
            let date = nextDate.clone();
            date.add(DAY_FOR_SHIPPING[indexSaison], 'day');
            if (indexLang) {
                date.locale('fr-ca')
                if (date.format('D') === '1') {
                    return { date: date.format('Do MMMM YYYY'), indexSaison: indexSaison };
                } else {
                    return { date: date.format('D MMMM YYYY'), indexSaison: indexSaison };
                }
            } else {
                date.locale('en-ca')
                return { date: date.format('MMMM Do YYYY'), indexSaison: indexSaison };
            }
        }

        const seasons = [HIVER[indexLang].toLowerCase(), ETE[indexLang].toLowerCase()];

        let referedFriends = 0;
        let subscribedFriend = 0;
        let savings = 0;
        if (user.referedUsersDetails) {
            referedFriends = user.referedUsersDetails.length;
            for (let i = 0; i < user.referedUsersDetails.length; i++) {
                if (user.referedUsersDetails[i].used) {
                    subscribedFriend += 1;
                    savings += CREDIT_AMOUNT;
                }
            }
        }
        let ordersNeedingUpgrade = [];
        for (let i = 0; i < arraySubs.length; i++) {
            const order = arraySubs[i];
            if (order.item.slug[0] !== SLUG_START[0] && order.item.slug[0] !== SLUG_START[1] && order.item.trial === false) {
                const itemType = parseInt(order.item.slug.split('_')[order.item.slug.split('_').length - 1]);
                if (itemType === 0 || itemType === 2) {
                    ordersNeedingUpgrade.push(order);
                }
            }
        }

        return (
            isFetching ? null :
                <Container className="white-background container-top-margin">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={activeTab === '1' ? 'active' : ''}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    this.toggle('1');
                                }}
                            >
                                {PROFIL[indexLang]}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === '2' ? 'active' : ''}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    this.toggle('2');
                                }}
                            >
                                {ADRESSES[indexLang]}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === '3' ? 'active' : ''}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    this.toggle('3');
                                }}
                            >
                                {COMMANDES[indexLang] + ' & ' + ABONNEMENTS[indexLang]}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === '4' ? 'active' : ''}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    this.toggle('4');
                                }}
                            >
                                {REFERENCETAB[indexLang]}
                            </NavLink>
                        </NavItem>

                    </Nav>
                    <TabContent activeTab={activeTab} style={{ marginTop: '10px' }}>
                        <TabPane tabId="1">
                            <UserProfileForm
                                initialValues={user.values}
                                onFormSubmit={this.handleUserValues}
                                onPwChangeSubmit={this.handleUserValuesPW}
                                indexLang={indexLang}
                                user={user}
                                resetError={this.resetError}
                                allReferedUsers={allReferedUsers}
                            />
                        </TabPane>
                        <TabPane tabId='2'>
                            <AddressesForm
                                dispatch={dispatch}
                                onAddressAdd={this.handleAddressAdd}
                                onAddressUpdate={this.handleAddressUpdate}
                                onAddressDelete={this.handleAddressDelete}
                                user={user}
                                indexLang={indexLang}
                                submittingID={submittingID}
                            />
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                <ResumSubModal
                                    indexLang={indexLang}
                                    toggleModal={this.toggleModal}
                                    modal={modal2}
                                    title={['Resume Subscription', 'Réactiver l\'abonnement']}
                                    text={['Are you sure you want to resume this subscription?', 'Êtes-vous sûr de vouloir réactiver cet abonnement?']}
                                    onConfirm={this.onConfirm}
                                />
                                <Col lg='12' className='background-image-header' style={{ backgroundImage: `url(${indexLang ? imgOrdersHeaderFrench : imgOrdersHeaderEnglish})`, backgroundClip: 'padding-box' }}></Col>
                                <AlertCovid indexLang={indexLang}/>
                            </Row>
                            {arraySubs.length > 0 ?
                                <div style={{ color: 'black' }}>
                                    {ordersNeedingUpgrade.length > 0 &&
                                    <Row style={{ border: '3px solid red', margin: '10px 0px', backgroundColor: '#fff100' }}>
                                        <Col lg='12' style={{ fontWeight: 'bold' }}>{NOPARTYULTIMATE[indexLang]}</Col>
                                        <Col lg='12'>{NOPARTYULTIMATE3[indexLang]}</Col>
                                        <Col lg='12'>{NOPARTYULTIMATE2[indexLang]}</Col>
                                        <Col lg='12'>
                                            <ul style={{ marginBottom: 0 }}>
                                                {ordersNeedingUpgrade.map((order, index) => {
                                                    return (<li key={index}>{order.number} - {indexLang ? order.item.nameFR : order.item.nameEN}</li>);
                                                })}
                                            </ul>
                                        </Col>
                                        <Col lg='12'>{NOPARTYULTIMATE4[indexLang]}<a href={'mailto:' + SUPPORT_EMAIL}>{SUPPORT_EMAIL}</a></Col>
                                    </Row>
                                    }
                                    <Row className='desktop-only-lg' style={{ border: '1px solid black', margin: '10px 0px', fontWeight: 'bold' }}>
                                        <Col lg='3'>{NUMEROCOMMANDE[indexLang]}</Col>
                                        <Col lg='2'>Total</Col>
                                        <Col lg='2'>Type</Col>
                                        <Col lg='2'>Description</Col>
                                        {!getNoMoreOrders && <Col lg='3'>Action</Col>}
                                    </Row>
                                    {arraySubs.map((order, index) => {
                                        let itemType, productImage, version;
                                        let needsUpgrading = false;
                                        let upgradeMessage = GOTOSUPER[indexLang];
                                        let refundedTrial = false;
                                        if (order.item.slug[0] === SLUG_START[0] || order.item.slug[0] === SLUG_START[1]) {
                                            version = 1;
                                            let trial = order.item.slug[0] === SLUG_START[1];
                                            if (trial) {
                                                productImage = [DiscoveryPackImageEnglish, DiscoveryPackImageFrench][indexLang];
                                                refundedTrial = Date.parse(order.createdAt) > Date.parse('2019-11-20T23:59:59-05:00');
                                            } else {
                                                itemType = parseInt(order.item.slug.split('_')[order.item.slug.split('_').length - 1]);
                                                productImage = [SuperPartyPackImageEnglish, SuperPartyPackImageFrench][indexLang];
                                                if (itemType === 0) {
                                                    upgradeMessage = ADDBED[indexLang];
                                                } else {
                                                    upgradeMessage = REMOVEBED[indexLang];
                                                }
                                            }
                                        } else {
                                            version = 0;
                                            itemType = parseInt(order.item.slug.split('_')[order.item.slug.split('_').length - 1]);
                                            productImage = [
                                                [OldPartyPackImage, OldSuperPartyPackImageEnglish, OldUltimatePartyPackImageEnglish],
                                                [OldPartyPackImage, OldSuperPartyPackImageFrench, OldUltimatePartyPackImageFrench],
                                            ][indexLang][itemType];
                                            needsUpgrading = (itemType === 0 || itemType === 2) && order.item.trial === false;
                                        }

                                        return (
                                            <Row className='subscription-row' key={index} style={index === arraySubs.length - 1 ? { marginBottom: 0 } : {}}>
                                                <Col lg='3'>
                                                    <Col lg='12'>
                                                        <Link to={'/user/orders/' + order._id}>
                                                            <div className='bold-text'>{order.number ? order.number : order._id}</div>
                                                        </Link>
                                                    </Col>
                                                    {!order.typeId &&
                                                        < Col lg='12' style={{ textAlign: 'center' }}>
                                                            <img src={productImage} className='order-item-image' alt='Order item' />
                                                        </Col>
                                                    }
                                                </Col>
                                                <Col lg='2'>
                                                    <span className='order-line-through-price'>{order.rabais > 0 && formatPrice(order.total + order.rabais, indexLang)}</span><span style={{ color: '#a70000' }}>{order.rabais > 0 && ' (' + ((order.rabais / order.subTotal) * 100).toFixed(2) + '%)'}</span>
                                                    <div className={getClosed() ? 'gray-text' : 'bold-text'}>{formatPrice(order.total, indexLang)}</div>
                                                    {getNoMoreOrders() && order.item.trial && !order.item.canceled && refundedTrial && <div>{REFUNDED[indexLang]}</div>}
                                                </Col>
                                                <Col lg='2'>
                                                    <div className='bold-text'>{indexLang ? order.item.nameFR : order.item.nameEN}</div>
                                                    {!this.state.onMobile && itemType === 1 && version === 1 && <div><img src={PictoBed} alt='Lit/bed' /></div>}
                                                </Col>
                                                <Col lg='2'>
                                                    <div className='bold-text'>{indexLang ? order.item.descriptionFR : order.item.descriptionEN}</div>
                                                </Col>
                                                <Col lg='3'>
                                                    {!getNoMoreOrders() && !order.item.canceled && !order.item.trial && order.item.typeId === 0 && (!order.item.paused) && (needsUpgrading || version === 1) &&
                                                        <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                                                            <Button style={{ fontWeight: 'bold' }} onClick={() => this.openUpgradeModal(order)} className='upgrade-pack-button' size='lg' block>{upgradeMessage}</Button>
                                                        </div>
                                                    }
                                                    {getClosed() || order.item.canceled ? null :
                                                        <Col md='12' style={{ marginBottom: 10, paddingLeft: 0, paddingRight: 0 }}>
                                                            <Link to={'/user/orders/modify/' + order._id} style={{ textDecoration: 'none' }}><Button size="sm" block>{MODIFINFOS[indexLang]}</Button></Link>
                                                        </Col>
                                                    }
                                                    {!getClosed() && order.item.typeId === 0 &&
                                                        <div>
                                                            {!order.item.paused ?
                                                                this.props.removingSubscriptionId === order.item.subId && !order.item.trial ?
                                                                    <Button size="sm" block style={{ textAlign: 'center' }}><CircularProgress color='inherit' size={15} style={{ verticalAlign: 'middle' }} /></Button>
                                                                    :
                                                                    !order.item.trial && <Button size="sm" onClick={() => this.handleSubscriptionCancel(order.item.subId)} block>{ANNULERABO[indexLang]}</Button>
                                                                :
                                                                this.props.resumeSubscriptionId === order._id && !order.item.trial ?
                                                                    <Button size="sm" block style={{ textAlign: 'center' }}><CircularProgress color='inherit' size={15} style={{ verticalAlign: 'middle' }} /></Button>
                                                                    :
                                                                    !order.item.trial && <Button size="sm" onClick={() => this.handleSubscriptionPause(order._id, order.item.paused)} block>{RESUME[indexLang]}</Button>
                                                            }

                                                        </div>
                                                    }
                                                    {getNoMoreOrders() || order.item.canceled || order.item.trial || order.couponAdded || order.item.typeId || order.item.paused ? null :
                                                        <div>
                                                            <Col md='12' style={{ fontWeight: 900, marginTop: 20, paddingLeft: 0, paddingRight: 0 }}>{AJOUTCODE[indexLang]}</Col>
                                                            <Col md='12' style={{ paddingLeft: 0, paddingRight: 0 }}><CouponInput actionError={this.handleCouponError} actionSuccess={this.handleCouponUpdated} itemSlug={order.item.slug} orderId={order._id} couponId={order.coupon} enabled={true} /></Col>
                                                        </div>
                                                    }
                                                </Col>
                                                <Col lg='12' style={{ marginTop: 20 }}>
                                                    {!getNoMoreOrders() && !order.item.trial && !order.item.paused && !order.item.typeId &&
                                                        <div>
                                                            <p>{NEXTSEASON[indexLang]}{seasons[nextPayment().indexSaison]}</p>
                                                            <p>{NEXTPAYMENT[indexLang]} : {nextPayment().date} - {NEXTSHIPPING[indexLang]} : {nextShipping().date}</p>
                                                        </div>
                                                    }
                                                    {!getClosed() && order.item.paused &&
                                                        <div>
                                                            <p>{ENPAUSE[indexLang]}</p>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        );
                                    },
                                    )}
                                </div>
                                : <div style={{ color: 'black' }}>{AUCUNABONNEMENTS[indexLang]}</div>}
                            <UpgradeModal
                                indexLang={indexLang}
                                upgradeModal={this.state.upgradeModal}
                                order={this.state.orderToUpgrade}
                                dispatch={dispatch}
                                orderUpgrading={this.props.orderUpgrading}
                                orderUpgradeSuccess={this.props.orderUpgradeSuccess}
                                orderUpgradeError={this.props.orderUpgradeError}
                                closeUpgradeModal={this.closeUpgradeModal}
                                products={this.props.products}
                            />
                        </TabPane>
                        <TabPane tabId="4">
                            {!getNoMoreOrders() &&
                            <UserReferedForm
                                indexLang={indexLang}
                                user={user}
                                isSendingReference={isSendingReference}
                                title={title}
                                modal={modal}
                                text={text}
                                confirmation={confirmation}
                            />
                            }
                            <UserReferedStats
                                user={user}
                                indexLang={indexLang}
                                referedFriends={referedFriends}
                                subscribedFriend={subscribedFriend}
                                savings={savings}
                            />
                        </TabPane>

                    </TabContent>
                </Container>
        )
    }
}

export default connect(
    ({ user, orders, langs: { indexLang }, boxSubscription, products: { products } }) => ({
        isUpdating: user.isUpdating,
        isFetching: user.isFetching || orders.isFetching ? true : false,
        activeTab: user.activeTab,
        orders: orders.items,
        submittingID: user.submittingID,
        user,
        indexLang,
        products,
        removingSubscriptionId: boxSubscription.removingSubscriptionId,
        text: boxSubscription.text,
        title: boxSubscription.title,
        modal: boxSubscription.modal,
        modal2: boxSubscription.modal2,
        confirmation: boxSubscription.confirmation,
        pauseSubscriptionId: boxSubscription.pauseSubscriptionId,
        resumeSubscriptionId: boxSubscription.resumeSubscriptionId,
        confirmType: boxSubscription.confirmType,
        orderUpgrading: orders.orderUpgrading,
        orderUpgradeSuccess: orders.orderUpgradeSuccess,
        orderUpgradeError: orders.orderUpgradeError,
        isSendingReference: user.isSendingReference,
    }),
)(UserProfilePage)
