import FontIcon from '@material-ui/core/Icon';
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { ABOUTB1B, CONTACTUS, FAQS, PRIVACYPOLICY, SUBSCRIBE, TOS, REFERFRIENDS } from '../../constant/stringTraduction';
import footerContainer from '../../containers/footer/footerContainer';
import './footer1.css';
import { getNoMoreOrders, getClosed } from '../../utils/specialEvents';

const Footer = ({
    indexLang,
}) => 
    <footer>
        <div className="footer-paper">
            <Row>
                <Col sm="4" className="footer-links-left">
                    {!getNoMoreOrders() && <div><Link className="hover-white" to="/box">{SUBSCRIBE[indexLang]}</Link></div>}
                    <div><Link className="hover-white" to="/referral-program">{REFERFRIENDS[indexLang]}</Link></div>
                    {!getClosed() && <div><Link className="hover-white" to="/faq">{CONTACTUS[indexLang]}</Link></div>}
                    <div><a className="hover-white" href="https://canada.beonebreed.com/pages/our-story-notre-histoire">{ABOUTB1B[indexLang]}</a></div>
                </Col>
                <Col sm="4">
                    <div className="footer-social-media">
                        <a className="hover-white" href="https://www.facebook.com/beonebreed/"><FontIcon className="fa fa-facebook-square" /></a>
                        <a className="hover-white" href="https://www.instagram.com/beonebreed/"><FontIcon className="fa fa-instagram" /></a>
                        <a className="hover-white" href="https://www.youtube.com/channel/UCTLy1dhxZoBu-fPyjH2wguA/videos/"><FontIcon className="fa fa-youtube-play" /></a>
                    </div>
                    <div className="footer-brand">
                        <Link className="hover-white" to="/">GoodyPack {indexLang ? ' par ' : 'by '} BeOneBreed {new Date().getFullYear()}</Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div><Link className="hover-white" to="/faq">{FAQS[indexLang]}</Link></div>
                    <div><Link className="hover-white" to="/tos">{TOS[indexLang]}</Link></div>
                    <div><Link className="hover-white" to="/privacy-policy">{PRIVACYPOLICY[indexLang]}</Link></div>
                </Col>
            </Row>
        </div>
    </footer >
  

export default footerContainer(Footer)
