const validateAddress = (values) => {
    const errors = {}
    const requiredFields = ['fullAddress', 'name', 'phone', 'street', 'city', 'state', 'zip']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = ['*', '*'];
        }
    })
    if (values.phone && values.phone.length < 14) {
        errors.phone = ['EX: (555) 555-5555', 'EX: (555) 555-5555'];
    }
    if (values.state && values.state.length < 2) {
        errors.state = ['EX: QC', 'EX: QC'];
    }
    if (values.zip && values.zip.length < 6) {
        errors.zip = ['EX: A1A1A1', 'EX: A1A1A1'];
    }

    return errors
}

export default validateAddress
