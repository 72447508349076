import { type } from '../actions/user';
let tab = window.location.href.split('#').length > 1 && window.location.href.split('#')[1] === 'subscriptions' ? '3' : '1';
const user = (state = {
    isFetching: false,
    isUpdating: false,
    addresses: [],
    roles: [],
    values: {},
    error: null,
    activeTab: tab,
    submittingID: '',
    referedUsers: [],
    referedUsersDetails: [],
    isSendingReference: false,
    allReferedUsers: [],
    subscribedUsers: [],
    confirmedEmail: false,
    notConfirmed: false,
}, action) => {
    switch (action.type) {
        case `ADD_${type}`:
            return {
                ...state,
                isFetching: false,
                ...action.item,
            }
        case `REQUEST_${type}`:
            return {
                ...state,
                isFetching: true,
            }
        case `RECEIVE_${type}`:
            return {
                ...state,
                isFetching: false,
                ...action.item,
            }
        case 'USER_UPDATING': {
            return {
                ...state,
                isUpdating: true,
            }
        }
        case `UPDATE_${type}`:
            return {
                ...state,
                submittingID: '',
                isUpdating: false,
                ...action.item,
            };
        case 'DELETE_ADDRESSE': {
            return {
                ...state,
                submittingID: action.id,
            }
        }
        case `DELETE_${type}`:
            return {
                isFetching: false,
                isUpdating: false,
                addresses: [],
                roles: [],
                values: {},
                error: null,
                activeTab: '1',
                submittingID: '',
            }
        case 'REDIRECT_USER':
            return {
                ...state,
                redirect: action.path,
            }
        case 'ERROR_USER':
            return {
                ...state,
                error: action.error,
                isFetching: false,
            }
        case 'RECOVER_USER':
            return {
                ...state,
                ...action.recovery,
            }
        case 'RESET_USER':
            return {
                ...state,
                ...action.user,
            }
        case 'CONTACT_USER':
            return {
                ...state,
                message: action.values,
            }
        case 'SWITCH_TAB':
            return {
                ...state,
                activeTab: action.indexTab,
            }
        case 'RESET_ERROR_USER': {
            return {
                ...state,
                error: null,
            }
        }
        case 'ADDING_COUPON_ORDER': {
            return {
                ...state,
                error: null,
            }
        }
        case 'ADD_USER_CART': {
            return {
                ...state,
                error: null,
            }
        }
        case 'ERROR_ORDER_COUPON': {
            return {
                ...state,
                error: action.error,
            }
        }
        case 'SET_REFERED_USERS':
            return {
                ...state,
                referedUsers: action.values,
            }
        case 'SET_REFERED_USERS_DETAILS':
            return {
                ...state,
                referedUsersDetails: action.values,
            }
        case 'SET_ALLREFERED_USERS':
            return {
                ...state,
                allReferedUsers: action.values,

            }
        case 'SET_SUBSCRIBED_USERS':
            return {
                ...state,
                subscribedUsers: action.values,

            }
        case 'SUBMIT_REFERERENCE':
            return {
                ...state,
                isSendingReference: true,
            }
        case 'SUBMIT_REFERERENCE_DONE':
            return {
                ...state,
                isSendingReference: false,
            }
        case 'SUBMIT_REFERERENCE_SUCCEEDED':
            return {
                ...state,
                isSendingReference: false,
            }
        case 'CONFIRM_EMAIL_SUCEEDED':
            return {
                ...state,
                confirmedEmail: true,
                isUpdating: false,
            }
        case 'CONFIRM_EMAIL_FAILED':
            return {
                ...state,
                isUpdating: false,
                error: action.values,
            }
        case 'SET_CONFIRMATION_MODAL':
            return {
                ...state,
                notConfirmed: action.values,
            }
        case 'RESEND_EMAIL_SUCCESS':
            return {
                ...state,
                isUpdating: false,
            }
        case 'RESEND_EMAIL_FAILURE':
            return {
                ...state,
                isUpdating: false,
                error: action.values,
            }
        case 'SET_RESEND_EMAIL':
            return {
                ...state,
                notConfirmed: true,
            }
        case 'RESET_CONFIRMATION':
            return {
                ...state,
                notConfirmed: false,
            }
        default:
            return state
    }
}

export default user
