import React, { Component } from 'react'
import { connect } from 'react-redux'

const adminContainer = (ComposedComponent) => {
    class AdminContainer extends Component {
        render() {
            return (
                <ComposedComponent {...this.props} />
            )
        }
    }
    const mapStateToProps = ({
        langs: { indexLang },
        coupons: { coupons },
        user: { activeTab, allReferedUsers },
        admin: { submitSucceeded, submitting, error, cancelForms, count },
        orders: { orders, oldOrder, oldOrdersCount },
    }) => ({
        indexLang,
        coupons,
        activeTab,
        allReferedUsers,
        submitSucceeded,
        submitting,
        error,
        orders,
        cancelForms,
        count,
        oldOrder,
        oldOrdersCount,
    })

    return connect(mapStateToProps)(AdminContainer)
}

export default adminContainer
