import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Col, Row, Button } from 'reactstrap';
import {
    SOUSTOTAL, FREQUENCY, PRODUCT, SHIPPING, COLON,
    NEXTCHARGEOCCUR, CARTTOTAL, NECKSIZE, SUBEXPLANATION, EVERYSIXMONTHS,
    PROMOCODE, CALCULATEDCHECKOUT, RABAIS, TRIALEXPLANATION1,
    TRIALEXPLANATION2, TRIALPACK, RECEIVEPACK,
    FOROTHERPROVINCESSHIPPING, NOTCALCULATED, NECKSIZECAT, MODIFYCART,
    HIVER, ETE, ANDWILLBETHEMED1, ANDWILLBETHEMED2,
    ANDWILLBETHEMED3, TRYINGADDCODEEXIST, CLICKHERE, ADDITIONALCOST, BED,
    UNIQUETRIAL, BEDEXPLAINATION,
} from '../../constant/stringTraduction';
import formatPrice from '../../utils/formatPrice';
import CheckoutButton from './CheckoutButton';
import CouponInput from '../coupons/CouponInput';
import SuperPartyPackImageEnglish from '../../assets/products/new/super_pack_128x128.png';
import SuperPartyPackImageFrench from '../../assets/products/new/super_pack_FR_128x128.png';
import DiscoveryPackImageEnglish from '../../assets/products/new/discovery_pack_128x128.png';
import DiscoveryPackImageFrench from '../../assets/products/new/discovery_pack_FR_128x128.png';
import { Link } from 'react-router-dom';
import { getNextPackDate } from '../../utils/dates';
import { BED_COSTS, SLUG_ANIMAL_POS, SLUG_SIZE_POS } from '../../constant/otherConst';
import { getNoMoreOrders } from '../../utils/specialEvents';

const DrawShipping = ({ checkout, shipping, indexLang, addressId, isFetching, shippingCalculated }) => {
    if (isFetching) {
        return <CircularProgress size={20} />;
    } else {
        return (
            checkout ?
                checkout && (!addressId || (addressId === 'newAddress' && !shippingCalculated)) ? NOTCALCULATED[indexLang] : formatPrice(shipping, indexLang)
                :
                CALCULATEDCHECKOUT[indexLang]
        );
    }
}

const CartSummary = ({ cart, dispatch, history, user, indexLang, checkout, addressId, isFetching, shippingCalculated }) => {
    const productImages = [
        [SuperPartyPackImageEnglish, SuperPartyPackImageFrench],
        [DiscoveryPackImageEnglish, DiscoveryPackImageFrench],
    ];
    
    const boxTypeId = cart.item.trial ? 0 : cart.item.slug.split('_')[cart.item.slug.split('_').length - 1];
    let stdNeckSize = '';
    if (cart.neckSize === 0) {
        if (indexLang === 1) {
            if (cart.standardNeckSize === 'yes') {
                stdNeckSize = 'oui'
            } else {
                stdNeckSize = 'non'
            }
        } else {
            stdNeckSize = cart.standardNeckSize
        }
    }
    const indexAnimal = cart.item.slug[SLUG_ANIMAL_POS];
    const indexSize = cart.item.slug[SLUG_SIZE_POS];
    const bedPrice = parseInt(boxTypeId) !== 1 ? 0 : BED_COSTS[indexAnimal][indexSize];
    const subTotal = cart.subTotal - bedPrice;

    const modifyCart = () => {
        dispatch({ type: 'RESET_BOX', payload: cart.item.trial });
    }

    const nextPayment = () => {
        const { nextDate, indexSaison } = getNextPackDate();
        let date = nextDate.clone();
        if (indexLang === 0) {
            let month = date.format('MMMM');
            let day = date.format('Do');
            return { date: month + ' ' + day, indexSaison: indexSaison };
        } else {
            const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
            let month = date.format('M');
            let day = date.format('D');
            if (day === 1) {
                day = '1er'
            }
            return { date: day + ' ' + months[month - 1], indexSaison: indexSaison };
        }
    }

    const goToOrders = () => {
        dispatch({ type: 'SWITCH_TAB', indexTab: '3' });
        history.push('/user/profile');
    }

    const seasons = [
        [ANDWILLBETHEMED1[0] + ' a ' + HIVER[0].toLowerCase() + ANDWILLBETHEMED2[0], ANDWILLBETHEMED3[1] + 'd\'' + HIVER[1].toLowerCase() + '.'][indexLang],
        [ANDWILLBETHEMED1[0] + ' a ' + ETE[0].toLowerCase() + ANDWILLBETHEMED2[0], ANDWILLBETHEMED3[1] + 'd\'' + ETE[1].toLowerCase() + '.'][indexLang],
    ];

    return (
        <div>
            {!getNoMoreOrders() &&
            <Row style={{ padding: '12px 0px' }} >
                <Col id='cart_left_section' md="8">
                    <Row className='cart-header-row desktop-only'>
                        <Col md="2">{PRODUCT[indexLang]}</Col>
                        <Col md="7">Description</Col>
                        <Col md="3">{FREQUENCY[indexLang]}</Col>
                    </Row>
                    {/* Contenu du cart */}
                    <Row style={{ marginTop: 15 }}>
                        <Col sm="2" className='cart-item-image-container'><img src={productImages[cart.item.trial ? 1 : 0][indexLang]} className='cart-item-image' alt="Cart item" /></Col>
                        <Col sm="7">
                            {cart.item.trial && <b>({TRIALPACK[indexLang]})</b>} {indexLang ? cart.item.nameFR : cart.item.nameEN}<br />
                        Type{COLON[indexLang]}{indexLang ? cart.item.descriptionFR : cart.item.descriptionEN}<br />
                            {!cart.item.trial &&
                        <div>
                            {cart.neckSize !== 0 ?
                                <div>{NECKSIZE[indexLang]}{COLON[indexLang]}{cart.neckSize} cm</div>
                                :
                                <div>{NECKSIZECAT[indexLang]}{COLON[indexLang]}{stdNeckSize}</div>
                            }
                        </div>
                            }
                        </Col>
                        <Col sm="3">{cart.item.trial ? UNIQUETRIAL[indexLang] : EVERYSIXMONTHS[indexLang]}</Col>
                    </Row>
                    <div>
                        {!checkout && bedPrice > 0 &&
                        <Row>
                            <Col md='2' />
                            <Col md='10' className='cart-explaination cart-explaination-blue'>
                                <b>{BEDEXPLAINATION[indexLang]}</b>
                            </Col>
                        </Row>
                        }
                        {!checkout && !cart.item.trial &&
                        <Row>
                            <Col md='2' />
                            <Col md='10' className='cart-explaination'>
                                <b>{NEXTCHARGEOCCUR[indexLang]}{nextPayment().date} {seasons[nextPayment().indexSaison]}</b><br/>
                                {SUBEXPLANATION[indexLang]}
                            </Col>
                        </Row>
                        }
                        {cart.item.trial &&
                        <Row>
                            <Col md='2'></Col>
                            <Col md='10' className='cart-explaination'>
                                {TRIALEXPLANATION1[indexLang]}<b>{TRIALPACK[indexLang].toLowerCase()}</b>{TRIALEXPLANATION2[indexLang]}
                            </Col>
                        </Row>
                        }
                        <Row>
                            <Col md='2' />
                            <Col md='10' className='cart-explaination'>
                                {RECEIVEPACK[indexLang]} {FOROTHERPROVINCESSHIPPING[indexLang]}
                            </Col>
                        </Row>


                    </div>
                </Col>
                <Col md='4'>
                    <Row className='cart-header-row'><Col md='12'>{CARTTOTAL[indexLang]}</Col></Row>
                    <Row style={{ marginTop: 15 }}>
                        <Col xs='6'>{SOUSTOTAL[indexLang]}{COLON[indexLang]}</Col>
                        <Col xs='6' className='total-right-section'>{formatPrice(subTotal, indexLang)}</Col>
                    </Row>
                    {bedPrice > 0 &&
                <Row>
                    <Col xs='8'>{ADDITIONALCOST[indexLang]} ({BED[indexLang].toLowerCase()}){COLON[indexLang]}</Col>
                    <Col xs='4' className='total-right-section'>{formatPrice(bedPrice, indexLang)}</Col>
                </Row>
                    }
                    <Row>
                        <Col xs='4'>{SHIPPING[indexLang]}{COLON[indexLang]}</Col>
                        <Col xs='8' className='total-right-section'>
                            <DrawShipping
                                checkout={checkout}
                                shipping={cart.shipping}
                                indexLang={indexLang}
                                addressId={addressId}
                                isFetching={isFetching}
                                shippingCalculated={shippingCalculated}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='6'>Taxes{COLON[indexLang]}</Col>
                        <Col xs='6' className='total-right-section'>{formatPrice(cart.taxes, indexLang)}</Col>
                    </Row>
                    {cart.rabais > 0 &&
                    <Row>
                        <Col xs='6'>{RABAIS[indexLang]}{COLON[indexLang]}</Col>
                        <Col xs='6' className='total-right-section'>-{formatPrice(cart.rabais, indexLang)}</Col>
                    </Row>
                    }
                    <Row>
                        <Col xs='9'>Total{cart.item.trial ? '' : ' (' + EVERYSIXMONTHS[indexLang] + ')'}{COLON[indexLang]}</Col>
                        <Col xs='3' className='total-right-section'>{formatPrice(cart.total, indexLang)}</Col>
                        {!checkout &&
                        <Col md='12' style={{ marginTop: 12 }}>
                            <CheckoutButton
                                dispatch={dispatch}
                                user={user}
                                indexLang={indexLang}
                                cart={cart}
                            />
                        </Col>
                        }
                        {!checkout &&
                        <Col md="12" style={{ marginTop: 5 }}>
                            <Link to={'/box'}>
                                <Button className='cartButton' onClick={() => modifyCart()} style={{ width: '100%' }}>{MODIFYCART[indexLang]}</Button>
                            </Link>
                        </Col>
                        }
                    </Row>
                    {/* Promo */}
                    {!checkout && !cart.item.trial &&
                    <Row>
                        <Col md='12' style={{ fontWeight: 900, marginTop: 40 }}>{PROMOCODE[indexLang]}</Col>
                        <Col md='12'>{TRYINGADDCODEEXIST[indexLang]} <div onClick={goToOrders} className='link'>{CLICKHERE[indexLang]}</div></Col>
                        <Col md='12'><CouponInput itemSlug={cart.item.slug} enabled={true} /></Col>
                    </Row>
                    }
                </Col>
            </Row>
            }
        </div>
    );
}

export default CartSummary
