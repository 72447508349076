import React from 'react';
import { Alert } from 'reactstrap';
import { getNoMoreOrders } from '../../utils/specialEvents';
import { COVID19_ALERT_1, COVID19_ALERT_2 } from '../../constant/stringTraduction';
import { SUPPORT_EMAIL } from '../../constant/otherConst';

const AlertCovid = ({ indexLang }) => {
    return (
        <div>{getNoMoreOrders() &&
        <Alert style={{ marginBottom: 0, marginTop: 0, backgroundColor: '#ff5341', color: 'white', fontWeight: 'bold', textAlign: 'center' }} color="danger">
            {COVID19_ALERT_1[indexLang]}
            <a href={'mailto:' + SUPPORT_EMAIL}>{SUPPORT_EMAIL}</a>
            {COVID19_ALERT_2[indexLang]}
        </Alert>}
        </div>
    );
}

export default AlertCovid
