import React from 'react';
import { Container } from 'reactstrap';

const VideoCorpo = ({ indexLang }) => {
    return (
        <div className="video_container" id="showcase_video">
            <Container >
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src={'https://www.youtube.com/embed/' + ['uDT92SRNImY', 'uDT92SRNImY'][indexLang] + '?rel=0'} title="Video bureau" allowFullScreen></iframe>
                </div>
            </Container>
        </div>
    )
}

export default VideoCorpo
