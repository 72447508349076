import React from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import { checkboxTheme } from '../../utils/themes';
import { MuiThemeProvider } from '@material-ui/core/styles';

const renderCheckbox = ({
    input,
    label,
    className,
}) =>
    <div>
        <InputLabel style={{ color: 'black' }}>
            {label}
        </InputLabel>
        <MuiThemeProvider theme={checkboxTheme}>
            <Checkbox
                {...input}
                checked={typeof input.value === 'string' ? (input.value === 'true' ? true : false) : input.value}
                value={input.value ? 'true' : 'false'}
                className={className}
                color="primary"
            />
        </MuiThemeProvider>
    </div>

export default renderCheckbox
