import React from 'react'

import usersContainer from '../../containers/users/usersContainer'
import AdminUsersItem from './AdminUsersItem'

const AdminUsersPage = ({
    dispatch,
    users,
    indexLang,
}) => 
    <div className="page">
        <section>
            <div className="users-page-heading">
                <h2>Users</h2>
            </div>
            {users.map((user) => 
                <AdminUsersItem
                    key={user._id}
                    dispatch={dispatch}
                    user={user}
                    form={`userEdit_${user._id}`}
                    indexLang={indexLang}
                />,
            )}
        </section>
    </div>


export default usersContainer(AdminUsersPage)
