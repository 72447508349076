import React, { Component } from 'react'
import { connect } from 'react-redux'

const couponContainer = (ComposedComponent) => {
    class CouponContainer extends Component {
        render () {
            return (
                <ComposedComponent {...this.props} />
            )
        }
    }
    const mapStateToProps = ({
        langs: { indexLang },
        coupons: { coupons },
    }) => ({
        indexLang,
        coupons,
    })

    return connect(mapStateToProps)(CouponContainer)
}

export default couponContainer
