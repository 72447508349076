import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { Button, Col, Container, Row, Input, FormGroup, Label } from 'reactstrap';
import {
    ANNULERABO, SUBCANCEL, UNSUBMESSAGE, UNSUBMESSAGE2, DOGANDBOYS, OTHER,
    ANSWER1, ANSWER2, ANSWER3, PAUSE, SUSPENSIONMSG, SUSPENSIONNOTE,
    ANNULATIONNOTE,
} from '../../constant/stringTraduction';
import retroactionContainer from '../../containers/retroaction/retroactionContainer';
import { fetchSendUnsubscribeRetroaction, resetRetroactionForm } from '../../actions/retroaction';
import { fetchCancelSubscription, fetchPauseSubscription } from '../../actions/orders';
import topImg from '../../assets/Lifestyle_boys_dogs_pano.jpg';
import AlertModal from '../alert/AlertModal';

class RetroactioUnsubscribe extends React.Component {
    constructor() {
        super();
        this.state = {
            comments: '',
            disabled: true,
            answer: '',
            inputValue: '',
            modal2: false,
            showCancel: false,
        }
    }

    componentWillUnmount() {
        this.props.dispatch(resetRetroactionForm());
    }

    setAnswer(e) {
        if (e.target.type === 'text') {
            this.setState({ inputValue: e.target.value });
        } else {
            this.setState({ inputValue: '' });
        }
        this.setState({ answer: e.target.value });
        if (e.target.value) {
            this.setState({ disabled: false });
        } else {
            this.setState({ disabled: true });
        }
    }

    sendForm = async () => {
        const {
            dispatch,
            order,
            indexLang,
        } = this.props;
        await dispatch(fetchSendUnsubscribeRetroaction({
            answer: this.state.answer, order: order,
        }));
        await dispatch(fetchCancelSubscription(order.item.subId, indexLang));
    }

    toggleModal = () => {
        this.props.dispatch({ type: 'CLOSE_ALERT' });
    }

    handleSubscriptionPause = async (orderId) => {
        const { dispatch } = this.props;
        await dispatch({ type: 'SET_PAUSE_ID', payload: orderId })
        dispatch({ type: 'PAUSE_SUB' });
    }

    onConfirm = async () => {
        const { dispatch } = this.props;
        await dispatch({ type: 'CLOSE_ALERT_ONLY' });
        await dispatch(fetchPauseSubscription(this.props.pauseSubscriptionId));
        await dispatch({ type: 'RESET_ALERT' });
        this.setState({ modal2: true });
    }

    back = () => {
        const { dispatch, history } = this.props;
        dispatch({ type: 'SWITCH_TAB', indexTab: '3' });
        history.push('/user/profile');
    }
    toggleModal2 = () => {
        this.back();
        this.props.dispatch({ type: 'CLOSE_ALERT' });
    }

    toggleCancelForm = () => {
        this.setState({ showCancel: true })
    }

    render() {
        const {
            indexLang,
            isSending,
            succeeded,
            order,
            confirmation,
            modal,
            title,
            text,
            pauseSubscriptionId,
        } = this.props;
        return (
            <div>
                <Container className='unsubContainer'>
                    <img className='unsubImg' src={topImg} alt={DOGANDBOYS[indexLang]} />
                </Container>
                <Container className='white-background container-top-margin' style={{ paddingTop: 20, border: 'solid 5px yellow' }}>
                    {!this.state.showCancel ?
                        <Row>
                            <Col lg={12} style={{ marginBottom: '25px' }}>
                                {SUSPENSIONMSG[indexLang]} <br /> {SUSPENSIONNOTE[indexLang]}
                            </Col>
                            <Col md={6} style={{ marginBottom: '25px', paddingLeft: '5%', paddingRight: '5%' }}>
                                <div>
                                    {pauseSubscriptionId ?
                                        <Button size='sm' block style={{ textAlign: 'center' }}><CircularProgress color='inherit' size={15} style={{ verticalAlign: 'middle' }} /></Button>
                                        :
                                        !order.item.trial && <Button block size='sm' onClick={() => this.handleSubscriptionPause(order._id)} >{PAUSE[indexLang]}</Button>

                                    }
                                </div>
                            </Col>
                            <Col md={6} style={{ marginBottom: '25px', paddingLeft: '5%', paddingRight: '5%' }}>
                                <div>
                                    <Button size='sm' block style={{ textAlign: 'center', color: 'white', backgroundColor: 'black' }} onClick={() => this.toggleCancelForm()}>{ANNULERABO[indexLang]}</Button>
                                </div>
                            </Col>
                        </Row>
                        :
                        <div>
                            <Row>
                                <Col lg={12} style={{ marginBottom: '25px' }}>
                                    <p style={{ marginBottom: '20px' }}>{UNSUBMESSAGE[indexLang]}<a style={{ color: 'black' }} href='mailto:info@goodypack.ca'> info@goodypack.ca</a></p>
                                    <p>{UNSUBMESSAGE2[indexLang]}</p>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: 'left' }}>
                                <Col md={12}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="answer" value={ANSWER1[indexLang]} checked={this.state.answer === ANSWER1[indexLang]} onChange={(e) => this.setAnswer(e)} />{' '} {ANSWER1[indexLang]}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="answer" value={ANSWER2[indexLang]} checked={this.state.answer === ANSWER2[indexLang]} onChange={(e) => this.setAnswer(e)} />{' '} {ANSWER2[indexLang]}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="answer" value={ANSWER3[indexLang]} checked={this.state.answer === ANSWER3[indexLang]} onChange={(e) => this.setAnswer(e)} />{' '} {ANSWER3[indexLang]}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup style={{ marginTop: 20 }}>
                                        <Label for='other'><span>{OTHER[indexLang]} :</span></Label>
                                        <Input id='other' className='form-line' type='input' value={this.state.inputValue} onChange={(e) => this.setAnswer(e)} />
                                    </FormGroup>
                                    <Col md={12}>
                                        {ANNULATIONNOTE[indexLang]}
                                    </Col>
                                    <Col md={12} style={{ textAlign: 'center', width: '100%', marginTop: '20px' }}>
                                        <Button disabled={succeeded || this.state.disabled} className='black-button unsub-button' style={{ maxWidth: '350px', border: 'none' }} onClick={this.sendForm}>
                                            {isSending ? <CircularProgress size={24} style={{ verticalAlign: 'middle', color: '#000000' }} /> : succeeded ? SUBCANCEL[indexLang] : ANNULERABO[indexLang]}
                                        </Button>
                                    </Col>
                                </Col>
                            </Row>
                        </div>
                    }
                    <AlertModal
                        indexLang={indexLang}
                        confirmation={confirmation}
                        toggleModal={this.toggleModal}
                        modal={modal}
                        title={title}
                        text={text}
                        onConfirm={this.onConfirm}
                    />
                    <AlertModal
                        indexLang={indexLang}
                        confirmation={false}
                        toggleModal={this.toggleModal2}
                        modal={this.state.modal2}
                        title={['Pause Subscription', 'Suspendre l\'abonnement']}
                        text={['Your subscription is now paused, you can resume it at anytime', 'Votre abonnement est maintenant suspendu, vous pouvez le réactiver à tout moment']}
                        alternateBouttonText={['Back to my orders', 'Retour aux commandes']}
                    />
                </Container >
            </div>
        );
    }
}

export default retroactionContainer(RetroactioUnsubscribe)
