import React, { Component } from 'react';
import cat from '../../../assets/selection/cat.png'
import { CHAT } from '../../../constant/stringTraduction';
export default class CatPicto extends Component {
    render () {
        return (
            <div>
                <img src={cat} alt="chat"></img>
                <div style={{ marginTop: 10 }}><b>{CHAT[this.props.indexLang]}</b></div>
            </div>
        );
    }
}
