import React, { Component } from 'react'
import { connect } from 'react-redux'

import './users.css'

const usersContainer = (ComposedComponent) => {
    class UsersContainer extends Component {
        render () {
            const {
                dispatch,
                isFetching,
                users,
            } = this.props
            const props = {
                dispatch,
                users,
            }
            return (
                isFetching ? null : <ComposedComponent {...props} />
            )
        }
    }
    return connect(
        ({
            users: { isFetching, items },
        }) => ({
            isFetching,
            users: items,
        }),
    )(UsersContainer)
}

export default usersContainer
