import { getNoMoreOrders } from './specialEvents';

const translateStripeError = (error) => {
    switch (error.code) {
        case 'expired_card': {
            return ['The card has expired. Check the expiration date or use a different card.', 'La carte a expiré. Vérifiez la date d\'expiration ou utilisez une autre carte.'];
        }
        case 'incorrect_cvc': {
            return ['Your card cvc is incorrect.', 'Le code de sécurité de votre carte est incorrect.'];
        }
        case 'incorrect_number': {
            return ['Your card number is incorrect.', 'Votre numéro de carte est incorrect.'];
        }
        case 'invalid_cvc': {
            return ['Your card\'s security code is invalid.', 'Le code de sécurité de votre carte est invalide.'];
        }
        case 'invalid_expiry_month': {
            return ['Your card\'s expiration month is invalid.', 'Le mois d\'expiration de votre carte est invalide.'];
        }
        case 'invalid_expiry_year': {
            return ['Your card\'s expiration year is invalid.', 'L\'année d\'expiration de votre carte est invalide.'];
        }
        case 'invalid_number': {
            return ['Your card number is invalid.', 'Votre numéro de carte n\'est pas valide.'];
        }
        default: {
            return ['Oops, there\'s been a mistake.', 'Oops, il y a eu une erreur'];
        }
    }
}

/* global Stripe */
export const getStripeToken = async (card) => {
    if (getNoMoreOrders()) {
        return;
    }
    return new Promise((resolve, reject) => {
        Stripe.card.createToken(card, (status, { error, id }) => {
            if (error) {
                reject(translateStripeError(error))
            } else {
                resolve(id)
            }
        })
    })
}
