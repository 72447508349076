import { Component } from 'react';
import userContainer from '../../containers/user/userContainer';

class UserConfirmation extends Component {
    constructor(props) {
        super();
        props.history.push('/user/cart');
    }

    render() {
        return (
            null
        );
    }

}
export default userContainer(UserConfirmation)
