import { REGULAR_URL } from '../constant/otherConst'

const boxSubscription = (state = {
    indexQuestion: 0,
    indexAnimal: 0,
    neckSize: 10,
    standardNeckSize: null,
    reponses: {},
    subs: [],
    boxBought: [],
    paymentType: -1,
    boiteSaison: [],
    removingSubscriptionId: null,
    trial: false,
    confirmation: true,
    modal: false,
    title: '',
    text: '',
    pauseSubscriptionId: null,
    resumeSubscriptionId: null,
    confirmType: null,
    typeId: 0,
    modal2: false,
    customSlider: REGULAR_URL,
}, action) => {
    switch (action.type) {
        case `SET_COUNTER`:
            return {
                ...state,
                indexQuestion: action.payload,
            }
        case `SET_COUNTER_REPONSE`:
            return {
                ...state,
                reponses: {
                    ...state.reponses,
                    [state.indexQuestion]: action.payload.reponse,
                },
            }
        case 'SET_COUNTER_REPONSE_FIRST':
            return {
                ...state,
                indexAnimal: action.payload.indexAnimal,
                reponses: {
                    ...state.reponses,
                    [state.indexQuestion]: action.payload.reponse,
                },
            }
        case 'SET_BOX_BOUGHT': {
            return {
                ...state,
                boxBought: action.payload,
            }
        }
        case 'SET_SUBSCRIPTIONS': {
            return {
                ...state,
                subs: action.payload,
            }
        }
        case 'ERROR_SUBSCRIPTION': {
            return {
                ...state,
                error: action.payload,
            }
        }
        case 'SET_PAYMENT_TYPE': {
            return {
                ...state,
                paymentType: action.payload,
            }
        }
        case 'LOADING_BOX_BOUGHT_SAISON': {
            return {
                ...state,
                isBoxSaisonLoading: true,
            }
        }
        case 'SET_BOX_BOUGHT_SAISON': {
            return {
                ...state,
                boiteSaison: action.payload,
                isBoxSaisonLoading: false,
            }
        }
        case 'SET_REMOVING_SUB_ID': {
            return {
                ...state,
                removingSubscriptionId: action.payload,
            }
        }
        case 'SET_NECK_SIZE': {
            return {
                ...state,
                reponses: {
                    ...state.reponses,
                    [state.indexQuestion]: action.payload.indexQuestion,
                },
                neckSize: action.payload.neckSize,
                standardNeckSize: action.payload.standardNeckSize,
            }
        }
        case 'SET_TRIAL': {
            return {
                ...state,
                trial: action.payload,
                indexQuestion: 0,
                reponses: {},
                typeId: 0,
            }
        }
        case 'RESET_BOX': {
            return {
                ...state,
                indexQuestion: 0,
                indexAnimal: 0,
                neckSize: 10,
                standardNeckSize: null,
                reponses: {},
                trial: action.payload,
                typeId: 0,
            }
        }
        case 'COUPON_ERROR': {
            return {
                ...state,
                title: ['Adding Promo Code', 'Ajout de coupon'],
                text: ['Your promo code is invalid', 'Votre coupon n\'est pas valide'],
                confirmation: false,
                modal: true,
            }
        }
        case 'COUPON_SUCCESS': {
            return {
                ...state,
                title: ['Adding Promo Code', 'Ajout de coupon'],
                text: ['Your promo code has been applied', 'Votre coupon a été appliqué'],
                confirmation: false,
                modal: true,
            }
        }
        case 'CANCEL_SUB': {
            return {
                ...state,
                title: ['Cancel Subscription', 'Annuler l\'abonnement'],
                text: ['Are you sure you want to cancel this subscription?', 'Êtes-vous sûr de vouloir vous désabonner?'],
                confirmation: true,
                modal: true,
                confirmType: 0,
            }
        }
        case 'PAUSE_SUB': {
            return {
                ...state,
                title: ['Pause Subscription', 'Suspendre l\'abonnement'],
                text: ['Are you sure you want to pause this subscription?', 'Êtes-vous sûr de vouloir suspendre cet abonnement?'],
                confirmation: true,
                modal: true,
                confirmType: 1,
            }
        }
        case 'RESUME_SUB': {
            return {
                ...state,
                title: ['Resume Subscription', 'Réactiver l\'abonnement'],
                text: ['Are you sure you want to resume this subscription?', 'Êtes-vous sûr de vouloir réactiver cet abonnement?'],
                confirmation: true,
                modal2: true,
                confirmType: 2,
            }
        }
        case 'CLOSE_ALERT': {
            return {
                ...state,
                title: '',
                text: '',
                resumeSubscriptionId: null,
                pauseSubscriptionId: null,
                confirmation: false,
                modal: false,
                modal2: false,
            }
        }
        case 'SET_PAUSE_ID': {
            return {
                ...state,
                pauseSubscriptionId: action.payload,
            }
        }
        case 'SET_RESUME_ID': {
            return {
                ...state,
                resumeSubscriptionId: action.payload,
            }
        }
        case 'FAQ_MSG_ERROR': {
            return {
                ...state,
                title: ['Error', 'Erreur'],
                text: ['You must enter a valid email and fill in all the fields', 'Vous devez saisir un courriel valide et remplir tous les champs'],
                confirmation: false,
                modal: true,
            }
        }
        case 'RETURN_MSG_ERROR': {
            return {
                ...state,
                title: ['Error', 'Erreur'],
                text: action.payload,
                confirmation: false,
                modal: true,
            }
        }
        case 'CLOSE_ALERT_ONLY': {
            return {
                ...state,
                modal: false,
            }
        }
        case 'RESET_ALERT': {
            return {
                ...state,
                title: '',
                text: '',
                resumeSubscriptionId: null,
                pauseSubscriptionId: null,
                confirmation: false,
            }
        }
        case 'REFER_USER_ERROR1': {
            return {
                ...state,
                title: ['Error', 'Erreur'],
                text: ['Sorry, you can\'t refer yourself.', 'Désolé, vous ne pouvez pas vous référer vous même.'],
                modal: true,
                confirmation: false,
            }
        }
        case 'REFER_USER_ERROR2': {
            return {
                ...state,
                title: ['Error', 'Erreur'],
                text: ['Sorry, you can\'t refer the same person twice.', 'Désolé, vous ne pouvez pas référer deux fois la même personne.'],
                modal: true,
                confirmation: false,
            }
        }
        case 'REFER_USER_ERROR3': {
            return {
                ...state,
                title: ['Error', 'Erreur'],
                text: ['Sorry, this person has already been refer by somebody else.', 'Désolé, cette personne a déjà été référée par quelqu\'un d\'autre.'],
                modal: true,
                confirmation: false,
            }
        }
        case 'REFER_USER_RESET': {
            return {
                ...state,
                title: '',
                text: '',
                modal: false,
                confirmation: false,
            }
        }
        case 'REFER_USER_SUCCESS': {
            return {
                ...state,
                title: ['Thank you', 'Merci'],
                text: ['Your friend has been refered, thank you.', 'Vous avez bien référé votre ami, merci.'],
                modal: true,
                confirmation: false,
            }
        }
        case 'REFER_USER_ERROR4': {
            return {
                ...state,
                title: ['Error', 'Erreur'],
                text: ['Sorry, this person has already subscribed to Goodypack.', 'Désolé, cette personne a déjà un abonnement au Goodypack.'],
                modal: true,
                confirmation: false,
            }
        }
        case 'SET_CUSTOM_SLIDER': {
            return {
                ...state,
                customSlider: action.payload,
            }
        }
        default:
            return state
    }
}

export default boxSubscription
