import React from 'react';
import { Table, Container, Row, Col } from 'reactstrap';
import './user.css';
import { COURRIEL, SUBSCRIBEDFRIEND, SUBSCRIBED, SAVINGS, REFERENCESTAT, REFERENCESTATSING, SUBSCRIBEDFRIENDSING } from '../../constant/stringTraduction';
import formatPrice from '../../utils/formatPrice';
import { getNoMoreOrders } from '../../utils/specialEvents';

const UserReferedStats = ({
    user,
    indexLang,
    referedFriends,
    subscribedFriend,
    savings,
}) => {

    return (
        <Container style={{ marginTop: '22px' }}>
            <Row>
                <Col md='4' xs='6' className='refered-stats'>
                    <div className='circle-stats'>
                        {referedFriends}
                    </div>
                    <p className='stats-title'>{referedFriends > 1 ? REFERENCESTAT[indexLang] : REFERENCESTATSING[indexLang]}</p>
                </Col>
                <Col md='4' xs='6' className='refered-stats'>
                    <div className='circle-stats'>
                        {subscribedFriend}
                    </div>
                    <p className='stats-title'>{subscribedFriend > 1 ? SUBSCRIBEDFRIEND[indexLang] : SUBSCRIBEDFRIENDSING[indexLang]}</p>
                </Col>
                <Col md={{ size: 4, offset: 0 }} xs={{ size: 6, offset: 3 }} className='refered-stats'>
                    <div className='circle-stats'>
                        {formatPrice(getNoMoreOrders() ? 0 : savings, indexLang)}
                    </div>
                    <p className='stats-title'>{SAVINGS[indexLang]}</p>
                </Col>
            </Row>
            <Table responsive striped style={{ color: 'black', marginTop: 20 }}>
                <thead>
                    <tr>
                        <th>{COURRIEL[indexLang]}</th>
                        <th style={{ textAlign: 'center' }}>{SUBSCRIBED[indexLang]}</th>
                    </tr>
                </thead>
                {user.referedUsersDetails &&
                    <tbody>
                        {user.referedUsersDetails.map((x, index) => {

                            return <tr key={index}>
                                <td>{x.email}</td>
                                <td style={{ textAlign: 'center' }}>
                                    {x.used ? <i style={{ color: 'green' }} className='fa fa-check'></i> : <p><b>&mdash;</b></p>}
                                </td>

                            </tr>;
                        })}
                    </tbody>
                }
            </Table>
        </Container>
    );
}

export default UserReferedStats
