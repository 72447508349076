import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { toggleDrawer } from '../../actions/drawer';
import Lang from '../lang/Lang';
import CartIcon from './CartIcon';
import UserButtons from './UserButtons';
import { LEARNMORE, SUBSCRIBE, WHYGOODYPACK, PREVIOUSPACKS } from '../../constant/stringTraduction';
import { getNoMoreOrders } from '../../utils/specialEvents';

class DrawerNavigation extends Component {
    handleCloseDrawer = () => this.props.dispatch(toggleDrawer())

    render() {
        const {
            cart,
            dispatch,
            firstName,
            history,
            indexLang,
        } = this.props

        return (
            <List>
                <UserButtons
                    dispatch={dispatch}
                    firstName={firstName}
                    history={history}
                    onSelect={this.handleCloseDrawer}
                    indexLang={indexLang}
                />
                <div>
                    {!getNoMoreOrders() &&
                    <Link to="/box">
                        <ListItem
                            onClick={this.handleCloseDrawer}
                            button={true}
                        >
                            {SUBSCRIBE[indexLang]}
                        </ListItem>
                    </Link>
                    }
                    <Link to="/whygp" >
                        <ListItem
                            button={true}
                            onClick={this.handleCloseDrawer}
                        >
                            {WHYGOODYPACK[indexLang]}
                        </ListItem>
                    </Link>
                    <Link to="/pack-examples" >
                        <ListItem
                            button={true}
                            onClick={this.handleCloseDrawer}
                        >
                            {PREVIOUSPACKS[indexLang]}
                        </ListItem>
                    </Link>
                    <Link to="/learn-more" >
                        <ListItem
                            button={true}
                            onClick={this.handleCloseDrawer}
                        >
                            {LEARNMORE[indexLang]}
                        </ListItem>
                    </Link>
                </div>
                {cart.item && !getNoMoreOrders() ?
                    <Link to="/user/cart">
                        <ListItem
                            button={true}
                            onClick={this.handleCloseDrawer}>
                            <CartIcon
                                dispatch={dispatch}
                                isAppBar={false} />
                        </ListItem>
                    </Link>
                    : null
                }
                <Lang menu={true} />
            </List>
        )
    }
}

export default withRouter(DrawerNavigation)
