import { SUPPORT_EMAIL } from './otherConst';

// Traduction du site selon la langue
// Utilisation : import { bienvenue } from './path/stringTraduction';
// Redux tiendra l'indice de la langue qu'on est présentement
// <div>{BIENVENUE[indexLangue]}</div>
// 0 = Anglais, 1 = Francais

// All
export const COLON = [': ', ' : '];
export const SEEMYORDERS = ['See my orders', 'Voir mes commandes'];
export const BIENVENUE = ['Welcome', 'Bienvenue'];
export const ERROR = ['Error', 'Erreur'];
export const SERVER_ERROR = [
    'Server error: email this issue at ' + SUPPORT_EMAIL,
    'Erreur du serveur : veuillez envoyer un courriel par rapport à ce problème à l\'adresse suivante : ' +
        SUPPORT_EMAIL,
];

//Header et SideBar
export const CONNEXION = ['Sign in', 'Se connecter'];
export const INSCRIPTION = ['Sign up', 'S\'inscrire'];
export const DECONNEXION = ['Sign out', 'Se déconnecter'];
export const PROFIL = ['Profile', 'Profil'];
export const BONJOUR = ['Hello', 'Bonjour'];
export const RECHERCHE = ['Search', 'Recherche'];
export const REFERFRIENDS = ['Refer friends', 'Référer des amis'];
export const MYACCOUNT = ['My account', 'Mon compte'];
export const WHATISGOODYPACK = ['How it works', 'En savoir plus'];
export const PREVIOUSPACKS = ['Previous packs', 'Packs précédents'];

//Sign in page
export const ENTRERINFO = ['Enter your information', 'Entrez vos informations'];
export const COURRIEL = ['Email', 'Courriel'];
export const MOTDEPASSE = ['Password', 'Mot de passe'];
export const RECUPERERCOMPTE = [
    'Recover your account!',
    'Récupérer votre compte!',
];
export const PASDECOMPTE = [
    'Don\'t have an account?',
    'Vous n\'avez pas de compte?',
];
export const MOTDEPASSEOUBLIE = [
    'Forgot your password?',
    'Mot de passe oublié?',
];
export const DEJACOMPTE = [
    'Already have an account?',
    'Vous avez déjà un compte?',
];

//Sign up page
export const PRENOM = ['First Name', 'Prénom'];
export const NOMFAMILLE = ['Last Name', 'Nom de famille'];
export const CONFIRMATIONMDP = [
    'Confirm password',
    'Confirmer le mot de passe',
];

//Recovery page
export const RECUPERER = ['Recovery', 'Récupération'];
export const ENTERINFOTORECOVER = [
    'Enter your email to recover your account',
    'Entrez votre email pour récupérer votre compte',
];
export const SUCCESRECOVERY = [
    'An email was sent with instructions',
    'Un courriel a été envoyé avec les instructions',
];
export const TOKENENVOYE = [
    'A password reset token has been sent to',
    'Un jeton de réinitialisation de mot de passe a été envoyé à',
];
export const FERMER = ['Close', 'Fermer'];
export const RESET = ['Reset', 'Réinitialisation'];
export const RESETVERB = ['Reset', 'Réinitialiser'];

// Email confirmation page
export const CONFIRMEDEMAIL = [
    'Your email has been confirmed!',
    'Votre courriel est confirmé!',
];
export const ISBEINGCONFIRMEDEMAIL = [
    'Your email is being confirmed.',
    'Votre email est en cours de confirmation.',
];
export const THANKSSIGNINGUP = [
    'Thanks for signing up!',
    'Merci de vous être inscrit!',
];
export const ONEMORESTEP = ['Just one more step!', 'Une dernière étape!'];
export const EMAILSENT = [
    'We sent you an email confirmation.',
    'Nous vous avons envoyé une confirmation de courriel.',
];
export const PLEASECONFIRM = [
    'Please confirm your email to activate your account.',
    'Veuillez confirmer votre courriel pour activer votre compte.',
];
export const SENDAGAIN = ['Send again', 'Envoyer de nouveau'];
export const NOTRECEIVE = [
    'You did not receive the email?',
    'Vous n\'avez pas reçu le courriel?',
];
export const EMAILHASBEENSENT = [
    'An email has been sent',
    'Un courriel à été envoyé',
];
export const TO = ['to', 'à'];
export const EMAILSENT2 = [
    'We have sent an email confirmation',
    'Nous vous avons envoyé une confirmation de courriel',
];
export const SENDAGAINEXPLICIT = [
    'Send the email again',
    'Envoyer le courriel de nouveau',
];

//Reset page
export const RESETSUCCESS = ['Reset successful', 'Réinitialiser avec succès'];

//UserProfileForm page
export const UTILISATEUR = ['User', 'Utilisateur'];
export const TELEPHONE = ['Phone', 'Téléphone'];
export const METTREAJOURSUSER = ['Update', 'Mettre à jour'];
export const MOTDEPASSEACTUEL = ['Current password', 'Mot de passe actuel'];
export const NOUVEAUMOTDEPASSE = ['New password', 'Nouveau mot de passe'];
export const MODIFIERMOTDEPASSE = [
    'update password',
    'Modifier le mot de passe',
];
export const USERMODIFIER = ['User Updated!', 'Utilisateur Modifié!'];
export const SUPPRIMERCOMPTE = ['Delete Account', 'Supprimer le compte'];
export const VALIDATIONSUPRESSION = [
    'Are you sure you want to delete your account? All your subscriptions will be canceled.',
    'Êtes-vous sûr de vouloir supprimer votre compte? Tous vos abonnements seront annulés.',
];
export const VALIDATIONSUPRESSIONADRESS = [
    'Are you sure you want to delete this address?',
    'Êtes-vous sûr de vouloir supprimer cette adresse?',
];
export const ANNULERABO = ['Cancel subscription', 'Annuler l\'abonnement'];
export const AUCUNABONNEMENTS = [
    'You do not have subscriptions yet',
    'Vous n\'avez pas encore d\'abonnements',
];
export const ANIMAUX = ['Animals', 'Animaux'];
export const NEXTPAYMENT = ['Next payment', 'Prochain paiement'];
export const NEXTSHIPPING = ['Next shipping', 'Prochaine expédition'];
export const MODIFABO = ['Modify subcription', 'Modifer l\'abonnement'];
export const MODIFPACK = ['Modify pack', 'Modifer le pack'];
export const UPGRADEPACK = ['Upgrade pack', 'Passer à un pack'];
export const GOTOSUPER = ['Go to SUPER', 'Convertir à SUPER'];
export const SUPERIEUR = ['', 'supérieur'];
export const UPGRADESUP = [
    'Upgrade to Super Party Pack',
    'Passer au Super Party Pack',
];
export const CANCEL = ['Cancel', 'Annuler'];
export const CONFIRMATIONUPGRADE = [
    'Are you sure you want to upgrade to ',
    'Êtes-vous certain de vouloir passer au ',
];
export const SUPERPARTYPACKUP = ['Super Party Pack', 'Super party pack'];
export const ORDERUPGRADED = [
    'Your subscription has been updated to',
    'Votre abonnement a été modifié pour un',
];
export const ORDERERROR = [
    'An error has occur, please try again',
    'Une erreur s\'est produite s\'il vous plaît essayez de nouveau',
];
export const SUSPENSIONMSG = [
    'Did you know that you can suspend your subscription for as long as you want? Start again when you decide.',
    'Saviez-vous que vous pouvez suspendre votre abonnement pendant aussi longtemps que vous le souhaitez? Reprenez lorsque bon vous semblera.',
];
export const SUSPENSIONNOTE = [
    '* The suspension must be made before the payment to be valid on the next pack.',
    '*La suspension doit être faite avant le paiement pour être valide sur le prochain pack.',
];
export const ANNULATIONNOTE = [
    '* You must unsubscribe before the payment in order not to receive the next pack.',
    '*Vous devez vous désabonner avant le paiement afin de ne pas recevoir le prochain pack.',
];
export const MODIFINFOS = ['Edit informations', 'Modifier les informations'];
export const AJOUTER = ['Add', 'Ajouter'];
export const REFERENCEADDED = ['Friend added', 'Ami ajouté'];
export const REFERAFRIENDTITLE = [
    'It pays to gossip',
    'C\'est payant de partager',
];
export const REFERAFRIENDTEXT = [
    'You can refer friends to GoodyPack by adding their emails below and clicking "REFER"',
    'Vous pouvez référer vos amis vers  GoodyPack en ajoutant leurs adresses e-mail ci-dessous et en cliquant sur «RÉFÉRER»',
];
export const REFERAFRIENDTEXT2 = [
    'Your friend will get a 20% coupon to use on his/her subscription. Each subscribed friend earns you a $3 credit that will be deducted from your next pack. NO LIMITS! Invite as many friends as you like to ',
    'Votre ami recevra un coupon de 20% à utiliser sur son abonnement. Chaque ami inscrit vous donne un crédit de 3$ qui sera déduit de votre prochain pack. PAS DE LIMITE! Invitez autant d\'amis que vous le voulez pour ',
];
export const REFERAFRIENDTEXT3 = [
    'For more details, visit our ',
    'Pour plus de détails, visitez notre section',
];
export const REFERENCETAB = ['Refer Friends', 'Référer des amis'];
export const SUBSCRIBEDFRIEND = ['Subscribed friends', 'Amis abonnés'];
export const SUBSCRIBED = ['Subscribed', 'Abonné'];
export const SAVINGS = ['Accumulated credits', 'Crédits accumulés'];
export const GETFREEPACK = ['get a free pack', 'avoir un pack gratuit'];
export const REFERFRIEND = [
    'Refer friends & you could ',
    'Invitez des amis & vous pourriez ',
];
export const REFER = ['REFER', 'RÉFÉRER'];
export const REFERENCESTAT = ['Sent invites', 'Invitations envoyées'];
export const REFERENCESTATSING = ['Sent invite', 'Invitation envoyée'];
export const SUBSCRIBEDFRIENDSING = ['Subscribed friend', 'Ami abonné'];
export const NEW = ['NEW', 'NOUVEAU'];
export const ADDBED = ['Add bed', 'Ajouter un lit'];
export const REMOVEBED = ['Remove bed', 'Enlever le lit'];
export const NOPARTYULTIMATE = [
    'Warning: Party and Ultimate subscriptions will be canceled on Octobre 15 unless they are converted to Super.',
    'Avertissement : Les abonnements aux packs Party et Ultime qui ne seront pas convertis en packs Super avant le 15 octobre seront annulés.',
];
export const NOPARTYULTIMATE2 = [
    'List of orders to convert: ',
    'Liste de commandes à convertir : ',
];
export const NOPARTYULTIMATE3 = [
    'To convert an order, simply click on GO TO SUPER next to the order you wish to convert.',
    'Pour convertir une commande, il suffit de cliquer sur Convertir à SUPER.',
];
export const NOPARTYULTIMATE4 = [
    'If you have any questions, make sure to contact us at ',
    'Si vous avez des questions, n\'hésitez pas à nous contacter à l\'adresse suivante : ',
];
export const UPGRADETO = ['Upgrade to ', 'Passer au '];
export const REFUNDED = ['Refunded', 'Remboursé'];

//AddressesForm page
export const ADRESSES = ['Addresses', 'Adresses'];
export const NOMADRESSE = ['Addresse Name', 'Nom d\'adresse'];
export const VILLE = ['City', 'Ville'];
export const PROVINCE = ['Province', 'Province'];
export const CODEPOSTAL = ['Postal Code', 'Code Postal'];
export const MODIFIERADRESS = ['UPDATE ADDRESS', 'MODIFIER L\'ADRESSE'];
export const ADRESSEMODIFIER = ['Address Updated!', 'Adresse Mise À jour!'];
export const AJOUTERADRESSE = ['New address', 'Nouvelle adresse'];
export const ADRESSEAJOUTER = ['Address Added!', 'Adresse ajoutée'];
export const EXAMPLEADDRESSNAME = ['Ex: Home', 'Ex: Maison'];
export const ADDRESSCHANGENOTE = [
    'To change the address of an active order, please make the change in the Orders & Subscriptions tab',
    'Pour changer l\'adresse d\'une commande active, veuillez faire la modification dans l\'onglet Commandes & Abonnements',
];
export const TAB = ['tab', 'onglet'];
export const COMMANDE2 = ['Orders & Subscriptions', 'Commandes & Abonnements'];

//OrderList page
export const COMMANDES = ['Orders', 'Commandes'];
export const PASDECOMMANDE = [
    'You do not have any orders yet',
    'Vous n\'avez pas encore de commande',
];
export const AJOUTCODE = ['Add promo code', 'Ajouter un code promo'];

//OrderItem
export const COMMANDEPLACEE = ['Order Placed', 'Commande placée le'];
export const LIVREEA = ['Ship To', 'Livrée à'];
export const NUMEROCOMMANDE = ['Order #', 'Commande #'];

//Validation traduction section
export const REQUIS = ['Required', 'Requis'];
export const COURRIELINVALID = [
    'Invalid email address',
    'Adresse courriel invalide',
];

//BoxSubscription page
export const CHAT = ['Cat', 'Chat'];
export const CHIEN = ['Dog', 'Chien'];
export const HIVER = ['Winter', 'Hiver'];
export const ETE = ['Summer', 'Été'];
export const SUIVANT = ['Next', 'Suivant'];
export const PETIT = ['Small', 'Petit'];
export const MEDIUM = ['Medium', 'Médium'];
export const LARGE = ['Large', 'Large'];
export const SAISONS = [HIVER, ETE];
export const TYPEANIMAL = [CHIEN, CHAT];
export const GROSSEURANIMAL = [PETIT, MEDIUM, LARGE];
export const TAILLE = ['Size', 'Taille'];
export const SAISON = ['Season', 'Saison'];
export const PRIXPARBOITE = ['Price/box', 'Prix/boîte'];
export const TEXTECHIENSMALL = [
    'A lot in a little',
    'Un petit qui en a de dans',
];
export const TEXTECHIENMEDIUM = ['All purpose', 'Passe-partout'];
export const TEXTECHIENLARGE = ['Clumsy & colossal', 'Maladroit & colossal'];
export const TEXTECHATSMALL = [
    'A lot in a little',
    'Un petit qui en a de dans',
];
export const TEXTECHATMEDIUM = ['All purpose', 'Passe-partout'];
export const SELECTIONSUPERPARTYPACK = [
    'Super party pack selection',
    'Selection du super party pack',
];
export const QUESTION1 = ['Who\'s the party animal?', 'Qui fera la fête?'];
export const QUESTION2 = ['Select pet size?', 'Choisissez sa taille'];
export const QUESTION3_1 = [
    'How much does your pet’s neck measure?',
    'Combien mesure le cou de votre animal?',
];
export const QUESTION3_2 = [
    'Please be sure to select the correct circumference ',
    'Veuillez sélectionner la bonne circonférence ',
];
export const QUESTION3_3 = [' in centimeters', 'en centimètres'];
export const QUESTIONFINAL = [
    'Choose the pack you want to receive.',
    'Choisissez le pack que vous voulez recevoir.',
];
export const YESPLEASE = ['SUBSCRIBE', 'M\'ABONNER'];
export const NOTHANKS = ['NO THANKS', 'NON MERCI'];
export const STILLNOTCONV = [
    'Still not convinced? Try out the GoodyPack once* without subscribing.',
    'Toujours frileux? Faites l\'essai unique* du GoodyPack sans vous abonner.',
];
export const ASTERIXPOPUP = [
    '*Unique offer for new customers only. Can not be combined with any other offer.',
    '*Offre unique pour nouveaux clients seulement. Ne peut être combiné à aucune autre offre.',
];
export const SIZEDOESNTINFLUENCE = [
    'The size doesn\'t influence the price of the pack.',
    'La taille n\'influence pas le prix du pack.',
];
export const WANTTRYTRIAL1 = ['Scared of commitment?', 'Peur de l\'engagement?'];
export const WANTTRYTRIAL2 = [
    'No stress! Subscriptions can be canceled easily and quickly.',
    'Soyez sans crainte ! Les abonnements peuvent être annulés facilement et rapidement.',
];
export const CHANGETRIALTOSUB = [
    'You like FREE gifts? Subscribe instead and let us spoil your pet!',
    'Vous aimez les cadeaux GRATUITS? Abonnez-vous à la place et laissez gâter votre animal!',
];
export const CHANGEDTOSUB = [
    'You just changed to subscription.',
    'Vous venez de changer à un abonnement.',
];
export const YES = ['Yes', 'Oui'];
export const NO = ['No', 'Non'];
export const QUESTION3B_1 = [
    'Does your cat’s neck measure between 17cm and 27cm (standard size)?',
    'Est-ce que le cou de votre chat mesure entre 17cm et 27cm (grandeur standard)?',
];
export const TRYOUT = [
    'Try out without subscribing!',
    'Essayer sans s\'abonner!',
];
export const SEEPREVIOUSPACK = [
    'See previous season packs',
    'Voir les packs de la saison précédente',
];
export const TRYOUT_RIGHT = [
    'Try it once before subscribing',
    'Essayez-le une fois avant de vous abonner',
];
export const BEDEXPLAINATIONCAT1 = [
    'Add a bed to your SUPER pack for $19.99',
    'Ajoutez un lit à votre SUPER pack pour 19,99$',
];
export const BEDEXPLAINATIONCAT2 = [
    'Save over 40% on a cat bed',
    'Économisez plus de 40% sur un lit pour chat',
];
export const BEDEXPLAINATIONDOGSM1 = [
    'Add a bed to your SUPER pack for $29.99',
    'Ajoutez un lit à votre SUPER pack pour 29,99$',
];
export const BEDEXPLAINATIONDOGSM2 = [
    'Save over 40% on a dog bed',
    'Économisez plus de 40% sur un lit pour chien',
];
export const BEDEXPLAINATIONDOGL1 = [
    'Add a bed to your SUPER pack for $39.99',
    'Ajoutez un lit à votre SUPER pack pour 39,99$',
];
export const BEDEXPLAINATIONDOGL2 = [
    'Save over 40% on a large dog bed',
    'Économisez plus de 40% sur un grand lit pour chien',
];
export const SMALL_DOG_NOTICE = [
    'GoodyPack is not suggested for dogs under 10 lbs.',
    'GoodyPack n\'est pas recommandé pour les chiens de moins de 10 lbs.',
];
export const TRYOUTSHORT = ['Try out!', 'Essayer!'];

// Sign up (modal)
export const SIGNUP = ['Sign up!', 'S\'inscrire!'];
export const SIGNUP10OFF1 = [
    'Sign up to our newsletter and get',
    'Inscrivez-vous à notre infolettre et obtenez',
];
export const SIGNUP10OFF2 = ['15% OFF', '15% de rabais'];
export const SIGNUP10OFF3 = ['your next pack!', 'sur votre prochain pack!'];
export const NOTHANKSPOPUP = ['No thanks', 'Non merci'];

//Cart page
export const PANIER = ['Cart', 'Panier'];
export const PANIERVIDE = [
    'Your cart is empty at the moment',
    'Votre panier est vide pour le moment',
];
export const SOUSTOTAL = ['Subtotal', 'Sous-total'];
export const PROCEDERAUPAIEMENT = ['Checkout', 'Procéder au paiement'];
export const ABONNEMENTS = ['Subscriptions', 'Abonnements'];
export const ACHATIMMEDIAT = ['Immediate purchase', 'Achat immédiat'];
export const RESUMEABONNEMENTS = [
    'Subscription summary',
    'Résumé de l\'abonnement',
];
export const QUANTITY = ['Quantity', 'Quantité'];
export const PRICE = ['Price', 'Prix'];
export const PRODUCT = ['Product', 'Produit'];
export const SHIPPING = ['Shipping', 'Livraison'];
export const CONTINUESHOPPING = ['Continue shopping', 'Poursuivre vos achats'];
export const CARTTOTAL = ['Order\'s total', 'Total de la commande'];
export const NECKSIZE = ['Neck size', 'Grandeur de cou'];
export const SUBEXPLANATION = [
    'You will receive 2 packs/year (Decembre and June). The amount will be charged to your credit card 7 days before each pack is shipped. Don’t worry, we’ll send you a reminder email before we charge your card. Contact us if you want to unsubscribe.',
    'Vous recevrez 2 packs/an (décembre et juin). Le montant sera chargé sur votre carte de crédit 7 jours avant que chaque pack soit expédié. Pas de stress, on vous envoie un courriel de rappel avant de vous charger. Contactez-nous si vous souhaitez vous désabonner.',
];
export const EVERYSIXMONTHS = ['Every 6 months', 'À tous les 6 mois'];
export const PROMOCODE = ['Promo code', 'Code promo'];
export const CALCULATEDCHECKOUT = [
    'Calculated at checkout',
    'Calculé au paiement',
];
export const ESTIMATED = ['estimated', 'estimé'];
export const ONLYONEPACK = ['only one pack', 'un seul pack'];
export const TRIALEXPLANATION1 = ['The ', 'Le '];
export const TRIALPACK = ['Discovery Pack', 'Pack Découverte'];
export const TRIALEXPLANATION2 = [
    ' allows you to test the GoodyPack once without commitment. The amount will be charged to your credit card the day of the purchase. To receive more packs, you will need to subscribe.',
    ' vous permet de tester le GoodyPack une fois sans engagement. Le montant sera chargé sur votre carte de crédit au moment de l\'achat. Pour recevoir plus de packs, vous devrez vous abonner.',
];
export const FOROTHERPROVINCESSHIPPING = [
    'For other provinces, shipping will be calculated at checkout.',
    'Pour les autres provinces, les frais de livraison seront calculés au paiement.',
];
export const NEXTCHARGEOCCUR = [
    'The next payment will occur on ',
    'Le prochain paiement aura lieu le ',
];
export const NECKSIZECAT = ['Standard neck size', 'Grandeur de cou standard'];
export const CYBERMONDAYEXC = [
    '* Cyber monday offer excluded',
    '* Exclu l\'offre du Cyber monday',
];
export const ANDWILLBETHEMED1 = [' and will be for ', ''];
export const ANDWILLBETHEMED2 = [' themed pack.', ''];
export const ANDWILLBETHEMED3 = ['', ' et sera pour un pack '];
export const NEXTSEASON = ['Next season: ', 'Prochaine saison : '];
export const TRYINGADDCODEEXIST = [
    'Trying to apply a code to an existing subscription?',
    'Vous essayez d\'appliquer un code sur un abonnement existant?',
];
export const CLICKHERE = ['Click here!', 'Cliquez ici!'];
export const ADDITIONALCOST = ['Additional fees', 'Frais additionels'];
export const BED = ['Bed', 'Lit'];
export const UNIQUETRIAL = ['Unique trial', 'Essai unique'];
export const BEDEXPLAINATION = [
    'The bed extra will only be valid and billed for the current pack. For the next pack, you will need to select the option to add bed again to receive an extra bed in your next SUPER pack.',
    'L’ajout de lit ne sera valide et facturé que pour le pack courant seulement. Pour le prochain pack, vous devrez sélectionner l’option d’ajout de lit à nouveau afin de recevoir un lit additionnel à votre SUPER pack.',
];

//Checkout page
export const PAIEMENT = ['Checkout', 'Paiement'];
export const NUMEROCARTE = ['Card Number', 'Numéro de carte'];
export const EXPIRATIONCARTE = ['Expiration MM / YYYY', 'Expiration MM / AAAA'];
export const CVC = ['Card CVC', 'CVC de la carte'];
export const PLACERCOMMANDE = ['Place Order', 'Placer la commande'];
export const MODIFYCART = ['Modify order', 'Modifier ma commande'];
export const SETTRIALTOSUB = [
    'Switch to subscription',
    'Transformer en abonnement',
];

//OrderDetail page
export const COMMANDE = ['Order', 'Commande'];
export const DETAILSCOMMANDE = ['Order Detail', 'Détails de la commande'];
export const REMERCIEMENTCOMMANDE = [
    ', thank you for your order',
    ', Nous vous remercions de votre commande',
];
export const ADRESSE = ['Address', 'Adresse'];
export const RESUMECOMMANDE = ['Order Summary', 'Résumé de la commande'];
export const ACTIVE = ['Active', 'Actif'];
export const ANNULER = ['Canceled', 'Annulé'];
export const COMMANDEINTROUVABLE = [
    'Order not found...',
    'Commande introuvable...',
];
export const STATUS = ['Status', 'État'];
export const FREQUENCY = ['Frequency', 'Fréquence'];
export const MONTHS = ['months', 'mois'];
export const ONCE = ['Once', 'Une fois'];
export const FREETOY = ['With free toy', 'Avec jouet gratuit'];
export const PAUSE = ['Pause subscription', 'Suspendre l\'abonnement'];
export const RESUME = ['Resume subscription', 'Reprendre l\'abonnement'];
export const ENPAUSE = ['Paused subscirption', 'Abonnement en pause'];

//Home page
export const STARTTHEPARTY = ['Start the PARTY', 'Commencer le PARTY'];
export const FETEJAMAISTERMINEE = [
    'THE PARTY IS NEVER OVER!',
    'LA FÊTE N\'EST JAMAIS TERMINÉE!',
];
export const COMPTEAREBOURS = [' PACK ORDERS END IN', ' SE TERMINE DANS'];
export const JOURS = ['Days', 'Jours'];
export const HEURES = ['Hours', 'Heures'];
export const MINUTES = ['Minutes', 'Minutes'];
export const SECONDES = ['Seconds', 'Secondes'];
export const COMMENTCAFONCTIONNE1 = ['How ', 'Comment '];
export const COMMENTCAFONCTIONNE2 = ['GoodyPack ', 'GoodyPack '];
export const COMMENTCAFONCTIONNE3 = ['works?', 'fonctionne?'];
export const PERSONNALISEPLAN = [
    'You will receive 2 packs/year, in the winter (Decembre) and summer (June).',
    'Vous recevrez 2 packs/an, à l\'hiver (décembre) et à l\'été (juin).',
];
export const PERSONNALISEPLAN2 = [
    'You can unsubscribe easily and quickly.',
    'Vous pouvez vous désabonner facilement et rapidement.',
];
export const ABONNEMENTDEBUTEA = [
    'Packs are shipped in December and June.',
    'Les packs sont envoyés en décembre et en juin.',
];
export const ABONNEMENTDEBUTEA2 = [
    '(See subscription calendar)',
    '(Voir calendrier des abonnements)',
];
export const RECEVEZCHAQUESAISON = [
    'Treat your pet to BeOneBreed products designed in Quebec, fun freebies and more for the happiness and wellbeing of your pet.',
    'Offrez à votre animal des produits BeOneBreed créés au Québec, des gratuités amusantes et bien plus pour le bonheur et le bien-être de votre animal.',
];
export const WHATSINABPACK = [
    'What\'s in a GoodyPack',
    'Ce qu\'il y a dans un GoodyPack',
];
export const LEARNMORE = ['Learn more', 'En apprendre plus'];
export const SUPERPARTYPACK = ['SUPER Party Pack', 'SUPER Pack de fête'];
export const CHOOSEPACK = [
    'Choose from 2 pawesome packs that your pet will love.',
    'Choisissez parmi 2 packs que votre animal va adorer.',
];
export const CATTYPING = [
    'cat typing on keyboard',
    'chat qui tape sur un clavier',
];
export const BOXDROP = ['box dropping from the sky', 'boîte qui tombe du ciel'];
export const PARTYBOX = ['party box', 'boîte de party'];
export const CHOOSEMYPACK = ['Select my pack', 'Choisir mon pack'];
export const RECEIVEPACK = [
    'Your GoodyPack is shipped at no extra cost in Quebec and Ontario.',
    'Votre GoodyPack est expédié sans frais supplémentaires au Québec et en Ontario.',
];
export const PARTYSTARTED = [
    'Let\'s get this party started!',
    'Que la fête commence!',
];
export const CALENDARSEASONS = [
    '2 seasons calendar',
    'calendrier des 2 saisons',
];
export const DESCRIPTIONPACK1 = [
    'FUN, FUN & more FUN. Get loads of toys for your furry buddy.',
    '',
];
export const DESCRIPTIONPACK2 = ['', ''];
export const DESCRIPTIONPACK3 = ['', ''];
export const WHATSINABPACK1 = ['What\'s in a', 'Ce qu\'il y a dans un'];
export const WHATSINABPACK2 = ['GoodyPack', 'GoodyPack'];
export const YELLOWCIRCLEWITH = [
    'Yellow circle with particles next to it',
    'Cercle jaune avec particules',
];
export const GOSUBSCRIBE = ['Go! Subscribe!', 'Go! Je m\'abonne!'];
export const PREVIOUSCONTENT = [
    'Previous pack content',
    'Contenu du pack précédent',
];
export const GOODYPACKVIDEO = [
    'GoodyPack promotional video',
    'Vidéo promotionnelle de GoodyPack',
];
export const PACKEXAMPLE = ['Pack examples', 'Exemples de pack'];
export const COVID19_ALERT_1 = ['We are sorry to announce that GoodyPack has been shut down. Contact us at ', 'Nous sommes désolés d\'annoncer que GoodyPack a été fermé. Contactez-nous à '];
export const COVID19_ALERT_2 = [' for any questions.', ' pour toutes questions.'];

// What's inside
export const WHATSINSIDETITLE = ['Inside the', 'À l\'intérieur du'];
export const WHATSINSIDESUBTITLE = [
    'Fun & trendy themes',
    'Thèmes amusants & tendance',
];
export const WHATSINSIDEDESCRIPTION = [
    'Receive more than just toys and treats each season! Spoil your cat or dog with GoodyPack and save on BeOneBreed top quality designs.',
    'Recevez bien plus que des jouets et des gâteries chaque 6 mois! Gâtez votre chat ou votre chien avec GoodyPack et économisez sur les designs de top qualité signés BeOneBreed.',
];
export const SEEPACKS = ['See the packs', 'Voir les packs'];
// LearnMore
export const PACKSNEAKPEAK = [' pack sneak peek', 'Aperçu des packs '];

//Footer
export const SUBSCRIBE = ['Subscribe', 'S\'abonner'];
export const CONTACTUS = ['Contact us', 'Nous contacter'];
export const ABOUTB1B = ['About B1B', 'À propos de B1B'];
export const FAQS = ['FAQs', 'FAQs'];
export const TOS = ['Terms of service', 'Conditions d\'utilisation'];
export const PRIVACYPOLICY = ['Privacy policy', 'Politique de confidentialité'];

//OrderAdd
export const APPLIQUER = ['Apply', 'Appliquer'];
export const POURCENTAGECOUPONFOREVER = [
    '% off each pack : ',
    '% de rabais à chaque pack : ',
];
export const POURCENTAGECOUPONONCE = [
    '% off first pack : ',
    '% de rabais au permier pack : ',
];
export const AMMOUNTCOUPONFOREVER = [
    '$ off each pack : ',
    '$ de rabais à chaque pack : ',
];
export const AMMOUNTCOUPONONCE = [
    '$ off first pack : ',
    '$ de rabais au permier pack : ',
];
export const AMMOUNTCOUPONONCEACHAT = [
    '$ off on packs purchased now : ',
    '$ de rabais sur les packs achetées maintenant : ',
];
export const PERCENTCOUPONONCEACHAT = [
    '% off on packs purchased now : ',
    '% de rabais sur les packs achetées maintenant : ',
];
export const RABAIS = ['Discount', 'Rabais'];
export const NOTCALCULATED = ['Not calculated yet', 'Pas encore calculé'];
export const CALCULATEDSHIPPING = [
    'Shipping calculated',
    'Frais de livraison calculés',
];
export const CALCULATESHIPPING = [
    'Calculate shipping',
    'Calculer frais de livraison',
];
export const VALIDATEADD = ['Validate address', 'Valider l\'adresse'];

//LearnMore
export const GETWINTERPACK = [
    'To get the Winter Pack, subscribe before November 20th.',
    'Pour obtenir le pack d\'hiver, abonnez-vous avant le 20 novembre.',
];
export const GETSUMMERPACK = [
    'To get the Summer Pack, subscribe before May 20th.',
    'Pour obtenir le pack d\'été, abonnez-vous avant le 20 mai.',
];
export const RECEPTIONDATEVARY = [
    '*Reception dates of the packs may vary according to your location.',
    '*Les dates de réception des packs peuvent varier selon votre emplacement.',
];
export const SUBSCRIPTIONCAL = [
    'Subscription calendar',
    'Calendrier des abonnements',
];
export const STARTINGAT = ['Starting at', 'À partir de'];
export const MOREINFOABOUTPACK = [
    'More information about this pack',
    'Plus d\'information pour ce pack',
];
export const GOBACK = ['Go back', 'Précédent'];
export const SEEABOUTCATS = ['See about cats', 'Voir à propos du chat'];
export const SEEABOUTDOGS = ['See about dogs', 'Voir à propos du chien'];
export const CONTENTMAYVARY = [
    '*Pack contents may vary.',
    '*Le contenu des packs peut varier.',
];
export const PACKSUPERPARTYEXPLAIN = [
    'Each season, treat your pet to a 5-6 super accessories including the latest trends and surprises!',
    'Chaque saison, offrez à votre animal 5-6 super accessoires, y compris les dernières tendances et des surprises!',
];
export const SAVEOVER = ['Save ', 'Économisez '];
export const STARTINGPRICEDISCOVERY = ['$35', '35$'];
export const STARTINGPRICESUPER = ['$46', '46$'];
export const STARTINGPRICESUPERBED = ['may vary', 'peut varier'];
export const WEDONTSPOIL = [
    'We won’t spoil the surprise!',
    'On vous réserve la surprise!',
];
export const WEDONTSPOILCONTENT1 = [
    ' Each pack contains surprise items that our designers have carefully selected for each season. One thing is certain, you can expect the BeOneBreed quality you know and love! ',
    ' Chaque pack contient des items surprises que nos designers ont soigneusement sélectionnés pour chaque saison. Une chose est certaine, la qualité BeOneBreed que vous aimez est au rendez-vous! ',
];
export const WEDONTSPOILCONTENT2 = [
    '*Not recommended for animals with destructive tendencies.',
    '*Non conseillé pour les animaux à tendances destructrices.',
];
export const DOGS = ['Dogs', 'Chiens'];
export const CATS = ['Cats', 'Chats'];
export const SEEDETAILS = ['See details', 'Voir les détails'];
export const B1BQUALITY = [
    'See the BeOneBreed quality',
    'Voyez la qualité BeOneBreed',
];
export const INDICATIONCONT = [
    'Unless otherwise specified',
    'Sauf indication contraire',
];
export const LASTSEASONPACKS = [
    'Last season\'s pack',
    'Packs de la saison précédente',
];
export const VALUEOF = [' min. value', 'Valeur min. '];

//Why GoodyPack
export const WHYGOODYPACK = ['Why Goodypack ?', 'Pourquoi GoodyPack ?'];
export const SEASONALBOX = ['Bi-annual shipments', 'Envois bi-annuels'];
export const TIMESPERYEAR = ['2x per year', '2x par an'];
export const CANADIANCOMPAGNY = [
    'Proud Canadian company',
    'Entreprise fièrement québécoise',
];
export const FLEXIBLESUBSCRIPTION = [
    'Flexible subscription',
    'Abonnement flexible',
];
export const PRODUCTVARIETY = [
    'More than just treats & toys',
    'Plus que juste des gâteries & des jouets',
];
export const MEMORYFOAMTITLE = [
    '100% memory foam comfort',
    'Mousse mémoire 100% confort',
];
export const MEMORYFOAM = [
    'Our beds are designed with human-grade memory foam to ensure the optimal comfort of your pet. Memory foam also helps prevent pain and relieves joints.',
    'Nos lits sont conçus avec de la mousse mémoire de qualité humaine pour assurer le confort optimal de votre animal. La mousse mémoire aide à prévenir les maux et soulage les articulations.',
];
export const UNIQUEDESIGNSTITLE = [
    'Unique and fun designs',
    'Designs uniques et amusants',
];
export const UNIQUEDESIGNS = [
    'At BeOneBreed, our designers create useful and trendy accessories especially for you and your pet. No copy! From colorful patterns to innovative accessories everything is developed by our creative team!',
    'Chez BeOneBreed, nos designers conçoivent des accessoires utiles et tendance spécialement pour vous et votre animal. Pas de copie! Des motifs colorés aux accessoires innovateurs tout est développé par notre boîte créative!',
];
export const MORETHANTHREATSTITLE = [
    'More than just treats and toys!',
    'Bien plus que juste des gâteries et des jouets!',
];
export const MORETHANTHREATS = [
    'Thanks to GoodyPack, live the BeOneBreed variety! Get top quality seasonal accessories: beds, blankets, toys, bowls, collars, leashes and so much more! Each pack is a discovery!',
    'Grâce à GoodyPack, vivez la variété BeOneBreed! Recevez des accessoires saisonniers top qualité : lits, couvertures, jouets, bols, colliers, laisses et tellement plus! Chaque pack est une découverte! ',
];
export const PASSIONNATETEAMTITLE = [
    'Passionate team',
    'Une équipe de passionnés !',
];
export const PASSIONNATETEAM = [
    'At BeOneBreed, we have fun doing things differently and it shows in our products. Our team is composed of 18 passionate and authentic people in total symbiosis making BeOneBreed products shine all over the world... and having fun doing it!',
    'Chez BeOneBreed, nous nous plaisons à faire les choses différemment et cela se reflète dans nos produits. Notre équipe compte 18 personnes passionnées et authentiques en totale symbiose qui font rayonner les produits BeOneBreed partout dans le monde… et qui y prennent plaisir !',
];
export const B1BQUALITYSHORT = ['BeOneBreed Quality', 'Qualité BeOneBreed'];

//NotFoundPage
export const PAGENOTAVAILABLE = ['Page not available', 'Page non disponible'];
export const SORRYPAGENOT = [
    'We\'re sorry, we are unable to find the page you are looking for.',
    'Nous sommes désolés, mais la page que vous recherchez n\'a pas pu être trouvée.',
];
export const CATSTUCK = ['Cat stuck in cables', 'Chat pogné dans des câbles'];

// FAQ
export const YOURNAME = ['Your name', 'Votre nom'];
export const YOUREMAIL = ['Your email', 'Votre courriel'];
export const YOURMESSAGE = ['Your message', 'Votre message'];
export const SEND = ['Send', 'Envoyer'];
export const FAQABOUTPACKS = ['ABOUT THE PACKS', 'À PROPOS DES PACKS'];
export const FAQHOWDOESWORK = [
    'How does the GoodyPack work?',
    'Comment fonctionne le GoodyPack?',
];
export const FAQHOWDOESWORKCONTENT1 = [
    'Don’t miss a thing! Visit ',
    'Ne manquez rien! Rendez-vous sur ',
];
export const FAQHOWDOESWORKCONTENT2 = [
    'What’s the GoodyPack',
    'Qu’est-ce que le GoodyPack',
];
export const FAQHOWDOESWORKCONTENT3 = [
    ' to find out how it works. Follow ',
    ' pour tout savoir sur le fonctionnement de la boîte d’abonnement. Suivez ',
];
export const FAQHOWDOESWORKCONTENT4 = [
    ' on Facebook to stay informed. If you still have a question, give us a howl: ',
    ' sur Facebook pour rester informé. Si vous avez encore une question, contactez-nous : ',
];
export const FAQCANICHOOSE = [
    'Can I choose what comes in the GoodyPack?',
    'Puis-je choisir ce qui vient dans le GoodyPack?',
];
export const FAQCANICHOOSECONTENT1 = [
    'The GoodyPack concept is based on the surprise your pet and yourself will get when opening your pack. Each pack contains unisex surprise items that our designers have carefully selected with the objective in mind of creating maximum excitement and fun. For the time being, you can only customize your pack according to the size and type of pet you have at home. We will do our best to offer more customization options in the future! Get in touch with us at ',
    'Le concept GoodyPack est basé sur la surprise que votre animal et vous-même aurez en ouvrant votre pack. Chaque pack contient des articles surprises unisexes que nos designers ont soigneusement sélectionnés dans le but de créer un maximum d’excitation et de plaisir. Pour le moment, vous ne pouvez personnaliser votre pack qu\'en fonction de la taille et du type d\'animal que vous avez à la maison. Nous ferons de notre mieux pour offrir plus d\'options de personnalisation dans le futur! Contactez-nous à ',
];
export const FAQCANICHOOSECONTENT2 = [
    ' if you have ideas or constructive criticism.',
    ' si vous avez des idées ou des critiques constructives.',
];
export const FAQCANICHOOSECONTENT3 = [
    'One thing is sure, you can expect loads of high quality BeOneBreed products and, sometimes, a few top-notch surprises from other Canadian companies!',
    'Une chose est sûre, vous pouvez vous attendre à des tonnes de produits BeOneBreed de haute qualité et à quelques superbes surprises d’autres entreprises canadiennes!',
];
export const FAQHOWCANCEL = [
    'How can I cancel my subscription?',
    'Comment puis-je annuler mon abonnement?',
];
export const FAQHOWCANCELCONTENT1 = [
    'Please don’t say that out loud! Your pet might hear you and be so terribly sad… If you really want to unsubscribe, be sure to contact us at ',
    'S’il vous plaît, ne dites pas ça à voix haute! Votre animal pourrait vous entendre et être terriblement triste… Si vous souhaitez ne plus recevoir les boîtes GoodyPack, vous pouvez vous désabonner facilement en sélectionnant «Annuler l’abonnement» à côté de votre commande dans ',
];
export const FAQHOWCANCELCONTENT2 = [
    ' before your next pack ships. You can unsubscribe from the GoodyPack easily by selecting ‘’Cancel subscription’’ next to your subscription order in ',
    '. Vous devez vous désabonner avant le paiement de la prochaine boîte sans quoi, vous serez facturé et vous recevrez la prochaine boîte. Consultez le ',
];
export const FAQHOWCANCELCONTENT3 = [' your account', ' pour plus de détails.'];
export const FAQCANTRIAL = [
    'Can I try out the GoodyPack without committing?',
    'Puis-je essayer le GoodyPack sans m\'engager?',
];
export const FAQCANTRIALCONTENT1 = [
    'Just so you know, you can unsubscribe from the GoodyPack really easily by selecting ‘’Cancel subscription’’ next to your subscription order in ',
    'Pour votre information, vous pouvez vous désinscrire du GoodyPack en toute simplicité en sélectionnant «Annuler l’abonnement» à côté de votre commande dans ',
];
export const FAQCANTRIALCONTENT2 = [
    '. We totally get that you’d like to test out the GoodyPack before subscribing for packs. We offer a discovery pack for new customers only. The offer should pop up after a few seconds on the home page. Start the party now! ',
    '. Nous comprenons totalement que vous souhaitiez tester le GoodyPack avant de vous abonner. Nous proposons le pack découverte. Ce pack contient des accessoires vedettes et ne requiert pas d’abonnement. ',
];
export const FAQCANTRIALCONTENT3 = [
    'Start the party now!',
    'Commencez la fête maintenant!',
];
export const FAQSHIPPINGCONTENT1 = [
    'How much is the shipping cost and how long before I receive my pack?',
    'Combien coûtent les frais de livraison et combien de temps avant de recevoir mon colis?',
];
export const FAQSHIPPINGCONTENT2 = ['Quebec and Ontario', 'Québec et Ontario'];
export const FAQSHIPPINGCONTENT3 = [
    'Tadam! Shipping charges are free! Each pack is shipped on a specific date. Check the ',
    'Tadam! Les frais d\'expédition sont gratuits! Chaque pack est expédié à une date précise. Consultez le ',
];
export const FAQSHIPPINGCONTENT4 = [
    ' for more details. Your pack should arrive within 3-5 business days once you\'ve received your tracking number by email. The shipping duration may vary according to your location.',
    ' pour plus de détails. Votre colis devrait arriver dans un délai de 3-5 jours ouvrables après réception de votre numéro de suivi par courrier électronique. La durée de livraison peut varier selon votre emplacement.',
];
export const FAQSHIPPINGCONTENT5 = ['Rest of Canada', 'Reste du Canada'];
export const FAQSHIPPINGCONTENT6 = [
    'Shipping rates are determined by weight, size and destination. The fees will be calculated at checkout.',
    'Les tarifs d\'expédition sont déterminés par le poids, la taille et la destination. Les frais seront calculés à la caisse.',
];
export const FAQSHIPWORLD = [
    'Do we ship worldwide?',
    'Livrons-nous dans le monde entier?',
];
export const FAQSHIPWORLDCONTENT1 = [
    'We currently ship anywhere in Canada. Some regions are excluded of our shipping territory, such as Quebec far north. BeOneBreed reserves the right to refuse a sale or shipping without notice. Our customer service team will contact you if such a situation occurs.',
    'Nous expédions actuellement partout au Canada. Certaines régions sont exclues de notre territoire de transport, comme le Grand Nord du Québec. BeOneBreed se réserve le droit de refuser une vente ou une expédition sans préavis. Notre équipe de service à la clientèle vous contactera si une telle situation se produit.',
];
export const FAQWILLDELIVER = [
    'Will you deliver to my door?',
    'Allez-vous livrer à ma porte?',
];
export const FAQWILLDELIVERCONTENT1 = [
    'Yes, our carrier will deliver your order to your door! Our policy is to avoid safe drops as much as possible.',
    'Oui, notre transporteur livrera votre commande à votre porte! Notre politique est d\'éviter autant que possible les «dépôts en lieu sûr».',
];
export const FAQWHATHAPPENS = [
    'What happens if I am not at home when my order arrives?',
    'Que se passe-t-il si je ne suis pas à la maison quand ma commande arrive?',
];
export const FAQWHATHAPPENSCONTENT1 = [
    'In most cases, your parcel will be brought to the Canada Post office assigned to your area.',
    'Dans la plupart des cas, votre colis sera amené au bureau de Postes Canada affecté à votre région.',
];
export const FAQCANITRACK = [
    'Can I track my order online?',
    'Puis-je suivre ma commande en ligne?',
];
export const FAQCANITRACKCONTENT1 = [
    'You sure can! The moment the package leaves our warehouse, you receive an email indicating the tracking number for your order. Simply click on the provided number to track your package.',
    'Bien sûr! Dès que le colis quitte notre entrepôt, vous recevez un courrier électronique indiquant le numéro de suivi de votre commande. Cliquez simplement sur le numéro fourni pour suivre votre colis.',
];
export const FAQWHENANDHOWMUCH = [
    'When and how much will I be billed?',
    'Quand et combien serai-je facturé?',
];
export const FAQWHENANDHOWMUCHCONTENT1 = [
    'For SUPER pack subscriptions: You will pay pack per pack on November 20th (winter pack) and on May 20th (summer pack). If you added a bed to your SUPER pack, the bed extra will only be valid and billed for the current pack. For the next pack, you will need to select the option to add bed again to receive an extra bed in your next SUPER pack. Your pack will be shipped a few days later ',
    'Pour les abonnements au SUPER pack : Vous paierez pack par pack le 20 novembre (pack d’hiver) et le 20 mai (pack d’été). Si vous avez choisi d’ajouter un lit à votre SUPER pack, l’ajout de lit ne sera valide et facturé que pour le pack courant. Pour le prochain pack, vous devrez sélectionner l’option d’ajout de lit à nouveau afin de recevoir un lit additionnel à votre SUPER pack. Votre pack sera expédié quelques jours après ',
];
export const FAQWHENANDHOWMUCHCONTENT2 = [
    '(see Subscription calendar)',
    '(voir Calendrier des abonnements)',
];
export const FAQWHENANDHOWMUCHCONTENT3 = [
    ' For the order of the discovery pack: The unit amount of the pack will be charged to your credit card at the time of purchase. Your discovery pack will be shipped in December or June depending on the date of your order ',
    ' Pour la commande du pack découverte : Le montant unitaire du pack sera chargé sur votre carte de crédit au moment de l\'achat. Votre pack découverte sera expédié en décembre ou en juin selon la date de votre commande ',
];
export const FAQCANCELORDER = [
    'Can I change or cancel my order?',
    'Puis-je modifier ou annuler ma commande?',
];
export const FAQCANCELORDERCONTENT1 = [
    'Please contact us as soon as possible if you’d like to cancel your order. You must contact us by email at ',
    'Veuillez nous contacter dès que possible si vous souhaitez annuler votre commande. Vous devez nous contacter par email à ',
];
export const FAQCANCELORDERCONTENT2 = [
    ' before your order ships.',
    ' avant que votre commande soit expédiée.',
];
export const FAQACCOUNTO = [
    'Do I need an account to order?',
    'Ai-je besoin d\'un compte pour commander?',
];
export const FAQACCOUNTOCONTENT1 = [
    'Yes, an account is necessary to purchase the GoodyPack. You will be asked to create an account before checking out. To modify your account, click on your profile.',
    'Oui, un compte est nécessaire pour acheter le GoodyPack. Il vous sera demandé de créer un compte avant de valider. Pour modifier votre compte, cliquez sur votre profil.',
];
export const FAQPAYMENTMETHODS = [
    'What payment methods do we accept?',
    'Quels modes de paiement acceptons-nous?',
];
export const FAQPAYMENTMETHODSCONTENT1 = [
    'We accept Visa, MasterCard and American Express.',
    'Nous acceptons les cartes Visa, MasterCard et American Express.',
];
export const FAQRETURNS = ['RETURNS', 'RETOURS'];
export const FAQIFIDONT = [
    'What if I don’t like what I got in my surprise pack?',
    'Et si je n\'aime pas ce que j\'ai dans mon pack surprise?',
];
export const FAQIFIDONTCONTENT1 = [
    'The GoodyPack concept is based on the surprise your pet and yourself will get when opening your pack. Each pack contains surprise items that our designers have carefully selected for each season with the objective in mind of creating maximum excitement and fun.',
    'Le concept GoodyPack est basé sur la surprise que votre animal de compagnie et vous-même aurez en ouvrant votre sac de saison. Chaque pack contient des articles surprises que nos designers ont soigneusement sélectionnés pour chaque saison dans le but de créer un maximum d’excitation et de plaisir.',
];
export const FAQIFIDONTCONTENT2 = [
    'However, we want you to be 100% satisfied with your pack. We will send you a replacement item if you decide to return an item in your box. Please be sure it is in ',
    'Cependant, nous souhaitons que vous soyez 100% satisfait de votre pack. Nous vous enverrons un article de remplacement si vous décidez de retourner un article dans votre boîte. Veuillez vous assurer qu\'il est à l\'état ',
];
export const FAQIFIDONTCONTENT3 = ['new and unused', 'neuf et inutilisé'];
export const FAQIFIDONTCONTENT4 = [
    ' condition to request an exchange. Items must be returned in their original product packaging including all documentation and original hang tags within 30 days of the delivery date. Shipping charges are not refundable and all return shipping costs are payable by you. We will process the exchange within 7 to 10 business days after receiving the product in new condition at our warehouse. Products deemed not new and unused will not be refunded or returned at our expense. To request an exchange, complete the form on our ',
    ' pour demander un échange. Les articles doivent être retournés dans leur emballage d\'origine, y compris toute la documentation et les étiquettes volantes originales, dans les 30 jours suivant la date de livraison. Les frais d\'expédition ne sont pas remboursables et les frais de retour sont à votre charge. Nous traiterons l\'échange dans un délai de 7 à 10 jours ouvrables après réception du produit à l\'état neuf dans notre entrepôt. Les produits jugés non dans l’état neuf et inutilisé ne seront ni remboursés ni renvoyés à nos frais. Pour demander un échange, remplissez le formulaire sur notre page de ',
];
export const RETURNPOLICYPAGE = ['return policy page', 'politique de retour'];
export const FAQIFIDONTCONTENT6 = [
    ' and our customer service will help you.',
    ' et notre service client vous aidera.',
];
export const FAQWHATIFDEF = [
    'What if I got a defective item in my surprise pack?',
    'Et si un article défectueux se trouvait dans mon pack surprise?',
];
export const FAQWHATIFDEFCONTENT1 = [
    'Uh-oh! An item in your pack has a defect? No problem! Let us know what’s up and we will take care of it. We understand that your animal may feel an excess of enthusiasm for our products (we are excited too!!) but ',
    'Uh-oh! Un article de votre pack a un défaut? Aucun problème! Faites-nous savoir ce qui se passe et nous nous en occuperons. Nous comprenons que votre animal puisse ressentir un enthousiasme excessif pour nos produits (nous sommes excités aussi !!), ',
];
export const FAQWHATIFDEFCONTENT2 = [
    'items destroyed by the animal are not covered by the warranty',
    'mais des objets détruits par l\'animal ne sont pas couverts par la garantie.',
];
export const FAQWHATIFDEFCONTENT3 = [
    '. Complete the form on our ',
    ' Remplissez le formulaire sur notre page de ',
];
export const FAQWHATIFDEFCONTENT4 = [
    ' and do not forget to add pictures to your claim and a member of our team will get back to you asap!',
    ' et n\'oubliez pas d\'ajouter des photos à votre réclamation et un membre de notre équipe vous recontactera dès que possible!',
];
export const FAQCOUPONSDISCOUNTS = [
    'COUPONS & DISCOUNTS',
    'COUPONS & PROMOTIONS',
];
export const FAQCOUPONSDISCOUNTSCONTENT1 = [
    'Unless otherwise indicated, all coupons and promotions issued are applicable to the first pack of the subscription only.',
    'Sauf indication contraire, tous les coupons et promotions émis sont applicables au premier pack de l\'abonnement uniquement.',
];
export const FAQCOUPONSDISCOUNTSCONTENT2 = [
    'Unless otherwise indicated, all coupons and promotions issued by BeOneBreed apply to regular priced packs only, on the amount before taxes. Coupons and promotions cannot be combined with any other offer. Promotional code usage is limited to one per transaction. Coupons have no monetary value and cannot be sold, transferred or shared with others. Unused promotion codes cannot be reissued after the expiration date. Coupons from other retailers and competitors are not accepted. BeOneBreed reserves the right to modify or cancel any promotion or coupon at any time. Certain conditions may apply.',
    'Sauf indication contraire, tous les coupons et les promotions émis par BeOneBreed s\'appliquent uniquement aux packs à prix régulier, sur le montant avant taxes. Les coupons et les promotions ne peuvent être combinés à aucune autre offre. L\'utilisation du code promotionnel est limitée à un par transaction. Les coupons n\'ont aucune valeur monétaire et ne peuvent être vendus, transférés ou partagés avec d\'autres. Les codes promotionnels non utilisés ne peuvent pas être réédités après la date d\'expiration. Les coupons d\'autres détaillants et concurrents ne sont pas acceptés. BeOneBreed se réserve le droit de modifier ou d\'annuler toute promotion ou coupon à tout moment. Certaines conditions peuvent s\'appliquer.',
];
export const MESSAGESENT = [
    'Your message has been sent!',
    'Votre message a été envoyé!',
];
export const FAQPRICEADJUSMENT = [
    'Can I get a price adjustment following a promotion?',
    'Puis-je obtenir un ajustement de prix suite à une promotion?',
];
export const FAQPRICEADJUSMENTCONTENT1 = [
    'It is unfortunately not possible to obtain a price adjustment if you placed an order the day before a promotion. However, if you forgot to add the promo code in the shopping cart once the promotion has begun, email us at ',
    'Il n’est malheureusement pas possible d’obtenir un ajustement de prix si vous avez passé une commande la veille d’une promotion. Cependant, si vous avez oublié d’ajouter le code promo dans le panier d’achat une fois la promotion commencée, écrivez-nous à ',
];
export const FAQREFERTITLE1 = [
    'What is the GoodyPack Referral Program',
    'Qu\'est-ce que le programme de crédits GoodyPack',
];
export const FAQREFERTITLE2 = [
    'I\'m not a subscriber yet, can I still refer',
    'Je ne suis pas encore abonné, puis-je quand même référer',
];
export const FAQREFERTITLE3 = [
    'How do I use my referral credits',
    'Comment utiliser mes crédits',
];
export const FAQREFERTITLE4 = [
    'How do I earn credits',
    'Comment puis-je gagner des crédits',
];
export const FAQREFERTITLE5 = [
    'How do I invite friends',
    'Comment puis-je inviter des amis',
];
export const FAQREFERTEXT1 = [
    'The Referral Program automatically rewards you for the friends you refer to GoodyPack subscription boxes. You will earn $3 every time your friend subscribes, redeemable on your next pack. Start referring ',
    'Le programme de crédit vous récompense automatiquement pour les amis que vous référez aux boîtes d\'abonnement GoodyPack. Vous gagnerez 3$ chaque fois que votre ami s’abonne, à utiliser sur votre prochain pack. Référez ',
];
export const FAQREFERTEXT1A = [
    'to save on your next pack',
    'pour économiser sur votre prochain pack',
];
export const FAQREFERTEXT2 = [
    'Yes, you can refer friends and stack up credits. When you subscribe, the credits will be applied to your pack. Credits can only be used on subscription packs and not discovery packs',
    'Oui, vous pouvez référer des amis et accumuler des crédits. Lorsque vous vous abonnerez, les crédits seront appliqués à votre pack. Les crédits ne peuvent être utilisés que sur les packs d\'abonnement et non sur le pack découverte',
];
export const FAQREFERTEXT3 = [
    'Your credits will automatically be applied to your next pack. After your next pack, you will be able to invite more friends to earn more credits and so on! Your credits will not expire, even if your account is paused, as long as you stay an active subscriber. Credits are not transferable',
    'Vos crédits seront automatiquement appliqués à votre prochain pack. Après votre prochain pack, vous pourrez inviter plus d\'amis pour gagner plus de crédits et ainsi de suite! Vos crédits n\'expirent pas, même si votre compte est suspendu, tant que vous restez un abonné actif. Les crédits ne sont pas transférables ',
];
export const FAQREFERTEXT4 = [
    'For each friend who signs up for an account through your link and purchases a subscription, we will post to your account a $3 credit, which will be automatically applied to your next order. Your referred friend will get a 20% discount applicable on his/her first subscription pack (discovery packs are excluded). There’re no limits as to how many friends you can refer so start spreading the word and you could ',
    'Pour chaque ami qui s\'inscrit en utilisant votre lien et achète un abonnement, vous recevrez un crédit de 3$, qui sera automatiquement appliqué à votre prochain pack. Votre ami référé bénéficiera d\'un rabais de 20% applicable sur son premier pack d\'abonnement (le pack découverte est exclu). Il n’y a pas de limite au nombre d’amis que vous pouvez référer, alors commencez à passer le mot et vous pourriez ',
];
export const FAQREFERTEXT5 = [
    'You can refer friends who have not previously purchased any subscription or discovery pack. Check your ',
    'Vous pouvez inviter des amis qui n\'ont pas encore acheté d\'abonnement ou de pack découverte. Consultez l\'onglet ',
];
export const FAQREFERTEXT5A = [
    'tab to keep track of who you referred and your credit total. Make sure you are signed in to be able to refer friends',
    'pour savoir qui vous avez référé et le montant total de votre crédit. Assurez-vous d\'être connecté pour pouvoir référer des amis',
];
export const FAQREFERNOTE = [
    'Each friend can only be referred once. If your referred friend unsubscribes before the payment, your earned credits will be deducted. BeOneBreed may modify or revoke the referral program at any time without notice',
    'Chaque ami ne peut être référé qu’une seule fois. Si votre ami référé se désabonne avant le paiement, vos crédits gagnés seront déduits. BeOneBreed peut modifier ou révoquer le programme de crédits en tout temps, et ce sans préavis',
];
export const FAQREFERMAINTITLE = ['REFERRAL PROGRAM', 'PROGRAMME DE CRÉDITS'];
export const WINAPACK = [
    'earn yourself a free pack!',
    'gagner un pack gratuit!',
];
export const NOW = ['now', 'maintenant'];
export const FAQREFERTEXT6 = [
    'In the event that you refer enough friends to have a free pack, only the amount of taxes will be charged to you. If, after the payment of the pack, you still have credits left, they will be applied to your next pack',
    'Dans l\'éventualité où vous référez assez d\'amis pour avoir un pack gratuit, seul le montant des taxes vous sera chargé. Si, après le paiement du pack, il vous reste des crédits en banque, ils seront appliqués à votre prochain pack',
];
export const FAQYOURORDERS = ['Your orders', 'Vos commandes'];
export const TEAMHOLIDAYBACK = [
    'Our team will be away from the office from December 24 to January 3. We will get back to you as soon as we are back.',
    'Notre équipe sera en dehors du bureau du 24 décembre au 3 janvier. Nous vous contacterons dès que nous serons de retour.',
];

//Return Policy
export const RETURNORWARANTY = [
    'Is this a return or warranty claim?',
    'Est-ce un retour ou une demande de garantie?',
];
export const RETURN = ['Return', 'Retour'];
export const WARANTY = ['Waranty', 'Garantie'];
export const DATEPURCHASE = ['Date of purchase', 'Date d\'achat'];
export const ORDERNUMBER = ['Order number', 'Numéro de commande'];
export const RETURNNAME = ['Name', 'Nom'];
export const RETURNEMAIL = ['Email', 'Courriel'];
export const RETURNMESSAGE = ['Message', 'Message'];
export const ADDPICTURES = ['Add pictures', 'Ajouter des images'];
export const BROWSES = ['Browse files', 'Parcourir les fichiers'];
export const ALLFIELDREQUIRED = [
    'You must provide all fields',
    'Vous devez remplir tous les champs',
];

//Order Modification page
export const CARDMODIFIED = ['Card modified', 'Carte modifiée'];
export const MODIFYCREDITCARD = [
    'Update credit card',
    'Modifier la carte de crédit',
];
export const MODIFYADDRESS = [
    'Update shipping address',
    'Modifier l\'adresse de livraison',
];
export const ADRESSMODIFIED = ['Address modified', 'Adresse modifiée'];
export const MODIFYADDRESS2 = ['Update address', 'Modifier l\'adresse'];
export const ORDERPRICECHANGED = [
    'Your shipping fees changed',
    'Vos frais de livraison ont changé',
];
export const NEWORDERSHIPPING = [
    'New shipping fees',
    'Nouveaux frais de livraison',
];
export const NEWORDERTOTAL = ['New total', 'Nouveau total'];
export const MODIFYNECKSIZE = [
    'Update neck size',
    'Modifier la grandeur de cou',
];
export const NECKMODIFIED = [
    'Neck size has been updated!',
    'La grandeur de cou a été mise à jour!',
];
export const STANDARDNECKSIZE = [
    'Standard size (17 to 27 cm):',
    'Grandeur standard (de 17 à 27 cm) :',
];

//Retroaction
export const COMMENTS = ['Comments', 'Commentaires'];
export const UNSUBMESSAGE = [
    'Please get in touch with us if you have questions and we\'ll be glad to help out :',
    'S\'il vous plaît nous contacter si vous avez des questions et nous serons heureux de vous aider:',
];
export const UNSUBMESSAGE2 = [
    'If you really, REALLY want to cancel your order and stop receiving awesome party packs, please select the reason why below:',
    'Si vous souhaitez vraiment, VRAIMENT vous désabonner et ne plus recevoir de party packs géniaux, veuillez en indiquer la raison ci-dessous:',
];
export const APPRECIATIONTEXT = [
    'we would like you to take a minute to answer a few question about your',
    'nous aimerions que vous preniez une petite minute pour répondre à quelques questions à propos de votre',
];
export const THANKFORRESPONSE = [
    'Thank you for your answer',
    'Merci pour votre réponse',
];
export const THANKSMAINTEXTHIVER = [
    'Share your thoughts about the pack you received and you could win your next pack! Take this 3 minute survey to help us make the next packs even better.',
    'Partagez vos impressions sur le pack que vous avez reçu et vous pourriez gagner votre prochain pack! Répondez au sondage de 3 minutes pour nous aider à perfectionner les prochains packs.',
];
export const TAKESURVEY = ['Take the survey', 'Participer au sondage'];
export const CHATBOITE = ['Cat and a pack', 'Chat avec un pack'];
export const DOGANDBOYS = ['Dogs and boys', 'Chiens et garçons'];
export const SUBCANCEL = ['Subscription canceled', 'Abonnement annulé'];
export const CHOOSEAREASON = [
    'You must choose a reason',
    'Vous devez choisir une raison',
];
export const OTHER = ['Other', 'Autre'];
export const ANSWER1 = [
    'I ordered the pack by mistake',
    'J\'ai commandé le pack par erreur',
];
export const ANSWER2 = [
    'I think the value is not good enough for the price',
    'Je pense que la valeur n\'est pas assez bonne pour le prix',
];
export const ANSWER3 = [
    'I did not like what I received',
    'Je n\'ai pas aimé ce que j\'ai reçu',
];
export const RETOURAUXCOMMANDES = ['Back to my orders', 'Retour aux commandes'];

//As seen on
export const ASSEENON = ['As Seen On', 'Tel que vu à'];

// Partenaires
export const OURPARTNERS = ['Our partners', 'Nos partenaires'];
//Examples page
export const EXAMPLESTITLE = [
    'Previous Goodypack Examples',
    'Exemples de GoodyPack précédents',
];

//Referral program
export const REFERRALTITLE = [
    'Invite friends, earn credits',
    'Inviter des amis, gagner des crédits',
];
export const REFERRALSUBTITLE = [
    'It pays to gossip',
    'C\'est payant de partager',
];
export const REFERRALTEXT = [
    'Spread the word and we’ll give you $3 for each subscribed friend and your friend will receive 20% off his/her first subscription pack. NO LIMITS! Invite as many friends as you like to',
    'Recommandez le GoodyPack et nous vous donnerons 3$ pour chaque ami inscrit. Votre ami recevra 20% de réduction sur son premier pack d\'abonnement. PAS DE LIMITE! Invitez autant d\'amis que vous le souhaitez pour',
];
export const REFERRALQUESTION = [
    'Questions? Visit our',
    'Des questions? Visitez notre section',
];
export const REFERRALQUESTION2 = [
    'section for the details',
    'pour les détails',
];
export const GETAFREEPACK = ['get a free pack', 'obtenir un pack gratuit'];

// Concours 4 février
export const CANADIANSUBSCRIPTIONBOX = [
    'Canadian subscription box',
    'Boîte d’abonnement québécoise',
];
export const SENTEACHSEASON = ['Sent each season', 'Envois saisonniers'];
export const SAVEHIGHQUALITY = [
    'Save on high quality accessories',
    'Économisez sur des accessoires de qualité',
];
export const ENTER4FEVLINE1 = [
    'Enter our contest by answering filling out the form below and you could win a Spring GoodyPack!',
    'Participez à notre concours en répondant au formulaire ci-dessous et courez la chance de gagner votre GoodyPack de printemps!',
];
export const ENTER4FEVLINE2 = [
    'How many types of Packs does the GoodyPack offer for cats or dogs?',
    'Combien de types de packs le GoodyPack propose-t-il pour chats ou chiens?',
];
export const ENTER8MAILINE2 = [
    'During which season was the first GoodyPack box sent?',
    'À quelle saison la première boîte GoodyPack a-t-elle été envoyée?',
];
export const TYPEEMAILHERE = ['Type your email here', 'Tapez votre email ici'];
export const ENDSIN = ['Ends in', 'Se termine dans'];
export const ENTERCONTEST = ['Enter', 'Participer'];
export const CONCOURSGOODYPACK = ['GoodyPack Contest', 'Concours GoodyPack'];
export const CONTESTFINISHED = [
    'This contest has finished.',
    'Ce concours est terminé.',
];

// Concours
export const ENTERCONTESTLINE1 = [
    'Enter our contest by answering filling out the form below and you could win a ',
    'Participez à notre concours en répondant au formulaire ci-dessous et courez la chance de gagner votre GoodyPack d',
];

// User profile
export const ACCEPTMARKETINGEMAILS = [
    ' Keep me up to date on news, exclusive offers and contests',
    'Tenez-moi au courant des nouveautés, des offres exclusives et des concours de promotion',
];
export const UNSUBSCRIBE = ['Unsubscribe', 'Se désabonner'];
export const UNSUBSCRIBE_TEXT = [
    'If you wish to unsubscribe, you can do so by entering your email and clicking on the button below',
    'Si vous désirez vous désabonner, vous pouvez entrer votre adresse courriel et cliquer sur le bouton en dessous.',
];
export const UNSUBSCRIBE_SUCCESS = [
    'You have been successfully unsubscribed from all GoodyPack/BeOneBreed newsletters!',
    'Vous avez été désabonné avec succès aux infolettres GoodyPack/BeOneBreed!',
];
