import handleAuthFetch from '../utils/handleAuthFetch';

export const type = 'USERS'
const route = 'users'

const ADD = `ADD_${type}`
const REQUEST = `REQUEST_${type}S`
const RECEIVE = `RECEIVE_${type}S`
const UPDATE = `UPDATE_${type}`
const DELETE = `DELETE_${type}`
const ERROR = `ERROR_${type}`

const fetchFailure = (error) => ({ type: ERROR, error })

// Create
const fetchAddSuccess = (item) => ({ type: ADD, item })
export const fetchAdd = (add) => {
    return async (dispatch) => {
        try {
            const json = await handleAuthFetch({
                path: `/api/${route}/admin`,
                method: 'POST',
                body: add,
            });
            dispatch(fetchAddSuccess(json));
        } catch (error) {
            console.log(error);
            dispatch(fetchFailure(error));
        }
    }
}

// Read
const fetchUsersRequest = () => ({ type: REQUEST })
export const fetchUsersSuccess = (items) => ({ type: RECEIVE, items })
const fetchUsersFailure = (error) => ({ type: ERROR, error })
export const fetchUsers = () => {
    return async (dispatch) => {
        dispatch(fetchUsersRequest())
        try {
            const json = await handleAuthFetch({
                path: `/api/${route}`,
                method: 'GET',
                body: null,
            });
            dispatch(fetchUsersSuccess(json));
        } catch (error) {
            console.log(error);
            dispatch(fetchUsersFailure(error));
        }
    }
}

// Update
export const fetchUpdateSuccess = (item) => ({ type: UPDATE, item })
export const fetchUpdate = (_id, update) => {
    return async (dispatch) => {
        try {
            const json = await handleAuthFetch({
                path: `/api/${route}/admin/${_id}`,
                method: 'PATCH',
                body: update,
            });
            dispatch(fetchUpdateSuccess(json));
        } catch (error) {
            dispatch(fetchFailure(error));
        }
    }
}

// Delete
export const fetchDeleteSuccess = (_id) => ({ type: DELETE, _id })
export const fetchDelete = (_id) => {
    return async (dispatch) => {
        try {
            const json = await handleAuthFetch({
                path: `/api/${route}/admin/${_id}`,
                method: 'DELETE',
                body: null,
            });
            dispatch(fetchDeleteSuccess(json._id));
        } catch (error) {
            dispatch(fetchFailure(error));
        }
    }
}
