import handleAuthFetch from '../utils/handleAuthFetch';

export const filterCoupon = () => {
    return async (dispatch) => {
        try {
            const orders = await handleAuthFetch({
                path: '/api/admin/orders-coupon',
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'SET_ORDERS', payload: orders })
        } catch (error) {
            console.log(error);
        }
    }
}

export const filterApprecie = () => {
    return async (dispatch) => {
        try {
            const orders = await handleAuthFetch({
                path: '/api/admin/orders-apprecie',
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'SET_ORDERS', payload: orders })
        } catch (error) {
            console.log(error);
        }
    }
}

export const filterNonApprecie = () => {
    return async (dispatch) => {
        try {
            const orders = await handleAuthFetch({
                path: '/api/admin/orders-non-apprecie',
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'SET_ORDERS', payload: orders })
        } catch (error) {
            console.log(error);
        }
    }
}

export const filterSubCanceled = () => {
    return async (dispatch) => {
        try {
            const orders = await handleAuthFetch({
                path: '/api/admin/orders-canceled',
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'SET_ORDERS', payload: orders })
        } catch (error) {
            console.log(error);
        }
    }
}

export const filterSubPaused = () => {
    return async (dispatch) => {
        try {
            const orders = await handleAuthFetch({
                path: '/api/admin/orders-paused',
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'SET_ORDERS', payload: orders })
        } catch (error) {
            console.log(error);
        }
    }
}

export const filterTrialConversion = () => {
    return async (dispatch) => {
        try {
            const orders = await handleAuthFetch({
                path: '/api/admin/orders-conversion',
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'SET_ORDERS', payload: orders })
        } catch (error) {
            console.log(error);
        }
    }
}
export const countOrders = () => {
    return async (dispatch) => {
        try {
            const count = await handleAuthFetch({
                path: '/api/admin/orders-count',
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'SET_COUNT_ORDERS', payload: count })
        } catch (error) {
            console.log(error);
        }
    }

}

export const searchOrders = (searchString) => {
    return async (dispatch) => {
        try {
            const orders = await handleAuthFetch({
                path: `/api/admin/search-orders`,
                method: 'POST',
                body: { searchString: searchString },
            });
            return dispatch({ type: 'SET_ORDERS', payload: orders })
        } catch (error) {
            console.log(error);
        }
    }
}

export const filterOldTrial = () => {
    return async (dispatch) => {
        try {
            const oldOrders = await handleAuthFetch({
                path: '/api/orders/old_trial',
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'RECEIVE_STAT_ORDERS', items: oldOrders })
        } catch (error) {
            console.log(error);
        }
    }
}

export const filterExceptions = () => {
    return async (dispatch) => {
        try {
            const oldOrders = await handleAuthFetch({
                path: '/api/orders/old_exceptions',
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'RECEIVE_STAT_ORDERS', items: oldOrders })
        } catch (error) {
            console.log(error);
        }
    }
}

export const countOldOrders = () => {
    return async (dispatch) => {
        try {
            const count = await handleAuthFetch({
                path: '/api/orders/old-orders-count',
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'SET_COUNT_OLD_ORDERS', payload: count })
        } catch (error) {
            console.log(error);
        }
    }

}

export const searchOldOrders = (searchOldString) => {
    return async (dispatch) => {
        try {
            const oldOrders = await handleAuthFetch({
                path: `/api/admin/search-old-orders`,
                method: 'POST',
                body: { searchString: searchOldString },
            });
            return dispatch({ type: 'SET_OLD_ORDERS', payload: oldOrders })
        } catch (error) {
            console.log(error);
        }
    }
}
