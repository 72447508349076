export const subToNewsletter = (values) => {
    return (dispatch) => {
        return fetch(`/api/users/addEmailToNewsletter`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
        })
            .then((res) => {
                return res.json();
            })
            .then((json) => {
                if (json.error) {
                    dispatch({ type: 'FAILED_SUB_SEND', payload: json.error });
                    return Promise.reject(json.error);
                }
                dispatch({ type: 'SUB_TO_NEWS_OK', payload: json.message });
            })
            .catch((error) => dispatch({ type: 'FAILED_SUB_SEND', payload: error }))
    }
}

export const subUserToNewsletter = (values) => {
    return (dispatch) => {
        return fetch(`/api/users/subUserToNewsletter`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
        })
            .then((res) => {
                return res.json();
            })
            .then((json) => {
                if (json.error) {
                    dispatch({ type: 'FAILED_SUB_SEND', payload: json.error });
                    return Promise.reject(json.error);
                }
                dispatch({ type: 'SUB_TO_NEWS_OK', payload: json.message });
            })
            .catch((error) => dispatch({ type: 'FAILED_SUB_SEND', payload: error }))
    }
}
