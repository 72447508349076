import TextField from '@material-ui/core/TextField';
import React from 'react';
import { fetchAddCouponCart, fetchRemoveCouponCart } from '../../actions/cart';
import { APPLIQUER } from '../../constant/stringTraduction';
import cartContainer from '../../containers/cart/cartContainer';
import SuccessableButton from '../buttons/SuccessableButton';
import { fetchAddCouponOrder } from '../../actions/orders'
import { getNoMoreOrders } from '../../utils/specialEvents';

class CouponInput extends React.Component {
    constructor() {
        super();
        this.state = {
            couponSlug: '',
            previousSlug: null,
            previousCoupon: null,
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.cart.coupon && state.previousCoupon && (state.previousCoupon !== props.cart.coupon || state.previousSlug !== props.itemSlug)) {
            props.dispatch(fetchAddCouponCart(props.cart.coupon.slug, props.itemSlug, props.itemType));
            setTimeout(() => props.dispatch({ type: 'RESET_COUPON_INPUT' }), 5000);
        }
        return {
            previousSlug: props.itemSlug,
            previousCoupon: props.cart.coupon,
        }
    }

    handleChangeCoupon = (e) => {
        this.setState({ couponSlug: e.target.value });
    }
    removeCouponCart = (idCoupon) => {
        const { dispatch } = this.props
        return dispatch(fetchRemoveCouponCart(idCoupon));
    }

    sendCoupon = (e) => {
        e.preventDefault();
        if (this.props.orderId) {
            const { dispatch, orderId, couponId } = this.props;
            return dispatch(fetchAddCouponOrder(this.state.couponSlug, orderId, couponId)).then(function (res) {
                if (res.error) {
                    this.props.actionError();
                } else {
                    this.props.actionSuccess();
                }
            }.bind(this));
        } else {
            const { dispatch, itemSlug, itemType } = this.props
            return dispatch(fetchAddCouponCart(this.state.couponSlug, itemSlug, itemType));
        }
    }

    resetError = () => {
        this.props.dispatch({ type: 'ERROR_RESET_COUPON' })
    }

    render() {
        const {
            enabled,
            errorCoupon,
            indexLang,
            submitSucceededCoupon,
            submittingCoupon,
            typeItemSubmittingCoupon,
            slugItemSubmittingCoupon,
            itemType,
            itemSlug,
        } = this.props

        let error = '';
        let submitSucceededCouponValue = false;
        let submittingCouponValue = false;
        if (errorCoupon) {
            error = errorCoupon.error;
        }
        if (typeof submitSucceededCoupon !== 'undefined') {
            submitSucceededCouponValue = submitSucceededCoupon;
        }
        if (typeof submittingCoupon !== 'undefined') {
            submittingCouponValue = submittingCoupon;
        }

        const isUsed = typeItemSubmittingCoupon === itemType && slugItemSubmittingCoupon === itemSlug;

        return (
            enabled && !getNoMoreOrders() ?
                <form onSubmit={this.sendCoupon}>
                    <div>
                        <TextField
                            value={this.state.couponSlug}
                            onChange={this.handleChangeCoupon}
                            style={{ width: '100%', marginTop: 10 }}
                        />
                    </div>
                    <div style={{ textAlign: 'center', marginTop: 10 }}>
                        <SuccessableButton
                            style={{ margin: '0', width: '100%' }}
                            disabled={this.state.couponSlug.length === 0}
                            error={isUsed ? error : ''}
                            label={APPLIQUER[indexLang]}
                            reset={this.resetError}
                            submitSucceeded={isUsed ? submitSucceededCouponValue : false}
                            submitting={isUsed ? submittingCouponValue : false}
                            successLabel={['Coupon Added', 'Coupon ajouté'][indexLang]}
                        />
                    </div>
                </form> : null
        )
    }
}

export default cartContainer(CouponInput)
