import FontIcon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from '@material-ui/core';

class CartIcon extends Component {
    render () {
        const {
            color,
            isAppBar,
        } = this.props
        return (

            <div>
                {isAppBar ?
                    <Link to="/user/cart" style={{ color: 'transparent' }} >
                        <IconButton>
                            <Badge
                                badgeContent={1}
                            >
                                <FontIcon
                                    className="fa fa-shopping-cart"
                                />
                            </Badge>
                        </IconButton>
                    </Link>
                    :
                    <div>
                        <Badge
                            badgeContent={1}
                        >
                            <FontIcon
                                className="fa fa-shopping-cart"
                                style={{ color }}
                            />
                        </Badge>
                    </div>
                }
            </div>
        )
    }
}

export default CartIcon
