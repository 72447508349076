import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { AJOUTERADRESSE } from '../../constant/stringTraduction';

const style = () => ({
    cssLabel: {
        color: '#000000 !important',

    },
    cssUnderline: {
        '&:after': {
            borderBottomColor: '#000000',
        },
        '&:before': {
            borderBottomColor: '#000000',
        },
        '&:hover': {
            borderBottomColor: '#000000',
        },
    },
})
const renderSelectFieldAddress = ({
    input,
    label,
    meta: { touched, error },
    color,
    classes,
    options,
    indexLang,
    handleAddressChange,
    ...custom
}) => {
    color = color ? color : '#000000';
    return (
        <FormControl {...custom}>
            <InputLabel
                FormLabelClasses={{
                    root: classes.cssLabel,
                }}
            >
                {label}
            </InputLabel>
            <Select
                onChange={(e) => {
                    handleAddressChange(e.target.value);
                    input.onChange(e.target.value)
                }}
                error={touched && error !== undefined}
                input={<Input
                    style={{ color: color }}
                    classes={{ underline: classes.cssUnderline }}
                    error={touched && error !== undefined}
                    {...input}
                    {...custom}
                    onBlur={() => { }}
                />}
                {...custom}
            >
                {options.map((address) =>
                    <MenuItem
                        key={address._id}
                        value={address._id}
                    >
                        {`
                  ${address.values.name}
                  ${address.values.phone}
                  ${address.values.street}
                  ${address.values.city},
                  ${address.values.state}
                  ${address.values.zip}`
                        }
                    </MenuItem>,
                )}
                {options.length < 6 &&
                    //Mettre un link pour aller gèrer les adresses voir si c'est ce qu'on veux. 
                    <MenuItem value="newAddress">{AJOUTERADRESSE[indexLang]}</MenuItem>
                }
            </Select>
        </FormControl>
    )
}

export default withStyles(style)(renderSelectFieldAddress)
