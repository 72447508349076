import React, { Component } from 'react'

import './orders.css'
import orderContainer from '../../containers/orders/orderContainer'
import OrderDetail from '../../components/orders/OrderDetail'
import { Container } from 'reactstrap';

class OrderDetailPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        const {
            dispatch,
            history,
            order,
            indexLang,
            pauseSubscriptionId,
            resumeSubscriptionId,
            confirmation,
            modal,
            text,
            title,
            confirmType,
            products,
            orderUpgradeError,
            orderUpgradeSuccess,
            orderUpgrading,
            upgradeModal,
        } = this.props
        return (
            <Container className='white-background container-top-margin'>
                <OrderDetail
                    dispatch={dispatch}
                    order={order}
                    indexLang={indexLang}
                    pauseSubscriptionId={pauseSubscriptionId}
                    resumeSubscriptionId={resumeSubscriptionId}
                    confirmation={confirmation}
                    modal={modal}
                    title={title}
                    text={text}
                    confirmType={confirmType}
                    upgradeModal={upgradeModal}
                    orderUpgrading={orderUpgrading}
                    orderUpgradeSuccess={orderUpgradeSuccess}
                    orderUpgradeError={orderUpgradeError}
                    products={products}
                    history={history}
                />
            </Container>
        )
    }
}

export default orderContainer(OrderDetailPage)
