import handleAuthFetch from '../utils/handleAuthFetch';

export const fetchAddCoupon = (values, isForever, isReferedDiscount) => {
    return (dispatch) => {
        return handleAuthFetch({
            path: `/api/coupons`,
            method: 'POST',
            body: {
                ...values,
                userIds: [],
                isForever: isForever,
                isReferedDiscount: isReferedDiscount,
            },
        }).then((json) => {
            if (json) {
                dispatch({ type: 'ADD_COUPONS_TO_ALL', payload: json })
            }
        })
            .catch((error) => {
                console.log(error);
            })
    }
}

export const fetchRemoveCoupon = (slug) => {
    return (dispatch) => {
        return handleAuthFetch({
            path: `/api/coupons`,
            method: 'DELETE',
            body: {
                slug: slug,
            },
        }).then((json) => {
            if (json) {
                dispatch({ type: 'REMOVE_COUPON_FROM_ALL', payload: json })
            }
        })
            .catch((error) => {
                console.log(error);
            })
    }
}

export const fetchAllCoupons = (orderBy, type, firstTime) => {
    return (dispatch) => {
        return handleAuthFetch({
            path: `/api/admin/get-coupons`,
            method: 'POST',
            body: { orderBy: orderBy, type: type, firstTime: firstTime },

        }).then((json) => {
            if (json) {
                dispatch({ type: 'SET_COUPONS', payload: json })
            }
        })
            .catch((error) => {
                console.log(error);
            })
    }
}

export const fetchSingleCoupon = (couponId) => {
    return () => {

        return fetch(`/api/orders/getcoupon/${couponId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.error) {
                    return Promise.reject()
                }
                return json;
            })
            .catch((error) => {
                console.log(error);
            })
    }
}
