import React from 'react';
import { Container } from 'reactstrap';
import { COMPTEAREBOURS, JOURS, HEURES, MINUTES, SECONDES } from '../../constant/stringTraduction';

const Compteur = ({ saison, jours, heures, minutes, secondes, indexLang }) => {
    return (
        <Container className={saison != null ? 'container-countdown' : ''}>
            <div>
                {saison &&
                                <h3 style={{ fontWeight: '800', paddingBottom: '10px', fontSize: '2.2rem' }}>
                                    {indexLang ? 'LE PACK D\'' : ''}
                                    <span style={{ color: '#FFF100' }}>
                                        {saison}
                                    </span>
                                    {COMPTEAREBOURS[indexLang]}
                                </h3>
                }
                <div className="d-flex justify-content-center">
                    <div className="box"><span id="js-clock-days">{jours}</span>{JOURS[indexLang]}</div>
                    <div className="box"><span id="js-clock-hours">{heures}</span>{HEURES[indexLang]}</div>
                    <div className="box"><span id="js-clock-minutes">{minutes}</span>{MINUTES[indexLang]}</div>
                    <div className="box"><span id="js-clock-seconds">{secondes}</span>{SECONDES[indexLang]}</div>
                </div>
            </div>
        </Container>
    )
}

export default Compteur
