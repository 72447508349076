import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { Component } from 'react';
import './button.css';
import { withStyles } from '@material-ui/core/styles';

class SuccessableButton extends Component {
    state = {
        background: null,
        disabled: null,
        label: '',
        timeoutId: null,
    }
    UNSAFE_componentWillMount() {
        const {
            disabled,
            backgroundColor = '#fff100',
            label,
        } = this.props
        this.setState({ background: backgroundColor, disabled, label })
    }
    UNSAFE_componentWillReceiveProps({
        backgroundColor,
        disabled,
        error,
        imageEdit,
        label,
        reset,
        submitSucceeded,
        successLabel,
    }) {
        if (imageEdit !== this.props.imageEdit) {
            this.state.timeoutId && clearTimeout(this.state.timeoutId)
            return this.setState({
                background: backgroundColor,
                disabled: false,
                label,
                timeoutId: null,
            })
        }

        if (error) {
            this.state.timeoutId && clearTimeout(this.state.timeoutId)
            const timeoutId = setTimeout(() => {
                clearTimeout(this.state.timeoutId)
                this.setState({
                    background: backgroundColor,
                    disabled: false,
                    label,
                    submitting: false,
                    timeoutId: null,
                })
                reset && reset()
            }, 2000)
            return this.setState({
                background: 'rgb(244, 67, 54)',
                disabled: true,
                label: error,
                timeoutId,
            })
        }

        if (disabled !== this.props.disabled) {
            this.state.timeoutId && clearTimeout(this.state.timeoutId)
            this.setState({
                background: backgroundColor,
                disabled,
                label,
                timeoutId: null,
            })
        }

        if (submitSucceeded !== this.props.submitSucceeded) {
            this.state.timeoutId && clearTimeout(this.state.timeoutId)
            const timeoutId = setTimeout(() => {
                clearTimeout(this.state.timeoutId)
                this.setState({
                    background: backgroundColor,
                    disabled: false,
                    label,
                    submitting: false,
                    timeoutId: null,
                })
                reset && reset()
            }, 4000)
            return this.setState({
                disabled: true,
                label: successLabel,
                timeoutId,
            })
        }
    }
    componentWillUnmount() {
        clearTimeout(this.state.timeoutId)
    }

    render() {
        const {
            submitting,
            style,
            onClick,
            onMouseUp,
            submitSucceeded,
            disabled,
        } = this.props

        const StyledButton =
            withStyles({
                root: { ...style, backgroundColor: '#fff100', color: '#000000', '&:hover': { backgroundColor: '#eeeeee' } },
                disabled: { ...style, backgroundColor: submitSucceeded ? '#fff100' : '#cccccc', color: '#000000 !important' },
            })(Button)

        return (
            <StyledButton
                disabled={disabled ? true : this.state.disabled}
                type={onClick ? 'button' : 'submit'}
                onClick={onClick}
                onMouseUp={onMouseUp}
            >
                {submitting ? <CircularProgress size={24} style={{ verticalAlign: 'middle', color: '#000000' }} /> : this.state.label && this.state.label.errors ? 'error' : this.state.label}
            </StyledButton>
        )
    }
}

export default SuccessableButton
