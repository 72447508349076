const admin = (state = {
    error: null,
    activeTab: '1',
    submitSucceeded: false,
    submitting: false,
    cancelForms: [],
    count: [],
}, action) => {
    switch (action.type) {
        case 'ADD_COUPON':
            return {
                ...state,
                submitting: true,
            }
        case 'COUPON_ADDED':
            return {
                ...state,
                submitting: false,
                submitSucceeded: true,
            }
        case 'SWITCH_TAB':
            return {
                ...state,
                activeTab: action.indexTab,
            }
        case 'SET_CANCEL_FORMS':
            return {
                ...state,
                cancelForms: action.payload,
            }
        case 'SET_COUNT_ORDERS':
            return {
                ...state,
                count: action.payload,
            }
        default:
            return state
    }
}

export default admin
