import handleAuthFetch from '../utils/handleAuthFetch';

export const type = 'CART'
const route = 'carts'

const ADD = `ADD_${type}`
const REQUEST = `REQUEST_${type}`
const RECEIVE = `RECEIVE_${type}`
const UPDATE = `UPDATE_${type}`
const DELETE = `DELETE_${type}`
const ERROR = `ERROR_${type}`

// Create
const fetchAddToCartSuccess = (cart) => ({ type: ADD, cart })
const fetchAddToCartFailure = (error) => ({ type: ERROR, error })
export const fetchAddToCart = (update, history) => {
    return (dispatch) => {
        dispatch({ type: 'IS_ADDING_CART' });
        const cartId = localStorage.getItem('cart')
        if (cartId) {
            return fetch(`/api/${route}/${cartId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(update),
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json()
                    }
                    throw new Error('Network response was not ok.')
                })
                .then((json) => {
                    if (json.error) {
                        return Promise.reject(json.error);
                    }
                    history.push('/user/cart');
                    dispatch(fetchAddToCartSuccess(json));
                })
                .catch((error) => dispatch(fetchAddToCartFailure(error)))
        } else {
            return fetch(`/api/${route}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(update),
            })
                .then((res) => {
                    if (res.ok) {
                        localStorage.setItem('cart', res.headers.get('cart'))
                    }
                    return res.json()
                })
                .then((json) => {
                    if (json.error) {
                        return Promise.reject(json.error);
                    }
                    history.push('/user/cart');
                    dispatch(fetchAddToCartSuccess(json));
                })
                .catch((error) => dispatch(fetchAddToCartFailure(error)))
        }
    }
}

// Read
const fetchCartRequest = () => ({ type: REQUEST })
const fetchCartSuccess = (cart) => ({ type: RECEIVE, cart })
const fetchCartFailure = (error) => ({ type: ERROR, error })
export const fetchCart = (cartId) => {
    return (dispatch) => {
        dispatch(fetchCartRequest())
        return fetch(`/api/${route}/${cartId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.error) {
                    return Promise.reject()
                }
                dispatch(fetchCartSuccess(json))
            })
            .catch((error) => {
                localStorage.removeItem('cart')
                dispatch(fetchCartFailure(error))
            })
    }
}

// Delete
const fetchDeleteCartSuccess = () => ({ type: DELETE })
const fetchDeleteCartFailure = (error) => ({ type: ERROR, error })
export const fetchDeleteCart = () => {
    const cartId = localStorage.getItem('cart')
    return (dispatch) => {
        return fetch(`/api/${route}/${cartId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.error) {
                    return Promise.reject(json.error)
                }
                localStorage.removeItem('cart')
                dispatch(fetchDeleteCartSuccess())
            })
            .catch((error) => fetchDeleteCartFailure(error))
    }
}

// Update
const fetchUpdateCartSuccess = (cart) => ({ type: UPDATE, cart })
const fetchUpdateCartFailure = (error) => ({ type: ERROR, error })
export const fetchUpdateCart = (update) => {
    return (dispatch) => {
        dispatch(fetchCartRequest())
        const cartId = localStorage.getItem('cart')
        return fetch(`/api/${route}/${cartId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(update),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.error) {
                    return Promise.reject(json.error)
                }
                dispatch(fetchUpdateCartSuccess(json))
                if (json.quantity === 0) {
                    dispatch(fetchDeleteCart(json._id))
                }
            })
            .catch((error) => dispatch(fetchUpdateCartFailure(error)))
    }
}

// Add
const fetchAddCouponCartSuccess = (cart) => ({ type: 'RECEIVE_CART_COUPON', cart })
const fetchAddCouponCartFailure = (error) => ({ type: 'ERROR_CART_COUPON', error })
export const fetchAddCouponCart = (slug, itemSlug, itemType) => {
    const cartId = localStorage.getItem('cart')
    return (dispatch) => {
        dispatch({ type: 'ADDING_COUPON_CART', itemType, itemSlug });
        return handleAuthFetch({
            path: `/api/coupons/${cartId}`,
            method: 'POST',
            body: { slug, itemSlug, itemType },
        })
            .then((json) => {
                if (!json) {
                    return dispatch(fetchAddCouponCartFailure({ error: 'cart not found' }));
                }
                if (json.error) {
                    return dispatch(fetchAddCouponCartFailure(json.error));
                }
                return dispatch(fetchAddCouponCartSuccess(json))
            })
            .catch((error) => dispatch(fetchAddCouponCartFailure({ error })))
    }
}

// Delete
const fetchRemoveCouponCartSuccess = (cart) => ({ type: 'REMOVED_CART_COUPON', cart })
const fetchRemoveCouponCartFailure = (error) => ({ type: 'ERROR_CART_COUPON', error })
export const fetchRemoveCouponCart = (slug, slugCoupon, typeCoupon) => {
    const cartId = localStorage.getItem('cart')
    return (dispatch) => {
        dispatch({ type: 'REMOVING_CART_COUPON', couponId: slugCoupon });

        return handleAuthFetch({
            path: `/api/coupons/${cartId}`,
            method: 'DELETE',
            body: { slug, slugCoupon, typeCoupon },
        })
            .then((json) => {
                if (!json) {
                    return dispatch(fetchRemoveCouponCartFailure({ error: 'cart not found' }));
                }
                if (json.error) {
                    return dispatch(fetchRemoveCouponCartFailure(json.error));
                }
                return dispatch(fetchRemoveCouponCartSuccess(json))
            })
            .catch((error) => dispatch(fetchRemoveCouponCartFailure({ error })))
    }
}
