import handleAuthFetch from '../utils/handleAuthFetch'

export const setLang = (indexLang, id) => {
    if (id) {
        handleAuthFetch({ //Modification de la langue du user pour les emails.
            path: '/api/users/lang',
            method: 'PATCH',
            body: { indexLang: indexLang, _id: id },
        });
    }

    return {
        type: 'SET_LANG',
        payload: indexLang,
    }
}
