import React from 'react';
import { Table, Container } from 'reactstrap';
import couponContainer from '../../containers/coupon/couponContainer';
import './coupons.css';
import { fetchAllCoupons } from '../../actions/coupon';

class Coupon extends React.Component {
    constructor() {
        super();
        this.state = {
            slugOrder: 'inv',
            useOrder: 'reg',
            nameOrder: 'reg',
            descOrder: 'reg',
            amountOrder: 'reg',
            percentOrder: 'reg',
            publicOrder: 'reg',
            usageOrder: 'reg',
            expOrder: 'reg',
            foreverOrder: 'reg',
        }
    }
    resetFilters = () => {
        this.setState({
            slugOrder: 'reg',
            useOrder: 'reg',
            nameOrder: 'reg',
            descOrder: 'reg',
            amountOrder: 'reg',
            percentOrder: 'reg',
            publicOrder: 'reg',
            usageOrder: 'reg',
            expOrder: 'reg',
            foreverOrder: 'reg',
        });
    }
    orderBySlug = async () => {
        const order = await this.state.slugOrder;
        await this.props.dispatch(fetchAllCoupons('slug', this.state.slugOrder, false));
        await this.resetFilters()
        if (order === 'reg') {
            this.setState({
                slugOrder: 'inv',
            });
        } else {
            this.setState({
                slugOrder: 'reg',
            });
        }
    }
    orderByUse = async () => {
        const order = await this.state.useOrder;
        await this.props.dispatch(fetchAllCoupons('usageCount', this.state.useOrder, false));
        await this.resetFilters()
        if (order === 'reg') {
            this.setState({
                useOrder: 'inv',
            });
        } else {
            this.setState({
                useOrder: 'reg',
            });
        }
    }
    orderByName = async () => {
        const order = await this.state.nameOrder;
        await this.props.dispatch(fetchAllCoupons('name', this.state.nameOrder, false));
        await this.resetFilters()
        if (order === 'reg') {
            this.setState({
                nameOrder: 'inv',
            });
        } else {
            this.setState({
                nameOrder: 'reg',
            });
        }
    }
    orderByDesc = async () => {
        const order = await this.state.descOrder;
        await this.props.dispatch(fetchAllCoupons('description', this.state.descOrder, false));
        await this.resetFilters()
        if (order === 'reg') {
            this.setState({
                descOrder: 'inv',
            });
        } else {
            this.setState({
                descOrder: 'reg',
            });
        }
    }
    orderByAmount = async () => {
        const order = await this.state.amountOrder;
        await this.props.dispatch(fetchAllCoupons('amountOff', this.state.amountOrder, false));
        await this.resetFilters()
        if (order === 'reg') {
            this.setState({
                amountOrder: 'inv',
            });
        } else {
            this.setState({
                amountOrder: 'reg',
            });
        }
    }
    orderByPercent = async () => {
        const order = await this.state.percentOrder;
        await this.props.dispatch(fetchAllCoupons('percentOff', this.state.percentOrder, false));
        await this.resetFilters()
        if (order === 'reg') {
            this.setState({
                percentOrder: 'inv',
            });
        } else {
            this.setState({
                percentOrder: 'reg',
            });
        }
    }
    orderByPublic = async () => {
        const order = await this.state.publicOrder;
        await this.props.dispatch(fetchAllCoupons('isPublic', this.state.publicOrder, false));
        await this.resetFilters()
        if (order === 'reg') {
            this.setState({
                publicOrder: 'inv',
            });
        } else {
            this.setState({
                publicOrder: 'reg',
            });
        }
    }
    orderByUsage = async () => {
        const order = await this.state.usageOrder;
        await this.props.dispatch(fetchAllCoupons('maximumUsage', this.state.usageOrder, false));
        await this.resetFilters()
        if (order === 'reg') {
            this.setState({
                usageOrder: 'inv',
            });
        } else {
            this.setState({
                usageOrder: 'reg',
            });
        }
    }
    orderByExp = async () => {
        const order = await this.state.expOrder;
        await this.props.dispatch(fetchAllCoupons('expiration', this.state.expOrder, false));
        await this.resetFilters()
        if (order === 'reg') {
            this.setState({
                expOrder: 'inv',
            });
        } else {
            this.setState({
                expOrder: 'reg',
            });
        }
    }
    orderByForever = async () => {
        const order = await this.state.foreverOrder;
        await this.props.dispatch(fetchAllCoupons('isForever', this.state.foreverOrder, false));
        await this.resetFilters()
        if (order === 'reg') {
            this.setState({
                foreverOrder: 'inv',
            });
        } else {
            this.setState({
                foreverOrder: 'reg',
            });
        }
    }
    render() {
        const {
            coupons,
        } = this.props

        return (
            <Container className='admin-container' style={{ marginTop: '50px' }}>
                <h2 className='admin-section-title'>Liste des coupons</h2>
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th className='clickable' onClick={this.orderBySlug}>Slug/Code {this.state.slugOrder === 'reg' ? <i className="fa fa-caret-down"></i> : <i className="fa fa-caret-up"></i>}</th>
                            <th className='clickable' onClick={this.orderByUse}>Utilisation {this.state.useOrder === 'reg' ? <i className="fa fa-caret-down"></i> : <i className="fa fa-caret-up"></i>}</th>
                            <th className='clickable' onClick={this.orderByName}>Nom {this.state.nameOrder === 'reg' ? <i className="fa fa-caret-down"></i> : <i className="fa fa-caret-up"></i>}</th>
                            <th className='clickable' onClick={this.orderByDesc}>Description {this.state.descOrder === 'reg' ? <i className="fa fa-caret-down"></i> : <i className="fa fa-caret-up"></i>}</th>
                            <th className='clickable' onClick={this.orderByAmount}>$ Off {this.state.amountOrder === 'reg' ? <i className="fa fa-caret-down"></i> : <i className="fa fa-caret-up"></i>}</th>
                            <th className='clickable' onClick={this.orderByPercent}>% Off {this.state.percentOrder === 'reg' ? <i className="fa fa-caret-down"></i> : <i className="fa fa-caret-up"></i>}</th>
                            <th className='clickable' onClick={this.orderByPublic}>Publique {this.state.publicOrder === 'reg' ? <i className="fa fa-caret-down"></i> : <i className="fa fa-caret-up"></i>}</th>
                            <th className='clickable' onClick={this.orderByUsage}>Usage max {this.state.usageOrder === 'reg' ? <i className="fa fa-caret-down"></i> : <i className="fa fa-caret-up"></i>}</th>
                            <th className='clickable' onClick={this.orderByExp}>Expiration {this.state.expOrder === 'reg' ? <i className="fa fa-caret-down"></i> : <i className="fa fa-caret-up"></i>}</th>
                            <th className='clickable' onClick={this.orderByForever}>Durée illimitée {this.state.foreverOrder === 'reg' ? <i className="fa fa-caret-down"></i> : <i className="fa fa-caret-up"></i>}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {coupons.map((x, index) => {
                            let exp = 'infini';
                            if (x.expiration) {
                                exp = x.expiration.substring(0, 10)
                            }
                            return <tr key={index}>
                                <td>{x.slug}</td>
                                <td>{x.usageCount} fois</td>
                                <td>{x.name}</td>
                                <td>{x.description}</td>
                                <td>{x.amountOff}</td>
                                <td>{x.percentOff}</td>
                                <td>{x.isPublic ? 'oui' : 'non'}</td>
                                <td>{x.maximumUsage ? x.currentUse + '/' : null} {x.maximumUsage ? x.maximumUsage : 'infini'}</td>
                                <td>{exp}</td>
                                <td>{x.isForever ? 'oui' : 'non'}</td>
                            </tr>;
                        })}
                    </tbody>
                </Table>
            </Container>
        )
    }
}

export default couponContainer(Coupon)
