import React, { Component } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { EXAMPLESTITLE, WEDONTSPOILCONTENT1, STARTTHEPARTY } from '../../constant/stringTraduction';
import examplesContainer from '../../containers/examples/examplesContainer';
import './examples.css';
import CatPictoSmall from './picto/CatPictoSmall';
import DogPictoSmall from './picto/DogPictoSmall';

import WINTER_CAT_18_FR from '../../assets/pack_examples/winter_2018/super_pack_fr_hiver_chat.png';
import WINTER_CAT_18_EN from '../../assets/pack_examples/winter_2018/super_pack_en_hiver_chat.png';
import WINTER_DOG_18_EN from '../../assets/pack_examples/winter_2018/super_pack_en_chien_hiver.png';
import WINTER_DOG_18_FR from '../../assets/pack_examples/winter_2018/super_pack_fr_hiver_chien.png';

import SPRING_DOG_19_EN from '../../assets/pack_examples/spring_2019/super_pack_en_chien_printemps.png';
import SPRING_DOG_19_FR from '../../assets/pack_examples/spring_2019/super_pack_fr_printemps_chien.png';
import SPRING_CAT_19_EN from '../../assets/pack_examples/spring_2019/super_pack_en_printemps_chat.png';
import SPRING_CAT_19_FR from '../../assets/pack_examples/spring_2019/super_pack_fr_printemps_chat.png';

import SUMMER_CAT_19_FR from '../../assets/pack_examples/summer_2019/super_pack_fr_ete_chat.png';
import SUMMER_CAT_19_EN from '../../assets/pack_examples/summer_2019/super_pack_en_ete_chat.png';
import SUMMER_DOG_19_EN from '../../assets/pack_examples/summer_2019/super_pack_en_chien_ete.png';
import SUMMER_DOG_19_FR from '../../assets/pack_examples/summer_2019/super_pack_fr_ete_chien.png';

import FALL_CAT_19_FR from '../../assets/pack_examples/fall_2019/super_pack_fr_chat_automne.png';
import FALL_CAT_19_EN from '../../assets/pack_examples/fall_2019/super_pack_en_chat_automne.png';
import FALL_DOG_19_EN from '../../assets/pack_examples/fall_2019/super_pack_en_chien_automne.png';
import FALL_DOG_19_FR from '../../assets/pack_examples/fall_2019/super_pack_fr_chien_automne.png';

import WINTER_CAT_19_FR from '../../assets/pack_examples/winter_2019/super_pack_fr_hiver_chat.png';
import WINTER_CAT_19_EN from '../../assets/pack_examples/winter_2019/super_pack_en_hiver_chat.png';
import WINTER_DOG_19_EN from '../../assets/pack_examples/winter_2019/super_pack_en_chien_hiver.png';
import WINTER_DOG_19_FR from '../../assets/pack_examples/winter_2019/super_pack_fr_hiver_chien.png';
import { getNoMoreOrders } from '../../utils/specialEvents';
import AlertCovid from '../home/alertCovid';

class PackExamples extends Component {
    constructor() {
        super();
        this.state = {
            indexAnimal: 0,
        }
    }
    changeAnimal(index) {
        this.setState({ indexAnimal: index });
    }
    render() {
        const { indexLang } = this.props;
        const packs = [
            [[WINTER_DOG_19_EN, WINTER_CAT_19_EN], [WINTER_DOG_19_FR, WINTER_CAT_19_FR]],
            [[FALL_DOG_19_EN, FALL_CAT_19_EN], [FALL_DOG_19_FR, FALL_CAT_19_FR]],
            [[SUMMER_DOG_19_EN, SUMMER_CAT_19_EN], [SUMMER_DOG_19_FR, SUMMER_CAT_19_FR]],
            [[SPRING_DOG_19_EN, SPRING_CAT_19_EN], [SPRING_DOG_19_FR, SPRING_CAT_19_FR]],
            [[WINTER_DOG_18_EN, WINTER_CAT_18_EN], [WINTER_DOG_18_FR, WINTER_CAT_18_FR]],
        ];
        return (
            <div style={{ backgroundColor: '#ffffff', color: 'black', marginTop: '64px' }}>
                <AlertCovid indexLang={indexLang} />
                <Container className='explication-container'>
                    <Row>
                        <Col md={12} className='title-example'>
                            <h2 style={{ marginBottom: 20 }}>{EXAMPLESTITLE[indexLang]}</h2>
                            <p className='explication-text'>{WEDONTSPOILCONTENT1[indexLang]}</p>
                        </Col>
                    </Row>
                    <Row className="noselect" style={{ marginTop: '20px' }}>
                        <Col xs="6">
                            <div
                                onClick={() => this.changeAnimal(0)}
                                className={'choice-box-example float-right-over-575' + (this.state.indexAnimal === 0 ? ' selected-reponse' : '')}><DogPictoSmall indexLang={indexLang} /></div>
                        </Col>
                        <Col xs="6">
                            <div
                                onClick={() => this.changeAnimal(1)}
                                className={'choice-box-example float-left-over-575' + (this.state.indexAnimal === 1 ? ' selected-reponse' : '')}><CatPictoSmall indexLang={indexLang} /></div>
                        </Col>
                    </Row>
                    <Row>
                        {packs.map((packImages, index) => {
                            return (
                                <Col md={12} className='example-section' key={index}>
                                    <img className='example-image' src={packImages[indexLang][this.state.indexAnimal]} alt={['Pack example', 'Exemple de pack'][indexLang]} />
                                </Col>);
                        })}
                    </Row>
                </Container>
                {!getNoMoreOrders() &&
                <div className="slider_jaune_example" style={{ marginTop: '40px' }}>
                    <Container className='example-bottom'>
                        <div className='example-bottom-action'>
                            <Row>
                                <Col md={12}><h2>{STARTTHEPARTY[indexLang]}</h2></Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Link to="/box"><Button className="example-button" size="lg" style={{ marginTop: 20, fontWeight: 'bold', padding: '10px 40px', marginBottom: '25px' }}>GO</Button></Link>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                }
            </div>
        );
    }
}

export default examplesContainer(PackExamples)
