import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import imageFR from '../../assets/headers_generic/homepage_header_mobile_french.jpg';
import imageEn from '../../assets/headers_generic/homepage_header_mobile_english.jpg';

const l = window.navigator.userLanguage || window.navigator.language;
const language = l.substring(0, 2);

let description = '';
let image = '';
let url = '';

if (language === 'fr') {
    description = 'Le GoodyPack est un programme de boîtes surprises saisonnières pour votre chat ou chien! Recevez un pack par saison rempli d\'items surprises à partir de 35$ le pack. Choisissez parmi 2 types de pack et faites des économies de plus de 40% sur des produits BeOneBreed tendance.';
    image = imageFR;
    url = '';
} else {
    description = 'The GoodyPack is a program of seasonal surprise boxes for your cat or dog! Receive one pack per season filled with surprise items starting at $35 per pack. Choose from 2 types of pack and save more than 40% on BeOneBreed trendy products! ';
    image = imageEn;
    url = '';
}

const Head = () => (
    <Helmet>
        <meta charSet="utf-8" />
        <title>GoodyPack</title>
        <meta name="description" content={description} />
        <meta property="og:title" content="GoodyPack" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={url} />
        <meta name="twitter:title" content="GoodyPack" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="window.location.hostname" />
    </Helmet>
)

export default connect(null)(Head)
