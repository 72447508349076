import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { CSSTransitionGroup } from 'react-transition-group';
import history from '../../containers/routers/history';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import Routes from './Routes';
import ScrollToTopOnMount from './ScrollToTopOnMount';

class AppRouter extends Component {
    render () {
        return (
            <Router history={history}>
                <ScrollToTopOnMount>
                    <div>
                        <CSSTransitionGroup
                            transitionName="fadein"
                            transitionAppear={true}
                            transitionAppearTimeout={300}
                            transitionEnter={false}
                            transitionLeave={false}
                        >
                            <Header />
                            <main>
                                <Routes />
                            </main>
                            <Footer />
                        </CSSTransitionGroup>
                    </div>
                </ScrollToTopOnMount>
            </Router>
        )
    }
}

export default AppRouter
