import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import unboxingLeft from '../../assets/unboxing_goodypack_left.jpg';
import unboxingRight from '../../assets/unboxing_goodypack_right.jpg';

const Unboxings = () => {
    return (
        <Container style={{ marginBottom: 30 }}>
            <Row style={{ paddingTop: 10 }}><Col md='12' style={{ padding:10 }}><span style={{ fontFamily: 'Gotham-Bold', fontWeight: 1000, fontSize: 38 }}>#goodypack</span></Col></Row>
            <Row>
                <Col md='6' className='unboxing-left-col'>
                    <img className='unboxing-left' src={unboxingLeft} alt='Unboxings'/>
                </Col>
                <Col md='6' className='unboxing-right-col'>
                    <img className='unboxing-right' src={unboxingRight} alt='Unboxings'/>
                </Col>
            </Row>
        </Container>
    )
}

export default Unboxings
