import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { DATE_CLOSED, DATE_NO_MORE_ORDERS, DATE_STRIPE_CLOSED } from '../constant/otherConst';
const moment = extendMoment(Moment);

export const getHolidays2019 = () => {
    const startDate = new Date(2019, 11, 24);
    const endDate = new Date(2020, 0, 3);
    const date = new Date();
    const range = moment().range(startDate, endDate);
    return range.contains(date);
};

export const getNoMoreOrders = () => {
    const date = new Date();
    return date >= DATE_NO_MORE_ORDERS;
}

export const getStripeClosed = () => {
    const date = new Date();
    return date >= DATE_STRIPE_CLOSED;
}

export const getClosed = () => {
    const date = new Date();
    return date >= DATE_CLOSED;
}
