import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PK_STRIPE, DEVMODE } from '../../constant/otherConst';

const orderContainer = (ComposedComponent) => {
    class OrderContainer extends Component {
        render() {
            const {
                dispatch,
                isFetching,
                order,
                indexLang,
                stripePk,
                submitSucceededAddress,
                submittingAddress,
                errorAddress,
                errorNeck,
                errorCard,
                addresses,
                orderPriceChanged,
                pauseSubscriptionId,
                resumeSubscriptionId,
                confirmation,
                modal,
                title,
                text,
                confirmType,
                orderUpgradeError,
                orderUpgradeSuccess,
                orderUpgrading,
                products,
                upgradeModal,

            } = this.props
            const props = {
                dispatch,
                isFetching,
                order,
                indexLang,
                stripePk,
                submitSucceededAddress,
                submittingAddress,
                errorAddress,
                errorNeck,
                errorCard,
                addresses,
                orderPriceChanged,
                pauseSubscriptionId,
                resumeSubscriptionId,
                confirmation,
                modal,
                title,
                text,
                confirmType,
                orderUpgradeError,
                orderUpgradeSuccess,
                orderUpgrading,
                products,
                upgradeModal,
            }
            return (
                isFetching ? null : <ComposedComponent {...props} />
            )
        }
    }
    return connect(
        ({
            orders: { isFetching, items, submitSucceededAddress, submittingAddress, errorAddress, errorCard, errorNeck, orderPriceChanged, upgradeModal, orderUpgrading, orderUpgradeSuccess, orderUpgradeError },
            langs: { indexLang },
            user: { addresses },
            boxSubscription: { pauseSubscriptionId, resumeSubscriptionId, confirmation, modal, title, text, confirmType },
            products: { products },
        }, {
            match: { params: { orderId } },
        }) => ({
            isFetching,
            order: items.find((item) => item._id === orderId),
            indexLang,
            stripePk: PK_STRIPE[DEVMODE ? 0 : 1],
            submitSucceededAddress,
            submittingAddress,
            errorAddress,
            errorNeck,
            errorCard,
            addresses,
            orderPriceChanged,
            pauseSubscriptionId,
            resumeSubscriptionId,
            confirmation,
            modal,
            title,
            text,
            confirmType,
            orderUpgradeError,
            orderUpgradeSuccess,
            orderUpgrading,
            products,
            upgradeModal,
        }),
    )(OrderContainer)
}

export default orderContainer

