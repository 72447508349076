import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { fetchRecovery } from '../../actions/user';
import renderTextFieldBlack from '../../components/fields/renderTextFieldBlack';
import { COURRIEL, COURRIELINVALID, ENTERINFOTORECOVER, INSCRIPTION, 
    CONNEXION, PASDECOMPTE, RECUPERER, RECUPERERCOMPTE, REQUIS, SUCCESRECOVERY, 
    DEJACOMPTE } from '../../constant/stringTraduction';
import userContainer from '../../containers/user/userContainer';
import SuccessableButton from '../buttons/SuccessableButton';
import './user.css';
import { validateEmail } from '../../utils/other';

let validate;

class Recovery extends Component {
  state = {
      email: null,
  }
  componentDidMount () {
      validate = (values) => {
          const errors = {}
          const requiredFields = ['email']
          requiredFields.forEach((field) => {
              if (!values[field]) {
                  errors[field] = REQUIS[this.props.indexLang]
              }
          })
          if (values.email && !validateEmail(values.email)) {
              errors.email = COURRIELINVALID[this.props.indexLang]
          }
          return errors
      }
  }
  handleFormSubmit = (values) => {
      const { dispatch, indexLang } = this.props;
      this.setState({ email: values.email });
      return dispatch(fetchRecovery(values, indexLang));
  }
  render () {
      const {
          handleSubmit,
          invalid,
          pristine,
          submitSucceeded,
          submitting,
          indexLang,
          user,
      } = this.props

      return (
          <Container className="white-background container-top-margin" style={{ paddingTop: 95 }}>
              <h2>{RECUPERER[indexLang]}</h2>
              <p>{ENTERINFOTORECOVER[indexLang]}</p>
              <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                  <Field name="email" color="black" component={renderTextFieldBlack} label={COURRIEL[indexLang]} fullWidth={true} />
                  <SuccessableButton
                      style={{ marginTop: 10 }}
                      disabled={pristine || invalid}
                      error={user.error ? user.error : ''}
                      label={RECUPERERCOMPTE[indexLang]}
                      reset={null}
                      submitSucceeded={submitSucceeded}
                      submitting={submitting}
                      successLabel={SUCCESRECOVERY[indexLang]}
                  />
              </form>
              <div className="card-actions">
                  <p>{PASDECOMPTE[indexLang]} <Link to="/user/signup">{INSCRIPTION[indexLang]}</Link></p>
                  <p>{DEJACOMPTE[indexLang]} <Link to="/user/signin">{CONNEXION[indexLang]}</Link></p>
              </div>
          </Container>
      )
  }
}

export default userContainer(reduxForm({
    form: 'recovery',
    validate,
})(Recovery))
