import React, { Component } from 'react';
import { Container } from 'reactstrap';
import userContainer from '../../containers/user/userContainer';
import { reduxForm } from 'redux-form';
import { unsubscribe } from '../../actions/user';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Button } from '@material-ui/core';
import { UNSUBSCRIBE, UNSUBSCRIBE_TEXT, UNSUBSCRIBE_SUCCESS, COURRIELINVALID } from '../../constant/stringTraduction';
import { validateEmail } from '../../utils/other';

class Unsubscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };
        this.unsubscribe = this.unsubscribe.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
    }

    unsubscribe() {
        const { dispatch } = this.props;
        if (validateEmail(this.state.email)) {
            dispatch(unsubscribe(this.state.email));
        } else {
            dispatch({ type: 'CONFIRM_EMAIL_FAILED', values: COURRIELINVALID });
        }
    }

    handleChangeEmail(event) {
        this.setState({ email: event.target.value }); 
    }

    render () {
        const {
            indexLang,
            user,
        } = this.props

        return (
            <Container className="white-background container-top-margin">
                <div>
                    {user.isUpdating ? <LinearProgress style={{ marginBottom:20 }}/> : ''}
                    <div style={{ textAlign:'center', paddingTop:20 }}>
                        <p>{user.confirmedEmail ? UNSUBSCRIBE_SUCCESS[indexLang] : UNSUBSCRIBE_TEXT[indexLang]}</p>
                        {user.error !== null ? <p className='error'>{user.error[indexLang]}</p> : ''}
                        <input className='concours-input' type='email' value={this.state.email} onChange={this.handleChangeEmail}/><br/>
                        {!user.confirmedEmail ? 
                            <Button 
                                style={{
                                    padding: '10px 30px',
                                    margin:'20px',
                                    fontFamily: 'Gotham-Black',
                                    border: 'none',
                                    backgroundColor:'#fff100',
                                }}
                                onClick={this.unsubscribe}>
                                {UNSUBSCRIBE[indexLang]}
                            </Button> 
                            : 
                            ''
                        }
                    </div>
                </div>
            </Container>
        )
    }
}
  
export default userContainer(reduxForm({
    form: 'email_unsubscribe',
})(Unsubscribe))
