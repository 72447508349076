import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { ADRESSEAJOUTER, AJOUTERADRESSE } from '../../constant/stringTraduction';
import validateAddress from '../../utils/validateAddress';
import SuccessableButton from '../buttons/SuccessableButton';
import AddressFields from './AddressFields';

class AddressAdd extends Component {
    render () {
        const {
            error,
            handleSubmit,
            onAddressAdd,
            reset,
            submitSucceeded,
            submitting,
            indexLang,
            pristine,
            invalid,
        } = this.props
        return (
            <div className='white-background'>
                <h3>{AJOUTERADRESSE[indexLang]}</h3>
                <form onSubmit={handleSubmit(onAddressAdd)}>
                    <AddressFields
                        autoFocus={false} indexLang={indexLang} />
                    <div className="button-container">
                        <SuccessableButton
                            style={{ width: '50%' }}
                            disabled={pristine || invalid}
                            error={error}
                            label={AJOUTERADRESSE[indexLang]}
                            reset={reset}
                            submitSucceeded={submitSucceeded}
                            submitting={submitting}
                            successLabel={ADRESSEAJOUTER[indexLang]}
                        />
                    </div>
                </form>
            </div>
        )
    }
}

export default reduxForm({
    validate: validateAddress,
    form: 'addressAdd',
    destroyOnUnmount: false,
})(AddressAdd)
