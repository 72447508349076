import React, { Component } from 'react'
import { connect } from 'react-redux'

const homeContainer = (ComposedComponent) => {
    class HomeContainer extends Component {
        render () {
            return (
                <ComposedComponent {...this.props} />
            )
        }
    }
    const mapStateToProps = ({
        user,
        langs: { indexLang },
        orders: { items },
        boxSubscription: { trial, customSlider },
    }) => ({
        user,
        indexLang,
        orders: items,
        trial,
        customSlider,
    })
    return connect(mapStateToProps)(HomeContainer)
}

export default homeContainer
