import CircularProgress from '@material-ui/core/CircularProgress';
import FontIcon from '@material-ui/core/Icon';
import React from 'react';
import { Button, Col, Container, Input, Progress, Row, Label, FormGroup } from 'reactstrap';
import { setNeckSize, setQuestionCpt, setQuestionCptAvecReponse } from '../../actions/boxSubscription';
import { fetchAddToCart } from '../../actions/cart';
import CatCircEnglish from '../../assets/circumference_cat_english.png';
import CatCircFrench from '../../assets/circumference_cat_french.png';
import DogCircEnglish from '../../assets/circumference_dog_english.png';
import DogCircFrench from '../../assets/circumference_dog_french.png';
import {
    QUESTION3_3, QUESTION1, QUESTION2, QUESTION3_1, QUESTION3_2, QUESTIONFINAL,
    SELECTIONSUPERPARTYPACK, SIZEDOESNTINFLUENCE, SUBSCRIBE, CHANGETRIALTOSUB,
    CHANGEDTOSUB, YES, NO, QUESTION3B_1, SEEPREVIOUSPACK, TRYOUT_RIGHT, TRIALPACK,
    BEDEXPLAINATIONCAT1, BEDEXPLAINATIONCAT2, BEDEXPLAINATIONDOGSM1, BEDEXPLAINATIONDOGSM2,
    BEDEXPLAINATIONDOGL1, BEDEXPLAINATIONDOGL2, SMALL_DOG_NOTICE,
} from '../../constant/stringTraduction';
import boxSubscriptionContainer from '../../containers/boxSubscription/boxSubscriptionContainer';
import './boxSubscription.css';
import CatMediumPicto from './picto/CatMediumPicto';
import CatPicto from './picto/CatPicto';
import CatSmallPicto from './picto/CatSmallPicto';
import DogLargePicto from './picto/DogLargePicto';
import DogMediumPicto from './picto/DogMediumPicto';
import DogPicto from './picto/DogPicto';
import DogSmallPicto from './picto/DogSmallPicto';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';

// ************** SUMMER
// Dog images
import DogDiscoveryEnglishGeneric from '../../assets/web_packs_generic/desktop/dog/new_pack_dog_EN_768x350.png';
import DogDiscoveryFrenchGeneric from '../../assets/web_packs_generic/desktop/dog/new_pack_dog_FR_768x350.png';
import DogSuperPartyPackEnglishGeneric from '../../assets/web_packs_generic/desktop/dog/new_pack_super_dog_EN_768x350.png';
import DogSuperPartyPackFrenchGeneric from '../../assets/web_packs_generic/desktop/dog/new_pack_super_dog_FR_768x350.png';
import DogSuperPartyPackBedEnglishGeneric from '../../assets/web_packs_generic/desktop/dog/bed_new_pack_dog_EN_768x290.png';
import DogSuperPartyPackBedFrenchGeneric from '../../assets/web_packs_generic/desktop/dog/bed_new_pack_dog_FR_768x290.png';

import DogDiscoveryEnglishMobileGeneric from '../../assets/web_packs_generic/mobile/dog/new_pack_dog_EN_285x350.png';
import DogDiscoveryFrenchMobileGeneric from '../../assets/web_packs_generic/mobile/dog/new_pack_dog_285x350_FR.png';
import DogSuperPartyPackEnglishMobileGeneric from '../../assets/web_packs_generic/mobile/dog/new_pack_dog_super_EN_285x350.png';
import DogSuperPartyPackFrenchMobileGeneric from '../../assets/web_packs_generic/mobile/dog/new_pack_super_dog_285x350_FR.png';
import DogSuperPartyPackBedEnglishMobileGeneric from '../../assets/web_packs_generic/mobile/dog/bed_new_pack_dog_EN_285x285.png';
import DogSuperPartyPackBedFrenchMobileGeneric from '../../assets/web_packs_generic/mobile/dog/bed_new_pack_dog_FR_285x285.png';

// Cat images
import CatDiscoveryEnglishGeneric from '../../assets/web_packs_generic/desktop/cat/new_pack_cat_en_768x350.png';
import CatDiscoveryFrenchGeneric from '../../assets/web_packs_generic/desktop/cat/new_pack_cat_FR_768x350.png';
import CatSuperPartyPackEnglishGeneric from '../../assets/web_packs_generic/desktop/cat/pack_super_cat_en_768x350.png';
import CatSuperPartyPackFrenchGeneric from '../../assets/web_packs_generic/desktop/cat/pack_cat_super_FR_768x350.png';
import CatSuperPartyPackBedEnglishGeneric from '../../assets/web_packs_generic/desktop/cat/bed_new_pack_cat_EN_768x290.png';
import CatSuperPartyPackBedFrenchGeneric from '../../assets/web_packs_generic/desktop/cat/bed_new_pack_cat_FR_768x290.png';

import CatDiscoveryEnglishMobileGeneric from '../../assets/web_packs_generic/mobile/cat/new_cat_pack_EN_285x350.png';
import CatDiscoveryFrenchMobileGeneric from '../../assets/web_packs_generic/mobile/cat/new_cat_pack_FR_285x350.png';
import CatSuperPartyPackEnglishMobileGeneric from '../../assets/web_packs_generic/mobile/cat/new_cat_super_pack_EN_285x350.png';
import CatSuperPartyPackFrenchMobileGeneric from '../../assets/web_packs_generic/mobile/cat/new_cat_super_pack_FR_285x350.png';
import CatSuperPartyPackBedEnglishMobileGeneric from '../../assets/web_packs_generic/mobile/cat/bed_new_pack_cat_EN_285x285.png';
import CatSuperPartyPackBedFrenchMobileGeneric from '../../assets/web_packs_generic/mobile/cat/bed_new_pack_cat_FR_285x285.png';

// ************** WINTER
// Dog images
import DogDiscoveryEnglishWinter from '../../assets/web_packs_winter/desktop/dog/winter_discovery_pack_dog_EN.png';
import DogDiscoveryFrenchWinter from '../../assets/web_packs_winter/desktop/dog/winter_discovery_pack_dog_FR.png';
import DogSuperPartyPackEnglishWinter from '../../assets/web_packs_winter/desktop/dog/winter_super_pack_dog_EN.png';
import DogSuperPartyPackFrenchWinter from '../../assets/web_packs_winter/desktop/dog/winter_super_pack_dog_FR.png';
import DogSuperPartyPackBedEnglishWinter from '../../assets/web_packs_winter/desktop/dog/winter_super_pack_dog_bed_EN.png';
import DogSuperPartyPackBedFrenchWinter from '../../assets/web_packs_winter/desktop/dog/winter_super_pack_dog_bed_FR.png';

import DogDiscoveryEnglishMobileWinter from '../../assets/web_packs_winter/mobile/dog/winter_discovery_pack_dog_EN.png';
import DogDiscoveryFrenchMobileWinter from '../../assets/web_packs_winter/mobile/dog/winter_discovery_pack_dog_FR.png';
import DogSuperPartyPackEnglishMobileWinter from '../../assets/web_packs_winter/mobile/dog/winter_super_pack_dog_EN.png';
import DogSuperPartyPackFrenchMobileWinter from '../../assets/web_packs_winter/mobile/dog/winter_super_pack_dog_FR.png';
import DogSuperPartyPackBedEnglishMobileWinter from '../../assets/web_packs_winter/mobile/dog/winter_super_pack_dog_bed_EN.png';
import DogSuperPartyPackBedFrenchMobileWinter from '../../assets/web_packs_winter/mobile/dog/winter_super_pack_dog_bed_FR.png';

// Cat images
import CatDiscoveryEnglishWinter from '../../assets/web_packs_winter/desktop/cat/winter_discovery_pack_cat_EN.png';
import CatDiscoveryFrenchWinter from '../../assets/web_packs_winter/desktop/cat/winter_discovery_pack_cat_FR.png';
import CatSuperPartyPackEnglishWinter from '../../assets/web_packs_winter/desktop/cat/winter_super_pack_cat_EN.png';
import CatSuperPartyPackFrenchWinter from '../../assets/web_packs_winter/desktop/cat/winter_super_pack_cat_FR.png';
import CatSuperPartyPackBedEnglishWinter from '../../assets/web_packs_winter/desktop/cat/winter_super_pack_cat_bed_EN.png';
import CatSuperPartyPackBedFrenchWinter from '../../assets/web_packs_winter/desktop/cat/winter_super_pack_cat_bed_FR.png';

import CatDiscoveryEnglishMobileWinter from '../../assets/web_packs_winter/mobile/cat/winter_discovery_pack_cat_EN.png';
import CatDiscoveryFrenchMobileWinter from '../../assets/web_packs_winter/mobile/cat/winter_discovery_pack_cat_FR.png';
import CatSuperPartyPackEnglishMobileWinter from '../../assets/web_packs_winter/mobile/cat/winter_super_pack_cat_EN.png';
import CatSuperPartyPackFrenchMobileWinter from '../../assets/web_packs_winter/mobile/cat/winter_super_pack_cat_FR.png';
import CatSuperPartyPackBedEnglishMobileWinter from '../../assets/web_packs_winter/mobile/cat/winter_super_pack_cat_bed_EN.png';
import CatSuperPartyPackBedFrenchMobileWinter from '../../assets/web_packs_winter/mobile/cat/winter_super_pack_cat_bed_FR.png';

import { getNextPackDate } from '../../utils/dates';
import { SLUG_START, SLUG_LENGTH, SLUG_SEP, SLUG_BLANK } from '../../constant/otherConst';
import { getNoMoreOrders } from '../../utils/specialEvents';

class BoxSubscription extends React.Component {
    constructor() {
        super();
        this.state = {
            questions: 
            [
                [4, 4],
                [1, 1],
            ],
            neckSize: null,
            changedToSub: false,
            standardNeckSize: null,
            onMobile: false,
        }
        this.setNeckSize = this.setNeckSize.bind(this);
    }

    componentDidMount() {
        if (window.innerWidth <= 768) {
            this.setState({ onMobile: true });
        }
    }

    componentWillUnmount() {
        window.clearInterval(this.idUltimateInterval);
    }

    setNeckSize(e) {
        const {
            indexQuestion,
            dispatch,
        } = this.props;
        this.setState({ neckSize: e.target.value });
        dispatch(setNeckSize(indexQuestion, e.target.value, this.state.standardNeckSize));
    }

    handleTrialChange() {
        const {
            dispatch,
        } = this.props;
        this.setState({ changedToSub: true });
        dispatch({ type: 'SET_TRIAL', payload: false });
    }

    handleChoice(indexReponse, p_value) {
        const {
            dispatch,
        } = this.props;

        dispatch(setQuestionCptAvecReponse(indexReponse, p_value));
        let x = document.getElementById('next_previous_buttons')
        smoothScrollIntoView(x, { scrollMode: 'if-needed', block: 'center', behavior: 'smooth' });
    }

    buildSlug() {
        const { reponses, indexAnimal, trial } = this.props;
        const questionsNb = this.state.questions[trial ? 1 : 0][indexAnimal];
        let slug = SLUG_START[trial ? 1 : 0] + SLUG_SEP;
        for (let i = 0; i < questionsNb || slug.length !== SLUG_LENGTH; i++) {
            if (i !== 2) { // NECKSIZE
                slug += (reponses[i] != null && (trial === false || i === 0)) ? reponses[i] : SLUG_BLANK;
                if (slug.length < SLUG_LENGTH - 1) {
                    slug += SLUG_SEP;
                }
            }
        }
        return slug;
    }

    addToCart() {
        if (getNoMoreOrders()) {
            return;
        }
        const { dispatch, user, history, neckSize, trial, standardNeckSize } = this.props;
        const slug = this.buildSlug();
        dispatch(fetchAddToCart({
            type: 'ADD_TO_CART',
            userId: user._id,
            slug: slug,
            neckSize: neckSize,
            trial: trial,
            standardNeckSize: standardNeckSize,
        }, history));
    }

    handlePrev(indexQuestion) {
        const { dispatch } = this.props;
        dispatch(setQuestionCpt(indexQuestion - 1));
        window.scrollTo(0, 0);
    }

    handleNext() {
        const { dispatch, indexQuestion, trial, indexAnimal } = this.props;
        const calculated_length = this.state.questions[trial ? 1 : 0][indexAnimal];
        if (indexQuestion >= calculated_length - 1) {
            this.addToCart();
        } else {
            dispatch(setQuestionCpt(indexQuestion + 1));
            window.scrollTo(0, 0);
        }
    }

    setStandardNeckSize = (e) => {
        this.setState({ standardNeckSize: e.target.value });
        const {
            indexQuestion,
            dispatch,
        } = this.props;
        dispatch(setNeckSize(indexQuestion, 0, e.target.value));
    }

    render() {
        const {
            indexQuestion,
            indexAnimal,
            reponses,
            indexLang,
            trial,
            typeId,
            isAdding,
        } = this.props;
        let checkStandardNeckSize = '';
        if (this.props.standardNeckSize) {
            checkStandardNeckSize = this.props.standardNeckSize;
        } else {
            checkStandardNeckSize = this.state.standardNeckSize;
        }
        let questions = [];
        if (indexAnimal === 0) {
            questions = [QUESTION1[indexLang], QUESTION2[indexLang], QUESTION3_1[indexLang], ''];
        } else {
            questions = [QUESTION1[indexLang], QUESTION2[indexLang], QUESTION3B_1[indexLang], ''];
        }

        const questionFinal = QUESTIONFINAL[indexLang];
        const onMobile = this.state.onMobile ? 1 : 0;
        const indexSize = reponses[1];
        // Dimension 1: onMobile, 2: saison, 3: lang, 4: animal, 5: type
        const packImages =
        [
            [// Subscription
                [//Desktop
                    // Winter
                    [
                        [[DogSuperPartyPackEnglishWinter, DogSuperPartyPackBedEnglishWinter], [CatSuperPartyPackEnglishWinter, CatSuperPartyPackBedEnglishWinter]],
                        [[DogSuperPartyPackFrenchWinter, DogSuperPartyPackBedFrenchWinter], [CatSuperPartyPackFrenchWinter, CatSuperPartyPackBedFrenchWinter]],
                    ],
                    // Summer
                    [
                        [[DogSuperPartyPackEnglishGeneric, DogSuperPartyPackBedEnglishGeneric], [CatSuperPartyPackEnglishGeneric, CatSuperPartyPackBedEnglishGeneric]],
                        [[DogSuperPartyPackFrenchGeneric, DogSuperPartyPackBedFrenchGeneric], [CatSuperPartyPackFrenchGeneric, CatSuperPartyPackBedFrenchGeneric]],
                    ],
                ],
                [//Mobile
                    // Winter
                    [
                        [[DogSuperPartyPackEnglishMobileWinter, DogSuperPartyPackBedEnglishMobileWinter], [CatSuperPartyPackEnglishMobileWinter, CatSuperPartyPackBedEnglishMobileWinter]],
                        [[DogSuperPartyPackFrenchMobileWinter, DogSuperPartyPackBedFrenchMobileWinter], [CatSuperPartyPackFrenchMobileWinter, CatSuperPartyPackBedFrenchMobileWinter]],
                    ],
                    // Summer
                    [
                        [[DogSuperPartyPackEnglishMobileGeneric, DogSuperPartyPackBedEnglishMobileGeneric], [CatSuperPartyPackEnglishMobileGeneric, CatSuperPartyPackBedEnglishMobileGeneric]],
                        [[DogSuperPartyPackFrenchMobileGeneric, DogSuperPartyPackBedFrenchMobileGeneric], [CatSuperPartyPackFrenchMobileGeneric, CatSuperPartyPackBedFrenchMobileGeneric]],
                    ],  
                ],
            ],
            [// Trials
                [//Desktop
                    // Winter
                    [
                        [[DogDiscoveryEnglishWinter], [CatDiscoveryEnglishWinter]],
                        [[DogDiscoveryFrenchWinter], [CatDiscoveryFrenchWinter]],
                    ],
                    // Summer
                    [
                        [[DogDiscoveryEnglishGeneric], [CatDiscoveryEnglishGeneric]],
                        [[DogDiscoveryFrenchGeneric], [CatDiscoveryFrenchGeneric]],
                    ],
                ],
                [//Mobile
                    // Winter
                    [
                        [[DogDiscoveryEnglishMobileWinter], [CatDiscoveryEnglishMobileWinter]],
                        [[DogDiscoveryFrenchMobileWinter], [CatDiscoveryFrenchMobileWinter]],
                    ],
                    // Summer
                    [
                        [[DogDiscoveryEnglishMobileGeneric], [CatDiscoveryEnglishMobileGeneric]],
                        [[DogDiscoveryFrenchMobileGeneric], [CatDiscoveryFrenchMobileGeneric]],
                    ],  
                ],
            ],
        ];
        const circImages = [[DogCircEnglish, DogCircFrench], [CatCircEnglish, CatCircFrench]];

        const bedExplainations1 = [
            [BEDEXPLAINATIONDOGSM1, BEDEXPLAINATIONDOGSM1, BEDEXPLAINATIONDOGL1],
            [BEDEXPLAINATIONCAT1, BEDEXPLAINATIONCAT1],
        ];
        const bedExplainations2 = [
            [BEDEXPLAINATIONDOGSM2, BEDEXPLAINATIONDOGSM2, BEDEXPLAINATIONDOGL2],
            [BEDEXPLAINATIONCAT2, BEDEXPLAINATIONCAT2],
        ];

        const minNeckSize = [18, 15];
        const maxNeckSize = [68, 25];
        const circumferences = [''];
        const { indexSaison } = getNextPackDate();

        for (let i = minNeckSize[indexAnimal]; i <= maxNeckSize[indexAnimal]; i++) {
            circumferences.push(i);
        }

        return (
            <div>
                {!getNoMoreOrders() &&
                <div>
                    <Container style={{ textAlign: 'center', color: '#ffffff', paddingTop: '50px' }}>
                        <hr />
                        {!trial && !typeId &&
                        <Row className="progress-bar-box">
                            <Col>
                                <a className='link-previous' href='learn-more#unboxings_gallery' target="_blank">{SEEPREVIOUSPACK[indexLang]}</a>
                            </Col>
                        </Row>
                        }
                        {trial &&
                        <div>
                            <span className={'boxsub-trial boxsub-left'}>{TRIALPACK[indexLang]} - </span>
                            <span className={'boxsub-trial boxsub-right'}>{TRYOUT_RIGHT[indexLang]}</span>
                        </div>
                        }
                        <Row className="progress-bar-box">
                            <Col>
                                <Progress value={indexQuestion * (100 / questions.length)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="header-number">{indexQuestion + 1}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ fontSize: '2rem', padding: '15px 0 30px 0' }}>
                                <p>{indexQuestion < questions.length - 1 ? questions[indexQuestion] : questionFinal}</p>
                                {indexQuestion === 2 && indexAnimal === 0 &&
                                <div style={{ fontSize: 18 }}>{QUESTION3_2[indexLang]}<b>*{QUESTION3_3[indexLang]}*</b>.</div>
                                }
                            </Col>
                        </Row>
                        {trial && <Row className='trial-box-reminder-row'><div>{CHANGETRIALTOSUB[indexLang]}</div><Button className='yellow-button' onClick={() => this.handleTrialChange()}>{SUBSCRIBE[indexLang].toUpperCase()}</Button></Row>}
                        {this.state.changedToSub && <Row className='trial-box-reminder-row'>{CHANGEDTOSUB[indexLang]}</Row>}
                        {indexQuestion === 0 &&
                    <div>
                        <Row className="noselect">
                            <Col sm="6">
                                <div
                                    onClick={() => this.handleChoice(0, 0)}
                                    className={'choice-box float-right-over-575' + (reponses[0] === 0 ? ' selected-reponse' : '')}><DogPicto indexLang={indexLang} /></div>
                            </Col>
                            <Col sm="6">
                                <div
                                    onClick={() => this.handleChoice(0, 1)}
                                    className={'choice-box float-left-over-575' + (reponses[0] === 1 ? ' selected-reponse' : '')}><CatPicto indexLang={indexLang} /></div>
                            </Col>
                        </Row>
                        {(trial && reponses[0] != null) &&
                        <div>
                            <Row className="noselect">
                                <Col md='2'></Col>
                                <Col md='8'>
                                    <div
                                        className={'float-right-over-511 final-pack-choice' + (reponses[questions.length - 1] === 0 ? ' selected-reponse' : '')}>
                                        <img className='final-pack-image' src={packImages[1][onMobile][indexSaison][indexLang][indexAnimal][0]} alt={SELECTIONSUPERPARTYPACK[indexLang]} width="583" height="250" />
                                    </div>
                                </Col>
                                <Col md='2'></Col>
                            </Row>
                            {indexAnimal === 0 &&
                            <Row><div style={{ textAlign: 'center', margin: 'auto', fontWeight: 800 }}>{SMALL_DOG_NOTICE[indexLang]}</div></Row>
                            }
                        </div>
                        }

                    </div>
                        }
                        {indexQuestion === 1 &&
                        <div className="noselect">
                            <Row>
                                <Col sm={reponses[0] === 0 ? '' : '6'} lg={reponses[0] === 0 ? '4' : ''} >
                                    <div
                                        onClick={() => this.handleChoice(indexQuestion, 0)}
                                        className={'choice-box' + (reponses[0] === 0 ? ' float-right-over-511' : ' float-right-over-575') + (reponses[1] === 0 ? ' selected-reponse' : '')}>{reponses[0] === 0 ? <DogSmallPicto indexLang={indexLang} /> : <CatSmallPicto indexLang={indexLang} />}</div>
                                </Col>
                                <Col sm={reponses[0] === 0 ? '' : '6'} lg={reponses[0] === 0 ? '4' : ''}>
                                    <div
                                        onClick={() => this.handleChoice(indexQuestion, 1)}
                                        className={'choice-box' + (reponses[0] === 0 ? '' : ' float-left-over-575') + (reponses[1] === 1 ? ' selected-reponse' : '')}>{reponses[0] === 0 ? <DogMediumPicto indexLang={indexLang} /> : <CatMediumPicto indexLang={indexLang} />}</div>
                                </Col>
                                {indexAnimal === 0 ?
                                    <Col lg="4">
                                        <div
                                            onClick={() => this.handleChoice(indexQuestion, 2)}
                                            className={'choice-box float-left-over-511' + (reponses[1] === 2 ? ' selected-reponse' : '')}><DogLargePicto indexLang={indexLang} /></div>
                                    </Col>
                                    :
                                    null
                                }

                            </Row>
                            <Row><div style={{ textAlign: 'center', margin: 'auto' }}>{SIZEDOESNTINFLUENCE[indexLang]}</div></Row>
                            <Row><div style={{ textAlign: 'center', margin: 'auto', fontWeight: 800 }}>{SMALL_DOG_NOTICE[indexLang]}</div></Row>
                        </div>
                        }
                        {indexQuestion === 2 &&
                        <div className="noselect">
                            <Row style={{ marginBottom: 30 }}>
                                <Col lg={{ size: 8, offset: 2 }}><img className="img-fluid" src={circImages[indexAnimal][indexLang]} alt="Necksize" /></Col>
                            </Row>
                            <Row>
                                <Col lg="5" />
                                <Col lg="2">
                                    <div>
                                        {indexAnimal === 0 ?
                                            <Input type="select" max={maxNeckSize[indexAnimal]} min={minNeckSize[indexAnimal]} value={this.state.neckSize ? this.state.neckSize : 'Any'} onClick={this.setNeckSize} onChange={this.setNeckSize}>
                                                {circumferences.map((value) => {
                                                    return <option key={value} disabled={value === '' ? true : false} value={value}>{value} cm</option>
                                                })}
                                            </Input>
                                            :
                                            <div style={{ margin: 'auto', maxWidth: '60px', textAlign: 'left' }}>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="couStd" value="yes" onChange={this.setStandardNeckSize} checked={checkStandardNeckSize === 'yes'} />{' '}
                                                        {YES[indexLang]}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="couStd" value="no" onChange={this.setStandardNeckSize} checked={checkStandardNeckSize === 'no'} />{' '}
                                                        {NO[indexLang]}
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        }
                                    </div>
                                </Col>
                                <Col lg="5" />
                            </Row>
                        </div>
                        }
                        {indexQuestion >= questions.length - 1 &&
                        <div className="noselect">
                            <div>
                                <Row>
                                    <Col md='2'></Col>
                                    <Col md='8'>
                                        <div
                                            onClick={(e) => this.handleChoice(indexQuestion, 0)}
                                            className={'choice-box float-right-over-511 final-pack-choice' + (reponses[questions.length - 1] === 0 ? ' selected-reponse' : '')}>
                                            <img className='final-pack-image' src={packImages[0][onMobile][indexSaison][indexLang][indexAnimal][0]} alt={SELECTIONSUPERPARTYPACK[indexLang]} width="583" height="250" />
                                        </div>
                                    </Col>
                                    <Col md='2'></Col>
                                </Row>
                                <Row>
                                    <Col md='2'></Col>
                                    <Col md='8' className='bed-explain-section'>
                                        <div className='save-over-section'>{bedExplainations2[indexAnimal][indexSize][indexLang]}</div>
                                        {bedExplainations1[indexAnimal][indexSize][indexLang]}
                                    </Col>
                                    <Col md='2'></Col>
                                </Row>
                                <Row>
                                    <Col md='2'></Col>
                                    <Col md='8'>
                                        <div
                                            onClick={(e) => this.handleChoice(indexQuestion, 1)}
                                            className={'choice-box final-pack-choice' + (reponses[questions.length - 1] === 1 ? ' selected-reponse' : '')}>
                                            <img className='final-pack-image' src={packImages[0][onMobile][indexSaison][indexLang][indexAnimal][1]} alt={SELECTIONSUPERPARTYPACK[indexLang]} width="583" height="250" />
                                        </div>
                                    </Col>
                                    <Col md='2'></Col>
                                </Row>
                            </div>
                        </div>
                        }
                        <Row id="arrows">
                            <Col id='next_previous_buttons' style={{ margin: 'auto', marginTop: 30 }} sm="4">
                                {
                                    indexQuestion > 0 &&
                                <Button style={{ paddingTop: '6px', marginBottom: '10px', marginRight: '5px' }} onClick={() => {
                                    this.handlePrev(indexQuestion);
                                }}>
                                    <FontIcon
                                        className="fa fa-arrow-left"
                                        style={{ color: '#ffffff' }}
                                    />
                                </Button>
                                }
                                {Object.keys(reponses).length > indexQuestion && indexQuestion <= this.state.questions[trial ? 1 : 0][indexAnimal] - 1 &&
                                <Button
                                    id='next-arrow'
                                    style={{ paddingTop: '6px', marginBottom: '10px' }}
                                    onClick={() => {
                                        this.handleNext();
                                    }}>
                                    {isAdding ?
                                        <CircularProgress size={24} style={{ verticalAlign: 'middle', color: '#000000' }} /> :
                                        <FontIcon
                                            className="fa fa-arrow-right"
                                            style={{ color: '#ffffff' }}
                                        />
                                    }
                                </Button>
                                }
                            </Col>
                        </Row>
                        <hr />
                    </Container >
                    <div className="motif_background">
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default boxSubscriptionContainer(BoxSubscription)
