import React, { Component } from 'react'
import { connect } from 'react-redux'

const langContainer = (ComposedComponent) => {
    class LangContainer extends Component {
        render () {
            return (
                <ComposedComponent {...this.props} />
            )
        }
    }
    const mapStateToProps = ({
        langs: { indexLang },
        user: { _id },
    }) => ({
        indexLang,
        _id,
    })
    return connect(mapStateToProps)(LangContainer)
}

export default langContainer
