import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import BoxSubscription from '../box/BoxSubscription';
import Faq from '../faq/Faq';
import CartPage from '../cart/CartPage';
import Home from '../home/Home';
import LearnMore from '../learnmore/LearnMore';
import NotFoundPage from '../not-found/NotFoundPage';
import AdminOrderDetailPage from '../orders/AdminOrderDetailPage';
import OrderAdd from '../orders/OrderAdd';
import OrderConfirmation from '../orders/OrderConfirmation';
import OrderDetailPage from '../orders/OrderDetailPage';
import Recovery from '../user/Recovery';
import Reset from '../user/Reset';
import Signin from '../user/Signin';
import Signup from '../user/Signup';
import UserProfilePage from '../user/UserProfilePage';
import AdminUsersPage from '../users/AdminUsersPage';
import PrivateRoute from './PrivateRoute';
import TOS from '../staticPages/TOS.js';
import PrivacyPolicy from '../staticPages/privacyPolicy';
import ReturnPolicy from '../returnPolicy/ReturnPolicy';
import OrderModification from '../orders/OrderModification';
import RetroactioUnsubscribe from '../retroaction/retroactionUnsubscribe';
import RetroactionAppreciation from '../retroaction/retroactionAppreciation';
import RetroactionThanks from '../retroaction/retroactionThanks';
import Admin from '../admin/Admin';
import UserConfirmation from '../user/UserConfirmation';
import ReferralProgram from '../staticPages/referralProgram';
import WhyGP from '../whygp/WhyGP';
import EmailConfirmation from '../user/EmailConfirmation'
import PackExamples from '../examples/PackExamples';
import Unsubscribe from '../user/Unsubscribe';
import { TRIAL_URL, GPWINTER_URL } from '../../constant/otherConst';

const Routes = () =>
    <Switch>
        <Route exact path="/" component={(Home)} />
        <Route exact path="/box" component={(BoxSubscription)} />
        <Route exact path="/learn-more" component={(LearnMore)} />
        <Route exact path="/whygp" component={(WhyGP)} />
        <Route exact path="/faq" component={(Faq)} />
        <Route exact path='/tos' component={(TOS)} />
        <Route exact path='/privacy-policy' component={(PrivacyPolicy)} />
        <Route exact path="/user/cart" component={(CartPage)} />
        <PrivateRoute exact path="/admin/" requiredRoles={['admin']} component={Admin} />
        <PrivateRoute exact path="/admin/orders/:orderId" requiredRoles={['admin']} component={AdminOrderDetailPage} />
        <PrivateRoute exact path="/admin/users" requiredRoles={['owner']} component={AdminUsersPage} />
        <PrivateRoute exact path="/user/order" requiredRoles={['user']} component={(OrderAdd)} />
        <PrivateRoute exact path="/user/order/thankyou/:orderId" requiredRoles={['user']} component={withRouter(OrderConfirmation)} /> {/* Ne pas changer: besoin pour le SEO */}
        <PrivateRoute exact path="/user/thankyou/" requiredRoles={['user']} component={withRouter((UserConfirmation))} /> {/* Ne pas changer: besoin pour le SEO */}
        <PrivateRoute exact path="/user/orders/:orderId" requiredRoles={['user']} component={withRouter(OrderDetailPage)} />
        <PrivateRoute exact path="/user/orders/modify/:orderId" requiredRoles={['user']} component={withRouter(OrderModification)} />
        <PrivateRoute exact path="/user/profile" requiredRoles={['user', 'admin']} component={UserProfilePage} />
        <PrivateRoute exact path="/retroaction/appreciation/:orderId" requiredRoles={['user']} component={withRouter(RetroactionAppreciation)} />
        <PrivateRoute exact path="/retroaction/unsubscribe/:orderId" requiredRoles={['user']} component={withRouter(RetroactioUnsubscribe)} />
        <Route exact path="/retroaction/thanks/" component={withRouter(RetroactionThanks)} />
        <Route exact path="/user/recovery" component={Recovery} />
        <Route exact path="/user/reset/:resetToken" component={Reset} />
        <Route exact path="/user/signup" component={(Signup)} />
        <Route exact path="/user/signin" component={(Signin)} />
        <Route exact path="/user/confirmation/:userId" component={(EmailConfirmation)} />
        <Route exact path="/return-policy" component={(ReturnPolicy)} />
        <Route exact path="/pack-examples" component={(PackExamples)} />
        <Route exact path='/referral-program' component={(ReferralProgram)} />
        <Route exact path='/unsubscribe' component={(Unsubscribe)} />
        <Route exact path={'/' + TRIAL_URL} component={(Home)} />
        <Route exact path={'/' + GPWINTER_URL} component={(Home)} />
        <Route component={NotFoundPage} />
    </Switch>

export default Routes
