import React from 'react';
import { Link } from 'react-router-dom';
import { toggleDrawer } from '../../actions/drawer';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import flag from '../../assets/canada_flag.png';
import Lang from '../lang/Lang';

const HeaderBrand = ({
    className,
    color,
    fontWeight,
    letterSpacing,
    name,
    textShadow,
    dispatch,
}) => {
    const handleDrawerToggle = () => {
        dispatch(toggleDrawer())
    }
    return (
        <div style={{ display: 'flex' }}>
            <IconButton
                style={{ marginTop: '8px', marginLeft: '8px' }}
                color="inherit"
                aria-label="Open drawer"
                onClick={handleDrawerToggle}
            >
                <MenuIcon />
            </IconButton>
            <Link
                className={className}
                to="/"
            >
                <div
                    className="header-brand"
                    style={{
                        color,
                        fontWeight,
                        letterSpacing,
                        textShadow,
                    }}>
                    {name}
                    <img style={{ marginLeft: 5 }} src={flag} alt="Flag"/>
                </div>
            </Link>
            <Lang/>
        </div>
    )
}

export default HeaderBrand
