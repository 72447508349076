import React, { Component } from 'react';
import { connect } from 'react-redux';

const cartContainer = (ComposedComponent) => {
    class CartContainer extends Component {
        render () {
            const {
                cart,
                dispatch,
                isFetching,
                user,
                indexLang,
            } = this.props
            const props = {
                ...this.props,
                cart,
                dispatch,
                user,
                indexLang,
            }
            return (
                isFetching ? null : <ComposedComponent {...props} />
            )
        }
    }
    const mapStateToProps = ({
        carts: {
            cart,
            isFetching,
            submittingCoupon,
            submitSucceededCoupon,
            errorCoupon,
            slugItemSubmittingCoupon,
            typeItemSubmittingCoupon,
        },
        user,
        langs: { indexLang },
    }) => ({
        cart,
        isFetching: isFetching || user.isFetching ? true : false,
        user,
        indexLang,
        submittingCoupon,
        submitSucceededCoupon,
        errorCoupon,
        slugItemSubmittingCoupon,
        typeItemSubmittingCoupon,
    })
    return connect(mapStateToProps)(CartContainer)
}

export default cartContainer
