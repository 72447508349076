import React, { Component } from 'react'
import { connect } from 'react-redux'

const WhyGPContainer = (ComposedComponent) => {
    class WhyGPContainer extends Component {
        render () {
            return (
                <ComposedComponent {...this.props} />
            )
        }
    }
    const mapStateToProps = ({
        langs: { indexLang },
    }) => ({
        indexLang,
    })

    return connect(mapStateToProps)(WhyGPContainer)
}

export default WhyGPContainer
