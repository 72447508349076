import { reset } from 'redux-form';
import handleAuthFetch from '../utils/handleAuthFetch';
import * as userActions from './user';

export const type = 'ADDRESS'
const route = 'addresses'

const ERROR = `ERROR_${type}`

const fetchFailure = (error) => ({ type: ERROR, error })

// Create
export const fetchAdd = (values) => {
    return async (dispatch) => {
        try {
            const json = await handleAuthFetch({
                path: `/api/${route}`,
                method: 'POST',
                body: values,
            });
            const { user } = json;
            dispatch(reset('addressAdd'));
            return dispatch(userActions.fetchUpdateSuccess(user));
        } catch (error) {
            dispatch(fetchFailure(error));
        }
    }
}

// Update
export const fetchUpdate = (_id, update) => {
    return async (dispatch) => {
        try {
            const json = await handleAuthFetch({
                path: `/api/${route}/${_id}`,
                method: 'PATCH',
                body: update,
            });
            return dispatch(userActions.fetchUpdateSuccess(json));
        } catch (error) {
            dispatch(fetchFailure(error));
        }
    }
}

// Delete
export const fetchDelete = (_id) => {
    return async (dispatch) => {
        dispatch({ type: 'DELETE_ADDRESSE', id: _id })
        try {
            const json = await handleAuthFetch({
                path: `/api/${route}/${_id}`,
                method: 'DELETE',
                body: null,
            });
            const { user } = json;
            return dispatch(userActions.fetchUpdateSuccess(user));
        } catch (error) {
            dispatch(fetchFailure(error));
        }
    }
}
