const emailStudio = (state = {
    error: null,
    submitSucceeded: false,
    submitting: false,
    submitTemplate: false,
    filteredUsers: [],
    stockProducts: [],
    emailTemplates: [],
}, action) => {
    switch (action.type) {
        case 'SET_FILTERED_USERS':
            return {
                ...state,
                filteredUsers: action.values,
            }
        case 'DELETE_USER_FROM_LIST':
            return {
                ...state,
                filteredUsers: state.filteredUsers.filter((u) => u.values.email !== action.payload),
            }
        case 'SET_STOCK_PRODUCTS':
            return {
                ...state,
                stockProducts: action.values,
            }
        case 'SET_TEMPLATES':
            return {
                ...state,
                emailTemplates: action.values,
            }
        case 'SUBMIT_TEMPLATE_SUCCEEDED':
            return {
                ...state,
                submitTemplate: true,
            }
        case 'SUBMITTING_MAILS':
            return {
                ...state,
                submitting: true,
            }
        case 'SUBMIT_MAILS_SUCCEEDED':
            return {
                ...state,
                submitting: false,
                submitSucceeded: true,
            }
        case 'SUBMIT_MAILS_FAILED':
            return {
                ...state,
                error: action.values,
            }
        default:
            return state
    }
}

export default emailStudio
