import React, { Component } from 'react'
import './header.css'
import AppBarPageLink from './AppBarPageLink'
import AppBarUser from './AppBarUser'
import CartIcon from './CartIcon'
import { SUBSCRIBE, WHYGOODYPACK, WHATISGOODYPACK, PREVIOUSPACKS } from '../../constant/stringTraduction';
import { getNoMoreOrders } from '../../utils/specialEvents'

class AppBarNavigation extends Component {
    state = {
        navClass: null,
        width: 0,
    }

    componentDidMount() {
        const width = this.navigation.clientWidth
        const totalWidth = width / .75
        let navClass
        switch (true) {
            case totalWidth < 375:
                navClass = 'largerThanIphone375'
                break
            case totalWidth < 667:
                navClass = 'largerThanIphone667'
                break
            case totalWidth < 768:
                navClass = 'largerThanIpad768'
                break
            case totalWidth < 1024:
                navClass = 'largerThanIpad1024'
                break
            case totalWidth < 1366:
                navClass = 'largerThanIpad1366'
                break
            default:
                navClass = 'largerThan1920'
        }
        this.setState({ navClass, width });
    }
    render() {
        const {
            cart,
            dispatch,
            firstName,
            indexLang,
        } = this.props

        return (
            <div
                ref={(navigation) => {
                    this.navigation = navigation
                }}
                className="appbar-navigation-container"
            >
                <div className="appbar-navigation">
                    <div className='appbar-navigation'>
                        <AppBarPageLink
                            className="largerThanIpad1024"
                            nameFr={WHYGOODYPACK[1]}
                            nameEn={WHYGOODYPACK[0]}
                            key="whygp"
                            link="whygp"
                            indexLang={indexLang}
                        />
                        <AppBarPageLink
                            className="largerThanIpad768"
                            nameFr={WHATISGOODYPACK[1]}
                            nameEn={WHATISGOODYPACK[0]}
                            key="learn-more"
                            link="learn-more"
                            indexLang={indexLang}
                        />
                        <AppBarPageLink
                            className="largerThanIpad768"
                            nameFr={PREVIOUSPACKS[1]}
                            nameEn={PREVIOUSPACKS[0]}
                            key="pack-examples"
                            link="pack-examples"
                            indexLang={indexLang}
                        />
                        {!getNoMoreOrders() &&
                        <AppBarPageLink
                            className="largerThanIphone667"
                            nameFr={SUBSCRIBE[1]}
                            nameEn={SUBSCRIBE[0]}
                            key="Box"
                            link="box"
                            indexLang={indexLang}
                            color='#fff100'
                        />
                        }
                    </div>
                    <AppBarUser
                        cartQty={0}
                        dispatch={dispatch}
                        firstName={firstName}
                        indexLang={indexLang}
                    />
                    {cart.item && !getNoMoreOrders() &&
                        <CartIcon
                            dispatch={dispatch}
                            isAppBar={true}
                        />
                    }
                </div>
            </div>
        )
    }
}

export default AppBarNavigation
