import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import {
    ACTIVE, ADRESSE, ANNULER, COMMANDE, COLON, PRODUCT,
    PRICE, RABAIS, RESUMEABONNEMENTS, RESUMECOMMANDE, SHIPPING, NECKSIZE,
    SOUSTOTAL, STATUS, FREQUENCY, MONTHS, ONCE, NECKSIZECAT, NEXTPAYMENT, NEXTSHIPPING,
    MODIFINFOS, HIVER, ETE, NEXTSEASON, ANNULERABO,
    RESUME, ENPAUSE,
} from '../../constant/stringTraduction';
import formatPrice from '../../utils/formatPrice';
import { getFrenchDateFormats, getNextPackDate } from '../../utils/dates';
import { Link } from 'react-router-dom';
import headerImageEN from '../../assets/order_confirmation_english_v2.png';
import headerImageFR from '../../assets/order_confirmation_french_v2.png';
import GoToOrderButton from '../buttons/GoToOrdersButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchResumeSubscription } from '../../actions/orders';
import AlertModal from '../alert/AlertModal';
import UpgradeModal from '../user/UpgradeModal';
import history from '../../containers/routers/history';
import { DAY_FOR_SHIPPING, DATES_SAISONS_MOIS } from '../../constant/otherConst';
import { getNoMoreOrders, getClosed } from '../../utils/specialEvents';

const styles = {
    orderDetail: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
    },
    orderSummary: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
    },
    details: {
        margin: '8px 0',
    },
}

const OrderDetail = ({
    order: {
        _id,
        item,
        createdAt,
        address: { name, phone, street, city, state, zip },
        subTotal,
        shipping,
        total,
        rabais,
        taxes,
        neckSize,
        standardNeckSize,
        number,
    },
    dispatch,
    indexLang,
    resumeSubscriptionId,
    confirmation,
    modal,
    title,
    text,
    order,
    upgradeModal,
    orderUpgrading,
    orderUpgradeSuccess,
    orderUpgradeError,
    products,
}) => {
    let moment = getFrenchDateFormats(indexLang);

    const headerImage = [headerImageEN, headerImageFR];
    let stdNeckSize = '';
    if (neckSize === 0) {
        if (indexLang === 1) {
            if (standardNeckSize === 'yes') {
                stdNeckSize = 'oui'
            } else {
                stdNeckSize = 'non'
            }
        } else {
            stdNeckSize = standardNeckSize
        }
    }

    const nextPayment = () => {
        const { nextDate, indexSaison } = getNextPackDate();
        let date = nextDate.clone();
        if (indexLang) {
            date.locale('fr-ca')
            if (date.format('D') === '1') {
                return { date: date.format('Do MMMM YYYY'), indexSaison: indexSaison };
            } else {
                return { date: date.format('D MMMM YYYY'), indexSaison: indexSaison };
            }
        } else {
            date.locale('en-ca')
            return { date: date.format('MMMM Do YYYY'), indexSaison: indexSaison };
        }
    }

    const nextShipping = () => {
        const { nextDate, indexSaison } = getNextPackDate();
        let date = nextDate.clone();
        date.add(DAY_FOR_SHIPPING[indexSaison], 'day');
        if (indexLang) {
            date.locale('fr-ca')
            if (date.format('D') === '1') {
                return { date: date.format('Do MMMM YYYY'), indexSaison: indexSaison };
            } else {
                return { date: date.format('D MMMM YYYY'), indexSaison: indexSaison };
            }
        } else {
            date.locale('en-ca')
            return { date: date.format('MMMM Do YYYY'), indexSaison: indexSaison };
        }
    }

    const seasons = [HIVER[indexLang].toLowerCase(), ETE[indexLang].toLowerCase()];

    const handleSubscriptionPause = async (orderId, paused) => {
        if (paused) {
            await dispatch({ type: 'SET_RESUME_ID', payload: orderId })
            dispatch({ type: 'RESUME_SUB' });
        } else {
            await dispatch({ type: 'SET_PAUSE_ID', payload: orderId })
            dispatch({ type: 'PAUSE_SUB' });
        }
    }
    const toggleModal = () => {
        dispatch({ type: 'CLOSE_ALERT' });
    }
    const onConfirm = async () => {
        await dispatch({ type: 'CLOSE_ALERT' });
        dispatch(fetchResumeSubscription(resumeSubscriptionId));
    }
    const closeUpgradeModal = () => {
        dispatch({ type: 'CLOSE_UPGRADE_MODAL' });
        dispatch({ type: 'RESET_UPGRADING_SUBSCRIPTION' });
    }
    const handleSubscriptionCancel = (orderId) => {
        history.push('/retroaction/unsubscribe/' + orderId)
    };
    return (
        <div style={{ zDepth: 0, paddingTop: 24 }}>
            <div>
                <div style={{ backgroundImage: `url(${headerImage[indexLang]})`, minHeight: 180, width: '100%', backgroundPosition: 'center', marginBottom: 10 }} />
                <div style={styles.orderDetail}>
                    <div>{`Date: ${moment(createdAt).format('dddd, MMMM Do YYYY, h:mm a')}`}</div>
                    <div>{`${COMMANDE[indexLang]} #${number ? number : _id}`}</div>
                </div>
            </div>

            <div style={styles.orderDetail}>
                <div>
                    <strong>{ADRESSE[indexLang]}</strong>
                    <div style={styles.details}>
                        <div>{name}</div>
                        <div>{phone}</div>
                        <div>{street}</div>
                        <div>{city}, {state} {zip}</div>
                    </div>
                </div>
                <div>
                    <strong>{RESUMECOMMANDE[indexLang]}</strong>
                    <div style={styles.details}>
                        <div style={styles.orderSummary}>
                            <div style={{ marginRight: 16 }}>{SOUSTOTAL[indexLang]}{COLON[indexLang]}</div>
                            <div>{formatPrice(subTotal, indexLang)}</div>
                        </div>
                        {rabais > 0 && <div style={styles.orderSummary}>
                            <div style={{ marginRight: 16 }}>{RABAIS[indexLang]}{COLON[indexLang]}</div>
                            <div>{formatPrice(rabais, indexLang)}</div>
                        </div>}
                        <div style={styles.orderSummary}>
                            <div>Taxes{COLON[indexLang]}</div>
                            <div>{taxes ? formatPrice(taxes, indexLang) : formatPrice(0, indexLang)}</div>
                        </div>
                        <div style={styles.orderSummary}>
                            <div>{SHIPPING[indexLang]}{COLON[indexLang]}</div>
                            <div>{taxes ? formatPrice(shipping, indexLang) : formatPrice(0, indexLang)}</div>
                        </div>
                        <div style={{ ...styles.orderSummary, ...styles.details }}>
                            <strong>Total{COLON[indexLang]}</strong>
                            <strong>{formatPrice(total, indexLang)}</strong>
                        </div>
                    </div>
                </div>
            </div>
            {item ?
                <div>
                    <h3 style={{ textAlign: 'center', padding: '8px 8px' }}>{item.trial ? RESUMECOMMANDE[indexLang] : RESUMEABONNEMENTS[indexLang]}</h3>
                    <div>
                        <Row className='desktop-only-lg' style={{ border: '1px solid black', margin: '10px 0px' }}>
                            <Col lg='3'>{PRODUCT[indexLang]}</Col>
                            <Col lg='3'>Description</Col>
                            {neckSize !== 0 ?
                                <Col lg='2'>{NECKSIZE[indexLang]}</Col>
                                :
                                <Col lg='2'>{NECKSIZECAT[indexLang]}</Col>
                            }
                            <Col lg='1'>{PRICE[indexLang]}</Col>
                            <Col lg='2'>{FREQUENCY[indexLang]}</Col>
                            <Col lg='1'>{STATUS[indexLang]}</Col>
                        </Row>
                        <Row>
                            <Col lg='3'>{indexLang ? item.nameFR : item.nameEN}</Col>
                            <Col lg='3'>{indexLang ? item.descriptionFR : item.descriptionEN}</Col>
                            {neckSize !== 0 ?
                                <Col lg='2'>{neckSize} cm</Col>
                                :
                                <Col lg='2'>{stdNeckSize}</Col>
                            }
                            <Col lg='1'>{formatPrice(total, indexLang)}</Col>
                            <Col lg='2'>{item.trial ? ONCE[indexLang] : (12 / DATES_SAISONS_MOIS.length) + ' ' + MONTHS[indexLang]}</Col>
                            <Col lg='1'>{(getClosed() || item.canceled) || (getNoMoreOrders() && item.trial) ? ANNULER[indexLang] : ACTIVE[indexLang]}</Col>
                        </Row>
                        <Row>
                            <Col lg='12' style={{ marginTop: 20 }}>
                                {(!getNoMoreOrders() && !item.trial && !item.paused && !item.typeId) &&
                                    <div>
                                        <p>{NEXTSEASON[indexLang]}{seasons[nextPayment().indexSaison]}</p>
                                        <p>{NEXTPAYMENT[indexLang]} : {nextPayment().date} - {NEXTSHIPPING[indexLang]} : {nextShipping().date}</p>
                                    </div>
                                }
                                {!getNoMoreOrders() && item.paused &&
                                    <div>
                                        <p>{ENPAUSE[indexLang]}</p>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </div>
                </div> : null
            }
            <Row style={{ marginTop: '10px', textAlign: 'center' }}>
                {getClosed() || item.canceled ? null :
                    <Col md='3'>
                        <Link style={{ textDecoration: 'none' }} to={'/user/orders/modify/' + _id}><Button size="sm" style={{ marginTop: '10px' }} block>{MODIFINFOS[indexLang]}</Button></Link>
                    </Col>
                }
                {getClosed() || (item.canceled || item.trial || item.typeId) ? null :
                    <Col md='3'>
                        {!item.paused ?
                            <Button style={{ marginTop: '10px' }} size="sm" onClick={() => handleSubscriptionCancel(order.item.subId)} block>{ANNULERABO[indexLang]}</Button>
                            :
                            resumeSubscriptionId === _id && !item.trial ?
                                <Button size="sm" style={{ textAlign: 'center', marginTop: '10px' }} block><CircularProgress color='inherit' size={25} style={{ verticalAlign: 'middle' }} /></Button>
                                :
                                !item.trial && <Button size="sm" style={{ marginTop: '10px' }} onClick={() => handleSubscriptionPause(_id, item.paused)} block>{RESUME[indexLang]}</Button>
                        }
                    </Col>
                }
                <Col md='3'>
                    <GoToOrderButton dispatch={dispatch} indexLang={indexLang} />
                </Col>
            </Row>
            <AlertModal
                indexLang={indexLang}
                confirmation={confirmation}
                toggleModal={toggleModal}
                modal={modal}
                title={title}
                text={text}
                onConfirm={onConfirm}
            />
            <UpgradeModal
                indexLang={indexLang}
                upgradeModal={upgradeModal}
                order={order}
                dispatch={dispatch}
                orderUpgrading={orderUpgrading}
                orderUpgradeSuccess={orderUpgradeSuccess}
                orderUpgradeError={orderUpgradeError}
                closeUpgradeModal={closeUpgradeModal}
                products={products}
            />
        </div>
    )
}

export default OrderDetail
