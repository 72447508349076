import React from 'react';
import tosContainer from '../../containers/staticPages/tosContainer';
import { Container } from 'reactstrap';
import { SUPPORT_EMAIL } from '../../constant/otherConst';

class TOS extends React.Component {
    render () {
        const { indexLang } = this.props;

        return (
            <Container className='white-background container-top-margin'>
                {indexLang ?
                /* FRENCH */
                    <div>
                        <p><strong>TERMES &amp; CONDITIONS&nbsp;</strong></p>
                        <p>&nbsp;</p>
                        <p>APERÇU</p>
                        <p>Ce site est exploité par beonebreed. Tout au long du site, les termes "nous", "notre" et "notre" se réfèrent à l'abeille. beonebreed propose ce site, y compris toutes les informations, les outils et les services disponibles sur ce site pour vous, l'utilisateur, sous réserve de votre acceptation de tous les termes, conditions, politiques et avis énoncés ici.</p>
                        <p>En visitant notre site et / ou en achetant quelque chose chez nous, vous vous engagez dans notre "Service" et acceptez d'être lié par les termes et conditions suivants ("Conditions d'utilisation", "Conditions"), y compris ces termes et conditions supplémentaires et politiques référencé ici et / ou disponible par hyperlien. Ces Conditions d'utilisation s'appliquent à tous les utilisateurs du site, y compris, sans s'y limiter, les utilisateurs qui sont des navigateurs, des fournisseurs, des clients, des commerçants et / ou des contributeurs de contenu.</p>
                        <p>Veuillez lire attentivement ces conditions d'utilisation avant d'accéder ou d'utiliser notre site Web. En accédant ou en utilisant n'importe quelle partie du site, vous acceptez d'être lié par ces Conditions d'utilisation. Si vous n'acceptez pas tous les termes et conditions de cet accord, vous ne pouvez pas accéder au site ou utiliser des services. Si ces Conditions d'utilisation sont considérées comme une offre, l'acceptation est expressément limitée aux présentes Conditions d'utilisation.</p>
                        <p>Toutes les nouvelles fonctionnalités ou tous les outils ajoutés au magasin actuel sont également soumis aux conditions d'utilisation. Vous pouvez consulter la version la plus récente des Conditions d'utilisation à tout moment sur cette page. Nous nous réservons le droit de mettre à jour, modifier ou remplacer toute partie de ces Conditions d'utilisation en publiant des mises à jour et / ou des modifications sur notre site Web. Il est de votre responsabilité de vérifier cette page périodiquement pour les changements. Votre utilisation continue ou votre accès au site Web après l'affichage de tout changement constitue l'acceptation de ces changements.</p>
                        <p>Notre magasin est hébergé sur Shopify Inc. Ils nous fournissent la plateforme de commerce électronique en ligne qui nous permet de vous vendre nos produits et services.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 1 - CONDITIONS DE MAGASIN EN LIGNE</p>
                        <p>En acceptant ces Conditions d'utilisation, vous déclarez que vous avez au moins l'âge de la majorité dans votre état ou province de résidence, ou que vous avez l'âge de la majorité dans votre état ou province de résidence et que vous nous avez donné votre consentement pour permettre à l'une de vos personnes à charge mineures d'utiliser ce site.</p>
                        <p>Vous ne pouvez pas utiliser nos produits à des fins illégales ou non autorisées et vous ne pouvez pas, dans l'utilisation du Service, enfreindre les lois de votre juridiction (y compris, mais sans s'y limiter, les lois sur les droits d'auteur).</p>
                        <p>Vous ne devez pas transmettre de vers ou de virus ou de code de nature destructrice.</p>
                        <p>Une violation ou une violation de l'une des Conditions entraînera la résiliation immédiate de vos Services.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 2 - CONDITIONS GÉNÉRALES</p>
                        <p>Nous nous réservons le droit de refuser le service à quiconque pour n'importe quelle raison à tout moment.</p>
                        <p>Vous comprenez que votre contenu (à l'exclusion des informations de carte de crédit), peut être transféré en clair et impliquer (a) des transmissions sur différents réseaux; et (b) des modifications pour se conformer et s'adapter aux exigences techniques de la connexion de réseaux ou de dispositifs. Les informations de carte de crédit sont toujours cryptées lors du transfert sur les réseaux.</p>
                        <p>Vous acceptez de ne pas reproduire, dupliquer, copier, vendre, revendre ou exploiter toute partie du Service, l'utilisation du Service ou l'accès au Service ou à tout contact sur le site Web par lequel le service est fourni, sans autorisation écrite expresse de notre part .</p>
                        <p>Les titres utilisés dans cet accord sont inclus uniquement pour des raisons de commodité et ne limiteront ni n'affecteront ces conditions.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 3 - EXACTITUDE, EXHAUSTIVITÉ ET OPPORTUNITÉ DE L'INFORMATION</p>
                        <p>Nous ne sommes pas responsables si les informations mises à disposition sur ce site ne sont pas exactes, complètes ou à jour. Le contenu de ce site est fourni à titre d'information générale uniquement et ne devrait pas être utilisé comme seul fondement pour prendre des décisions sans consulter des sources d'information primaires, plus précises, plus complètes ou plus opportunes. Toute confiance dans le contenu de ce site est à vos risques et périls.</p>
                        <p>Ce site peut contenir certaines informations historiques. L'information historique, nécessairement, n'est pas à jour et n'est fournie qu'à titre de référence. Nous nous réservons le droit de modifier le contenu de ce site à tout moment, mais nous n'avons aucune obligation de mettre à jour les informations sur notre site. Vous acceptez qu'il est de votre responsabilité de surveiller les changements sur notre site.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 4 - MODIFICATIONS AU SERVICE ET PRIX</p>
                        <p>Les prix de nos produits sont sujets à changement sans préavis.</p>
                        <p>Nous nous réservons le droit à tout moment de modifier ou d'interrompre le Service (ou toute partie ou contenu de celui-ci) sans préavis à tout moment.</p>
                        <p>Nous ne serons pas responsables envers vous ou envers un tiers pour toute modification, changement de prix, suspension ou interruption du Service.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 5 - PRODUITS OU SERVICES (le cas échéant)</p>
                        <p>Certains produits ou services peuvent être disponibles exclusivement en ligne via le site Web. Ces produits ou services peuvent avoir des quantités limitées et ne peuvent être retournés ou échangés que conformément à notre politique de retour.</p>
                        <p>Nous avons fait tous les efforts pour afficher le plus fidèlement possible les couleurs et les images de nos produits qui apparaissent dans le magasin. Nous ne pouvons pas garantir que l'affichage de n'importe quelle couleur sur votre écran d'ordinateur sera exact.</p>
                        <p>Nous nous réservons le droit, mais ne sommes pas obligés, de limiter les ventes de nos produits ou services à toute personne, région géographique ou juridiction. Nous pouvons exercer ce droit au cas par cas. Nous nous réservons le droit de limiter les quantités de tous les produits ou services que nous offrons. Toutes les descriptions des produits ou les prix des produits sont sujets à changement à tout moment sans préavis, à la seule discrétion de nous. Nous nous réservons le droit d'interrompre tout produit à tout moment. Toute offre pour tout produit ou service fait sur ce site est nulle là où interdit.</p>
                        <p>Nous ne garantissons pas que la qualité de tout produit, service, information ou autre matériel acheté ou obtenu par vous répondra à vos attentes, ou que toute erreur dans le Service sera corrigée.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 6 - EXACTITUDE DES INFORMATIONS DE FACTURATION ET DE COMPTE</p>
                        <p>Nous nous réservons le droit de refuser toute commande que vous passez avec nous. Nous pouvons, à notre seule discrétion, limiter ou annuler les quantités achetées par personne, par foyer ou par commande. Ces restrictions peuvent inclure des commandes passées par ou sous le même compte client, la même carte de crédit et / ou des commandes qui utilisent la même adresse de facturation et / ou d'expédition. Dans l'éventualité où nous modifions ou annulons une commande, nous pouvons tenter de vous en informer en contactant l'adresse e-mail et / ou l'adresse de facturation / numéro de téléphone fournis au moment de la commande. Nous nous réservons le droit de limiter ou d'interdire les commandes qui, à notre seule discrétion, semblent être placées par des revendeurs, des revendeurs ou des distributeurs.</p>
                        <p>Vous acceptez de fournir des informations d'achat et de compte actuelles, complètes et exactes pour tous les achats effectués dans notre magasin. Vous acceptez de mettre à jour rapidement votre compte et d'autres informations, y compris votre adresse e-mail et vos numéros de carte de crédit et les dates d'expiration, afin que nous puissions effectuer vos transactions et vous contacter si nécessaire.</p>
                        <p>Pour plus de détails, veuillez consulter notre politique de retour.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 7 - OUTILS OPTIONNELS</p>
                        <p>Nous pouvons vous fournir un accès à des outils tiers sur lesquels nous ne surveillons pas et n'avons aucun contrôle ou apport.</p>
                        <p>Vous reconnaissez et acceptez que nous fournissons l'accès à ces outils «tel quel» et «tel que disponible» sans aucune garantie, représentation ou condition de quelque nature que ce soit et sans aucune approbation. Nous déclinons toute responsabilité découlant de ou liée à votre utilisation d'outils tiers optionnels.</p>
                        <p>Toute utilisation par vous des outils optionnels proposés sur le site est entièrement à vos risques et périls et vous devez vous assurer que vous connaissez et approuvez les conditions sur lesquelles les outils sont fournis par le (s) fournisseur (s) tiers concerné (s).</p>
                        <p>Nous pouvons également, à l'avenir, offrir de nouveaux services et / ou fonctionnalités via le site Web (y compris la publication de nouveaux outils et ressources). Ces nouvelles fonctionnalités et / ou services seront également soumis aux présentes Conditions d'utilisation.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 8 - LIENS DE TIERS</p>
                        <p>Certains contenus, produits et services disponibles via notre Service peuvent inclure du matériel provenant de tiers.</p>
                        <p>Les liens de tiers sur ce site peuvent vous diriger vers des sites Web tiers qui ne sont pas affiliés avec nous. Nous ne sommes pas responsables de l'examen ou de l'évaluation du contenu ou de l'exactitude et nous ne garantissons pas et n'assumons aucune responsabilité pour les matériaux ou sites Web de tiers, ou pour tout autre matériel, produit ou service de tiers.</p>
                        <p>&nbsp;</p>
                        <p>Nous ne sommes pas responsables des dommages ou dommages liés à l'achat ou à l'utilisation de biens, services, ressources, contenu ou toute autre transaction effectuée en relation avec des sites Web de tiers. Veuillez examiner attentivement les politiques et les pratiques de tiers et assurez-vous de les comprendre avant de vous engager dans une transaction. Les réclamations, réclamations, préoccupations ou questions concernant les produits de tiers doivent être adressées à la tierce partie.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 9 - COMMENTAIRES DES UTILISATEURS, COMMENTAIRES ET AUTRES SOUMISSIONS</p>
                        <p>Si, à notre demande, vous envoyez certaines soumissions spécifiques (par exemple des participations au concours) ou sans une demande de notre part, vous envoyez des idées créatives, suggestions, propositions, plans ou autres documents, que ce soit en ligne, par courriel, par courrier ou autrement (collectivement, «commentaires»), vous acceptez que nous puissions, à tout moment, sans restriction, éditer, copier, publier, distribuer, traduire et utiliser sur tout support les commentaires que vous nous transmettez. Nous sommes et n'aurons aucune obligation (1) de maintenir des commentaires confidentiels; (2) de payer une compensation pour les commentaires; ou (3) pour répondre à des commentaires.</p>
                        <p>Nous pouvons, mais n'avons aucune obligation de surveiller, éditer ou supprimer le contenu que nous déterminons à notre seule discrétion est illégal, offensant, menaçant, diffamatoire, pornographique, obscène ou autrement répréhensible ou viole la propriété intellectuelle d'une partie ou ces Conditions d'utilisation .</p>
                        <p>Vous acceptez que vos commentaires ne violent aucun droit d'un tiers, y compris les droits d'auteur, les marques de commerce, la confidentialité, la personnalité ou tout autre droit personnel ou exclusif. Vous convenez en outre que vos commentaires ne contiendront pas de matériel diffamatoire ou illégal, abusif ou obscène, ou contiendront des virus informatiques ou autres logiciels malveillants qui pourraient affecter le fonctionnement du Service ou de tout site Web connexe. Vous ne pouvez pas utiliser une fausse adresse e-mail, faire semblant d'être quelqu'un d'autre que vous-même, ou induire en erreur nous ou des tiers quant à l'origine des commentaires. Vous êtes seul responsable des commentaires que vous faites et de leur exactitude. Nous ne prenons aucune responsabilité et n'assumons aucune responsabilité pour les commentaires publiés par vous ou par un tiers.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 10 - RENSEIGNEMENTS PERSONNELS</p>
                        <p>Votre soumission de renseignements personnels dans le magasin est régie par notre politique de confidentialité. Pour voir notre politique de confidentialité.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 11 - ERREURS, INEXACTITUDES ET OMISSIONS</p>
                        <p>Occasionnellement, il peut y avoir des informations sur notre site ou dans le Service qui contiennent des erreurs typographiques, des inexactitudes ou des omissions liées aux descriptions de produits, prix, promotions, offres, frais d'expédition, délais de livraison et disponibilité. Nous nous réservons le droit de corriger toute erreur, inexactitude ou omission, de modifier ou de mettre à jour des informations ou d'annuler des commandes si des informations du Service ou de tout site Web connexe sont inexactes à tout moment sans préavis (y compris après avoir passé votre commande) .</p>
                        <p>Nous n'assumons aucune obligation de mettre à jour, de modifier ou de clarifier les informations dans le Service ou sur tout site Web connexe, y compris, sans s'y limiter, les informations sur les prix, sauf si la loi l'exige. Aucune date de mise à jour ou d'actualisation spécifiée dans le Service ou sur un site Web connexe ne doit être prise pour indiquer que toutes les informations du Service ou de tout site Web connexe ont été modifiées ou mises à jour.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 12 - UTILISATIONS INTERDITES</p>
                        <p>En plus des autres interdictions énoncées dans les Conditions d'utilisation, il vous est interdit d'utiliser le site ou son contenu: (a) à des fins illégales; (b) de solliciter d'autres personnes pour accomplir ou participer à des actes illicites; (c) de violer les règlements, règles, lois ou ordonnances locales internationales, fédérales, provinciales ou étatiques; (d) enfreindre ou violer nos droits de propriété intellectuelle ou les droits de propriété intellectuelle d'autrui; e) harceler, abuser, insulter, blesser, diffamer, calomnier, dénigrer, intimider ou discriminer selon le sexe, l'orientation sexuelle, la religion, l'appartenance ethnique, la race, l'âge, l'origine nationale ou le handicap; f) soumettre des informations fausses ou trompeuses; (g) pour télécharger ou transmettre des virus ou tout autre type de code malveillant qui sera ou pourra être utilisé de quelque façon que ce soit qui affectera la fonctionnalité ou le fonctionnement du Service ou de tout site Web connexe, d'autres sites Web ou Internet; (h) recueillir ou suivre les informations personnelles d'autrui; (i) pour spam, phishing, pharm, prétexte, spider, crawl, ou gratter; j) à des fins obscènes ou immorales; ou (k) interférer avec ou contourner les fonctions de sécurité du Service ou de tout site Web associé, d'autres sites Web ou d'Internet. Nous nous réservons le droit de mettre fin à votre utilisation du Service ou de tout site Web connexe pour violation de l'une des utilisations interdites.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 13 - EXONÉRATION DE GARANTIES; LIMITATION DE RESPONSABILITÉ</p>
                        <p>Nous ne garantissons pas, ne garantissons pas que votre utilisation de notre service sera ininterrompue, opportune, sécurisée ou sans erreur.</p>
                        <p>Nous ne garantissons pas que les résultats pouvant être obtenus de l'utilisation du service seront exacts ou fiables.</p>
                        <p>Vous acceptez que de temps en temps nous pouvons supprimer le service pour des périodes indéterminées ou annuler le service à tout moment, sans préavis.</p>
                        <p>Vous acceptez expressément que votre utilisation ou votre incapacité à utiliser le service est à vos risques et périls. Le service et tous les produits et services qui vous sont fournis par le service sont (sauf tel qu'expressément indiqué par nous) fournis «tel quel» et «tel que disponible» pour votre usage, sans aucune représentation, garanties ou conditions de toute nature, express ou implicite, y compris toutes les garanties ou conditions implicites de qualité marchande, de qualité marchande, d'adéquation à un usage particulier, de durabilité, de titre et de non-contrefaçon.</p>
                        <p>En aucun cas, nos administrateurs, dirigeants, employés, sociétés affiliées, agents, entrepreneurs, stagiaires, fournisseurs, fournisseurs de services ou concédants de licence ne peuvent être tenus responsables de toute blessure, perte, réclamation ou de tout lien direct, indirect, incident, punitif, spécial ou dommages indirects de toute nature, y compris, sans limitation, profits perdus, pertes de revenus, pertes de données, coûts de remplacement ou dommages similaires, qu'ils soient contractuels, délictuels (y compris la négligence), de responsabilité stricte ou autres, découlant de votre l'utilisation du service ou des produits achetés en utilisant le service, ou pour toute autre réclamation liée de quelque façon à votre utilisation du service ou de tout produit, y compris, mais sans s'y limiter, toute erreur ou omission dans tout contenu, ou toute toute perte ou dommage de quelque nature que ce soit encouru à la suite de l'utilisation du service ou de tout contenu (ou produit) affiché, transmis ou mis à disposition par le biais du service, même s'il est informé de leur possibilité. Parce que certains états ou juridictions n'autorisent pas l'exclusion ou la limitation de la responsabilité pour les dommages indirects ou accessoires, dans ces états ou juridictions, notre responsabilité sera limitée dans toute la mesure permise par la loi.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 14 - INDEMNISATION</p>
                        <p>Vous acceptez d'indemniser, défendre et tenir beonebreed inoffensif et notre parent, filiales, sociétés affiliées, partenaires, dirigeants, directeurs, agents, entrepreneurs, concédants, fournisseurs de services, sous-traitants, fournisseurs, stagiaires et employés, inoffensifs de toute réclamation ou demande, y compris raisonnable les honoraires d'avocats, faits par un tiers en raison de ou en raison de votre violation des présentes Conditions d'utilisation ou des documents qu'ils incorporent par référence, ou de votre violation de toute loi ou des droits d'un tiers.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 15 - DIVISIBILITÉ</p>
                        <p>Dans le cas où une disposition de ces Conditions d'utilisation est jugée illégale, nulle ou inapplicable, cette disposition sera néanmoins exécutoire dans toute la mesure permise par la loi applicable, et la partie inapplicable sera réputée être séparée des présentes Conditions de Service, une telle détermination n'affectera pas la validité et l'applicabilité des autres dispositions restantes.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 16 - RÉSILIATION</p>
                        <p>Les obligations et responsabilités des parties encourues avant la date de résiliation survivront à la résiliation de cet accord à toutes fins.</p>
                        <p>Ces Conditions d'utilisation sont en vigueur à moins que et jusqu'à ce que résilié par vous ou nous. Vous pouvez résilier ces Conditions d'utilisation à tout moment en nous notifiant que vous ne souhaitez plus utiliser nos Services, ou lorsque vous cessez d'utiliser notre site.</p>
                        <p>Si, à notre seule discrétion, vous omettez ou si nous soupçonnons que vous avez échoué, vous pouvez résilier le présent contrat à tout moment sans préavis et vous demeurerez responsable de tous les montants dus. jusqu'à et y compris la date de résiliation; et / ou, en conséquence, vous refuser l'accès à nos Services (ou toute partie de ceux-ci).</p>
                        <p>&nbsp;</p>
                        <p>SECTION 17 - L'ENSEMBLE DE L'ACCORD</p>
                        <p>L'échec de notre part à exercer ou à appliquer tout droit ou disposition des présentes Conditions d'utilisation ne constitue pas une renonciation à ce droit ou à cette disposition.</p>
                        <p>Les présentes Conditions d'utilisation et les règles ou règles de fonctionnement publiées par nous sur ce site ou concernant le Service constituent l'intégralité de l'accord et de la compréhension entre vous et nous et régissent votre utilisation du Service, remplaçant tout accord, communication et proposition antérieurs ou contemporains , oral ou écrit, entre vous et nous (y compris, mais sans s'y limiter, toute version antérieure des Conditions d'utilisation).</p>
                        <p>Toute ambiguïté dans l'interprétation de ces Conditions d'utilisation ne doit pas être interprétée contre la partie qui rédige.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 18 - DROIT APPLICABLE</p>
                        <p>Ces Conditions d'utilisation et tout contrat distinct par lequel nous vous fournissons des Services seront régis et interprétés conformément aux lois de 1893 rue de l'industrie, beloeil, QC, j3g0s4, Canada.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 19 - MODIFICATION DES CONDITIONS DE SERVICE</p>
                        <p>Vous pouvez consulter la version la plus récente des Conditions d'utilisation à tout moment sur cette page.</p>
                        <p>Nous nous réservons le droit, à notre seule discrétion, de mettre à jour, de modifier ou de remplacer toute partie des présentes Conditions d'utilisation en publiant des mises à jour et des modifications sur notre site Web. Il est de votre responsabilité de consulter périodiquement notre site Web pour connaître les changements. Votre utilisation continue ou l'accès à notre site Web ou au Service après l'affichage de tout changement aux présentes Conditions d'utilisation constitue une acceptation de ces changements.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 20 - COORDONNÉES</p>
                        <p><strong>Des questions sur les conditions d'utilisation doivent nous être envoyées à {SUPPORT_EMAIL}.</strong></p>
                    </div>
                    :
                /* ENGLISH */
                    <div>
                        <p><strong>TERMS &amp; CONDITIONS</strong></p>
                        <p>&nbsp;</p>
                        <p>OVERVIEW</p>
                        <p>This website is operated by beonebreed. Throughout the site, the terms “we”, “us” and “our” refer to beonebreed. beonebreed offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
                        <p>By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply&nbsp; to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.</p>
                        <p>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
                        <p>Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>
                        <p>Our store is hosted on Shopify Inc. They provide us with the online e-commerce platform that allows us to sell our products and services to you.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 1 - ONLINE STORE TERMS</p>
                        <p>By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>
                        <p>You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</p>
                        <p>You must not transmit any worms or viruses or any code of a destructive nature.</p>
                        <p>A breach or violation of any of the Terms will result in an immediate termination of your Services.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 2 - GENERAL CONDITIONS</p>
                        <p>We reserve the right to refuse service to anyone for any reason at any time.</p>
                        <p>You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.</p>
                        <p>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.</p>
                        <p>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</p>
                        <p>We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.</p>
                        <p>This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</p>
                        <p>Prices for our products are subject to change without notice.</p>
                        <p>We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.</p>
                        <p>We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 5 - PRODUCTS OR SERVICES (if applicable)</p>
                        <p>Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.</p>
                        <p>We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.</p>
                        <p>We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.</p>
                        <p>We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</p>
                        <p>We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.</p>
                        <p>You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.</p>
                        <p>For more detail, please review our Returns Policy.</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>SECTION 7 - OPTIONAL TOOLS</p>
                        <p>We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.</p>
                        <p>You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.</p>
                        <p>Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).</p>
                        <p>We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 8 - THIRD-PARTY LINKS</p>
                        <p>Certain content, products and services available via our Service may include materials from third-parties.</p>
                        <p>Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.</p>
                        <p>We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</p>
                        <p>If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.</p>
                        <p>We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.</p>
                        <p>You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 10 - PERSONAL INFORMATION</p>
                        <p>Your submission of personal information through the store is governed by our Privacy Policy. To view our Privacy Policy.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</p>
                        <p>Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).</p>
                        <p>We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 12 - PROHIBITED USES</p>
                        <p>In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</p>
                        <p>We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.</p>
                        <p>We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.</p>
                        <p>You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.</p>
                        <p>You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</p>
                        <p>In no case shall beonebreed, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 14 - INDEMNIFICATION</p>
                        <p>You agree to indemnify, defend and hold harmless beonebreed and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 15 - SEVERABILITY</p>
                        <p>In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 16 - TERMINATION</p>
                        <p>The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.</p>
                        <p>These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.</p>
                        <p>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).</p>
                        <p>&nbsp;</p>
                        <p>SECTION 17 - ENTIRE AGREEMENT</p>
                        <p>The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.</p>
                        <p>These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).</p>
                        <p>Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 18 - GOVERNING LAW</p>
                        <p>These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of 1893 rue de l'industrie, beloeil, QC, j3g0s4, Canada.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 19 - CHANGES TO TERMS OF SERVICE</p>
                        <p>You can review the most current version of the Terms of Service at any time at this page.</p>
                        <p>We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.</p>
                        <p>&nbsp;</p>
                        <p>SECTION 20 - CONTACT INFORMATION</p>
                        <p><strong>Questions about the Terms of Service should be sent to us at {SUPPORT_EMAIL}.</strong></p>
                    </div>
                }
            </Container>
        );
    }
}

export default tosContainer(TOS)
