import React from 'react'

import AddressItem from './AddressItem'

const AddressList = ({
    addresses,
    dispatch,
    onAddressUpdate,
    onAddressDelete,
    indexLang,
    submittingID,
}) => 
    <div>
        {addresses.map((address) => 
            <AddressItem
                key={address._id}
                dispatch={dispatch}
                item={address}
                initialValues={address.values}
                onAddressUpdate={onAddressUpdate}
                onAddressDelete={onAddressDelete}
                indexLang={indexLang}
                submittingID={submittingID}
            />,
        )}
    </div>
  

export default AddressList
