import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { fetchCart } from './actions/cart';
import { fetchUser, fetchReferedUsers, fetchReferedUsersDetails } from './actions/user';
import AppRouter from './components/routers/AppRouter';
import Head from './containers/head/Head';
import './index.css';
import configureStore from './store/configureStore';
import { getNoMoreOrders } from './utils/specialEvents';

const store = configureStore()

const accessToken = localStorage.getItem('x-access-token')
if (accessToken) {
    store.dispatch(fetchUser())
    if (!getNoMoreOrders()) {
        store.dispatch(fetchReferedUsers())
        store.dispatch(fetchReferedUsersDetails())
    }
}

if (!getNoMoreOrders()) {
    const cart = localStorage.getItem('cart')
    if (cart) {
        store.dispatch(fetchCart(cart))
    }
}

render(
    <Provider store={store}>
        <div>
            <Head />
            <AppRouter />
        </div>
    </Provider>,
    document.getElementById('root'),
)
