import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import {
    MOREINFOABOUTPACK, GOBACK, DOGS, CATS,
} from '../../constant/stringTraduction';

// ************** SUMMER
// Dog images
import DogDiscoveryEnglishGeneric from '../../assets/web_packs_generic/desktop/dog/new_pack_dog_EN_768x350.png';
import DogDiscoveryFrenchGeneric from '../../assets/web_packs_generic/desktop/dog/new_pack_dog_FR_768x350.png';
import DogSuperPartyPackEnglishGeneric from '../../assets/web_packs_generic/desktop/dog/new_pack_super_dog_EN_768x350.png';
import DogSuperPartyPackFrenchGeneric from '../../assets/web_packs_generic/desktop/dog/new_pack_super_dog_FR_768x350.png';
import DogSuperPartyPackBedEnglishGeneric from '../../assets/web_packs_generic/desktop/dog/bed_new_pack_dog_EN_768x290.png';
import DogSuperPartyPackBedFrenchGeneric from '../../assets/web_packs_generic/desktop/dog/bed_new_pack_dog_FR_768x290.png';

// Cat images
import CatDiscoveryEnglishGeneric from '../../assets/web_packs_generic/desktop/cat/new_pack_cat_en_768x350.png';
import CatDiscoveryFrenchGeneric from '../../assets/web_packs_generic/desktop/cat/new_pack_cat_FR_768x350.png';
import CatSuperPartyPackEnglishGeneric from '../../assets/web_packs_generic/desktop/cat/pack_super_cat_en_768x350.png';
import CatSuperPartyPackFrenchGeneric from '../../assets/web_packs_generic/desktop/cat/pack_cat_super_FR_768x350.png';
import CatSuperPartyPackBedEnglishGeneric from '../../assets/web_packs_generic/desktop/cat/bed_new_pack_cat_EN_768x290.png';
import CatSuperPartyPackBedFrenchGeneric from '../../assets/web_packs_generic/desktop/cat/bed_new_pack_cat_FR_768x290.png';

// ************** WINTER
// Dog images
import DogDiscoveryEnglishWinter from '../../assets/web_packs_winter/desktop/dog/winter_discovery_pack_dog_EN.png';
import DogDiscoveryFrenchWinter from '../../assets/web_packs_winter/desktop/dog/winter_discovery_pack_dog_FR.png';
import DogSuperPartyPackEnglishWinter from '../../assets/web_packs_winter/desktop/dog/winter_super_pack_dog_EN.png';
import DogSuperPartyPackFrenchWinter from '../../assets/web_packs_winter/desktop/dog/winter_super_pack_dog_FR.png';
import DogSuperPartyPackBedEnglishWinter from '../../assets/web_packs_winter/desktop/dog/winter_super_pack_dog_bed_EN.png';
import DogSuperPartyPackBedFrenchWinter from '../../assets/web_packs_winter/desktop/dog/winter_super_pack_dog_bed_FR.png';

// Cat images
import CatDiscoveryEnglishWinter from '../../assets/web_packs_winter/desktop/cat/winter_discovery_pack_cat_EN.png';
import CatDiscoveryFrenchWinter from '../../assets/web_packs_winter/desktop/cat/winter_discovery_pack_cat_FR.png';
import CatSuperPartyPackEnglishWinter from '../../assets/web_packs_winter/desktop/cat/winter_super_pack_cat_EN.png';
import CatSuperPartyPackFrenchWinter from '../../assets/web_packs_winter/desktop/cat/winter_super_pack_cat_FR.png';
import CatSuperPartyPackBedEnglishWinter from '../../assets/web_packs_winter/desktop/cat/winter_super_pack_cat_bed_EN.png';
import CatSuperPartyPackBedFrenchWinter from '../../assets/web_packs_winter/desktop/cat/winter_super_pack_cat_bed_FR.png';

import UnderBarForBedEnglish from '../../assets/web_packs/bar_under_english.png';
import UnderBarForBedFrench from '../../assets/web_packs/bar_under_french.png';

const LearnMoreModal = ({
    indexSaison,
    indexLang,
    modal,
    toggleModal,
    indexPack,
    indexAnimal,
    changeAnimal,
    fullPacksSelection,
    setIndexPack,
}) => {
    // Dimension 1: saison, 2: lang, 3: animal, 4: type
    const packImages =
    [
        [
            [[DogDiscoveryEnglishWinter, DogSuperPartyPackEnglishWinter, DogSuperPartyPackBedEnglishWinter], [CatDiscoveryEnglishWinter, CatSuperPartyPackEnglishWinter, CatSuperPartyPackBedEnglishWinter]],
            [[DogDiscoveryFrenchWinter, DogSuperPartyPackFrenchWinter, DogSuperPartyPackBedFrenchWinter], [CatDiscoveryFrenchWinter, CatSuperPartyPackFrenchWinter, CatSuperPartyPackBedFrenchWinter]],
        ],
        [
            [[DogDiscoveryEnglishGeneric, DogSuperPartyPackEnglishGeneric, DogSuperPartyPackBedEnglishGeneric], [CatDiscoveryEnglishGeneric, CatSuperPartyPackEnglishGeneric, CatSuperPartyPackBedEnglishGeneric]],
            [[DogDiscoveryFrenchGeneric, DogSuperPartyPackFrenchGeneric, DogSuperPartyPackBedFrenchGeneric], [CatDiscoveryFrenchGeneric, CatSuperPartyPackFrenchGeneric, CatSuperPartyPackBedFrenchGeneric]],
        ],
    ];

    const barUnderImages = [UnderBarForBedEnglish, UnderBarForBedFrench];

    return (
        <Modal id='learnmore_modal' isOpen={modal} toggle={toggleModal} backdrop='static'>
            <ModalHeader style={{ background: '#666666', border: 'none' }} toggle={toggleModal}>{MOREINFOABOUTPACK[indexLang]}</ModalHeader>
            <ModalBody className='white-background'>
                {fullPacksSelection && indexPack > 0 && <span className='fa fa-arrow-left full-selection-left-arrow' onClick={() => {
                    setIndexPack(indexPack - 1);
                }} ></span>}
                {fullPacksSelection && indexPack < 2 && <span className='fa fa-arrow-right full-selection-right-arrow' onClick={() => {
                    setIndexPack(indexPack + 1); 
                }}></span>}
                <img style={{ width: '100%' }} src={packImages[indexSaison][indexLang][indexAnimal][indexPack]} alt="Pack" />
                {indexPack === 3 && <img src={barUnderImages[indexLang]} alt='Schedule' />}
            </ModalBody>
            <ModalFooter style={{ background: '#666666', border: 'none', display: 'flex', justifyContent: 'center' }} >
                <Button style={{ width: '45%' }}
                    className="black-button"
                    onClick={toggleModal}>
                    {GOBACK[indexLang]}
                </Button>
                <Button style={{ width: '45%' }}
                    className="yellow-button"
                    onClick={changeAnimal}>
                    {indexAnimal ? <span>{DOGS[indexLang]}</span> : <span>{CATS[indexLang]}</span>}
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default LearnMoreModal
