import ListItem from '@material-ui/core/ListItem';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { signout } from '../../actions/user';
import {
    DECONNEXION, INSCRIPTION, PROFIL, SEEMYORDERS, REFERFRIENDS,
    MYACCOUNT,
} from '../../constant/stringTraduction';
import { getNoMoreOrders, getClosed } from '../../utils/specialEvents';

class UserButtons extends Component {
    handleSignout = () => {
        const { dispatch, history, onSelect } = this.props
        onSelect()
        return dispatch(signout(history))
    }
    render() {
        const {
            onSelect,
            indexLang,
        } = this.props
        return (
            (!getClosed() &&
            <div>
                firstName ?
                <div>
                    <Link to="/user/profile">
                        <ListItem
                            button={true}
                            onClick={onSelect}
                        >
                            {PROFIL[indexLang]}
                        </ListItem>
                    </Link>
                    <ListItem
                        button={true}
                        onClick={() => {
                            this.props.dispatch({ type: 'SWITCH_TAB', indexTab: '3' });
                            this.props.history.push('/user/profile');
                            onSelect();
                        }}
                    ><span className="underlineHover">{SEEMYORDERS[indexLang]}</span>
                    </ListItem>
                    {!getNoMoreOrders() && 
                    <ListItem
                        button={true}
                        onClick={() => {
                            this.props.dispatch({ type: 'SWITCH_TAB', indexTab: '4' });
                            this.props.history.push('/user/profile');
                            onSelect();
                        }}
                    >
                        <span className="underlineHover"><i className='fa fa-star blue-star'></i> {REFERFRIENDS[indexLang]}</span>
                    </ListItem>
                    }
                    <Link to="/user/signin">
                        <ListItem
                            button={true}
                            onClick={this.handleSignout}
                        >{DECONNEXION[indexLang]}
                        </ListItem>
                    </Link>
                </div >
                :
                <div>
                    <Link to="/user/signin">
                        <ListItem
                            onClick={onSelect}
                            button={true}
                        >
                            {MYACCOUNT[indexLang]}
                        </ListItem>
                    </Link>
                    <Link to="/user/signup" >
                        <ListItem
                            button={true}
                            onClick={onSelect}
                        >{INSCRIPTION[indexLang]}
                        </ListItem>
                    </Link>
                </div>
            </div>
            )
        )
    }
}

export default UserButtons
