import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { PROCEDERAUPAIEMENT } from '../../constant/stringTraduction';
import { Button } from 'reactstrap';
import { getNoMoreOrders } from '../../utils/specialEvents';

class CheckoutButton extends Component {
  handleNavigation = () => {
      const { dispatch, user: { values: { email } } } = this.props
      if (!email) {
          return dispatch({ type: 'REDIRECT_USER', path: '/user/order' })
      }
      return
  }
  render () {
      const { user, indexLang } = this.props
      return (
          <div>
              {!getNoMoreOrders() &&
                <Link to={user.values.email ? '/user/order' : '/user/signup'}>
                    <Button
                        onClick={this.handleNavigation}
                        style={{ width: '100%', backgroundColor: '#fff100', border: 'none', color: 'black', fontWeight: 900 }}
                        className='cartButton' 
                    >
                        {PROCEDERAUPAIEMENT[indexLang]}
                    </Button>
                </Link>
              }
          </div>
      )
  }
}

export default CheckoutButton
