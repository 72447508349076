import React, { Component } from 'react';
import { Nav, NavItem, NavLink, Container, TabContent, TabPane } from 'reactstrap';
import AddCoupon from './AddCoupon';
import Coupon from '../coupons/Coupon';
import Orders from './Orders';
import Details from './Details';
//import EmailStudio from './EmailStudio';
import ReferedUsers from './ReferedUsers';
import adminContainer from '../../containers/admin/adminContainer';
import './admin.css';
import { reset } from 'redux-form';
import { fetchAddCoupon, fetchAllCoupons } from '../../actions/coupon';
import { fetchAllOrders, fetchAllCancelForm, fetchOldOrders } from '../../actions/orders';
import {
    filterApprecie, filterNonApprecie, filterSubCanceled,
    filterSubPaused, filterCoupon, countOrders, searchOrders, searchOldOrders,
    filterTrialConversion, filterOldTrial, filterExceptions, countOldOrders,
} from '../../actions/admin';

class Admin extends Component {
    constructor(props) {
        super(props);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.selectFilter = this.selectFilter.bind(this);
        this.state = {
            isForever: false,
            utilisationIllimite: true,
            dateExpiration: false,
            orders: [],
            subscriptions: [],
            trials: [],
            dropdownOpen: false,
            searchString: '',
            searchOldString: '',
        };
    }

    componentDidMount = async () => {
        const { dispatch } = this.props;
        dispatch(fetchAllCancelForm());
        dispatch(fetchAllCoupons('slug', 'reg', true));
        dispatch(countOrders());
        dispatch(countOldOrders());
        dispatch(fetchOldOrders());
        await dispatch(fetchAllOrders());
        this.separateSubOrder();
    }

    handleDurationChange = (e) => {
        this.setState({ isForever: e })
    }
    handleUtilisationIllimite = (e) => {
        this.setState({ utilisationIllimite: !e })
    }

    handleDateExpiration = (e) => {
        this.setState({ dateExpiration: e })
    }

    changeTab(tab) {
        if (this.props.activeTab !== tab) {
            this.props.dispatch({ type: 'SWITCH_TAB', indexTab: tab })
            this.props.dispatch(fetchAllOrders())
        }
    }

    handleCouponSubmit = async (values) => {
        await this.props.dispatch(fetchAddCoupon(
            values,
            this.state.isForever,
            false,
        ));
        this.props.dispatch(reset('adminCouponAdd'));
        this.setState({
            isForever: false,
            utilisationIllimite: true,
            dateExpiration: false,
        })
    }
    toggleDropdown = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen })
    }
    selectFilter = async (event) => {
        const dispatch = this.props.dispatch;
        if (event.target.value === 'all') {
            await dispatch(fetchAllOrders());
        } else if (event.target.value === 'coupon') {
            await dispatch(filterCoupon());
        } else if (event.target.value === 'apprecie') {
            await dispatch(filterApprecie());
        } else if (event.target.value === 'non-apprecie') {
            await dispatch(filterNonApprecie());
        } else if (event.target.value === 'paused') {
            await dispatch(filterSubPaused());
        } else if (event.target.value === 'canceled') {
            await dispatch(filterSubCanceled());
        } else if (event.target.value === 'wasTrial') {
            await dispatch(filterTrialConversion());
        }
        this.separateSubOrder();
    }

    selectFilterDetails = async (event) => {
        const dispatch = this.props.dispatch;
        if (event.target.value === 'all') {
            await dispatch(fetchOldOrders());
        } else if (event.target.value === 'wasTrial') {
            await dispatch(filterOldTrial());
        } else if (event.target.value === 'exceptions') {
            await dispatch(filterExceptions());
        }
    }

    onSearchChange = async (e) => {
        this.setState({
            searchString: e.target.value,
        });
        if (!e.target.value) {
            await this.props.dispatch(searchOrders(''))
            this.separateSubOrder();
        }
    }

    onSearchClick = async () => {
        await this.props.dispatch(searchOrders(this.state.searchString))
        this.separateSubOrder();
    }

    onOldSearchChange = async (e) => {
        this.setState({
            searchOldString: e.target.value,
        });
        if (!e.target.value) {
            await this.props.dispatch(searchOldOrders(''))
        }
    }

    onOldSearchClick = async () => {
        await this.props.dispatch(searchOldOrders(this.state.searchOldString))
    }

    separateSubOrder() {
        let ord = [];
        let sub = [];
        let trials = [];
        for (let i = 0; i < this.props.orders.length; i++) {
            if (this.props.orders[i].item.trial || this.props.orders[i].item.typeId) {
                ord.push(this.props.orders[i]);
                if (this.props.orders[i].item.trial) {
                    trials.push(this.props.orders[i])
                }
            } else {
                sub.push(this.props.orders[i]);
            }
        }
        this.setState({
            orders: ord,
            subscriptions: sub,
            trials: trials,
        });
    }

    render() {
        const {
            activeTab,
            submitSucceeded,
            submitting,
            isFetching,
            pristine,
            invalid,
            error,
            dispatch,
            cancelForms,
            coupons,
            orders,
            count,
            oldOrder,
            oldOrdersCount,
            allReferedUsers,
        } = this.props

        return (
            <div>
                <Container className='white-background container-top-margin admin-container' style={{ textAlign: 'center', paddingTop: 99 }}>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={activeTab === '1' ? 'active' : ''}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    this.changeTab('1');
                                }}
                            >
                                Coupons
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === '2' ? 'active' : ''}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    this.changeTab('2');
                                }}
                            >
                                Commandes
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === '3' ? 'active' : ''}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    this.changeTab('3');
                                }}
                            >
                                Abonnements
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === '4' ? 'active' : ''}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    this.changeTab('4');
                                }}
                            >
                                Email Studio
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === '5' ? 'active' : ''}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    this.changeTab('5');
                                }}
                            >
                                Ancienne commandes
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === '6' ? 'active' : ''}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    this.changeTab('6');
                                }}
                            >
                                Personnes référées
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} style={{ marginTop: '10px' }}>
                        <TabPane tabId="1" className='admin-section'>
                            <AddCoupon
                                handleDurationChange={this.handleDurationChange}
                                handleUltimatePartyPackCatOnly={this.handleUltimatePartyPackCatOnly}
                                handleUltimatePartyPackDogOnly={this.handleUltimatePartyPackDogOnly}
                                handleSuperPackOnly={this.handleSuperPackOnly}
                                isForever={this.state.isForever}
                                utilisationIllimite={this.state.utilisationIllimite}
                                handleUtilisationIllimite={this.handleUtilisationIllimite}
                                handleDateExpiration={this.handleDateExpiration}
                                dateExpiration={this.state.dateExpiration}
                                submitSucceeded={submitSucceeded}
                                submitting={submitting}
                                isFetching={isFetching}
                                resetError={this.resetError}
                                pristine={pristine}
                                invalid={invalid}
                                errorCoupon={error}
                                handleCouponSubmit={this.handleCouponSubmit}
                                illimite={this.state.utilisationIllimite}
                                expiration={this.state.dateExpiration}
                            />

                            <Coupon
                                coupons={coupons}
                                orders={orders}
                            />

                        </TabPane>
                        <TabPane tabId='2' className='admin-section'>
                            <Orders
                                subscription={0}
                                orders={this.state.orders}
                                dispatch={dispatch}
                                cancelForms={cancelForms}
                                coupons={coupons}
                                toggleDropdown={this.toggleDropdown}
                                selectFilter={this.selectFilter}
                                dropdownOpen={this.state.dropdownOpen}
                                count={count}
                                onSearchChange={this.onSearchChange}
                                onSearchClick={this.onSearchClick}
                                trials={this.state.trials}
                            />
                        </TabPane>
                        <TabPane tabId='3' className='admin-section'>
                            <Orders
                                subscription={1}
                                orders={this.state.subscriptions}
                                dispatch={dispatch}
                                cancelForms={cancelForms}
                                coupons={coupons}
                                toggleDropdown={this.toggleDropdown}
                                selectFilter={this.selectFilter}
                                dropdownOpen={this.state.dropdownOpen}
                                count={count}
                                onSearchChange={this.onSearchChange}
                                onSearchClick={this.onSearchClick}
                                trials={this.state.trials}
                            />
                        </TabPane>
                        <TabPane tabId='4' className='admin-section'>
                            <Details
                                selectFilterDetails={this.selectFilterDetails}
                                oldOrder={oldOrder}
                                onOldSearchChange={this.onOldSearchChange}
                                onOldSearchClick={this.onOldSearchClick}
                                oldOrdersCount={oldOrdersCount}
                            />
                        </TabPane>
                        <TabPane tabId='5' className='admin-section'>
                            <ReferedUsers
                                dispatch={dispatch}
                                allReferedUsers={allReferedUsers}
                            />
                        </TabPane>
                    </TabContent>
                </Container >
            </div>
        );
    }
}

export default adminContainer(Admin)
