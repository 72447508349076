import React from 'react';
import { Field } from 'redux-form';
import { CODEPOSTAL, NOMADRESSE, PROVINCE, TELEPHONE, VILLE, ADRESSE, 
    EXAMPLEADDRESSNAME } from '../../constant/stringTraduction';
import { normalizePhone } from '../../utils/normalizers';
import { normalizeZip } from '../../utils/normalizers';
import renderTextFieldBlack from '../fields/renderTextFieldBlack';
import renderSelectFieldProvince from '../fields/renderSelectFieldProvince';

class AddressFields extends React.Component {
  
    render () {
        const {
            indexLang,
        } = this.props;
        return (
            <div className="field-container">
                <Field
                    color='black'
                    name="name"
                    label={NOMADRESSE[indexLang]}
                    type="text"
                    className="field"
                    placeholder={EXAMPLEADDRESSNAME[indexLang]}
                    component={renderTextFieldBlack}
                    indexLang={indexLang}
                />
                <Field
                    color='black'
                    name="phone"
                    label={TELEPHONE[indexLang]}
                    type="text"
                    className="field"
                    component={renderTextFieldBlack}
                    normalize={normalizePhone}
                    indexLang={indexLang}
                />
                <Field
                    color='black'
                    name="street"
                    label={ADRESSE[indexLang]}
                    type="text"
                    className="field"
                    component={renderTextFieldBlack}
                    indexLang={indexLang}
                />
                <Field
                    color='black'
                    name="city"
                    label={VILLE[indexLang]}
                    type="text"
                    className="field"
                    component={renderTextFieldBlack}
                    indexLang={indexLang}
                />
                <Field
                    color="black"
                    style={{ minWidth: '194px' }}
                    name="state"
                    label={PROVINCE[indexLang]}
                    component={renderSelectFieldProvince}
                    className="field"
                    indexLang={indexLang}
                >
                </Field>
                <Field
                    color='black'
                    name="zip"
                    label={CODEPOSTAL[indexLang]}
                    type="text"
                    className="field"
                    component={renderTextFieldBlack}
                    normalize={normalizeZip}
                    indexLang={indexLang}
                />
            </div>
        )
    }
}

export default AddressFields
