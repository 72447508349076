import React from 'react'

import OrderCartItem from './OrderCartItem'

const OrderCartList = ({ dispatch, items }) => 
    <div className="OrderCartList">
        {items.length > 0 ? 
            items.map((item) => 
                <OrderCartItem
                    dispatch={dispatch}
                    key={item._id}
                    item={item}
                />,
            )
            :
            <div>-----------------</div>
        }
    </div>


export default OrderCartList
