import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { PREVIOUSPACKS } from '../../constant/stringTraduction';
 
import image_1 from '../../assets/unboxings_gallery/spring_2019/unboxing_spring_1.jpg';
import image_2 from '../../assets/unboxings_gallery/spring_2019/unboxing_spring_2.jpg';
import image_3 from '../../assets/unboxings_gallery/spring_2019/unboxing_spring_3.jpg';
import image_5 from '../../assets/unboxings_gallery/spring_2019/unboxing_spring_5.jpg';
import image_6 from '../../assets/unboxings_gallery/spring_2019/unboxing_spring_6.jpg';
import image_7 from '../../assets/unboxings_gallery/spring_2019/unboxing_spring_7.jpg';
import image_8 from '../../assets/unboxings_gallery/summer_2019/unboxing_summer_1.jpg';
import image_9 from '../../assets/unboxings_gallery/summer_2019/unboxing_summer_2.jpg';
import image_10 from '../../assets/unboxings_gallery/summer_2019/unboxing_summer_3.jpg';
import image_11 from '../../assets/unboxings_gallery/summer_2019/unboxing_summer_4.jpg';
import image_12 from '../../assets/unboxings_gallery/summer_2019/unboxing_summer_5.jpg';

let settings = {
    centerMode: true,
    infinite: true,
    centerPadding: '40px',
    slidesToShow: 3,
    speed: 500,
}
if (window.innerWidth <= 768) {
    settings = {
        centerMode: true,
        infinite: true,
        centerPadding: '0px',
        slidesToShow: 1,
        speed: 500,
    }
}

const UnboxingsGallery = ({ indexLang }) => {
    return (
        <Container id='unboxings_gallery'>
            <Row>
                <Col md='12' style={{ margin: '24px 0px' }}>
                    <span className='learn-more-white-title' style={{ fontFamily: 'Gotham-Bold', fontSize: 30 }}>{PREVIOUSPACKS[indexLang]}</span>
                </Col>
            </Row>
            <Row>
                <Col md='12'>
                    <Slider {...settings}>
                        <div><img src={image_8} alt='Unboxing' className='learnmore-slider-img'/></div>
                        <div><img src={image_9} alt='Unboxing' className='learnmore-slider-img'/></div>
                        <div><img src={image_10} alt='Unboxing' className='learnmore-slider-img'/></div>
                        <div><img src={image_11} alt='Unboxing' className='learnmore-slider-img'/></div>
                        <div><img src={image_12} alt='Unboxing' className='learnmore-slider-img'/></div>
                        <div><img src={image_1} alt='Unboxing' className='learnmore-slider-img'/></div>
                        <div><img src={image_2} alt='Unboxing' className='learnmore-slider-img'/></div>
                        <div><img src={image_3} alt='Unboxing' className='learnmore-slider-img'/></div>
                        <div><img src={image_5} alt='Unboxing' className='learnmore-slider-img'/></div>
                        <div><img src={image_6} alt='Unboxing' className='learnmore-slider-img'/></div>
                        <div><img src={image_7} alt='Unboxing' className='learnmore-slider-img'/></div>
                    </Slider>
                </Col>
            </Row>
        </Container>
    )
}

export default UnboxingsGallery
