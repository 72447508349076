import React, { Component } from 'react'
import { connect } from 'react-redux'

const NewsletterContainer = (ComposedComponent) => {
    class NewsletterContainer extends Component {
        render () {
            return (
                <ComposedComponent {...this.props} />
            )
        }
    }
    const mapStateToProps = ({
        langs: { indexLang },
        newsletterSubs: { message, userSub, isClosed },
    }) => ({
        indexLang,
        message,
        userSub,
        isClosed,
    })
    return connect(mapStateToProps)(NewsletterContainer)
}

export default NewsletterContainer
