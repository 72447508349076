import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
    WHATSINSIDETITLE, WHATSINSIDESUBTITLE, WHATSINSIDEDESCRIPTION, SEEPACKS,
} from '../../constant/stringTraduction';
const WhatsInside = ({ indexLang }) => {
    const wiLang = ['whatsinside-en ', 'whatsinside-fr'];
    return (
        <div style={{ textAlign:'center' }}>
            <div className={'whatsinside ' + wiLang[indexLang]}>
                <Container className="wi-container" style={{ height:'100%', padding:'50px 0px' }}>
                    <Row style={{ height:'100%' }}>
                        <Col lg='5'>
                            <div style={{ padding:'25px', height:'100%' }}>
                                <h2 className='whatsinside_title'>{WHATSINSIDETITLE[indexLang]}</h2>
                                <h2 className='party-pack-title'>SUPER PARTY PACK</h2>
                                <h2 className='whatsinside_subtitle'>{WHATSINSIDESUBTITLE[indexLang]}</h2>
                                <h2 className='whatsinside_description'>{WHATSINSIDEDESCRIPTION[indexLang]}</h2>
                                <div className='seepacks-button'>
                                    <Link to="/pack-examples"><Button className="yellow-button whats-inside-btn">{SEEPACKS[indexLang]}</Button></Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default WhatsInside
