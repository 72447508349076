const faq = (state = {
    isSending: false,
    succeeded: false,
    error: null,
}, action) => {
    switch (action.type) {
        case 'IS_SENDING_MESSAGE':
            return {
                ...state,
                isSending: true,
                succeeded: false,
            }
        case 'IS_DONE':
            return {
                ...state,
                isSending: false,
                succeeded: false,
            }
        case 'SUCCEEDED':
            return {
                ...state,
                isSending: false,
                succeeded: true,
            }
        default:
            return state
    }
}

export default faq
