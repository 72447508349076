import React, { Component } from 'react';
import cat from '../../../assets/selection/cat.png'
import { CHAT } from '../../../constant/stringTraduction';
export default class CatPictoSmall extends Component {
    render() {
        return (
            <div className='picto-wrapper'>
                <img src={cat} alt="chat" className='example-picto'></img>
                <div style={{ marginTop: 10 }}><b>{CHAT[this.props.indexLang]}</b></div>
            </div>
        );
    }
}
