export const PK_STRIPE = ['pk_test_8IXwEQpBvWH8OgZAONZc6FHy', 'pk_live_VDGtWXMVsOgW8aSjHjKSUNYg']; // 0 = DEV, 1 = PROD
const env = process.env.NODE_ENV || 'development'
export const DEVMODE = env === 'development';
export const CREDIT_AMOUNT = 300;
export const DAY_FOR_SHIPPING = [20, 21];
export const DATE_JOUR = 20;
export const DATES_SAISONS_MOIS = [10, 4];
export const SEASON_NAMES = [
    ['Winter', 'Hiver'],
    ['Summer', 'Été'],
];
export const PROVINCES = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];
// Si on ajoute des types de conditions, les ajouter à la fin de la liste (sinon ça casse!)
export const EMAIL_STUDIO_TYPE_CONDS = [
    { id: 0, description: 'Choix de réponse' },
    { id: 1, description: 'Dates' },
    { id: 2, description: 'Choix de province' },
    { id: 3, description: 'Slug/Code de coupon' },
    { id: 4, description: 'Upgrade à un abonnement' },
    { id: 5, description: 'Produit(s) déjà réçu(s)' },
    { id: 6, description: 'Possède des commandes actives' },
];
// Si on ajoute des conditions, les ajouter à la fin de la liste (sinon ça casse!)
export const LIST_EMAIL_STUDIO_CONDS = [
    { id: 0, typeCond: 0, description: 'Selon le type du pack (Super ou Super avec lit)', choices: [0, 1] },
    { id: 1, typeCond: 0, description: 'Selon la langue', choices: [0, 1] },
    { id: 2, typeCond: 0, description: 'Selon le type de commande (GoodyPack ou Adoption Pack)', choices: [0, 1] },
    { id: 3, typeCond: 1, description: 'Selon la date de création du compte' },
    { id: 4, typeCond: 1, description: 'Selon la date d\'abonnement' },
    { id: 5, typeCond: 0, description: 'Selon l\'animal', choices: [0, 1] },
    { id: 6, typeCond: 0, description: 'Selon la taille de l\'animal', choices: [0, 1, 2] },
    { id: 7, typeCond: 1, description: 'Selon la date du désabonnement' },
    { id: 8, typeCond: 2, description: 'Selon la province', choices: PROVINCES },
    { id: 9, typeCond: 3, description: 'Selon le coupon utilisé' },
    { id: 10, typeCond: 4, description: 'Selon si le client a passé d\'un trial à un abonnement' },
    { id: 11, typeCond: 6, description: 'Selon si le client a des commandes actives' },
    { id: 12, typeCond: 5, description: 'Selon si le client a déjà le ou les produits sélectionnés' },
];
export const SUPPORT_EMAIL = 'info@goodypack.ca';
export const SLUG_START = ['A', 'T'];
export const SLUG_LENGTH = 7;
export const SLUG_SEP = '_';
export const SLUG_BLANK = 'X';
export const SLUG_ANIMAL_POS = 2;
export const SLUG_SIZE_POS = 4;
export const BED_COSTS = [[2999, 2999, 3999], [1999, 1999]];
export const REGULAR_URL = 'regular';
export const TRIAL_URL = 'trial';
export const GPWINTER_URL = '25gpwinter';
export const DATE_CLOSED = new Date(2020, 5, 1, 0, 0, 0, 0);
export const DATE_STRIPE_CLOSED = new Date(2020, 4, 12, 0, 0, 0, 0);
export const DATE_NO_MORE_ORDERS = new Date(2020, 3, 15, 8, 0, 0, 999);
export const MAX_INT = Math.pow(2, 53);
