import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { resetError, fetchGetCardInfo, resetOrderModification } from '../../actions/orders';
import orderContainer from '../../containers/orders/orderContainer';
import OrderModifyAddress from './OrderModifyAddress';
import OrderModifyCard from './OrderModifyCard';
import OrderModifyNeck from './OrderModifyNeck.js';
import renderSelectFieldAddress from '../fields/renderSelectFieldAddress';
import GoToOrderButton from '../buttons/GoToOrdersButton';
import { reset } from 'redux-form';
import { getClosed, getStripeClosed } from '../../utils/specialEvents';

class OrderModification extends Component {
    state = {
        currentCardExp: '',
        currentCardLast4Digits: '',
        currentBrand: '',
        newAddress: false,
        selectedAddressId: null,
    }

    componentDidMount() {
        this.getCardInfo();
    }

    componentWillUnmount() {
        this.props.dispatch(resetOrderModification());
        this.props.dispatch(reset('NewAddressForm'));
        this.props.dispatch(reset('NewNeckForm'));
        this.props.dispatch(reset('NewCreditCardForm'));
    }

    getCardInfo = async () => {
        if (!this.props.order.item.trial && !getStripeClosed()) {
            const infos = await this.props.dispatch(fetchGetCardInfo(this.props.order));
            this.setState({
                currentCardExp: infos.exp_month + '/' + infos.exp_year,
                currentCardLast4Digits: infos.last4,
                currentBrand: infos.brand,
            })
        }
    }

    resetError = () => {
        this.props.dispatch(resetError());
    }

    handleAddressChange = (value) => {
        this.setState({ newAddress: value === 'newAddress' ? true : false });
        this.setState({ selectedAddressId: value });
    }

    render() {
        const {
            order,
            submitSucceeded,
            submitting,
            indexLang,
            isFetching,
            error,
            pristine,
            invalid,
            submitSucceededAddress,
            submittingAddress,
            errorAddress,
            errorCard,
            errorNeck,
            invalidAddress,
            stripePk,
            addresses,
            dispatch,
            orderPriceChanged,
        } = this.props

        return (
            <Container className='white-background container-top-margin'>
                {!getStripeClosed() && !order.item.trial &&
                    <OrderModifyCard
                        order={order}
                        submitSucceeded={submitSucceeded}
                        submitting={submitting}
                        indexLang={indexLang}
                        isFetching={isFetching}
                        pristine={pristine}
                        invalid={invalid}
                        errorCard={errorCard}
                        currentCardExp={this.state.currentCardExp}
                        currentCardLast4Digits={this.state.currentCardLast4Digits}
                        currentBrand={this.state.currentBrand}
                        stripePk={stripePk}
                        resetError={this.resetError}
                        getCardInfo={this.getCardInfo}
                    />
                }
                {!getClosed() &&
                <div>
                    <OrderModifyNeck
                        order={order}
                        submitSucceeded={submitSucceeded}
                        submitting={submitting}
                        indexLang={indexLang}
                        errorNeck={errorNeck}
                        isFetching={isFetching}
                        pristine={pristine}
                        invalid={invalid}
                        resetError={this.resetError}
                        initialValues={order}
                    />
                    <OrderModifyAddress
                        order={order}
                        submitSucceeded={submitSucceeded}
                        submitting={submitting}
                        indexLang={indexLang}
                        isFetching={isFetching}
                        resetError={this.resetError}
                        error={error}
                        pristine={pristine}
                        invalid={invalid}
                        submitSucceededAddress={submitSucceededAddress}
                        submittingAddress={submittingAddress}
                        errorAddress={errorAddress}
                        invalidAddress={invalidAddress}
                        newAddress={this.state.newAddress}
                        selectedAddressId={this.state.selectedAddressId}
                        handleAddressChange={this.handleAddressChange}
                        handleNewAddressShipping={this.handleNewAddressShipping}
                        addresses={addresses}
                        renderSelectFieldAddress={renderSelectFieldAddress}
                        orderPriceChanged={orderPriceChanged}
                    />
                </div>
                }
                <Row>
                    <Col md='4' style={{ textAlign: 'center' }}>
                        <GoToOrderButton dispatch={dispatch} indexLang={indexLang} />
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default orderContainer(OrderModification)
