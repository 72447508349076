/* global Stripe */
import { getStripeToken } from '../utils/getStripeToken';
import handleAuthFetch from '../utils/handleAuthFetch';
import { fetchDeleteCart } from './cart';

export const type = 'ORDER'
const route = 'orders'

const ADD = `ADD_${type}`
const REQUEST = `REQUEST_${type}S`
const RECEIVE = `RECEIVE_${type}S`
const UPDATE = `UPDATE_${type}`
const DELETE = `DELETE_${type}S`
const ERROR = `ERROR_${type}`

const fetchOrdersRequest = () => ({ type: REQUEST })
export const fetchOrdersSuccess = (items) => ({ type: RECEIVE, items })
const fetchOrdersFailure = (error) => ({ type: ERROR, error })
export const fetchOrders = () => {
    return async (dispatch) => {
        dispatch(fetchOrdersRequest())
        try {
            const json = await handleAuthFetch({
                path: '/api/orders',
                method: 'GET',
                body: null,
            });
            return dispatch(fetchOrdersSuccess(json));
        } catch (error) {
            return dispatch(fetchOrdersFailure(error));
        }
    }
}

// Create
const fetchAddOrderSuccess = (item) => ({ type: ADD, item })
const fetchAddOrderFailure = (error) => ({ type: ERROR, error })
export const fetchAddOrder = ({
    cart,
    indexLang,
    history,
    values: {
        cvc,
        exp,
        fullAddress,
        number,
        name,
        phone,
        street,
        city,
        state,
        zip,
    },
    stripePk,
}) => {
    return async (dispatch) => {
        Stripe.setPublishableKey(stripePk)
        const expiration = exp.split('/')
        const exp_month = parseInt(expiration[0], 10)
        const exp_year = parseInt(expiration[1], 10)
        const card = { number, exp_month, exp_year, cvc }
        try {
            const stripeToken = await getStripeToken(card);
            const body = {
                stripeToken,
                fullAddress,
                name,
                phone,
                street,
                city,
                state,
                zip,
                cart,
                indexLang,
            };
            try {
                const json = await handleAuthFetch({
                    path: '/api/orders',
                    method: 'POST',
                    body: body,
                });
                dispatch(fetchAddOrderSuccess(json));
                dispatch(fetchDeleteCart());
                dispatch(fetchOrders());
                return history.push(`/user/order/thankyou/${json._id}`); // Ne pas changer
            } catch (error) {
                console.log(error);
                return dispatch(fetchAddOrderFailure(['Oops, something went wrong', 'Oups, quelque chose a mal tourné'][indexLang]));
            }
        } catch (error_1) {
            console.log(error_1);
            dispatch(fetchAddOrderFailure(error_1[indexLang]));
        }
    }
}

export const fetchCreate = () => {
    return async (dispatch) => {
        try {
            const json = await handleAuthFetch({
                path: '/api/users/notify',
                method: 'POST',
                body: null,
            });
            return dispatch(fetchOrdersSuccess(json));
        } catch (error) {
            return dispatch(fetchOrdersFailure(error));
        }
    }
}

// Update
const fetchUpdateSuccess = (item) => ({ type: UPDATE, item })
const fetchUpdateFailure = (error) => ({ type: ERROR, error })
export const fetchUpdate = (_id, update) => {
    return async (dispatch) => {
        try {
            const json = await handleAuthFetch({
                path: `/api/${route}/${_id}`,
                method: 'PATCH',
                body: update,
            });
            return dispatch(fetchUpdateSuccess(json));
        } catch (error) {
            dispatch(fetchUpdateFailure(error));
        }
    }
}

export const deleteOrders = () => {
    return {
        type: DELETE,
    }
}

export const fetchCancelSubscription = (p_id, p_indexLang) => {
    return async (dispatch) => {
        dispatch({ type: 'SET_REMOVING_SUB_ID', payload: p_id })
        try {
            const x = await handleAuthFetch({
                path: '/api/orders/cancelsubscription',
                method: 'POST',
                body: { subId: p_id, indexLang: p_indexLang },
            });
            dispatch({ type: 'SET_REMOVING_SUB_ID', payload: '' });
            return dispatch({ type: UPDATE, item: x });
        } catch (err) {
            dispatch({ type: 'SET_REMOVING_SUB_ID', payload: '' });
            console.log(err);
        }
    }
}

export const getOrderItemSubscription = (p_subId, history) => {
    return async () => {
        try {
            const order = await handleAuthFetch({
                path: '/api/orders/getordersubscription',
                method: 'POST',
                body: { subId: p_subId },
            });
            return history.push(`/user/order/thankyou/${order._id}`); // Ne pas changer
        } catch (err) {
            console.log('err');
        }
    }
}

export const resetError = () => {
    return { type: 'RESET_ERROR_ORDER' }
}

// Add coupon to order
const fetchAddCouponOrderSuccess = (order) => ({ type: 'RECEIVE_ORDER_COUPON', order })
const fetchAddCouponOrderFailure = (error) => ({ type: 'ERROR_ORDER_COUPON', error })
export const fetchAddCouponOrder = (slug, orderId, couponId) => {
    return (dispatch, getState) => {
        dispatch({ type: 'ADDING_COUPON_ORDER', slug, orderId, couponId });
        return handleAuthFetch({
            path: `/api/orders/addcoupontosubscription`,
            method: 'POST',
            body: { slug, orderId, couponId },
        })
            .then((json) => {
                if (!json) {
                    return dispatch(fetchAddCouponOrderFailure({ error: 'order not found' }));
                }
                if (json.error) {
                    return dispatch(fetchAddCouponOrderFailure(json.error));
                }
                dispatch(fetchOrders());
                return dispatch(fetchAddCouponOrderSuccess(json));
            })
            .catch((error) => dispatch(fetchAddCouponOrderFailure({ error })))
    }
}

export const fetchGetCardInfo = (order) => {
    return async () => {
        try {
            const card = await handleAuthFetch({
                path: '/api/orders/getcardinfo',
                method: 'POST',
                body: { userId: order.user, order: order },
            });
            return card;
        } catch (err) {
            console.log(err);
        }
    }
}

const fetchUpdateCardSuccess = (item) => ({ type: UPDATE, item })
const fetchUpdateCardFailure = (error) => ({ type: 'ERROR_UPDATE_CARD', error: error })
export const fetchUpdateCard = ({
    order,
    indexLang,
    stripePk,
    values: {
        cvc,
        exp,
        number,
    },
}) => {
    return async (dispatch) => {
        Stripe.setPublishableKey(stripePk)
        const expiration = exp.split('/')
        const exp_month = parseInt(expiration[0], 10)
        const exp_year = parseInt(expiration[1], 10)
        const card = { number, exp_month, exp_year, cvc }
        try {
            const stripeToken = await getStripeToken(card);
            const body = {
                stripeToken,
                order,
            };
            try {
                const json = await handleAuthFetch({
                    path: '/api/orders/update-card',
                    method: 'POST',
                    body: body,
                });
                return dispatch(fetchUpdateCardSuccess(json));
            } catch (error) {
                console.log(error);
                return dispatch(fetchUpdateCardFailure(['Oops, something went wrong', 'Oups, quelque chose a mal tourné'][indexLang]));
            }
        } catch (error_1) {
            console.log(error_1);
            return dispatch(fetchUpdateCardFailure(error_1[indexLang]));
        }
    }
}

const fetchUpdateNeckSuccess = (item) => ({ type: 'MODIFYING_NECK_SUCCESS', item })
const fetchUpdateNeckFailure = (error) => ({ type: 'ERROR_UPDATE_NECK', error: error })
export const fetchUpdateNeck = ({
    order,
    indexLang,
    values: {
        standardNeckSize,
        neckSize,
    },
}) => {
    return async (dispatch) => {
        try {
            const body = {
                order,
                standardNeckSize,
                neckSize,
            };
            try {
                const json = await handleAuthFetch({
                    path: '/api/orders/update-neck',
                    method: 'POST',
                    body: body,
                });
                return dispatch(fetchUpdateNeckSuccess(json));
            } catch (error) {
                console.log(error);
                return dispatch(fetchUpdateNeckFailure(error[indexLang]));
            }
        } catch (error_1) {
            console.log(error_1);
            return dispatch(fetchUpdateNeckFailure(error_1[indexLang]));
        }
    }
}

const fetchUpdateAddressSuccess = (item) => ({ type: 'MODIFYING_ADDRESS_SUCCESS', item })
const fetchUpdateAddressFailure = (error) => ({ type: 'ERROR_UPDATE_ADDRESS', error: error })
export const fetchUpdateAddress = ({
    order,
    indexLang,
    values: {
        name,
        phone,
        street,
        city,
        state,
        zip,
        fullAddress,
    },
}) => {
    return async (dispatch) => {
        dispatch({ type: 'MODIFYING_ADDRESS' });
        const body = {
            name,
            phone,
            street,
            city,
            state,
            zip,
            indexLang,
            order,
            fullAddress,
        };
        try {
            const json = await handleAuthFetch({
                path: '/api/orders/update-address',
                method: 'POST',
                body: body,
            });
            if (json.total !== order.total) {
                dispatch({ type: 'ORDER_PRICE_CHANGED' });
            }
            return dispatch(fetchUpdateAddressSuccess(json));
        } catch (error) {
            console.log(error);
            return dispatch(fetchUpdateAddressFailure(['Oops, something went wrong', 'Oups, quelque chose a mal tourné'][indexLang]));
        }
    }
}

export const resetOrderModification = () => {
    return { type: 'RESET_ORDER_MODIFICATION' }
}

export const fetchPauseSubscription = (orderId) => {
    return async (dispatch) => {
        await dispatch({ type: 'SET_PAUSE_ID', payload: orderId })
        try {
            const x = await handleAuthFetch({
                path: '/api/orders/pausesubscription',
                method: 'POST',
                body: { orderId: orderId },
            });
            dispatch({ type: 'SET_PAUSE_ID', payload: '' });
            return dispatch({ type: UPDATE, item: x });
        } catch (err) {
            dispatch({ type: 'SET_PAUSE_ID', payload: '' });
            console.log(err);
        }
    }
}

export const fetchResumeSubscription = (orderId) => {
    return async (dispatch) => {
        await dispatch({ type: 'SET_RESUME_ID', payload: orderId })
        try {
            const x = await handleAuthFetch({
                path: '/api/orders/resumesubscription',
                method: 'POST',
                body: { orderId: orderId },
            });
            dispatch({ type: 'SET_RESUME_ID', payload: '' });
            return dispatch({ type: UPDATE, item: x });
        } catch (err) {
            dispatch({ type: 'SET_RESUME_ID', payload: '' });
            console.log(err);
        }
    }
}

export const fetchAllOrders = () => {
    return async (dispatch) => {
        try {
            const orders = await handleAuthFetch({
                path: '/api/orders/admin',
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'SET_ORDERS', payload: orders })
        } catch (error) {
            console.log(error);
        }
    }
}

export const fetchAllCancelForm = () => {
    return async (dispatch) => {
        try {
            const forms = await handleAuthFetch({
                path: '/api/admin/cancel-forms',
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'SET_CANCEL_FORMS', payload: forms })
        } catch (error) {
            console.log(error);
        }
    }
}

export const upgradeSubscription = (order, newSlug) => {
    return async (dispatch) => {
        await dispatch({ type: 'SET_UPGRADING_SUBSCRIPTION' })
        try {
            const x = await handleAuthFetch({
                path: '/api/orders/upgradesubscription',
                method: 'POST',
                body: { order: order, newSlug: newSlug },
            });
            dispatch({ type: 'SET_UPGRADE_SUCCESS' });
            return dispatch({ type: UPDATE, item: x });
        } catch (err) {
            dispatch({ type: 'SET_UPGRADE_ERROR', payload: err });
            console.log(err);
        }
    }
}

const fetchOldOrdersRequest = () => ({ type: REQUEST })
export const fetchOldOrdersSuccess = (items) => ({ type: 'RECEIVE_STAT_ORDERS', items })
const fetchOldOrdersFailure = (error) => ({ type: ERROR, error })
export const fetchOldOrders = () => {
    return async (dispatch) => {
        dispatch(fetchOldOrdersRequest())
        try {
            const json = await handleAuthFetch({
                path: '/api/orders/old',
                method: 'GET',
                body: null,
            });
            return dispatch(fetchOldOrdersSuccess(json));
        } catch (error) {
            return dispatch(fetchOldOrdersFailure(error));
        }
    }
}
