import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import boxSubscription from '../reducers/boxSubscription';
import carts from '../reducers/carts';
import coupons from '../reducers/coupons';
import drawer from '../reducers/drawer';
import langs from '../reducers/langs';
import orders from '../reducers/orders';
import user from '../reducers/user';
import users from '../reducers/users';
import faq from '../reducers/faq';
import admin from '../reducers/admin';
import returnPolicy from '../reducers/returnPolicy';
import retroaction from '../reducers/retroaction';
import products from '../reducers/products';
import emailStudio from '../reducers/emailStudio';
import contests from '../reducers/contests';
import newsletterSubs from '../reducers/newsletterSub'

const rootReducer = combineReducers({
    carts,
    drawer,
    form: reduxForm,
    orders,
    user,
    users,
    boxSubscription,
    langs,
    coupons,
    faq,
    returnPolicy,
    retroaction,
    admin,
    products,
    contests,
    newsletterSubs,
    emailStudio,
})

export default rootReducer
