import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { fetchReset } from '../../actions/user';
import renderTextFieldBlack from '../../components/fields/renderTextFieldBlack';
import history from '../../containers/routers/history';
import resetHOC from '../../containers/user/resetHOC';
import SuccessableButton from '../buttons/SuccessableButton';
import { RESET, MOTDEPASSE, CONFIRMATIONMDP, RESETVERB } from '../../constant/stringTraduction';
import './user.css';

const validate = (values) => {
    const errors = {}
    const requiredFields = ['password', 'passwordConfirm']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = '*';
        }
    })
    if (values.password !== values.passwordConfirm) {
        errors.passwordConfirm = '';
    }
    return errors
}

class Reset extends Component {
    state = {
        message: null,
        passwordMatch: false,
    }
    handleFormSubmit = (values) => {
        const { dispatch, resetToken, indexLang } = this.props;
        const { password, passwordConfirm } = values;
        if (password === passwordConfirm) {
            this.setState({ passwordMatch: true });
            return dispatch(fetchReset(values, resetToken, indexLang));
        }
        this.setState({ message: ['Passwords don\'t match!', 'Les mots de passes ne correspondent pas!'][indexLang] });
    }
    static getDerivedStateFromProps(props, state) {
        if (props.submitSucceeded && state.passwordMatch) {
            history.push('/');
        }
        if (props.submitFailed) {
            return { message: `Your email reset token has expired, recover again` };
        }
        return null;
    }
    render() {
        const {
            user,
            handleSubmit,
            invalid,
            pristine,
            submitting,
            indexLang,
        } = this.props
        return (
            <Container className="white-background container-top-margin" style={{ paddingTop: 95 }}>
                <section className="section">
                    <div>
                        <h2>{RESET[indexLang]}</h2>
                        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                            <Field name="password" color="black" component={renderTextFieldBlack} label={MOTDEPASSE[indexLang]} type="password" fullWidth={true} />
                            <Field name="passwordConfirm" color="black" component={renderTextFieldBlack} label={CONFIRMATIONMDP[indexLang]} type="password" fullWidth={true} />
                            <div style={{ color: 'red' }}>{this.state.message}</div>
                            <div className="button-container">
                                <SuccessableButton
                                    disabled={pristine || invalid}
                                    error={user.error}
                                    label={RESETVERB[indexLang]}
                                    reset={null}
                                    submitting={submitting}
                                />
                            </div>
                        </form>
                    </div>
                </section>
            </Container>
        )
    }
}

export default resetHOC(reduxForm({
    form: 'reset',
    validate,
})(Reset))
