import React from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import userContainer from '../../containers/user/userContainer';
import { EMAILSENT, PLEASECONFIRM, FERMER, CONNEXION, ONEMORESTEP } from '../../constant/stringTraduction';

class EmailConfirmationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true,
        };
        this.toggle = this.toggle.bind(this);
        this.resetConfirmation = this.resetConfirmation.bind(this);
    }
    toggle() {
        const { dispatch } = this.props
        dispatch({ type: 'RESET_CONFIRMATION' })
        this.setState((avant) => ({
            modal: !avant.modal,
        }));
    }
    resetConfirmation() {
        const { dispatch } = this.props
        dispatch({ type: 'RESET_CONFIRMATION' })
    }
    render () {
        const {
            user,
            indexLang,
        } = this.props;
        return (
            (user.notConfirmed &&
            <Modal className="modal-lg confirmation-modal" isOpen={this.state.modal} backdrop={true} toggle={this.toggle} style={{ backgroundColor:'white', marginTop:100 }}>
                <ModalHeader style={{ textAlign: 'center' }}>{ONEMORESTEP[indexLang]}</ModalHeader>
                <ModalBody>{EMAILSENT[indexLang]} {PLEASECONFIRM[indexLang]}</ModalBody>
                <ModalFooter>
                    <Row style={{ margin:'auto' }}>
                        <Link to="/user/signin">
                            <Button className="yellow-button" onClick={this.resetConfirmation} style={{ margin:'0px 10px' }}>{CONNEXION[indexLang]}</Button>
                        </Link>
                        <Button onClick={this.toggle} style={{ margin:'0px 10px' }} >{FERMER[indexLang]}</Button>
                    </Row>
                </ModalFooter>
            </Modal>)
        );
    }
}

export default userContainer(EmailConfirmationModal)
