import React from 'react';
import privacyPolicyContainer from '../../containers/staticPages/privacyPolicyContainer.js';
import { Container } from 'reactstrap';
import { SUPPORT_EMAIL } from '../../constant/otherConst.js';

class PrivacyPolicy extends React.Component {
    render () {
        const { indexLang } = this.props;

        return (
            <Container className='white-background container-top-margin'>
                {indexLang ?
                /* FRENCH */
                    <div>
                        <p><strong>POLITIQUE DE CONFIDENTIALITÉ</strong></p>
                        <br/>
                        <p>Cette politique de confidentialité décrit comment vos informations personnelles sont collectées, utilisées et partagées lorsque vous visitez ou effectuez un achat sur https://goodypack.ca / (le «Site»).</p>
                        <p>&nbsp;</p>
                        <p>INFORMATIONS PERSONNELLES QUE NOUS COLLECTONS</p>
                        <p>Lorsque vous visitez le site, nous collectons automatiquement certaines informations sur votre appareil, notamment des informations sur votre navigateur Web, votre adresse IP, votre fuseau horaire et certains des cookies installés sur votre appareil. En outre, lorsque vous naviguez sur le Site, nous collectons des informations sur les pages Web ou les produits que vous consultez, sur les sites Web ou les termes de recherche qui vous ont référé au Site et sur la manière dont vous interagissez avec le Site. Nous nous référons à ces informations automatiquement collectées en tant que "Informations sur le périphérique".</p>
                        <p>Nous collectons des informations sur les périphériques à l'aide des technologies suivantes:</p>
                        <p>- Les "cookies" sont des fichiers de données qui sont placés sur votre appareil ou votre ordinateur et incluent souvent un identifiant unique anonyme. Pour plus d'informations sur les cookies, et comment désactiver les cookies, visitez http://www.allaboutcookies.org.</p>
                        <p>- Les «fichiers journaux» suivent les actions effectuées sur le Site et collectent des données comprenant votre adresse IP, le type de navigateur, le fournisseur de services Internet, les pages de renvoi / sortie et les horodatages.</p>
                        <p>- "Balises Web", "balises" et "pixels" sont des fichiers électroniques utilisés pour enregistrer des informations sur la navigation sur le Site.</p>
                        <p>En outre, lorsque vous effectuez un achat ou tentez d'effectuer un achat via le Site, nous collectons certaines informations, notamment votre nom, votre adresse de facturation, votre adresse de livraison, vos informations de paiement (numéros de carte de crédit, adresse e-mail et numéro de téléphone). à cette information en tant que "Informations sur la commande".</p>
                        <p>Lorsque nous parlons de «renseignements personnels» dans la présente politique de confidentialité, nous parlons à la fois de l'information sur l'appareil et de l'information sur la commande.</p>
                        <p>&nbsp;</p>
                        <p>COMMENT UTILISONS-NOUS VOS INFORMATIONS PERSONNELLES?</p>
                        <p>Nous utilisons les Informations de Commande que nous collectons généralement pour exécuter toutes les commandes passées sur le Site (y compris le traitement de vos informations de paiement, l'organisation de l'expédition et la fourniture de factures et / ou confirmations de commandes). De plus, nous utilisons cette information de commande pour:</p>
                        <p>Communiquer avec toi;</p>
                        <p>Examiner nos commandes pour détecter les risques potentiels ou les fraudes; et</p>
                        <p>En fonction des préférences que vous avez partagées avec nous, vous fournir des informations ou des publicités relatives à nos produits ou services.</p>
                        <p>Nous utilisons les Informations sur les appareils que nous collectons pour nous aider à détecter les risques potentiels et la fraude (en particulier votre adresse IP), et plus généralement pour améliorer et optimiser notre Site (par exemple, en générant des analyses sur la navigation et l'interaction de nos clients le Site, et d'évaluer le succès de nos campagnes marketing et publicitaires telles que la publicité / reciblage).</p>
                        <p>&nbsp;</p>
                        <p>PARTAGE DE VOS INFORMATIONS PERSONNELLES</p>
                        <p>Nous partageons vos informations personnelles avec des tiers pour nous aider à utiliser vos informations personnelles, comme décrit ci-dessus. Par exemple, nous utilisons Shopify pour alimenter notre boutique en ligne - vous pouvez en savoir plus sur la manière dont Shopify utilise vos informations personnelles ici: https://www.shopify.com/legal/privacy. Nous utilisons également Google Analytics pour nous aider à comprendre comment nos clients utilisent le site. Vous pouvez en savoir plus sur la façon dont Google utilise vos informations personnelles ici: https://www.google.com/intl/fr/policies/privacy/. Vous pouvez également vous désinscrire de Google Analytics ici: https://tools.google.com/dlpage/gaoptout.</p>
                        <p>Enfin, nous pouvons également partager vos renseignements personnels pour se conformer aux lois et règlements applicables, pour répondre à une citation à comparaître, un mandat de perquisition ou toute autre demande légitime d'information que nous recevons, ou pour protéger nos droits.</p>
                        <p>&nbsp;</p>
                        <p>PUBLICITÉ COMPORTEMENTALE</p>
                        <p>Comme décrit ci-dessus, nous utilisons vos informations personnelles pour vous fournir des publicités ciblées ou des communications marketing susceptibles de vous intéresser. Pour plus d'informations sur le fonctionnement de la publicité ciblée, vous pouvez consulter la page éducative de la Network Advertising Initiative («NAI») à l'adresse http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.</p>
                        <p>&nbsp;</p>
                        <p>Vous pouvez désactiver la publicité ciblée en:</p>
                        <p>[[</p>
                        <p>FACEBOOK - https://www.facebook.com/settings/?tab=ads</p>
                        <p>GOOGLE - https://www.google.com/settings/ads/anonymous</p>
                        <p>BING - https://advertise.bingads.microsoft.com/fr-fr/resources/policies/personalized-ads</p>
                        <p>]]</p>
                        <p>En outre, vous pouvez vous désengager de certains de ces services en visitant le portail d'exclusion de la Digital Advertising Alliance à l'adresse suivante: http://optout.aboutads.info/.</p>
                        <p>&nbsp;</p>
                        <p>NE PAS SUIVRE</p>
                        <p>Veuillez noter que nous ne modifions pas les pratiques de collecte et d'utilisation des données de notre site lorsque nous voyons un signal Ne pas suivre de votre navigateur.</p>
                        <p>&nbsp;</p>
                        <p>VOS DROITS<br/> <br/> Si vous êtes un résident européen, vous avez le droit d'accéder aux informations personnelles que nous détenons à votre sujet et de demander que vos informations personnelles soient corrigées, mises à jour ou effacées. Si vous souhaitez exercer ce droit, veuillez nous contacter via les informations de contact ci-dessous.</p>
                        <p>En outre, si vous êtes un résident européen, nous notons que nous traitons vos informations afin de remplir les contrats que nous pourrions avoir avec vous (par exemple si vous passez une commande via le Site), ou pour poursuivre nos intérêts commerciaux légitimes énumérés ci-dessus. De plus, veuillez noter que vos informations seront transférées en dehors de l'Europe, y compris au Canada et aux États-Unis.</p>
                        <p>&nbsp;</p>
                        <p>LA CONSERVATION DES DONNÉES<br/> <br/> Lorsque vous passez une commande par le biais du site, nous conservons vos informations de commande pour nos dossiers à moins que vous ne nous demandiez de supprimer ces informations.</p>
                        <p>&nbsp;</p>
                        <p>HOTJAR</p>
                        <p>Nous utilisons Hotjar pour mieux comprendre les besoins de nos utilisateurs et optimiser notre service et votre expérience. Hotjar est un service technologique qui nous aide à mieux comprendre l'expérience de nos utilisateurs (par exemple, combien de temps ils passent sur quelles pages, quels liens ils choisissent de cliquer, ce que les utilisateurs n'aiment pas, etc.) et cela nous permet de construire et maintenir notre service avec les commentaires des utilisateurs. Hotjar utilise des cookies et d'autres technologies pour collecter des données sur le comportement de nos utilisateurs et leurs périphériques (en particulier l'adresse IP du périphérique (capturé et stocké sous forme anonyme), la taille de l'écran, le type de périphérique (pays uniquement), langue préférée utilisée pour afficher notre site Web). Hotjar stocke ces informations dans un profil utilisateur pseudonymisé. Ni Hotjar ni nous n'utiliserons ces informations pour identifier des utilisateurs individuels ou pour les associer à d'autres données sur un utilisateur individuel.</p>
                        <p>&nbsp;</p>
                        <p>CHANGEMENTS<br/> <br/> Nous pouvons mettre à jour cette politique de confidentialité de temps en temps afin de refléter, par exemple, des changements à nos pratiques ou pour d'autres raisons opérationnelles, légales ou réglementaires.</p>
                        <p>&nbsp;</p>
                        <p>CONTACTEZ NOUS<br/> <br/> Pour plus d'informations sur nos pratiques de confidentialité, si vous avez des questions, ou si vous souhaitez déposer une plainte, s'il vous plaît contactez-nous par e-mail à <strong>{SUPPORT_EMAIL}</strong> ou par courrier en utilisant les informations fournies ci-dessous:</p>
                        <p>1893, rue de l'industrie, beloeil, QC, j3g0s4, Canada</p>
                        <p>&nbsp;</p>
                    </div>
                    :
                /* ENGLISH */
                    <div>
                        <p><strong>PRIVACY POLICY</strong></p>
                        <br/>
                        <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from https://goodypack.ca/ (the “Site”).</p>
                        <p>&nbsp;</p>
                        <p>PERSONAL INFORMATION WE COLLECT</p>
                        <p>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”</p>
                        <p>We collect Device Information using the following technologies:</p>
                        <p>&nbsp;&nbsp;&nbsp; - “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.</p>
                        <p>&nbsp;&nbsp;&nbsp; - “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</p>
                        <p>&nbsp;&nbsp;&nbsp; - “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.</p>
                        <p>Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers, email address, and phone number.&nbsp; We refer to this information as “Order Information.”</p>
                        <p>When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.</p>
                        <p>&nbsp;</p>
                        <p>HOW DO WE USE YOUR PERSONAL INFORMATION?</p>
                        <p>We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).&nbsp; Additionally, we use this Order Information to:</p>
                        <p>Communicate with you;</p>
                        <p>Screen our orders for potential risk or fraud; and</p>
                        <p>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</p>
                        <p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns such as advertising/retargeting).</p>
                        <p>&nbsp;</p>
                        <p>SHARING YOUR PERSONAL INFORMATION</p>
                        <p>We share your Personal Information with third parties to help us use your Personal Information, as described above.&nbsp; For example, we use Shopify to power our online store--you can read more about how Shopify uses your Personal Information here:&nbsp; https://www.shopify.com/legal/privacy.&nbsp; We also use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here:&nbsp; https://www.google.com/intl/en/policies/privacy/.&nbsp; You can also opt-out of Google Analytics here:&nbsp; https://tools.google.com/dlpage/gaoptout.</p>
                        <p>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>
                        <p>&nbsp;</p>
                        <p>BEHAVIOURAL ADVERTISING</p>
                        <p>As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you.&nbsp; For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.</p>
                        <p>You can opt out of targeted advertising by:</p>
                        <p>[[</p>
                        <p>&nbsp;&nbsp;&nbsp; FACEBOOK - https://www.facebook.com/settings/?tab=ads</p>
                        <p>&nbsp;&nbsp;&nbsp; GOOGLE - https://www.google.com/settings/ads/anonymous</p>
                        <p>&nbsp;&nbsp;&nbsp; BING - https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</p>
                        <p>]]</p>
                        <p>Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at:&nbsp; http://optout.aboutads.info/.</p>
                        <p>&nbsp;</p>
                        <p>DO NOT TRACK</p>
                        <p>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.</p>
                        <p>&nbsp;</p>
                        <p>YOUR RIGHTS</p>
                        <p>If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.</p>
                        <p>Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above.&nbsp; Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.</p>
                        <p>&nbsp;</p>
                        <p>DATA RETENTION</p>
                        <p>When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.</p>
                        <p>&nbsp;</p>
                        <p>CHANGES</p>
                        <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>
                        <p>&nbsp;</p>
                        <p>HOTJAR</p>
                        <p>We use Hotjar in order to better understand our users’ needs and to optimize this service and experience. Hotjar is a technology service that helps us better understand our users experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data on our users’ behavior and their devices (in particular device's IP address (captured and stored only in anonymized form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), preferred language used to display our website). Hotjar stores this information in a pseudonymized user profile. Neither Hotjar nor we will ever use this information to identify individual users or to match it with further data on an individual user. For further details, please see Hotjar’s privacy policy by clicking on this link.</p>
                        <p>&nbsp;</p>
                        <p>CONTACT US</p>
                        <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <strong>{SUPPORT_EMAIL}</strong> or by mail using the details provided below:</p>
                        <p>&nbsp;</p>
                        <p>1893 rue de l'industrie, beloeil, QC, j3g0s4, Canada</p>
                    </div>
                }
            </Container>
        );
    }
}

export default privacyPolicyContainer(PrivacyPolicy)
