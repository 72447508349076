import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import OrderCartList from '../../components/orders/OrderCartList';
import orderContainer from '../../containers/orders/orderContainer';
import formatPrice from '../../utils/formatPrice';
import './orders.css';

class AdminOrderDetailPage extends Component {
    componentDidMount () {
        window.scrollTo(0, 0)
    }
    render () {
        const {
            dispatch,
            indexLang,
            order: {
                _id,
                cart: { items, subTotal, taxes, total, rabais },
                address: { name, phone, street, city, state, zip },
            },
        } = this.props
        return (
            <div className="page">
                <section className="section-margin">
                    <div>
                        <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3>{`Order ${_id}`}</h3>
                        </div>
                        <OrderCartList
                            dispatch={dispatch}
                            items={items}
                        />
                        <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between' }}>
                            <div style={{ margin: 16 }}>
                                <div>Address</div>
                                <div>{name}</div>
                                <div>{phone}</div>
                                <div>{street}</div>
                                <div>{city}, {state} {zip}</div>
                            </div>
                            <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'flex-end' }}>
                                <h2 style={{ margin: '16px 16px 4px 16px' }}>Subtotal {formatPrice(subTotal, indexLang)}</h2>
                                <h2 style={{ margin: '4px 16px' }}>Tax {(taxes * 100).toFixed(2)}%</h2>
                                <h2 style={{ margin: '4px 16px' }}>Rabais {formatPrice(rabais, indexLang)}%</h2>
                                <h2 style={{ margin: '4px 16px' }}>Total {formatPrice(total, indexLang)}</h2>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default orderContainer(
    connect(({ order }) => ({ form: `order_${order._id}` }),
        reduxForm({}),
    )(AdminOrderDetailPage))
