import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import SuccessableButton from '../../components/buttons/SuccessableButton';
import renderTextFieldBlack from '../../components/fields/renderTextFieldBlack';
import renderCheckbox from '../../components/fields/renderCheckbox';
import { METTREAJOURSUSER, NOMFAMILLE, PRENOM, TELEPHONE, USERMODIFIER, COURRIEL, COLON,
    ACCEPTMARKETINGEMAILS } from '../../constant/stringTraduction';
import { normalizePhone } from '../../utils/normalizers';
import './user.css';
import imgProfileHeaderEnglish from '../../assets/my_profile_english.jpg';
import imgProfileHeaderFrench from '../../assets/my_profile_french.jpg';
import UserProfilePwForm from './UserProfilePwForm';
import { getNoMoreOrders } from '../../utils/specialEvents';

const validate = (values) => {
    const errors = {}
    const requiredFields = ['firstName', 'lastName', 'email']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = ['*', '*'];
        }
    })

    if (values.phone && values.phone.length < 14) {
        errors.phone = ['Ex : (555) 555-5555', 'Ex : (555) 555-5555'];
    }
    return errors
}

class UserProfileForm extends Component {
    render () {
        const {
            handleSubmit,
            error,
            invalid,
            onFormSubmit,
            onPwChangeSubmit,
            pristine,
            submitSucceeded,
            submitting,
            indexLang,
            user,
            resetError,
        } = this.props
        return (
            <div className="UserProfileForm white-background">
                <div className='background-image-header' style={{ backgroundImage: `url(${indexLang ? imgProfileHeaderFrench : imgProfileHeaderEnglish})` }}></div>
                <div className="user-profile-page-heading" style={{ marginTop: 10 }}>
                    {COURRIEL[indexLang]}{COLON[indexLang]}{user.values.email}
                </div>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <div className="field-container">
                        <Field
                            color='black'
                            name="firstName"
                            component={renderTextFieldBlack}
                            label={PRENOM[indexLang]}
                            className="field"
                            indexLang={indexLang}
                        />
                        <Field
                            color='black'
                            name="lastName"
                            component={renderTextFieldBlack}
                            label={NOMFAMILLE[indexLang]}
                            className="field"
                            indexLang={indexLang}
                        />
                        <Field
                            color='black'
                            name="phone"
                            component={renderTextFieldBlack}
                            label={TELEPHONE[indexLang]}
                            normalize={normalizePhone}
                            className="field"
                            indexLang={indexLang}
                        />
                        {!getNoMoreOrders() &&
                        <Field
                            name="acceptMarketingEmails"
                            component={renderCheckbox}
                            label={ACCEPTMARKETINGEMAILS[indexLang]}
                        />
                        }
                    </div>
                    <div className="button-container" style={{ marginBottom: 10 }}>
                        <SuccessableButton
                            disabled={pristine || invalid}
                            error={error}
                            label={METTREAJOURSUSER[indexLang]}
                            reset={null}
                            submitSucceeded={submitSucceeded}
                            submitting={submitting}
                            successLabel={USERMODIFIER[indexLang]}
                        />
                    </div>
                </form>
                <UserProfilePwForm
                    indexLang={indexLang}
                    user={user}
                    resetError={resetError}
                    onFormSubmit={onPwChangeSubmit}
                />
                {/* AJOUTER ANIMAUX ICI */}
                {/* <div className='background-image-header' style={{ backgroundImage: `url(${indexLang ? imgCompanionHeaderFrench : imgCompanionHeaderEnglish})` }}></div> */}
            </div>
        )
    }
}

export default reduxForm({
    form: 'user_profile',
    validate,
    destroyOnUnmount: false,
})(UserProfileForm)
