import React, { Component } from 'react';
import dog from '../../../assets/selection/dog.png'
import { CHIEN } from '../../../constant/stringTraduction';
export default class DogPicto extends Component {
    render () {
        return (
            <div>
                <img src={dog} alt="dog"></img>
                <div style={{ marginTop: 10 }}><b>{CHIEN[this.props.indexLang]}</b></div>
            </div>
        );
    }
}
