import React, { Component } from 'react';
import retroactionThanksContainer from '../../containers/retroaction/retroactionThanksContainer';
import { fetchAppreciationYesNo } from '../../actions/retroaction';
import { Container, Button } from 'reactstrap';
import { THANKSMAINTEXTHIVER, THANKFORRESPONSE, TAKESURVEY, CHATBOITE } from '../../constant/stringTraduction';
import topImg from '../../assets/GoodyPack_cat_1200x628.jpg';
import './retroaction.css'

class RetroactionThanks extends Component {

  componentDidMount = async () => {
      const orderId = this.getUrlParams(this.props.location.search).order[0];
      const answer = this.getUrlParams(this.props.location.search).answer[0];
      await fetchAppreciationYesNo({ orderId, answer });
      this.props.dispatch({ type : 'SET_ORDERID', orderId:orderId })
  }

  getUrlParams = (query) => {
      let obj = {};
      if (query) {
          query = query.split('#')[0];
          query = query.replace('?', '');
          let arr = query.split('&');
          for (let i = 0; i < arr.length; i++) {
              let a = arr[i].split('=');
              let name = a[0];
              let value = typeof (a[1]) === 'undefined' ? true : a[1];
              if (!obj[name]) {
                  obj[name] = [];
              }
              obj[name].push(value)
          }
      }
      return obj;
  }

  render() {
      const { indexLang } = this.props;
      const sondage = ['https://launchleap.com/en/campaign/ll_Del#intro', 'https://launchleap.com/fr/campagne/ll_Del#intro'];
      return (
          <div style={{ textAlign: 'center', marginTop:'70px' }}>
              <img className='topImg' src={topImg} alt={CHATBOITE[indexLang]}/>
              <Container>
                  <h2 className='notFound-title'>{THANKFORRESPONSE[indexLang]}</h2>
                  <div>{
                      THANKSMAINTEXTHIVER[indexLang]}
                  </div>
                  <div style={{ marginTop:'20px' }}>
                      <a href={sondage[indexLang]}>
                          <Button className='yellow-button'>
                              {TAKESURVEY[indexLang]}
                          </Button>  
                      </a>
                  </div>
              </Container>
          </div>
      );  
  }    
}        
    
export default retroactionThanksContainer(RetroactionThanks)
