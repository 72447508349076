import React from 'react';
import { Table, Container, Row, Col, Button, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import './admin.css';
import { SEASON_NAMES, DATE_JOUR, DATES_SAISONS_MOIS } from '../../constant/otherConst';

const title = ['Liste des commandes directes (inclus pack d\'adoption)', 'Liste des abonnements'];

const Orders = ({
    orders,
    subscription,
    cancelForms,
    coupons,
    selectFilter,
    count,
    onSearchChange,
    onSearchClick,
    trials,
}) => {
    let nbCommandesAnnules = 0;
    for (let i = 0; i < orders.length; i++) {
        if (orders[i].item.canceled === true) {
            nbCommandesAnnules++;
        }
    }
    return (
        <Container className='admin-container' style={{ marginTop: '50px' }}>
            <h2 className='admin-section-title'>{title[subscription]}</h2>
            <Row>
                <Col lg={12} style={{ textAlign: 'left', marginBottom: '20px' }}>
                    <Button className='filter-button' onClick={selectFilter} value='coupon'>Avec coupon <span className='badge'>{subscription ? count[3] : count[2]}</span></Button>
                    <Button className='filter-button' onClick={selectFilter} value='apprecie'>Appréciées (1er pack) <span className='badge'>{subscription ? count[5] : count[4]}</span></Button>
                    <Button className='filter-button' onClick={selectFilter} value='non-apprecie'>Non-appréciées (1er pack) <span className='badge'>{subscription ? count[7] : count[6]}</span></Button>
                    {subscription ? <Button className='filter-button' onClick={selectFilter} value='canceled'>Annulées <span className='badge'>{count[8]}</span></Button> : null}
                    {subscription ? <Button className='filter-button' onClick={selectFilter} value='paused'>Suspendues <span className='badge'>{count[9]}</span></Button> : null}
                    {subscription ? <Button className='filter-button' onClick={selectFilter} value='wasTrial'>Conversion trial <span className='badge'>{count[10]}</span></Button> : null}
                    <Button className='filter-button' onClick={selectFilter} value='all'>Tous <span className='badge'>{subscription ? count[1] : count[0]}</span></Button>
                    <InputGroup className='filter-button'>
                        <InputGroupAddon addonType='append'><Button onClick={onSearchClick}><i className='fa fa-search'></i></Button></InputGroupAddon>
                        <Input onChange={onSearchChange} placeholder='rechercher' style={{ maxWidth: '200px' }} />
                    </InputGroup>
                    {subscription === 1 ?
                        <div>
                            <p>Nombre de commandes annulées : {nbCommandesAnnules}</p>
                            <p>Taux de rétention : {Math.abs(((nbCommandesAnnules / orders.length) * 100) - 100)}%</p>
                        </div>
                        :
                        <p></p>
                    }
                </Col>
            </Row>
            <Table responsive striped>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Client</th>
                        <th>Courriel</th>
                        <th>Description</th>
                        <th>Apprécié<br />1er pack</th>
                        {subscription ? <th>En pause</th> : null}
                        {subscription ? <th>Annulé</th> : null}
                        <th>Coupon</th>
                        {subscription ? <th>Premier pack</th> : <th>Moment de l'achat</th>}
                        {subscription ? <th>Suite au trial</th> : null}
                    </tr>
                </thead>
                <tbody>
                    {orders.map((x, index) => {
                        let usedCoupon;
                        let indexSeason = 0;
                        if (x.coupon) {
                            usedCoupon = coupons.find((c) => c._id === x.coupon)
                        }
                        const year = x.createdAt.substring(0, 4);
                        const firstMonth = DATES_SAISONS_MOIS[0] + 1;
                        const lastMonth = DATES_SAISONS_MOIS[1] + 1;
                        const orderDate = new Date(x.createdAt);
                        if (orderDate < new Date(year, firstMonth, DATE_JOUR) && orderDate > new Date(year - 1, lastMonth, DATE_JOUR)) {
                            indexSeason = 1;
                        }
                        let wasTrial = false;
                        if (!x.item.trial && !x.item.typeId && trials.filter((trial) => trial.user === x.user).length > 0) {
                            wasTrial = true;
                        }
                        return <tr key={index}>
                            <td>{x.number}</td>
                            <td>{x.firstName}<br />{x.lastName}</td>
                            <td>{x.email}</td>
                            <td>{x.item.descriptionFR}<br />{x.item.nameFR}</td>
                            <td>{x.appreciated === false && 'non'}{x.appreciated === true && 'oui'}</td>
                            {subscription ? <td>{x.item.paused ? 'oui' : 'non'}</td> : null}
                            {subscription ? <td>{x.item.canceled ? 'oui' : 'non'}<br />{cancelForms.find((form) => form.order === x._id) && cancelForms.find((form) => form.order === x._id).answer}</td> : null}
                            <td>{usedCoupon && usedCoupon.slug}</td>
                            <th>{SEASON_NAMES[indexSeason][1] + '/' + year}</th>
                            {subscription ? <td>{wasTrial ? 'oui' : 'non'}</td> : null}
                        </tr>;
                    })}
                </tbody>
            </Table>
        </Container>
    );
}

export default Orders
