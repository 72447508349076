import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { fetchAdd, resetError } from '../../actions/user';
import { CONFIRMATIONMDP, CONNEXION, COURRIEL, DEJACOMPTE, INSCRIPTION, MOTDEPASSE,
    MOTDEPASSEOUBLIE, NOMFAMILLE, PRENOM, RECUPERERCOMPTE, ACCEPTMARKETINGEMAILS, THANKSSIGNINGUP } from '../../constant/stringTraduction';
import userContainer from '../../containers/user/userContainer';
import SuccessableButton from '../buttons/SuccessableButton';
import renderTextFieldBlack from '../fields/renderTextFieldBlack';
import renderCheckbox from '../fields/renderCheckbox';
import './user.css';
import imgHeaderEnglish from '../../assets/sign_up_english.jpg';
import imgHeaderFrench from '../../assets/sign_up_french.jpg';
import { validateEmail } from '../../utils/other';
import EmailConfirmationModal from './EmailConfirmationModal';
import { getNoMoreOrders } from '../../utils/specialEvents';

const validate = (values) => {
    const errors = {}
    const requiredFields = ['firstName', 'lastName', 'email', 'password', 'passwordConfirm']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = ['*', '*'];
        }
    })
    if (values.email && !validateEmail(values.email)) {
        errors.email = ['EX : abcdef@ghijkl.com', 'EX : abcdef@ghijkl.com'];
    }
    if (values.password !== values.passwordConfirm) {
        errors.passwordConfirm = ['Must match password', 'Doit correspondre au mot de passe'];
    }
    if (values && values.password && values.password.length < 8) {
        errors.password = ['8 characters minimum', '8 caractères minimum'];
    }
    if (values && values.passwordConfirm && values.passwordConfirm.length < 8) {
        errors.passwordConfirm = ['8 characters minimum', '8 caractères minimum'];
    }
    return errors
}

class Signup extends Component {
  handleFormSubmit = (values) => {
      const {
          dispatch,
          indexLang,
      } = this.props;
      dispatch(fetchAdd(values, indexLang));
  }
  resetError = () => {
      this.props.dispatch(resetError()); 
  }
  render () {
      const {
          handleSubmit,
          invalid,
          pristine,
          submitSucceeded,
          submitting,
          user,
          indexLang,
      } = this.props
      return (
          <div>
              <Container className="white-background container-top-margin" style={{ paddingTop: 95 }}>
                  <div className='background-image-header' style={{ backgroundImage: `url(${indexLang ? imgHeaderFrench : imgHeaderEnglish})` }}></div>
                  <form onSubmit={handleSubmit(this.handleFormSubmit)} >
                      <div>
                          <Field name="firstName" color='black' component={renderTextFieldBlack} label={PRENOM[indexLang]} fullWidth={true} indexLang={indexLang} />
                          <Field name="lastName" color='black' component={renderTextFieldBlack} label={NOMFAMILLE[indexLang]} fullWidth={true} indexLang={indexLang} />
                          <Field name="email" color='black' component={renderTextFieldBlack} label={COURRIEL[indexLang]} fullWidth={true} indexLang={indexLang} />
                          <Field name="password" color='black' component={renderTextFieldBlack} label={MOTDEPASSE[indexLang]} fullWidth={true} type="password" indexLang={indexLang} />
                          <Field name="passwordConfirm" color='black' component={renderTextFieldBlack} label={CONFIRMATIONMDP[indexLang]} fullWidth={true} type="password" indexLang={indexLang} />
                          {!getNoMoreOrders() && <Field name="acceptMarketingEmails" component={renderCheckbox} label={ACCEPTMARKETINGEMAILS[indexLang]} />}
                      </div>
                      <div className="button-container">
                          <SuccessableButton
                              style={{ width: '100%', marginTop: 5 }}
                              disabled={pristine || invalid}
                              error={user.error}
                              label={INSCRIPTION[indexLang]}
                              reset={this.resetError}
                              submitSucceeded={submitSucceeded}
                              submitting={submitting}
                              successLabel={THANKSSIGNINGUP[indexLang]}
                          />
                      </div>
                  </form>
                  <div className="card-actions">
                      <p>{DEJACOMPTE[indexLang]} <Link to="/user/signin">{CONNEXION[indexLang]}</Link></p>
                      <p>{MOTDEPASSEOUBLIE[indexLang]} <Link to="/user/recovery">{RECUPERERCOMPTE[indexLang]}</Link></p>
                  </div>
              </Container>
              <EmailConfirmationModal
                  indexLang={indexLang}
                  user={user}
              />
          </div>
      )
  }
}

export default userContainer(
    reduxForm({
        form: 'signup',
        validate,
        initialValues: {
            acceptMarketingEmails: true,
        },
    })(withRouter(Signup)))
