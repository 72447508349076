export const fetchSendReturnMessage = (values) => {
    return (dispatch) => {
        dispatch({ type: 'IS_SENDING_MESSAGE' });
        return fetch(`/api/return-policy`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
                dispatch({ type: 'IS_DONE' });
                throw new Error('Network response was not ok.');
            })
            .then((json) => {
                if (json.error) {
                    dispatch({ type: 'RETURN_MSG_ERROR', payload: json.error });
                    return Promise.reject(json.error);
                }
                dispatch({ type: 'SUCCEEDED' });
            })
            .catch((error) => dispatch({ type: 'RETURN_MSG_ERROR', payload: error }))
    }
}

export const fetchUploadImage = (img, name) => {
    const data = new FormData();
    data.append('image', img);
    data.append('name', name);
    return (dispatch) => {
        fetch('/api/return-policy/files', {
            method: 'POST',
            body: data,
        })
            .then((res) => {
                if (res.statusText === 'OK') {
                    return res.url;
                }
                throw new Error('Network response was not ok.');
            })
            .catch((error) => dispatch({ type: 'RETURN_MSG_ERROR', payload: error }))
    };
}
