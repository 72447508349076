import React from 'react';
import { Table, Container, Row, Col, Button, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import './admin.css';
import { SEASON_NAMES } from '../../constant/otherConst';

const title = 'Liste des commandes précédentes';

const Details = ({
    selectFilterDetails,
    onOldSearchChange,
    onOldSearchClick,
    oldOrder,
    oldOrdersCount,
}) => {
    // Get number of trial converted
    let nbTrialConverted = 0;
    let nbTrials = 0;
    let ordersList = [];
    const ordersListOrdered = oldOrder.sort((a, b) => {
        if (a.year === b.year) {
            return a.season_id - b.season_id;
        } else {
            return a.year - b.year;
        }
    });
    for (let i = 0; i < ordersListOrdered.length; i++) {
        const orders = ordersListOrdered[i].orders;
        ordersList.push(...orders);
    }
    for (let i = 0; i < ordersList.length; i++) {
        const order = ordersList[i];
        const user_id = order.user_id;
        if (order.is_trial === 1) {
            nbTrials++;
            let foundConverted = false;
            for (let ii = i + 1; ii < ordersList.length && foundConverted === false; ii++) {
                const otherOrder = ordersList[ii];
                if (otherOrder.is_trial === 0 && user_id === otherOrder.user_id) {
                    foundConverted = true;
                    nbTrialConverted++;
                }
            }
        }
    }

    return (
        <Container className='admin-container' style={{ marginTop: '50px' }}>
            <h2 className='admin-section-title'>{title}</h2>
            <Row>
                <Col lg={12} style={{ textAlign: 'left', marginBottom: '20px' }}>
                    <Button className='filter-button' onClick={selectFilterDetails} value='exceptions'>Avec exceptions <span className='badge'>{oldOrdersCount[2]}</span></Button> 
                    <Button className='filter-button' onClick={selectFilterDetails} value='wasTrial'>Trial <span className='badge'>{oldOrdersCount[1]}</span></Button> 
                    <Button className='filter-button' onClick={selectFilterDetails} value='all'>Tous <span className='badge'>{oldOrdersCount[0]}</span></Button>
                    <InputGroup className='filter-button'>
                        <InputGroupAddon addonType='append'><Button onClick={onOldSearchClick}><i className='fa fa-search'></i></Button></InputGroupAddon>
                        <Input onChange={onOldSearchChange} placeholder='rechercher' style={{ maxWidth: '200px' }} />
                    </InputGroup>
                    <p>Nombre de trials : {nbTrials}</p>
                    <p>Nombre de commandes : {ordersList.length}</p>
                    <p>Nombre de trials convertis : {nbTrialConverted} => ({(nbTrialConverted / nbTrials) * 100}%)</p>
                </Col>
            </Row>
            <Table responsive striped>
                <thead>
                    <tr>
                        <th>Numéro</th>
                        <th>Saisons</th>
                        <th>Année</th>
                        <th>Client</th>
                        <th>Email</th>
                        <th>Trial</th>
                        <th>Slug</th>
                        <th>Exception</th>
                        <th>Neck-size</th> 
                    </tr>
                </thead>
                <tbody>
                    {renderTable(oldOrder)}
                </tbody>
            </Table>
        </Container> 
    );
}

const renderTable = (oldOrder) => {
    return (
        oldOrder.map((x) => {
            const year = x.year;
            const season_id = x.season_id;
            const orders = x.orders.sort((a, b) => {
                return a.order_number - b.order_number;
            });
            return orders.map((y, index) => {
                const number = y.order_number;
                const client = y.fullname;
                const trial = y.is_trial;
                const email = y.email;
                const slug = y.item_slug;
                const exceptions = y.exceptions;
                const neckSize = y.neck_size;
                return (
                    <tr key={index}>
                        <td>{number}</td>
                        <td>{SEASON_NAMES[season_id][1]}</td>
                        <td>{year}</td>
                        <td>{client}</td>
                        <td>{email}</td>
                        <td>{trial ? 'oui' : 'non'}</td>
                        <td>{slug}</td>
                        <td>{exceptions ? 'oui' : 'non'}</td>
                        <td>{neckSize}</td>
                    </tr>
                );
            });
        })
    );
}

export default Details
