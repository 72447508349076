import 'moment/locale/fr-ca'
import 'moment/locale/en-ca'
import moment from 'moment';
import { DATES_SAISONS_MOIS, DATE_JOUR, MAX_INT } from '../constant/otherConst';
import { getNoMoreOrders } from './specialEvents'

// Retourne un objet moment
export const getFrenchDateFormats = (p_indexLang) => {
    moment.locale('fr', {
        months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
        monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
        monthsParseExact: true,
        weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
        weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
        weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
        weekdaysParseExact: true,
        longDateFormat: {
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'DD/MM/YYYY',
            LL: 'D MMMM YYYY',
            LLL: 'D MMMM YYYY HH:mm',
            LLLL: 'dddd D MMMM YYYY HH:mm',
        },
        calendar: {
            sameDay: '[Aujourd’hui à] LT',
            nextDay: '[Demain à] LT',
            nextWeek: 'dddd [à] LT',
            lastDay: '[Hier à] LT',
            lastWeek: 'dddd [dernier à] LT',
            sameElse: 'L',
        },
        relativeTime: {
            future: 'dans %s',
            past: 'il y a %s',
            s: 'quelques secondes',
            m: 'une minute',
            mm: '%d minutes',
            h: 'une heure',
            hh: '%d heures',
            d: 'un jour',
            dd: '%d jours',
            M: 'un mois',
            MM: '%d mois',
            y: 'un an',
            yy: '%d ans',
        },
        dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
        ordinal: function (number) {
            return number;
        },
        meridiemParse: /PD|MD/,
        isPM: function (input) {
            return input.charAt(0) === 'M';
        },
        meridiem: function (hours) {
            return hours < 12 ? 'AM' : 'PM';
        },
        week: {
            dow: 1, // Monday is the first day of the week.
            doy: 4, // The week that contains Jan 4th is the first week of the year.
        },
    });

    if (p_indexLang === 0) {
        moment.locale('en');
    } else {
        moment.locale('fr');
    }

    return moment;
}

// Il faut donner un array des jours et des mois associés
// Ex: si tu veux 5 décembre, tu aurais (au premier index) 5 pour p_arrDay et 11 pour p_arrMonths
export const getNextPackDate = () => {
    const now = moment();
    const dates = [];
    let date;

    for (let saison = 0; saison < DATES_SAISONS_MOIS.length; saison++) {
        let date;
        date = moment({
            year: now.format('YYYY'),
            month: DATES_SAISONS_MOIS[saison],
            day: DATE_JOUR,
            hour: 0, minute: 0, second: 0,
        });
        if (date.isBefore(now)) {
            date = moment({
                year: parseInt(now.format('YYYY'), 10) + 1,
                month: DATES_SAISONS_MOIS[saison],
                day: DATE_JOUR,
                hour: 0, minute: 0, second: 0,
            });
        }
        dates.push(date);
    }

    date = moment.min(dates);
    const indexSaison = dates.indexOf(date);

    if (getNoMoreOrders()) {
        return {
            indexSaison: 0,
            secondsTilNextBox: MAX_INT,
            nextDate: moment.unix(MAX_INT),
        }; 
    }

    return {
        indexSaison: indexSaison,
        secondsTilNextBox: date.diff(now, 'seconds'),
        nextDate: date,
    };
}
