import React from 'react';
import { BONJOUR, COMMANDEINTROUVABLE, REMERCIEMENTCOMMANDE } from '../../constant/stringTraduction';
import { Container } from 'reactstrap';
import orderContainer from '../../containers/orders/orderContainer';
import OrderDetail from './OrderDetail';
import './orders.css';

const OrderConfirmation = ({
    dispatch,
    order,
    indexLang,
    pauseSubscriptionId,
    resumeSubscriptionId,
    confirmation,
    modal,
    text,
    title,
    confirmType,
    products,
    orderUpgradeError,
    orderUpgradeSuccess,
    orderUpgrading,
    upgradeModal,
}) => {
    return (
        order ?
            <Container className="white-background container-top-margin">
                <div className="card" style={{ zDepth: 0, border: 'none' }}>
                    <h4 style={{ marginBottom: 0 }}>
                        {BONJOUR[indexLang]} {order.firstName}{REMERCIEMENTCOMMANDE[indexLang]}!
                    </h4>
                    <OrderDetail
                        dispatch={dispatch}
                        order={order}
                        indexLang={indexLang}
                        pauseSubscriptionId={pauseSubscriptionId}
                        resumeSubscriptionId={resumeSubscriptionId}
                        confirmation={confirmation}
                        modal={modal}
                        title={title}
                        text={text}
                        confirmType={confirmType}
                        upgradeModal={upgradeModal}
                        orderUpgrading={orderUpgrading}
                        orderUpgradeSuccess={orderUpgradeSuccess}
                        orderUpgradeError={orderUpgradeError}
                        products={products}
                    />
                </div>
            </Container >
            :
            <div className="card" style={{ zDepth: 0 }}>
                <h3>{COMMANDEINTROUVABLE[indexLang]}</h3>
            </div>
    )
}

export default orderContainer(OrderConfirmation)
