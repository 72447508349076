import { type } from '../actions/orders'

const orders = (state = {
    isFetching: false,
    items: null,
    error: '',
    submitSucceededCoupon: false,
    submittingCoupon: false,
    submitSucceededAddress: false,
    submittingAddress: false,
    invalidAddress: false,
    errorCard: '',
    errorAddress: '',
    errorNeck: '',
    slugItemSubmittingCoupon: '',
    typeItemSubmittingCoupon: null,
    orderPriceChanged: false,
    orders: [],
    orderUpgrading: false,
    orderUpgradeSuccess: false,
    orderUpgradeError: '',
    upgradeModal: false,
    oldOrder:[],
    oldOrdersCount:[],
}, action) => {
    switch (action.type) {
        case `REQUEST_${type}S`:
            return {
                ...state,
                isFetching: true,
            }
        case `RECEIVE_${type}S`:
            return {
                ...state,
                isFetching: false,
                items: action.items,
            }
        case 'RECEIVE_STAT_ORDERS':
            return {
                ...state,
                oldOrder: action.items,
            }
        case `ADD_${type}`:
            return {
                ...state,
                items: [
                    action.item,
                ],
            }
        case `UPDATE_${type}`:
            return {
                ...state,
                items: state.items.map((item) =>
                    item._id === action.item._id ? { ...item, ...action.item } : item,
                ),
                isFetching: false,
            }
        case `DELETE_${type}S`:
            return {
                ...state,
                items: [],
                isFetching: false,
            }
        case `ERROR_${type}`:
            return {
                ...state,
                error: action.error,
                isFetching: false,
            }
        case 'RESET_ERROR_ORDER': {
            return {
                ...state,
                error: null,
                errorCard: null,
                errorAddress: null,
            }
        }
        case 'ADDING_COUPON_ORDER': {
            return {
                ...state,
                slugItemSubmittingCoupon: action.itemSlug,
                typeItemSubmittingCoupon: action.itemType,
                submittingCoupon: true,
                errorCoupon: { error: '' },
                submitSucceededCoupon: false,
            }
        }
        case 'RECEIVE_ORDER_COUPON': {
            return {
                ...state,
                submittingCoupon: false,
                errorCoupon: { error: '' },
                submitSucceededCoupon: true,
            }
        }
        case 'ERROR_ORDER_COUPON': {
            return {
                ...state,
                errorCoupon: action.error,
                submittingCoupon: false,
                submitSucceededCoupon: false,
            }
        }
        case 'MODIFYING_ADDRESS':
            return {
                ...state,
                submittingAddress: true,
                submitSucceededAddress: false,
                errorAddress: '',
            }

        case 'MODIFYING_ADDRESS_SUCCESS':
            return {
                ...state,
                items: state.items.map((item) =>
                    item._id === action.item._id ? { ...item, ...action.item } : item,
                ),
                submittingAddress: false,
                submitSucceededAddress: true,
                errorAddress: '',
            }

        case 'MODIFYING_NECK_SUCCESS':
            return {
                ...state,
                items: state.items.map((item) =>
                    item._id === action.item._id ? { ...item, ...action.item } : item,
                ),
                errorNeck: '',
            }

        case 'ERROR_UPDATE_ADDRESS':
            return {
                ...state,
                errorCard: action.error,
                isFetching: false,
            }
        case 'ERROR_UPDATE_CARD':
            return {
                ...state,
                errorCard: action.error,
                isFetching: false,
            }
        case 'ERROR_UPDATE_NECK':
            return {
                ...state,
                errorNeck: action.error,
                isFetching: false,
            }
        case 'ORDER_PRICE_CHANGED':
            return {
                ...state,
                orderPriceChanged: true,
            }
        case 'RESET_ORDER_MODIFICATION':
            return {
                ...state,
                orderPriceChanged: false,
                submitSucceededAddress: false,
            }
        case 'SET_ORDERS':
            return {
                ...state,
                orders: action.payload,
            }
        case 'SET_OLD_ORDERS':
            return {
                ...state,
                oldOrder: action.payload,
            }
        case 'SET_UPGRADE_ERROR':
            return {
                ...state,
                orderUpgrading: false,
                orderUpgradeSuccess: false,
                orderUpgradeError: action.payload,
            }
        case 'SET_UPGRADE_SUCCESS':
            return {
                ...state,
                orderUpgrading: false,
                orderUpgradeSuccess: true,
                orderUpgradeError: '',
            }
        case 'SET_UPGRADING_SUBSCRIPTION':
            return {
                ...state,
                orderUpgrading: true,
                orderUpgradeSuccess: false,
                orderUpgradeError: '',
            }
        case 'RESET_UPGRADING_SUBSCRIPTION':
            return {
                ...state,
                orderUpgrading: false,
                orderUpgradeSuccess: false,
                orderUpgradeError: '',
            }
        case 'CLOSE_UPGRADE_MODAL':
            return {
                ...state,
                upgradeModal: false,
            }
        case 'OPEN_UPGRADE_MODAL':
            return {
                ...state,
                upgradeModal: true,
            }
        case 'SET_COUNT_OLD_ORDERS':
            return {
                ...state,
                oldOrdersCount:action.payload,
            }    
        default:
            return state
    }
}

export default orders
