import React from 'react';
import referralProgramContainer from '../../containers/staticPages/referralProgramContainer.js';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import gifFR from '../../assets/refer/referez_ami_site.gif';
import gifEN from '../../assets/refer/refer_friend_site.gif';
import imgFR from '../../assets/refer/referez_ami_nouveau.png';
import imgEN from '../../assets/refer/refer_friend_new.png';

import {
    REFERRALTEXT, REFERRALQUESTION, REFERRALQUESTION2, GETAFREEPACK, REFER,
} from '../../constant/stringTraduction';
import './referral.css';
import { getNoMoreOrders } from '../../utils/specialEvents.js';
import AlertCovid from '../home/alertCovid';

class ReferralProgram extends React.Component {
    constructor() {
        super();
        this.state = {
            userLogged: false,
        }
        this.buttonClick = this.buttonClick.bind(this);

    }
    componentDidMount() {
        const accessToken = localStorage.getItem('x-access-token');
        if (accessToken) {
            this.setState({ userLogged: true })
        }
    }
    buttonClick = (toSignIn) => {
        const { dispatch, history } = this.props;
        if (toSignIn) {
            history.push('/user/signin');
        } else {
            dispatch({ type: 'SWITCH_TAB', indexTab: '4' });
            history.push('/user/profile');
        }
    }
    render() {
        const { indexLang } = this.props;
        const referralImg = [imgEN, imgFR];
        const referralGif = [gifEN, gifFR];
        return (
            <Container style={{ textAlign: 'center' }} className='white-background container-top-margin'>
                <AlertCovid indexLang={indexLang} />
                <Row>
                    <Col md='12'>
                        <img className='referral-gif' src={referralImg[indexLang]} alt={['Referral', 'Référence'][indexLang]} />
                    </Col>
                    <Col md='12'>
                        <img className='referral-gif' src={referralGif[indexLang]} alt={['Referral', 'Référence'][indexLang]} />
                    </Col>
                </Row>
                <Row>
                    <Col md={{ size: 6, offset: 3 }} style={{ marginTop: '25px' }}>
                        <div className='referral-content'>{REFERRALTEXT[indexLang]} <b>{GETAFREEPACK[indexLang]}!</b></div>
                    </Col>
                </Row>
                <Row>
                    {!getNoMoreOrders() &&
                    <Col md='12'>
                        <Button className='referral-button' onClick={() => this.buttonClick(0)}>{REFER[indexLang]}</Button>
                    </Col>
                    }
                    <Col md='12' style={{ marginTop: '25px' }}>
                        <div className='referral-content'>{REFERRALQUESTION[indexLang]} <Link style={{ textDecoration: 'underline' }} to='/faq#referral'>FAQ</Link> {REFERRALQUESTION2[indexLang]}.</div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default referralProgramContainer(ReferralProgram)
