import { SEASON_NAMES } from '../constant/otherConst';
import { ENTERCONTESTLINE1 } from '../constant/stringTraduction';

export const validateEmail = (email) => {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const getContestLine1 = (season_id, indexLang) => {
    const season_name = SEASON_NAMES[season_id][indexLang].toLowerCase();
    let line_1 = ENTERCONTESTLINE1[indexLang] + season_name + ' GoodyPack!';
    if (indexLang === 1) {
        line_1 = ENTERCONTESTLINE1[indexLang];
        if (season_id === 0 || season_id === 2) {
            line_1 += '\'';
        } else {
            line_1 += 'e ';
        }
        line_1 += season_name + '!';
    }
    return line_1;
}

export const isEmpty = (json_obj) => {
    for (let key in json_obj) {
        if (json_obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}
