const newsletterSubs = (state = {
    message: [],
    userSub: false,
    isClosed: false,
}, action) => {
    switch (action.type) {
        case `INVALID_EMAIL`:
            return {
                ...state,
                message: action.payload,
            }
        case `SUB_TO_NEWS_OK`:
            return {
                ...state,
                message: action.payload,
                userSub: true,
            }
        case `FAILED_SUB_SEND`:
            return {
                ...state,
                message: action.payload,
            }  
        case `CLOSE_MODAL`:
            return {
                ...state,
                isClosed: action.payload,
            }  
        default:
            return state
    }
}

export default newsletterSubs
