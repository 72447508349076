import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { PROVINCES } from '../../constant/otherConst';

const style = () => ({
    cssLabel: {
        color: '#000000 !important',

    },
    cssUnderline: {
        '&:after': {
            borderBottomColor: '#000000',
        },
        '&:before': {
            borderBottomColor: '#000000',
        },
        '&:hover': {
            borderBottomColor: '#000000',
        },
    },
})

const renderSelectField = ({
    indexLang,
    input,
    label,
    meta: { touched, error },
    color,
    classes,
    ...custom
}) => {
    color = color ? color : '#000000';
    return (
        <FormControl {...custom}>
            <InputLabel
                FormLabelClasses={{
                    root: classes.cssLabel,
                }}
            >
                {label}
            </InputLabel>
            <Select
                error={touched && error !== undefined}
                input={<Input
                    style={{ color: color }}
                    classes={{ underline: classes.cssUnderline }}
                    error={touched && error !== undefined}
                    {...input}
                    {...custom}
                />}
                {...custom}
            >
                {PROVINCES.map((option) =>
                    <MenuItem
                        key={option}
                        value={option}
                    >{option}</MenuItem>,
                )}
            </Select>
            <FormHelperText>{error && error[indexLang]}</FormHelperText>
        </FormControl>
    )
}

export default withStyles(style)(renderSelectField)
