import React from 'react';
import { Modal } from 'reactstrap';
import { SIGNUP10OFF1, SIGNUP10OFF2, SIGNUP10OFF3, COURRIELINVALID, COURRIEL, SIGNUP, NOTHANKSPOPUP } from '../../constant/stringTraduction';
import NewsletterContainer from '../../containers/newsletter/newsletterContainer';
import { subToNewsletter, subUserToNewsletter } from '../../actions/newsletterSub'
import { validateEmail } from '../../utils/other';
import './signupmodal.css'; 
class SignUpModal extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
        };
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    handleChangeEmail(event) {
        this.setState({ email: event.target.value }); 
    }
    handleSubmit(event) {
        event.preventDefault();
        const { dispatch, user } = this.props;
        const email = this.state.email;
        if (user.values.firstName) { 
            dispatch(subUserToNewsletter({ user: user, indexLang: this.props.indexLang })); 
        } else {
            if (!validateEmail(email)) {
                dispatch({ type: 'INVALID_EMAIL', payload: COURRIELINVALID });
            } else {
                dispatch(subToNewsletter({ email: email, indexLang : this.props.indexLang }));
            }
        }
    }
    closeModal() {
        const { dispatch } = this.props;
        dispatch({ type: 'CLOSE_MODAL', payload: true }); 
    }
    render () {
        const {
            indexLang,
            user,
            message,
            userSub,
            toggle,
            modal,
            isClosed,
        } = this.props;
        if (userSub) {
            setTimeout(this.closeModal, 3500);
        }
        return (
            ((!user || !user.values.acceptMarketingEmails) && !isClosed && !user.values.newsletterSub) &&
            <Modal isOpen={modal} toggle={toggle} className='modal-lg signup-modal'>
                <div className="modal-background">
                    <div className="popup-background">
                        <div className="black-topline"></div>
                        <form onSubmit={this.handleSubmit} style={{ padding:'15px 5px' }}>
                            <h3 className="popup-text sub-text">{SIGNUP10OFF1[indexLang].toUpperCase()}</h3>
                            <h3 className="popup-text discount-text">{SIGNUP10OFF2[indexLang].toUpperCase()}</h3>
                            <h3 className="popup-text nextpack-text">{SIGNUP10OFF3[indexLang].toUpperCase()}</h3>
                            <div>
                                <div className="input-wrapper" style={ user.values.firstName ? { display: 'none' } : { display: 'block' }}>
                                    <input className="email-input" type="text" placeholder={COURRIEL[indexLang].toUpperCase()} name={COURRIEL[indexLang]} value={this.state.email} onChange={this.handleChangeEmail}/><br/>
                                </div>
                                <p className={!userSub ? 'error-text' : 'thanks-text'} style={{ marginBottom:20 }}>{message[indexLang]}</p>
                                {!userSub &&
                                <div>
                                    <div style={{ marginTop: 10 }}>
                                        <input className="popup-button" type="submit" disabled={this.state.done} value={SIGNUP[indexLang].toUpperCase()}/>
                                    </div>
                                    <div style={{ margin:10 }}>
                                        <p className="popup-text nothanks-text" onClick={toggle}>{NOTHANKSPOPUP[indexLang]}</p>
                                    </div>
                                </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default NewsletterContainer(SignUpModal)
