import React, { Component } from 'react'
import { connect } from 'react-redux'

const privacyPolicyContainer = (ComposedComponent) => {
    class PrivacyPolicyContainer extends Component {
        render () {
            return (
                <ComposedComponent {...this.props} />
            )
        }
    }
    const mapStateToProps = ({
        langs: { indexLang },
    }) => ({
        indexLang,
    })
    return connect(mapStateToProps)(PrivacyPolicyContainer)
}

export default privacyPolicyContainer
