import React, { Component } from 'react';
import dogmedium from '../../../assets/selection/dog_medium_150.png'
import { TEXTECHIENMEDIUM } from '../../../constant/stringTraduction';
export default class DogMediumPicto extends Component {
    render () {
        return (
            <div>
                <img src={dogmedium} alt="dog medium 20-49lbs"></img>
                <div><b>{TEXTECHIENMEDIUM[this.props.indexLang]}</b></div>
                <div>20-49 lbs</div>
            </div>
        );
    }
}
