import React, { Component } from 'react';
import dogsmall from '../../../assets/selection/dog_small_150.png'
import { TEXTECHIENSMALL } from '../../../constant/stringTraduction';

export default class DogSmallPicto extends Component {
    render () {
        return (
            <div style={{ marginTop: -22 }}>
                <img src={dogsmall} alt="dog small 20-49lbs"></img>
                <div><b>{TEXTECHIENSMALL[this.props.indexLang]}</b></div>
                <div>10-19 lbs</div>
            </div>);
    }
}
