import React, { Component } from 'react';
import { Field, reduxForm, submit } from 'redux-form';
import renderTextFieldBlack from '../fields/renderTextFieldBlack';
import { COURRIEL, REFER, REFERAFRIENDTITLE, REFERAFRIENDTEXT, REFERAFRIENDTEXT2, REFERAFRIENDTEXT3, GETFREEPACK } from '../../constant/stringTraduction';
import './user.css';
import { addReferedFriend, fetchReferedUsers, fetchReferedUsersDetails } from '../../actions/user';
import AlertModal from '../alert/AlertModal';
import { Button } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { validateEmail } from '../../utils/other';

const validate = (values) => {
    const errors = {}
    const requiredFields = ['email']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = ['*', '*'];
        }
    })
    if (values.email && !validateEmail(values.email)) {
        errors.email = ['example: abcdef@ghijkl.com', 'exemple : abcdef@ghijkl.com'];
    }
    return errors
}

class UserReferedForm extends Component {

    onFormSubmit = async (values) => {
        const { user, dispatch } = this.props;
        await dispatch({ type: 'SUBMIT_REFERERENCE' })
        if (user.values.email === values.email) {
            await dispatch({ type: 'REFER_USER_ERROR1' })
            dispatch({ type: 'SUBMIT_REFERERENCE_DONE' })
        } else if (user.referedUsers.includes(values.email)) {
            await dispatch({ type: 'REFER_USER_ERROR2' })
            dispatch({ type: 'SUBMIT_REFERERENCE_DONE' })
        } else if (user.allReferedUsers.includes(values.email)) {
            await dispatch({ type: 'REFER_USER_ERROR3' })
            dispatch({ type: 'SUBMIT_REFERERENCE_DONE' })
        } else if (user.subscribedUsers.includes(values.email)) {
            await dispatch({ type: 'REFER_USER_ERROR4' })
            dispatch({ type: 'SUBMIT_REFERERENCE_DONE' })
        } else {
            await dispatch(addReferedFriend(user._id, values))
            await dispatch({ type: 'REFER_USER_SUCCESS' })
            dispatch({ type: 'SUBMIT_REFERERENCE_SUCCEEDED' });
        }
        dispatch(fetchReferedUsers())
        dispatch(fetchReferedUsersDetails())
    }
    toggleModal = () => {
        const { dispatch } = this.props;
        dispatch({ type: 'REFER_USER_RESET' })
    }
    submitForm = () => {
        submit('user_refered');
    }
    render() {
        const {
            handleSubmit,
            invalid,
            pristine,
            indexLang,
            isSendingReference,
            confirmation,
            modal,
            text,
            title,
        } = this.props

        return (
            <div className="UserReferedForm white-background">
                <h2>{REFERAFRIENDTITLE[indexLang]}</h2>
                <p style={{ marginTop: '25px' }}>{REFERAFRIENDTEXT[indexLang]}!</p>
                <p>{REFERAFRIENDTEXT2[indexLang]} {GETFREEPACK[indexLang]}.</p>
                <p>{REFERAFRIENDTEXT3[indexLang]} {' '}
                    {!indexLang ?
                        <span><b><Link style={{ textDecoration: 'underline' }} to='/faq#referral'>FAQ</Link></b> section.</span>
                        : <b><Link style={{ textDecoration: 'underline' }} to='/faq#referral'>FAQ</Link></b>
                    }
                </p>
                <form onSubmit={handleSubmit(this.onFormSubmit)}>
                    <div className="field-container">
                        <Field color='black' name="email" component={renderTextFieldBlack} label={COURRIEL[indexLang]} className="field" indexLang={indexLang} />
                    </div>
                    <div className="button-container" style={{ marginBottom: 10 }}>
                        {isSendingReference ?
                            <Button size="md" style={{ textAlign: 'center', color: 'black', backgroundColor: '#cccccc', border: 'none' }}>
                                <CircularProgress color='inherit' size={20} style={{ verticalAlign: 'middle' }} />
                            </Button>
                            :
                            <div>
                                {pristine || invalid ?
                                    <Button size="md" style={{ color: 'black', backgroundColor: '#cccccc', border: 'none' }} disabled>{REFER[indexLang]}</Button>
                                    :
                                    <Button size="md" style={{ color: 'black', backgroundColor: '#fff100', border: 'none' }} type="submit">{REFER[indexLang]}</Button>
                                }

                            </div>
                        }
                    </div>
                </form>
                <AlertModal
                    indexLang={indexLang}
                    confirmation={confirmation}
                    toggleModal={this.toggleModal}
                    modal={modal}
                    title={title}
                    text={text}
                />
            </div>

        )
    }
}

export default reduxForm({
    form: 'user_refered',
    validate,
    onSubmit: submit,
    destroyOnUnmount: false,
})(UserReferedForm)
