import React, { Component } from 'react'
import { connect } from 'react-redux'

const boxSubscriptionContainer = (ComposedComponent) => {
    class BoxSubscriptionContainer extends Component {
        render() {
            return (
                this.props.isFetching ? null : <ComposedComponent {...this.props} />
            )
        }
    }
    const mapStateToProps = ({
        user,
        carts: { isAdding },
        orders: { isFetching, items },
        boxSubscription: { indexQuestion, reponses, indexAnimal, neckSize, trial, standardNeckSize, typeId },
        langs: { indexLang },
    }) => ({
        isFetching,
        orders: items,
        indexQuestion, reponses, indexAnimal, neckSize,
        indexLang,
        user,
        isAdding,
        trial,
        standardNeckSize,
        typeId,
    })
    return connect(mapStateToProps)(BoxSubscriptionContainer)
}

export default boxSubscriptionContainer
