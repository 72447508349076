import React from 'react';
import AddressAdd from './AddressAdd';
import AddressList from './AddressList';
import imgAddressesHeaderEnglish from '../../assets/my_addresses_english.jpg';
import imgAddressesHeaderFrench from '../../assets/my_addresses_french.jpg';
import { ADDRESSCHANGENOTE } from '../../constant/stringTraduction';
const AddressesForm = ({
    dispatch,
    onAddressAdd,
    onAddressUpdate,
    onAddressDelete,
    user,
    indexLang,
    submittingID,
}) =>
    <div className="AddressesForm">
        <div className='background-image-header' style={{ backgroundImage: `url(${indexLang ? imgAddressesHeaderFrench : imgAddressesHeaderEnglish})` }}></div>
        <div style={{ width: '100%', color: 'black', marginTop: '15px', fontSize: '15px' }}>
            <em>
                *{ADDRESSCHANGENOTE[indexLang]}
            </em>
        </div>
        <AddressList
            addresses={user.addresses}
            dispatch={dispatch}
            onAddressUpdate={onAddressUpdate}
            onAddressDelete={onAddressDelete}
            indexLang={indexLang}
            submittingID={submittingID}
        />
        {user.addresses.length < 5 &&
            <AddressAdd
                onAddressAdd={onAddressAdd}
                indexLang={indexLang}
            />}
    </div>


export default AddressesForm
