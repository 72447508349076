import React from 'react';
import { Container, Table, Button } from 'reactstrap';
import { fetchAllReferedUsers } from '../../actions/user';
import './admin.css';

const ReferedUsers = ({
    dispatch,
    allReferedUsers,
}) => {
    let nbPersonnesRef = 0;
    let nbPersonnesAbo = 0;
    for (let i = 0; i < allReferedUsers.length; i++) {
        const referedUsers = allReferedUsers[i].referedUsers;
        for (let ii = 0; ii < referedUsers.length; ii++) {
            const user = referedUsers[ii];
            nbPersonnesRef++;
            if (user.used === true) {
                nbPersonnesAbo++;
            }
        }
    }

    return (
        <Container className='admin-container' style={{ marginTop: '50px' }}>
            < h2 className='admin-section-title'>Personnes référées</h2>
            <p style={{ marginBottom: 10 }}>Si le courriel est vert, c'est que le coupon a été utilisé.</p>
            <p>Nombre de personnes qui réfèrent : {allReferedUsers.length}</p>
            <p>Nombre de personnes référées : {nbPersonnesRef}</p>
            <p>Nombre de référés abonnés : {nbPersonnesAbo}</p>
            <Button style={{ marginBottom: 10, marginTop: 10 }} onClick={() => {
                dispatch(fetchAllReferedUsers()); 
            }}>
            Charger liste
            </Button>
            <Table responsive striped>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Courriel</th>
                        <th>Référées</th>
                    </tr>
                </thead>
                <tbody>
                    {allReferedUsers.map((x, index) => {
                        const referedUsers = x.referedUsers;
                        return (
                            <tr key={index}>
                                <td>{x.id}</td>
                                <td>{x.email}</td>
                                <td>{referedUsers.map((y, index2) => {
                                    return (
                                        <div key={index2}>
                                            <span className={y.used === true && 'green-text'}>{y.email}</span>
                                        </div>);
                                })}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </Container>
    );
}

export default ReferedUsers
