import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import {
    Button,
    Col,
    Container,
    Row,
    Input,
    Label,
    FormGroup,
} from 'reactstrap';
import {
    fetchSendReturnMessage,
    fetchUploadImage,
} from '../../actions/returnPolicy';
import {
    RETURNNAME,
    RETURNEMAIL,
    RETURNMESSAGE,
    SEND,
    MESSAGESENT,
    RETURNORWARANTY,
    RETURN,
    WARANTY,
    ADRESSE,
    VILLE,
    PROVINCE,
    CODEPOSTAL,
    DATEPURCHASE,
    ORDERNUMBER,
    TELEPHONE,
    ADDPICTURES,
    BROWSES,
    COURRIELINVALID,
    ALLFIELDREQUIRED,
    TEAMHOLIDAYBACK,
} from '../../constant/stringTraduction';
import returnPolicyContainer from '../../containers/returnPolicy/returnPolicyContainer';
import './returnPolicy.css';
import { normalizePhone } from '../../utils/normalizers';
import { normalizeZip } from '../../utils/normalizers';
import AlertModal from '../alert/AlertModal';
import { PROVINCES } from '../../constant/otherConst';
import { validateEmail } from '../../utils/other';
import { getHolidays2019 } from '../../utils/specialEvents';

class ReturnPolicy extends React.Component {
    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            description: '',
            type: '',
            orderNumber: '',
            datePurchase: null,
            address: '',
            city: '',
            zip: '',
            province: '',
            phone: '',
            images: [],
            imagesLinks: [],
        };
    }

    uploadFile(file, name) {
        return this.props.dispatch(fetchUploadImage(file, name));
    }
    sendForm = () => {
        if (!validateEmail(this.state.email)) {
            this.props.dispatch({
                type: 'RETURN_MSG_ERROR',
                payload: COURRIELINVALID,
            });
        } else {
            if (
                !this.state.name ||
                !this.state.email ||
                !this.state.description ||
                !this.state.type ||
                !this.state.orderNumber ||
                !this.state.datePurchase ||
                !this.state.address ||
                !this.state.city ||
                !this.state.zip ||
                !this.state.province ||
                !this.state.phone
            ) {
                this.props.dispatch({
                    type: 'RETURN_MSG_ERROR',
                    payload: ALLFIELDREQUIRED,
                });
            } else {
                const { dispatch } = this.props;
                for (let i = 0; i < this.state.images.length; i++) {
                    this.uploadFile(
                        this.state.images[i],
                        this.state.images[i].name,
                    );
                }
                dispatch(
                    fetchSendReturnMessage({
                        name: this.state.name,
                        email: this.state.email,
                        description: this.state.description,
                        type: this.state.type,
                        order: this.state.orderNumber,
                        dateOfPurchase: this.state.datePurchase,
                        address: this.state.address,
                        city: this.state.city,
                        zip: this.state.zip,
                        province: this.state.province,
                        phone: this.state.phone,
                        images: this.state.imagesLinks,
                    }),
                );
            }
        }
    };
    setZip = (e) => {
        let zip = normalizeZip(e.target.value);
        this.setState({ zip: zip });
    };
    setPhone = (e) => {
        let phone = normalizePhone(e.target.value);
        this.setState({ phone: phone });
    };
    setProvince = (e) => {
        this.setState({ province: e.target.value });
    };
    setEmail = (e) => {
        this.setState({ email: e.target.value });
    };
    addFiles = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            let newImg = e.target.files[i];
            let newImgPath = '/returnImages/' + e.target.files[i].name;
            this.setState((prevState) => ({
                images: [...prevState.images, newImg],
            }));
            this.setState((prevState) => ({
                imagesLinks: [...prevState.imagesLinks, newImgPath],
            }));
        }
    };
    removeImage = (image, index) => {
        let array = [...this.state.images];
        array.splice(index, 1);
        this.setState({ images: array });
        let array2 = [...this.state.imagesLinks];
        array2.splice(index, 1);
        this.setState({ imagesLinks: array });
    };

    render() {
        const {
            indexLang,
            dispatch,
            isSending,
            succeeded,
            modal,
            title,
            text,
        } = this.props;

        return (
            <div>
                <Container
                    className="white-background container-top-margin"
                    style={{ textAlign: 'center', paddingTop: 99 }}>
                    <Row style={{ textAlign: 'left' }}>
                        <Col md={12}>
                            <FormGroup tag="fieldset">
                                <p>{RETURNORWARANTY[indexLang]}</p>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="returnWaranty"
                                            value="return"
                                            onChange={(e) =>
                                                this.setState({
                                                    type: e.target.value,
                                                })
                                            }
                                        />{' '}
                                        {RETURN[indexLang]}
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="returnWaranty"
                                            value="waranty"
                                            onChange={(e) =>
                                                this.setState({
                                                    type: e.target.value,
                                                })
                                            }
                                        />{' '}
                                        {WARANTY[indexLang]}
                                    </Label>
                                </FormGroup>
                            </FormGroup>
                            <Input
                                className="form-line"
                                placeholder={RETURNNAME[indexLang]}
                                value={this.state.name}
                                onChange={(e) =>
                                    this.setState({ name: e.target.value })
                                }
                            />
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="datePurchase">
                                            {DATEPURCHASE[indexLang]}
                                        </Label>
                                        <Input
                                            type="date"
                                            id="datePurchase"
                                            onChange={(e) =>
                                                this.setState({
                                                    datePurchase: e.target.value,
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="datePurchase">
                                            {ORDERNUMBER[indexLang]}
                                        </Label>
                                        <Input
                                            type="number"
                                            id="orderNumber"
                                            onChange={(e) =>
                                                this.setState({
                                                    orderNumber: e.target.value,
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Input
                                className="form-line"
                                placeholder={ADRESSE[indexLang]}
                                value={this.state.address}
                                onChange={(e) =>
                                    this.setState({ address: e.target.value })
                                }
                            />
                            <Input
                                className="form-line"
                                placeholder={VILLE[indexLang]}
                                value={this.state.city}
                                onChange={(e) =>
                                    this.setState({ city: e.target.value })
                                }
                            />
                            <Input
                                className="form-line"
                                placeholder={CODEPOSTAL[indexLang]}
                                value={this.state.zip}
                                onChange={this.setZip}
                                maxLength="6"
                            />
                            <FormGroup>
                                <Label for="province">
                                    {PROVINCE[indexLang]}
                                </Label>
                                <Input
                                    type="select"
                                    id="province"
                                    onClick={this.setProvince}
                                    onChange={this.setProvince}>
                                    <option value=""></option>
                                    {PROVINCES.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <Input
                                className="form-line"
                                placeholder={RETURNEMAIL[indexLang]}
                                value={this.state.email}
                                onChange={this.setEmail}
                            />
                            <Input
                                className="form-line"
                                placeholder={TELEPHONE[indexLang]}
                                value={this.state.phone}
                                onChange={this.setPhone}
                            />
                            <Input
                                className="form-line"
                                type="textarea"
                                placeholder={RETURNMESSAGE[indexLang]}
                                value={this.state.description}
                                onChange={(e) =>
                                    this.setState({
                                        description: e.target.value,
                                    })
                                }
                            />
                            <FormGroup>
                                <Label for="files">
                                    {ADDPICTURES[indexLang]}
                                    <div id="hide">
                                        <Input
                                            type="file"
                                            name="files"
                                            id="files"
                                            onChange={this.addFiles}
                                            multiple
                                        />
                                        <span
                                            className="btn btn-upload"
                                            type="btn">
                                            {BROWSES[indexLang]}
                                        </span>
                                    </div>
                                </Label>
                            </FormGroup>
                            <Row>
                                {this.state.images.map((image, index) => (
                                    <Col
                                        key={index}
                                        md={12}
                                        style={{ marginBottom: '10px' }}>
                                        <Button
                                            style={{
                                                float: 'left',
                                                marginRight: '10px',
                                            }}
                                            onClick={() => {
                                                this.removeImage(image, index);
                                            }}
                                        />
                                        {image.name}
                                    </Col>
                                ))}
                            </Row>
                            {getHolidays2019() ? (
                                <div className="holiday-away-message">
                                    {TEAMHOLIDAYBACK[indexLang]}
                                </div>
                            ) : (
                                ''
                            )}
                            <Button
                                disabled={succeeded}
                                className="yellow-button"
                                style={{ maxWidth: '350px' }}
                                onClick={this.sendForm}>
                                {isSending ? (
                                    <CircularProgress
                                        size={24}
                                        style={{
                                            verticalAlign: 'middle',
                                            color: '#000000',
                                        }}
                                    />
                                ) : succeeded ? (
                                    MESSAGESENT[indexLang]
                                ) : (
                                    SEND[indexLang]
                                )}
                            </Button>
                        </Col>
                    </Row>
                    <AlertModal
                        indexLang={indexLang}
                        confirmation={false}
                        toggleModal={() => dispatch({ type: 'CLOSE_ALERT' })}
                        modal={modal}
                        title={title}
                        text={text}
                    />
                </Container>
            </div>
        );
    }
}

export default returnPolicyContainer(ReturnPolicy);
