import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { fetchUpdateNeck } from '../../actions/orders';
import { MODIFYNECKSIZE, NECKMODIFIED, STANDARDNECKSIZE, NECKSIZE } from '../../constant/stringTraduction';
import SuccessableButton from '../buttons/SuccessableButton';
import renderTextFieldBlack from '../fields/renderTextFieldBlack';
import renderCheckbox from '../fields/renderCheckbox';
import { SLUG_ANIMAL_POS } from '../../constant/otherConst';

const OrderModifyNeck = ({
    order,
    handleSubmit,
    dispatch,
    submitSucceeded,
    submitting,
    indexLang,
    errorNeck,
    isFetching,
    pristine,
    invalid,
    resetError,
}) => {

    const handleNeckFormSubmit = async (values) => {
        await dispatch(fetchUpdateNeck({ values, order, indexLang }));
    }

    const getAnimalId = () => {
        return order.item.slug[SLUG_ANIMAL_POS];
    }

    return (
        <Container style={{ marginTop: 45 }} className='white-background'>
            <div>
                <Row>
                    <Col lg='12'><h4>{MODIFYNECKSIZE[indexLang]}</h4></Col>
                </Row>
                <Row style={{ paddingBottom: 25, borderBottom: 'solid black 1px' }}>
                    <div style={{ width: '100%' }}>
                        <form onSubmit={handleSubmit(handleNeckFormSubmit)}>
                            <div className="field-container">
                                {getAnimalId() === '0' &&
                                <div>
                                    <Field
                                        name="neckSize"
                                        label={NECKSIZE[indexLang] + ' (cm)'}
                                        className="field"
                                        component={renderTextFieldBlack}
                                        color='#000'
                                        indexLang={indexLang}
                                    />
                                </div>
                                }
                                {getAnimalId() === '1' &&
                                <div>
                                    <Field
                                        name="standardNeckSize"
                                        label={STANDARDNECKSIZE[indexLang]}
                                        className="field"
                                        component={renderCheckbox}
                                    />
                                </div>
                                }
                            </div>
                            <Col lg='12' style={{ width: '100%', textAlign: 'center' }}>
                                {isFetching ?
                                    <CircularProgress size={36} style={{ verticalAlign: 'middle', color: '#000000' }} />
                                    :
                                    <SuccessableButton
                                        disabled={pristine || invalid}
                                        error={errorNeck}
                                        label={MODIFYNECKSIZE[indexLang]}
                                        reset={resetError}
                                        submitSucceeded={submitSucceeded}
                                        submitting={submitting}
                                        successLabel={NECKMODIFIED[indexLang]}
                                    />
                                }
                            </Col>
                        </form>
                    </div>
                </Row>
            </div>
        </Container>
    )
}

export default reduxForm({
    form: 'NewNeckForm',
    destroyOnUnmount: false,
})(withRouter(OrderModifyNeck))
