import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { OURPARTNERS } from '../../constant/stringTraduction';
import partner_purebites from '../../assets/partner_purebites.png';
import partner_litterlocker from '../../assets/partner_litterlocker.jpg';
import partner_cookina from '../../assets/partner_cookina.jpg';
import partner_wilderharrier from '../../assets/partner_wilderharrier.png';
import './partners.css'
const Partners = ({
    indexLang,
}) => {
    return (
        <div style={{ backgroundColor: '#ffffff', color: 'black', paddingBottom: '50px' }}>
            <Container style={{ marginBottom:'20px' }}>
                <div>
                    <h2 style={{ marginBottom: 20 }}><span style={{ fontWeight: '800', fontSize: '38px' }}>{OURPARTNERS[indexLang]}</span></h2>
                    <Row>
                        <Col md='3' className='logo-wrapper'>
                            <img className='partner-logo purebites' src={partner_purebites} alt='Purebites logo'/>
                        </Col>
                        <Col md='3' className='logo-wrapper'>
                            <img className='partner-logo' src={partner_cookina} alt='Cookina logo'/>
                        </Col>
                        <Col md='3' className='logo-wrapper'>
                            <img className='partner-logo' src={partner_litterlocker} alt='Litterlocker logo'/>
                        </Col>
                        <Col md='3' className='logo-wrapper'>
                            <img className='partner-logo' src={partner_wilderharrier} alt='Wilder Harrier logo'/>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>    
    );
}
export default Partners
