const retroaction = (state = {
    isSending: false,
    succeeded: false,
    error: null,
    survey: true,
    orderId: null,
}, action) => {
    switch (action.type) {
        case 'IS_SENDING_MESSAGE':
            return {
                ...state,
                isSending: true,
                succeeded: false,
            }
        case 'IS_DONE':
            return {
                ...state,
                isSending: false,
                succeeded: false,
            }
        case 'SUCCEEDED':
            return {
                ...state,
                isSending: false,
                succeeded: true,
            }
        case 'RESET_FORM':
            return {
                ...state,
                isSending: false,
                succeeded: false,
            }
        case 'SET_ORDERID':
            return {
                ...state,
                orderId: action.orderId,
            }     
        case 'RESET_SURVEY':
            return {
                ...state,
                survey: false,
                orderId: null,
            }   
        default:
            return state
    }
}
  
export default retroaction
