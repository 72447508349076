import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import AsSeenOnVideoModal from './AsSeenOnVideoModal';
import { ASSEENON } from '../../constant/stringTraduction';
import rc_logo from '../../assets/asSeenOn_logos/rc_logo.jpg';
import sb_logo from '../../assets/asSeenOn_logos/sb_logo.png';
import muramur_logo from '../../assets/asSeenOn_logos/muramur_logo.png';
import './asSeenOn.css';

const AsSeenOn = ({
    indexLang,
    openVideo,
    closeViceo,
    videoRCOpened,
    videoSBOpened,
}) => {
    return (
        <div style={{ backgroundColor: '#ffffff', color: 'black', marginTop:30 }}>
            <Container style={{ marginBottom:'20px' }}>
                <div>
                    <h2 style={{ marginBottom: 20 }}><span style={{ fontWeight: '800', fontSize: '38px' }}>{ASSEENON[indexLang]}</span></h2>
                    <Row>
                        <Col md='4' className='logo-wrapper'>
                            <img className='tv-logo tv-left' src={rc_logo} alt='Radio-Canada logo'/>
                        </Col>
                        <Col md='4' className='logo-wrapper'>
                            <img className='tv-logo' src={sb_logo} alt='Salut Bonjour logo'/>
                        </Col>
                        <Col md='4' className='logo-wrapper'>
                            <img className='tv-logo tv-right' src={muramur_logo} alt='Muramur logo'/>
                        </Col>
                    </Row>
                </div>
            </Container>
            <AsSeenOnVideoModal
                indexLang={indexLang} 
                Opened={videoRCOpened} 
                closeVideo={closeViceo} 
                modalId='RC-Video'
                indexVideo={0}
            />
            <AsSeenOnVideoModal
                indexLang={indexLang} 
                Opened={videoSBOpened} 
                closeVideo={closeViceo} 
                modalId='SB-Video'
                indexVideo={1}
            />
        </div>    
    );
}

export default AsSeenOn
