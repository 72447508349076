import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Row, Col } from 'reactstrap';
import {
    YES, NO, UPGRADETO, SELECTIONSUPERPARTYPACK, CANCEL, CONFIRMATIONUPGRADE, SUPERPARTYPACKUP, FERMER, ORDERUPGRADED, ORDERERROR, COLON,
} from '../../constant/stringTraduction';
import './user.css';

// ************** SUMMER
// Dog images
import DogSuperPartyPackEnglishGeneric from '../../assets/web_packs_generic/desktop/dog/new_pack_super_dog_EN_768x350.png';
import DogSuperPartyPackFrenchGeneric from '../../assets/web_packs_generic/desktop/dog/new_pack_super_dog_FR_768x350.png';
import DogSuperPartyPackEnglishMobileGeneric from '../../assets/web_packs_generic/mobile/dog/new_pack_dog_super_EN_285x350.png';
import DogSuperPartyPackFrenchMobileGeneric from '../../assets/web_packs_generic/mobile/dog/new_pack_super_dog_285x350_FR.png';
import CatSuperPartyPackBedEnglishGeneric from '../../assets/web_packs_generic/desktop/cat/bed_new_pack_cat_EN_768x290.png';
import CatSuperPartyPackBedFrenchGeneric from '../../assets/web_packs_generic/desktop/cat/bed_new_pack_cat_FR_768x290.png';
import CatSuperPartyPackBedEnglishMobileGeneric from '../../assets/web_packs_generic/mobile/cat/bed_new_pack_cat_EN_285x285.png';
import CatSuperPartyPackBedFrenchMobileGeneric from '../../assets/web_packs_generic/mobile/cat/bed_new_pack_cat_FR_285x285.png';

// Cat images
import CatSuperPartyPackEnglishGeneric from '../../assets/web_packs_generic/desktop/cat/pack_super_cat_en_768x350.png';
import CatSuperPartyPackFrenchGeneric from '../../assets/web_packs_generic/desktop/cat/pack_cat_super_FR_768x350.png';
import CatSuperPartyPackEnglishMobileGeneric from '../../assets/web_packs_generic/mobile/cat/new_cat_super_pack_EN_285x350.png';
import CatSuperPartyPackFrenchMobileGeneric from '../../assets/web_packs_generic/mobile/cat/new_cat_super_pack_FR_285x350.png';
import DogSuperPartyPackBedEnglishGeneric from '../../assets/web_packs_generic/desktop/dog/bed_new_pack_dog_EN_768x290.png';
import DogSuperPartyPackBedFrenchGeneric from '../../assets/web_packs_generic/desktop/dog/bed_new_pack_dog_FR_768x290.png';
import DogSuperPartyPackBedEnglishMobileGeneric from '../../assets/web_packs_generic/mobile/dog/bed_new_pack_dog_EN_285x285.png';
import DogSuperPartyPackBedFrenchMobileGeneric from '../../assets/web_packs_generic/mobile/dog/bed_new_pack_dog_FR_285x285.png';

// ************** WINTER
// Dog images
import DogSuperPartyPackEnglishWinter from '../../assets/web_packs_winter/desktop/dog/winter_super_pack_dog_EN.png';
import DogSuperPartyPackFrenchWinter from '../../assets/web_packs_winter/desktop/dog/winter_super_pack_dog_FR.png';
import DogSuperPartyPackBedEnglishWinter from '../../assets/web_packs_winter/desktop/dog/winter_super_pack_dog_bed_EN.png';
import DogSuperPartyPackBedFrenchWinter from '../../assets/web_packs_winter/desktop/dog/winter_super_pack_dog_bed_FR.png';
import DogSuperPartyPackEnglishMobileWinter from '../../assets/web_packs_winter/mobile/dog/winter_super_pack_dog_EN.png';
import DogSuperPartyPackFrenchMobileWinter from '../../assets/web_packs_winter/mobile/dog/winter_super_pack_dog_FR.png';
import DogSuperPartyPackBedEnglishMobileWinter from '../../assets/web_packs_winter/mobile/dog/winter_super_pack_dog_bed_EN.png';
import DogSuperPartyPackBedFrenchMobileWinter from '../../assets/web_packs_winter/mobile/dog/winter_super_pack_dog_bed_FR.png';

// Cat images
import CatSuperPartyPackEnglishWinter from '../../assets/web_packs_winter/desktop/cat/winter_super_pack_cat_EN.png';
import CatSuperPartyPackFrenchWinter from '../../assets/web_packs_winter/desktop/cat/winter_super_pack_cat_FR.png';
import CatSuperPartyPackBedEnglishWinter from '../../assets/web_packs_winter/desktop/cat/winter_super_pack_cat_bed_EN.png';
import CatSuperPartyPackBedFrenchWinter from '../../assets/web_packs_winter/desktop/cat/winter_super_pack_cat_bed_FR.png';
import CatSuperPartyPackEnglishMobileWinter from '../../assets/web_packs_winter/mobile/cat/winter_super_pack_cat_EN.png';
import CatSuperPartyPackFrenchMobileWinter from '../../assets/web_packs_winter/mobile/cat/winter_super_pack_cat_FR.png';
import CatSuperPartyPackBedEnglishMobileWinter from '../../assets/web_packs_winter/mobile/cat/winter_super_pack_cat_bed_EN.png';
import CatSuperPartyPackBedFrenchMobileWinter from '../../assets/web_packs_winter/mobile/cat/winter_super_pack_cat_bed_FR.png';

import formatPrice from '../../utils/formatPrice';
import CircularProgress from '@material-ui/core/CircularProgress';
import { upgradeSubscription } from '../../actions/orders';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import { getNextPackDate } from '../../utils/dates';
import { SLUG_START, SLUG_ANIMAL_POS, SLUG_SEP } from '../../constant/otherConst';

class UpgradeModal extends Component {
    constructor() {
        super();
        this.state = {
            newPack: null,
            onMobile: false,
            newPackSlug: null,
        };
    }
    componentDidMount() {
        if (window.innerWidth <= 768) {
            this.setState({ onMobile: true });
        }
    }
    onConfirmUpgrade = async () => {
        this.props.dispatch(upgradeSubscription(this.props.order, this.state.newPackSlug))
    }
    setNewPack = async (pack, version) => {
        let currentSlug = this.props.order.item.slug;
        if (version === 0) {
            currentSlug = SLUG_START[0] + SLUG_SEP + currentSlug;
        }
        const newSlug = currentSlug.slice(0, -1) + pack;
        const price = await this.props.products.filter((product) => product.slug === newSlug)[0].price;
        this.setState({
            newPack: pack,
            newPackSlug: newSlug,
            newPackPrice: price,
        });
        let x = document.getElementById('confirm_buttons')
        smoothScrollIntoView(x, { scrollMode: 'if-needed', block: 'center', behavior: 'smooth' });
    }
    closeModal = () => {
        const { closeUpgradeModal } = this.props;
        this.setState({ newPack: null, newPackSlug: null });
        closeUpgradeModal();
    }

    render() {
        const {
            orderUpgrading,
            orderUpgradeSuccess,
            orderUpgradeError,
            indexLang,
            order,
            upgradeModal,
        } = this.props

        let packImages = [];
        // Dimension 1: saison, 2: lang, 3: animal, 4: type
        if (this.state.onMobile) {
            packImages =
            [
                [
                    [[DogSuperPartyPackEnglishMobileWinter, DogSuperPartyPackBedEnglishMobileWinter], [CatSuperPartyPackEnglishMobileWinter, CatSuperPartyPackBedEnglishMobileWinter]],
                    [[DogSuperPartyPackFrenchMobileWinter, DogSuperPartyPackBedFrenchMobileWinter], [CatSuperPartyPackFrenchMobileWinter, CatSuperPartyPackBedFrenchMobileWinter]],
                ],
                [
                    [[DogSuperPartyPackEnglishMobileGeneric, DogSuperPartyPackBedEnglishMobileGeneric], [CatSuperPartyPackEnglishMobileGeneric, CatSuperPartyPackBedEnglishMobileGeneric]],
                    [[DogSuperPartyPackFrenchMobileGeneric, DogSuperPartyPackBedFrenchMobileGeneric], [CatSuperPartyPackFrenchMobileGeneric, CatSuperPartyPackBedFrenchMobileGeneric]],
                ],
            ];
        } else {
            packImages =
            [
                [
                    [[DogSuperPartyPackEnglishWinter, DogSuperPartyPackBedEnglishWinter], [CatSuperPartyPackEnglishWinter, CatSuperPartyPackBedEnglishWinter]],
                    [[DogSuperPartyPackFrenchWinter, DogSuperPartyPackBedFrenchWinter], [CatSuperPartyPackFrenchWinter, CatSuperPartyPackBedFrenchWinter]],
                ],
                [
                    [[DogSuperPartyPackEnglishGeneric, DogSuperPartyPackBedEnglishGeneric], [CatSuperPartyPackEnglishGeneric, CatSuperPartyPackBedEnglishGeneric]],
                    [[DogSuperPartyPackFrenchGeneric, DogSuperPartyPackBedFrenchGeneric], [CatSuperPartyPackFrenchGeneric, CatSuperPartyPackBedFrenchGeneric]],
                ],
            ];
        }

        let indexAnimal, currentPack, newPackId, version;
        if (order) {
            currentPack = parseInt(order.item.slug[order.item.slug.length - 1]);
            if (order.item.slug[0] === SLUG_START[0] || order.item.slug[0] === SLUG_START[1]) {
                version = 1;
                indexAnimal = parseInt(order.item.slug[SLUG_ANIMAL_POS]);
                newPackId = currentPack === 1 ? 0 : 1;
            } else {
                version = 0;
                indexAnimal = parseInt(order.item.slug[0]);
                newPackId = 0;
            }
        }
        
        let chosenPack = SUPERPARTYPACKUP;
        if (newPackId === 1) {
            chosenPack = [SUPERPARTYPACKUP[0] + ' (with bed)', SUPERPARTYPACKUP[1] + ' (avec lit)']
        }

        const { indexSaison } = getNextPackDate();

        return (
            <Modal id='upgrade-modal' isOpen={upgradeModal} toggle={this.closeModal} backdrop='static' >
                <ModalHeader className='upgrade-header' toggle={this.closeModal} ></ModalHeader>
                <ModalBody className='upgrade-body' >
                    {order &&
                    <Row>
                        <Col md='2'></Col>
                        <Col md='8'>
                            <div className='pack-explain'>{UPGRADETO[indexLang]}{chosenPack[indexLang]}{COLON[indexLang]}</div>
                            <div
                                onClick={(e) => this.setNewPack(newPackId, version)}
                                className={'choice-box float-right-over-511 final-pack-choice' + (this.state.newPack === '1' ? ' selected-reponse' : '')}
                            >
                                <img className='final-pack-image' src={packImages[indexSaison][indexLang][indexAnimal][newPackId]} alt={SELECTIONSUPERPARTYPACK[indexLang]} width="583" height="250" />
                            </div>
                        </Col>
                        <Col md='2'></Col>
                    </Row>
                    }
                </ModalBody>
                <ModalFooter className='upgrade-footer' >
                    {this.state.newPack != null ?
                        <div id='confirm_buttons'>
                            {!orderUpgradeSuccess && !orderUpgradeError && <p style={{ fontSize: '18px', marginBottom: '15px' }}>{CONFIRMATIONUPGRADE[indexLang]} {chosenPack[indexLang]} {['for', 'pour'][indexLang]} {formatPrice(this.state.newPackPrice, indexLang)} {['each season', 'chaque saison'][indexLang]}?</p>}
                            <Row>
                                <Col>
                                    {!orderUpgradeSuccess && !orderUpgradeError &&
                                        <Button style={{ display: 'inline' }} className="black-button upgrade-button" onClick={this.closeModal} >
                                            {NO[indexLang]}
                                        </Button>
                                    }
                                    {orderUpgrading ?
                                        <Button style={{ display: 'inline' }} className="yellow-button upgrade-button" >
                                            <CircularProgress color='inherit' size={25} style={{ verticalAlign: 'middle' }} />
                                        </Button>
                                        :
                                        <div style={{ display: 'inline' }}>
                                            {!orderUpgradeSuccess && !orderUpgradeError ?
                                                <Button className="yellow-button upgrade-button" onClick={this.onConfirmUpgrade} >
                                                    {YES[indexLang]}
                                                </Button>
                                                :
                                                <div style={{ display: 'inline' }}>
                                                    {orderUpgradeSuccess ?
                                                        <p>{ORDERUPGRADED[indexLang]} {chosenPack[indexLang]}</p>
                                                        :
                                                        <p style={{ color: 'red' }}>{ORDERERROR[indexLang]}</p>
                                                    }
                                                    <Button className="yellow-button upgrade-button" onClick={this.closeModal} >
                                                        {FERMER[indexLang]}
                                                    </Button>
                                                </div>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>
                        :
                        <Button className="black-button upgrade-button" onClick={this.closeModal} >
                            {CANCEL[indexLang]}
                        </Button>
                    }
                </ModalFooter>
            </Modal>
        );
    }
}

export default UpgradeModal
