import React, { Component } from 'react'
import { connect } from 'react-redux'

const examplesContainer = (ComposedComponent) => {
    class ExamplesContainer extends Component {
        render() {
            return (
                <ComposedComponent {...this.props} />
            )
        }
    }
    const mapStateToProps = ({
        langs: { indexLang },
    }) => ({
        indexLang,
    })

    return connect(mapStateToProps)(ExamplesContainer)
}

export default examplesContainer
