import CircularProgress from '@material-ui/core/CircularProgress';
import Payment from 'payment';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { fetchAddOrder, resetError } from '../../actions/orders';
import { fetchUpdateCart } from '../../actions/cart';
import checkout_header_english from '../../assets/check_out_english.jpg';
import checkout_header_french from '../../assets/check_out_french.jpg';
import { ADRESSES, CVC, EXPIRATIONCARTE, NUMEROCARTE, PLACERCOMMANDE, VALIDATEADD, MODIFYCART } from '../../constant/stringTraduction';
import orderAddContainer from '../../containers/orders/orderAddContainer';
import validateCheckout from '../../utils/validateCheckout';
import SuccessableButton from '../buttons/SuccessableButton';
import CartSummary from '../cart/CartSummary';
import renderSelectFieldAddress from '../fields/renderSelectFieldAddress';
import renderTextFieldBlack from '../fields/renderTextFieldBlack';
import './orders.css';
import AddressFields from '../addresses/AddressFields';
import { Link } from 'react-router-dom';
import { getNoMoreOrders } from '../../utils/specialEvents';

class OrderAdd extends Component {
    state = {
        newAddress: false,
        selectedAddressId: null,
        shippingCalculated: false,
    }

    componentDidMount = () => {
        const { dispatch } = this.props
        dispatch(fetchUpdateCart({ type: 'SHIPPING', address_id: 'newAddress' }));
    }

    resetError = () => {
        this.props.dispatch(resetError());
    }

    handleNewAddressShipping = () => {
        const { dispatch } = this.props;
        const postalCode = document.querySelector('input[name=zip]').value;
        const prov = document.querySelector('input[name=state]').value;
        this.setState({ shippingCalculated: true });
        return dispatch(fetchUpdateCart({ type: 'SHIPPING_NEW', postalCode, prov }));
    }

    handleAddressChange = (value) => {
        const { dispatch } = this.props
        this.setState({ newAddress: value === 'newAddress' ? true : false });
        this.setState({ selectedAddressId: value });
        return dispatch(fetchUpdateCart({ type: 'SHIPPING', address_id: value }));
    }

    handleFormSubmit = (values) => {
        const { dispatch, cart, history, stripePk, indexLang } = this.props
        return dispatch(fetchAddOrder({ values, cart, history, stripePk, indexLang }))
    }

    modifyOrder = () => {
        const { dispatch } = this.props;
        dispatch({ type: 'RESET_BOX', payload: this.props.cart.item.trial });
    }

    render() {
        const {
            addresses,
            cart,
            orders,
            handleSubmit,
            invalid,
            pristine,
            submitSucceeded,
            submitting,
            indexLang,
            isFetching,
        } = this.props

        const headerImage = [checkout_header_english, checkout_header_french];

        return (
            <Container className='white-background container-top-margin'>
                <div style={{ paddingTop: 24 }}>
                    <Row>
                        <Col lg='12'><div className="header-checkout" style={{ backgroundImage: `url(${headerImage[indexLang]})` }}></div></Col>
                    </Row>
                    {!getNoMoreOrders() &&
                    <Row>
                        <div>
                            <ul className="credit-card-list">
                                <li><i data-brand="visa" className="fa fa-cc-visa"></i></li>
                                <li><i data-brand="mastercard" className="fa fa-cc-mastercard"></i></li>
                            </ul>
                        </div>
                        <br />
                        <div style={{ width: '100%' }}>
                            <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                                <div className="field-container">
                                    <Field
                                        name="number"
                                        label={NUMEROCARTE[indexLang]}
                                        className="field"
                                        component={renderTextFieldBlack}
                                        color='#000'
                                        onFocus={(e) => Payment.formatCardNumber(e.target)}
                                        indexLang={indexLang}
                                    />
                                    <Field
                                        name="exp"
                                        label={EXPIRATIONCARTE[indexLang]}
                                        className="field"
                                        color='#000'
                                        component={renderTextFieldBlack}
                                        onFocus={(e) => Payment.formatCardExpiry(e.target)}
                                        indexLang={indexLang}
                                    />
                                    <Field
                                        name="cvc"
                                        label={CVC[indexLang]}
                                        className="field"
                                        component={renderTextFieldBlack}
                                        color='#000'
                                        onFocus={(e) => Payment.formatCardCVC(e.target)}
                                        indexLang={indexLang}
                                    />
                                    <Field
                                        color="black"
                                        name="fullAddress"
                                        indexLang={indexLang}
                                        component={renderSelectFieldAddress}
                                        label={ADRESSES[indexLang]}
                                        fullWidth={true}
                                        className="field"
                                        options={addresses}
                                        handleAddressChange={this.handleAddressChange}
                                    />
                                    {this.state.newAddress &&
                                        <div style={{ border: '1px solid #ccc', marginTop: 5, padding: '5px 10px' }}>
                                            <AddressFields
                                                autoFocus={false}
                                                indexLang={indexLang}
                                            />
                                            <Button style={{ marginBottom: 4 }} onClick={this.handleNewAddressShipping} className="yellow-button" size="md">{VALIDATEADD[indexLang]}</Button>
                                        </div>
                                    }
                                </div>
                                <div className="button-container">
                                    {isFetching ?
                                        <CircularProgress size={36} style={{ verticalAlign: 'middle', color: '#000000' }} />
                                        :
                                        <SuccessableButton
                                            disabled={(pristine || invalid || !this.state.selectedAddressId || this.state.selectedAddressId === 'newAddress') && !this.state.shippingCalculated}
                                            error={orders.error}
                                            label={PLACERCOMMANDE[indexLang]}
                                            reset={this.resetError}
                                            submitSucceeded={submitSucceeded}
                                            submitting={submitting}
                                            successLabel="Order Placed!"
                                        />
                                    }
                                </div>
                                <div style={{ width: '100%', textAlign: 'right', paddingRight: '15px', paddingLeft: '15px', marginTop: '15px', textDecoration: 'underline', fontSize: '14px' }}>
                                    <Link onClick={() => this.modifyOrder()} to={'/box'}>
                                        {MODIFYCART[indexLang]}
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </Row>
                    }
                    {cart && cart.item && !getNoMoreOrders() &&
                        <CartSummary
                            cart={cart}
                            indexLang={indexLang}
                            checkout={true}
                            addressId={this.state.selectedAddressId}
                            shippingCalculated={this.state.shippingCalculated}
                            isFetching={isFetching}
                        />
                    }
                </div>
            </Container>
        )
    }
}

export default orderAddContainer(reduxForm({
    form: 'CheckoutForm',
    validate: validateCheckout,
})(withRouter(OrderAdd)))
