// Pas utilisé pour l'instant
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { Button, Col, Container, Row, Input, FormGroup, Label } from 'reactstrap';
import { ALLFIELDREQUIRED, COMMENTS, MESSAGESENT, SEND, BONJOUR, APPRECIATIONTEXT, ERROR } from '../../constant/stringTraduction';
import retroactionContainer from '../../containers/retroaction/retroactionContainer';
import { fetchSendAppreciationRetroaction, resetRetroactionForm } from '../../actions/retroaction'
import AlertModal from '../alert/AlertModal';

class RetroactioAppreciation extends React.Component {
    constructor() {
        super();
        this.state = {
            answer1: '',
            answer2: '',
            answer3: '',
            comments: '',
            modal: false,
            title: ERROR,
            text: ALLFIELDREQUIRED,
        }
    }

    toggleModal = () => {
        const inversedModal = !this.state.modal;
        this.setState({
            modal: inversedModal,
        });
    }

    componentDidMount() {
        this.props.dispatch({ type: 'RESET_SURVEY' })
    }

    componentWillUnmount() {
        this.props.dispatch(resetRetroactionForm());
    }

    sendForm = () => {
        if (!this.state.answer1 || !this.state.answer2 || !this.state.answer3) {
            this.toggleModal();
        } else {
            const {
                dispatch,
                order,
            } = this.props;                
                             
            dispatch(fetchSendAppreciationRetroaction({ 
                answer1: this.state.answer1,
                answer2: this.state.answer2,
                answer3: this.state.answer3,
                comments: this.state.comments,
                order: order,
            })); 
        }  
    }
  
    render() {
        const {
            indexLang,
            isSending,
            succeeded,
            firstName,
            order,
        } = this.props;

        return (
            <div>
                <Container className='white-background container-top-margin' style={{ textAlign: 'center', paddingTop: 99 }}>
                    <Row>
                        <Col lg={12} style={{ marginBottom:'25px' }}>
                            {BONJOUR[indexLang]} {firstName}, 
                            <span> {APPRECIATIONTEXT[indexLang]}</span>
                            <span style={{ textTransform: 'lowercase' }}> {!indexLang ? order.item.descriptionEN : order.item.descriptionFR} {!indexLang ? order.item.nameEN : order.item.nameFR}</span>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: 'left' }}>
                        <Col md={12}>
                            <FormGroup tag="fieldset">
                                <p>Question 1</p>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="returnWaranty" value="yes" onChange={(e) => this.setState({ answer1: e.target.value })}/>{' '}
                                        Oui
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="returnWaranty" value="no" onChange={(e) => this.setState({ answer1: e.target.value })}/>{' '}
                                        Non
                                    </Label>
                                </FormGroup>
                            </FormGroup>
                            <FormGroup tag="fieldset">
                                <p>Question 2</p>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="returnWaranty" value="yes" onChange={(e) => this.setState({ answer2: e.target.value })}/>{' '}
                                        Oui
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="returnWaranty" value="no" onChange={(e) => this.setState({ answer2: e.target.value })}/>{' '}
                                        Non
                                    </Label>
                                </FormGroup>
                            </FormGroup>
                            <FormGroup tag="fieldset">
                                <p>Question 3</p>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="returnWaranty" value="yes" onChange={(e) => this.setState({ answer3: e.target.value })}/>{' '}
                                        Oui
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="returnWaranty" value="no" onChange={(e) => this.setState({ answer3: e.target.value })}/>{' '}
                                        Non
                                    </Label>
                                </FormGroup>
                            </FormGroup>
                            
                            <Input className='form-line' type='textarea' placeholder={COMMENTS[indexLang]} value={this.state.comments} onChange={(e) => this.setState({ comments: e.target.value })}/>
                            
                            <Button disabled={succeeded} className='yellow-button' style={{ maxWidth: '350px' }} onClick={this.sendForm}>
                                {isSending ? <CircularProgress size={24} style={{ verticalAlign: 'middle', color: '#000000' }} /> : succeeded ? MESSAGESENT[indexLang] : SEND[indexLang]}
                            </Button>
                        </Col>
                        <AlertModal
                            indexLang={indexLang}
                            toggleModal={this.toggleModal}
                            modal={this.state.modal}
                            title={this.state.title}
                            text={this.state.text}
                        />
                    </Row>
                </Container >
            </div>
        );
    }
}

export default retroactionContainer(RetroactioAppreciation)
