import React, { Component } from 'react';
import dog from '../../../assets/selection/dog.png'
import { CHIEN } from '../../../constant/stringTraduction';
export default class DogPictoSmall extends Component {
    render() {
        return (
            <div className='picto-wrapper'>
                <img src={dog} alt="dog" className='example-picto'></img>
                <div style={{ marginTop: 10 }}><b>{CHIEN[this.props.indexLang]}</b></div>
            </div>
        );
    }
}
