import Payment from 'payment'

const validateCheckout = (values) => {
    const errors = {}
    const requiredFields = [
        'fullAddress',
        'number',
        'exp',
        'cvc',
        'name',
        'phone',
        'street',
        'city',
        'state',
        'zip',
    ]
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = ['*', '*'];
        }
    })
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = ['example : abcdef@ghijkl.com', 'exemple : abcdef@ghijkl.com'];
    }
    if (values.number) {
        const type = Payment.fns.cardType(values.number);
        const cards = document.querySelectorAll('[data-brand]');

        [].forEach.call(cards, (element) => {
            if (element.getAttribute('data-brand') === type) {
                element.classList.add('active');
            } else {
                element.classList.remove('active');
            }
        })
    }
    if (values.exp) {
        if (!/^([0][0-9]|[1][0-2])[ ][/][ ][0-9][0-9][0-9][0-9]$/.test(values.exp) && !/^([0][0-9]|[1][0-2])[/][0-9][0-9][0-9][0-9]$/.test(values.exp) && !/^([0][0-9]|[1][0-2])[ ][/][0-9][0-9][0-9][0-9]$/.test(values.exp) && !/^([0][0-9]|[1][0-2])[/][ ][0-9][0-9][0-9][0-9]$/.test(values.exp)){
            errors.exp = ['EX: 12/2020', 'EX: 12/2020'];
        }
    }
    if (values.phone && values.phone.length < 14) {
        errors.phone = ['EX: (555) 555-5555', 'EX: (555) 555-5555'];
    }
    if (values.state && values.state.length < 2) {
        errors.state = ['EX: QC', 'EX: QC'];
    }
    if (values.zip && values.zip.length < 6) {
        errors.zip = ['EX: A1A1A1', 'EX: A1A1A1'];
    }
    return errors
}

export default validateCheckout
