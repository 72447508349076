import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import { PANIERVIDE, STARTTHEPARTY } from '../../constant/stringTraduction';
import cartContainer from '../../containers/cart/cartContainer';
import './cart.css';
import cartImageEN from '../../assets/my_cart_english.png';
import cartImageFR from '../../assets/my_cart_french.png';
import CartSummary from './CartSummary';
import { getNoMoreOrders } from '../../utils/specialEvents';

const CartPage = ({ cart, dispatch, history, user, indexLang }) => {
    const headerImage = [cartImageEN, cartImageFR];

    return (
        <Container className='white-background container-top-margin'>
            {
                !cart.item && !getNoMoreOrders() ?
                    <div style={{ paddingTop: '100px' }}>
                        <h3 >{PANIERVIDE[indexLang]}</h3>
                        <Link to="/box">{STARTTHEPARTY[indexLang]}</Link>
                    </div>
                    :
                    <div>
                        <Row id='cart_header'>
                            <Col md='12'><div style={{ backgroundImage: `url(${headerImage[indexLang]})`, minHeight: 180, width: '100%', backgroundPosition: 'center' }}/></Col>
                        </Row>
                        {!getNoMoreOrders() &&
                        <CartSummary
                            cart={cart}
                            dispatch={dispatch}
                            history={history}
                            user={user}
                            indexLang={indexLang}
                            checkout={false}
                        />
                        }
                    </div>
            }
        </Container >
    );
}

export default cartContainer(CartPage)
