import { destroy } from 'redux-form';
import handleAuthFetch from '../utils/handleAuthFetch';
import { isEmpty } from '../utils/other';
import { SERVER_ERROR } from '../constant/stringTraduction';
import { fetchOrdersSuccess } from './orders';
import { fetchDeleteSuccess as usersDeleteSuccess, fetchUsersSuccess } from './users';
export const type = 'USER'
const route = 'users'

const REQUEST = `REQUEST_${type}`
const RECEIVE = `RECEIVE_${type}`
const UPDATE = `UPDATE_${type}`
const DELETE = `DELETE_${type}`
const ERROR = `ERROR_${type}`

const fetchFailure = (error) => {
    return {
        type: ERROR,
        error,
    }
}
// Add user to cart
const fetchAddUserCartSuccess = (cart) => ({ type: 'ADD_USER_CART', cart })
const fetchAddUserCartFailure = (error) => ({ type: 'ERROR_USER_CART', error })
const fetchAddUserCart = (userId, cartId) => {
    return (dispatch) => {
        dispatch({ type: 'ADDING_USER_CART', userId, cartId });
        return handleAuthFetch({
            path: `/api/users/addusertocart`,
            method: 'POST',
            body: { userId, cartId },
        })
            .then((json) => {
                if (!json) {
                    return dispatch(fetchAddUserCartFailure({ error: 'cart not found' }));
                }
                if (json.error) {
                    return dispatch(fetchAddUserCartFailure(json.error));
                }
                return dispatch(fetchAddUserCartSuccess(json));
            })
            .catch((error) => dispatch(fetchAddUserCartFailure({ error })))
    }
}

// Create
export const fetchAdd = (values, indexLang) => {
    values.indexLang = indexLang;
    return async (dispatch) => {
        try {
            const res = await fetch(`/api/${route}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });
            let json = await res.json();
            if (json.error) {
                return dispatch(fetchFailure(json.error[indexLang]));
            }
            dispatch({ type: 'SET_CONFIRMATION_MODAL', values: true });
        } catch (error) {
            dispatch(fetchFailure(error));
        }
    }
}

// Read
const fetchUserRequest = () => ({ type: REQUEST })
const fetchUserSuccess = (item) => ({ type: RECEIVE, item })
export const fetchUser = () => {
    return async (dispatch) => {
        dispatch(fetchUserRequest())
        try {
            let json = await handleAuthFetch({
                path: `/api/${route}`,
                method: 'GET',
                body: null,
            });
            const { user, users, orders } = json;
            if (users) {
                dispatch(fetchUsersSuccess(users));
            }
            if (orders) {
                dispatch(fetchOrdersSuccess(orders));
            }
            return dispatch(fetchUserSuccess(user));
        } catch (error) {
            console.log(error);
            localStorage.removeItem('x-access-token');
            localStorage.removeItem('x-refresh-token');
            dispatch({ type: 'DELETE_USER' });
            dispatch({ type: 'DELETE_ALL_USERS' });
            dispatch({ type: 'DELETE_ORDERS' });
            dispatch(fetchFailure(error));
        }
    }
}

// Update
export const fetchUpdateSuccess = (item) => {
    return { type: UPDATE, item }
}

export const fetchUpdate = (update) => {
    return async (dispatch) => {
        dispatch({ type: 'USER_UPDATING' });
        try {
            const json = await handleAuthFetch({
                path: `/api/${route}`,
                method: 'PATCH',
                body: update,
            });
            dispatch(fetchUpdateSuccess(json));
        } catch (error) {
            console.error(error);
            dispatch(fetchFailure(error));
        }
    }
}

export const fetchUpdatePW = (update) => {
    return async (dispatch) => {
        dispatch({ type: 'USER_UPDATING' });
        try {
            const json = await handleAuthFetch({
                path: `/api/${route}/pw`,
                method: 'PATCH',
                body: update,
            });
            dispatch(fetchUpdateSuccess(json));
        } catch (error) {
            console.error(error);
            dispatch(fetchFailure(error));
        }
    }
}

// Delete
const fetchDeleteSuccess = () => ({ type: DELETE })
export const fetchDelete = () => {
    return async (dispatch) => {
        try {
            const json = await handleAuthFetch({
                path: `/api/${route}`,
                method: 'DELETE',
                body: null,
            });
            localStorage.removeItem('x-access-token');
            localStorage.removeItem('x-refresh-token');
            dispatch(fetchDeleteSuccess());
            dispatch(usersDeleteSuccess(json));
        } catch (error) {
            console.log(error);
            dispatch(fetchFailure(error));
        }
    }
}

export const redirectUser = (path) => {
    return {
        type: 'REDIRECT_USER',
        path,
    }
}

export const fetchSignin = ({ history, values }) => {
    return async (dispatch, getState) => {
        try {
            const res = await fetch('/api/users/signin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });
            if (res.ok) {
                localStorage.setItem('x-access-token', res.headers.get('x-access-token'));
                localStorage.setItem('x-refresh-token', res.headers.get('x-refresh-token'));
            }
            let json = await res.json();
            const indexLang = getState().langs.indexLang;
            if (json.error) {
                if (json.notConfirmed) {
                    dispatch({ type: 'SET_RESEND_EMAIL' })
                }
                return dispatch(fetchFailure(json.error[indexLang]));
            }
            const { user, users, orders } = json;
            if (users) {
                dispatch(fetchUsersSuccess(users));
            }
            if (orders) {
                dispatch(fetchOrdersSuccess(orders));
            }
            dispatch(fetchUserSuccess(user));
            dispatch(fetchReferedUsersDetails());
            dispatch(fetchReferedUsers());
            const item = getState().carts.cart.item;
            const survey = getState().retroaction.survey;
            const orderId = getState().retroaction.orderId;

            const cartId = localStorage.getItem('cart')
            if (cartId && user) {
                dispatch(fetchAddUserCart(user._id, cartId))
            }
            if (survey && orderId) {
                return history.push('/retroaction/appreciation/' + orderId);
            }
            if (item) {
                return history.push('/user/thankyou');
            }
            return history.push('/');
        } catch (error) {
            console.log(error);
            dispatch(fetchFailure(error));
        }
    }
}

export const signout = (history) => {
    return function (dispatch) {
        localStorage.removeItem('x-access-token')
        localStorage.removeItem('x-refresh-token')
        dispatch({ type: 'DELETE_USER' })
        dispatch({ type: 'DELETE_ORDERS' })
        dispatch({ type: 'DELETE_ALL_USERS' })
        dispatch(destroy('user_profile'));
        dispatch(destroy('user_password'));
        return history.push('/user/signin')
    }
}

const fetchRecoverySuccess = (message) => ({ type: 'RECOVER_USER', message })
export const fetchRecovery = ({ email }, indexLang) => {
    return async function (dispatch) {
        try {
            const res = await fetch('/api/users/recovery', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, indexLang }),
            });
            const json = await res.json();

            if (json.error.error) {
                return dispatch(fetchFailure(json.error.error[indexLang]));
            }
            dispatch(fetchRecoverySuccess(json));
        } catch (error) {
            console.log(error);
        }
    }
}

export const fetchReset = ({ password }, resetToken, indexLang) => {
    return async (dispatch) => {
        try {
            const res = await fetch(`/api/users/reset/${resetToken}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password }),
            });
            if (res.ok) {
                localStorage.setItem('x-access-token', res.headers.get('x-access-token'));
                localStorage.setItem('x-refresh-token', res.headers.get('x-refresh-token'));
            }
            let json = await res.json();
            if (json.error) {
                dispatch(fetchFailure(json.error[indexLang]));
            }
            const { user, users, orders } = json;
            if (users) {
                dispatch(fetchUsersSuccess(users));
            }
            if (orders) {
                dispatch(fetchOrdersSuccess(orders));
            }
            return dispatch(fetchUserSuccess(user));
        } catch (error) {
            console.log(error);
            dispatch(fetchFailure({ error: 'invalid reset token' }));
        }
    }
}

export const resetError = () => {
    return {
        type: 'RESET_ERROR_USER',
    }
}

export const getProductPrice = () => {
    return async (dispatch) => {
        try {
            let json = await handleAuthFetch({
                path: `/api/products/price`,
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'SET_PRODUCT_PRICE', payload: json });
        } catch (error) {
            console.log(error);
        }
    }
}

export const addReferedFriend = (userId, values) => {
    return async (dispatch) => {
        dispatch({ type: 'USER_UPDATING' });
        try {
            const json = await handleAuthFetch({
                path: `/api/${route}/refer`,
                method: 'PATCH',
                body: { userId, values },
            });
            dispatch(fetchUpdateSuccess(json));
            dispatch(fetchReferedUsersDetails());
            dispatch(fetchReferedUsers());
        } catch (error) {
            console.error(error);
            dispatch(fetchFailure(error));
        }
    }
}

export const fetchReferedUsers = () => {
    return async (dispatch) => {
        try {
            const referedUsers = await handleAuthFetch({
                path: '/api/users/refered-users',
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'SET_REFERED_USERS', values: referedUsers })
        } catch (error) {
            console.log(error);
        }
    }
}

export const fetchReferedUsersDetails = () => {
    return async (dispatch) => {
        try {
            const referedUsersDetails = await handleAuthFetch({
                path: '/api/users/refered-users-details',
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'SET_REFERED_USERS_DETAILS', values: referedUsersDetails })
        } catch (error) {
            console.log(error);
        }
    }
}

export const fetchAllReferedUsers = () => {
    return async (dispatch) => {
        try {
            const referedUsers = await handleAuthFetch({
                path: '/api/users/all-refered-users',
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'SET_ALLREFERED_USERS', values: referedUsers })
        } catch (error) {
            console.log(error);
        }
    }
}

export const fetchSubscribedUsers = () => {
    return async (dispatch) => {
        try {
            const subscribedUsers = await handleAuthFetch({
                path: '/api/users/subscribed-users',
                method: 'GET',
                body: null,
            });
            return dispatch({ type: 'SET_SUBSCRIBED_USERS', values: subscribedUsers })
        } catch (error) {
            console.log(error);
        }
    }
}

export const confirmEmail = (userId) => {
    return async (dispatch) => {
        dispatch({ type: 'USER_UPDATING' });
        try {
            const res = await fetch(`/api/users/email-confirmation`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId }),
            });
            let json = await res.json();
            if (json.error) {
                console.log(json)
                return dispatch({ type: 'CONFIRM_EMAIL_FAILED', values: json.error });
            }
            dispatch({ type: 'CONFIRM_EMAIL_SUCEEDED' })
        } catch (error) {
            console.error(error);
        }
    }
}

export const resendEmail = (email, indexLang) => {
    return async (dispatch) => {
        dispatch({ type: 'USER_UPDATING' });
        try {
            const res = await fetch(`/api/users/resend-confirmation`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, indexLang }),
            });
            let json = await res.json();
            if (json.error) {
                return dispatch({ type: 'RESEND_EMAIL_FAILURE', values: json.error });
            }
            dispatch({ type: 'RESEND_EMAIL_SUCCESS' })
        } catch (error) {
            console.error(error);
        }
    }
}

export const unsubscribe = (email) => {
    return async (dispatch) => {
        dispatch({ type: 'USER_UPDATING' });
        try {
            const res = await fetch(`/api/users/unsubscribe`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });
            let json = await res.json();
            if (json.error) {
                if (isEmpty(json.error)) {
                    json.error = SERVER_ERROR;
                }
                return dispatch({ type: 'CONFIRM_EMAIL_FAILED', values: json.error });
            }
            dispatch({ type: 'CONFIRM_EMAIL_SUCEEDED' })
        } catch (error) {
            console.error(error);
        }
    }
}
