import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { fetchSignin, resetError } from '../../actions/user';
import imgHeaderEnglish from '../../assets/sign_in_english.jpg';
import imgHeaderFrench from '../../assets/sign_in_french.jpg';
import renderTextFieldBlack from '../fields/renderTextFieldBlack';
import { CONNEXION, COURRIEL, INSCRIPTION, MOTDEPASSE, MOTDEPASSEOUBLIE, PASDECOMPTE, RECUPERERCOMPTE, FERMER, EMAILHASBEENSENT, TO, EMAILSENT2, SENDAGAINEXPLICIT } from '../../constant/stringTraduction';
import userContainer from '../../containers/user/userContainer';
import SuccessableButton from '../buttons/SuccessableButton';
import './user.css';
import { resendEmail } from '../../actions/user';
import { validateEmail } from '../../utils/other';
import { Button } from '@material-ui/core';

const validate = (values) => {
    const errors = {}
    const requiredFields = ['email', 'password']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = '*';
        }
    })
    if (values.email && !validateEmail(values.email)) {
        errors.email = 'example : abcdef@ghijkl.com';
    }
    return errors
}

class Signin extends Component {
    state = {
        open: false,
        message: null,
        modal: false,
        toggle: false,
        email: '',
    }
    handleSignin = (values) => {
        const { dispatch, history } = this.props
        dispatch({ type: 'RESET_CONFIRMATION' })
        this.setState({ email: values.email });
        return dispatch(fetchSignin({ history, values }))
    }
    resetError = () => {
        this.props.dispatch(resetError());
    }
    sendEmail = () => {
        const { dispatch, indexLang } = this.props
        dispatch({ type: 'RESET_CONFIRMATION' })
        this.setState({ modal: true });
        dispatch(resendEmail(this.state.email, indexLang));
    }
    toggle = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }));
    }
    
    render() {
        const {
            handleSubmit,
            invalid,
            pristine,
            submitSucceeded,
            submitting,
            user,
            indexLang,
        } = this.props
        
        return (
            <div>
                <Container className="white-background container-top-margin" style={{ paddingTop: 95 }}>
                    <div className='background-image-header' style={{ backgroundImage: `url(${indexLang ? imgHeaderFrench : imgHeaderEnglish})` }}></div>
                    <form onSubmit={handleSubmit(this.handleSignin)}>
                        <Field name="email" color='black' component={renderTextFieldBlack} label={COURRIEL[indexLang]} fullWidth={true} autoComplete="username" />
                        <Field name="password" color='black' component={renderTextFieldBlack} label={MOTDEPASSE[indexLang]} fullWidth={true} type="password" autoComplete="current-password" />
                        <SuccessableButton
                            style={ !user.notConfirmed ? { width: '100%', marginTop:'10px' } : { width: '70%', marginTop: '10px' }}
                            disabled={pristine || invalid}
                            error={user.error}
                            label={CONNEXION[indexLang]}
                            reset={this.resetError}
                            submitSucceeded={submitSucceeded}
                            submitting={submitting}
                        />
                        {user.notConfirmed === true && <Button onClick={this.sendEmail} style={{ marginTop:'10px', width:'30%' }}>{SENDAGAINEXPLICIT[indexLang]}</Button>}
                    </form>
                    <div className="card-actions">
                        <p>{PASDECOMPTE[indexLang]} <Link to="/user/signup">{INSCRIPTION[indexLang]}</Link></p>
                        <p>{MOTDEPASSEOUBLIE[indexLang]} <Link to="/user/recovery">{RECUPERERCOMPTE[indexLang]}</Link></p>
                    </div>
                </Container>
                <Modal className="modal-lg confirmation-modal" isOpen={this.state.modal} backdrop={true} toggle={this.toggle} style={{ backgroundColor:'white', marginTop:100 }}>
                    <ModalHeader style={{ textAlign: 'center' }}>{EMAILHASBEENSENT[indexLang]}</ModalHeader>
                    <ModalBody>{EMAILSENT2[indexLang]} {TO[indexLang]} {this.state.email}</ModalBody>
                    <ModalFooter>
                        <Button onClick={this.toggle} style={{ margin:'0px auto' }} >{FERMER[indexLang]}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default userContainer(reduxForm({
    form: 'signin',
    validate,
})(withRouter(Signin)))
