import React from 'react';
import history from '../../containers/routers/history';
import { Button } from 'reactstrap';
import { SEEMYORDERS } from '../../constant/stringTraduction';

const GoToOrderButton = ({
    dispatch,
    indexLang,
}) => {
    return (
        <Button onClick={() => {
            dispatch({ type: 'SWITCH_TAB', indexTab: '3' });
            history.push('/user/profile');
        }} className="yellow-button" size="sm" style={{ marginTop: 10, fontSize: 16, fontWeight: 'bold', border: 'none' }} block>{SEEMYORDERS[indexLang]}
        </Button>
    );
}

export default GoToOrderButton
